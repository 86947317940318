import React, { FunctionComponent } from 'react';
import { messages, SupportedLanguage, defaultLanguage } from '../../config';
import { IntlProvider } from 'react-intl';
import { Translation } from '../../translations/types';
import { useSyncedState } from '../../synced-state/synced-state';

export type IntlMessage = {
  language: SupportedLanguage;
  messages: Translation;
};

const provide = (language: SupportedLanguage): IntlMessage => {
  return {
    language,
    messages: messages[language] || messages[defaultLanguage],
  };
};

const MessagesProvider: FunctionComponent<any> = ({ children }) => {
  const [language] = useSyncedState('language');
  const intlMessage = provide(language);

  return (
    <IntlProvider locale={language} messages={intlMessage.messages}>
      {children}
    </IntlProvider>
  );
};

export default MessagesProvider;
