import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import 'firebase/compat/remote-config';
import { StoryboardConfig } from '../features/dashboard/types';
import { firebaseConfig } from './firebaseConfig';
import { RemoteConfigFeatureToggles } from './hooks/useFeatureToggle';
import { IntegrationsTypes } from '../sets/integration/types';

export type RemoteConfigPublishRules = {
  is_supported: boolean;
  min_aspect_ratio: string;
  max_aspect_ratio: string;
  min_media_count: number;
  max_media_count: number;
  max_media_file_size_in_mb: number;
  allowed_image_file_types: string[];
  max_content_length: number;
  max_hashtags: number;
  max_mentions: number;
  max_video_length: number;
  min_video_length: number;
};

export type RemoteConfig = {
  storyboard_types_v3: StoryboardConfig[];
  is_new_space_grid_enabled: boolean;
  auto_publish_config: Partial<
    Record<IntegrationsTypes, RemoteConfigPublishRules>
  >;
  web_feature_toggles: Partial<Record<RemoteConfigFeatureToggles, any>>;
};

export type Snapshot = firebase.firestore.QuerySnapshot<
  firebase.firestore.DocumentData
>;

export type SnapshotHandler = (snapshot: Snapshot | Snapshot[]) => void;

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();

let remoteConfigValuesPromise: Promise<RemoteConfig> | null = null;
export const getRemoteConfigValues = () => {
  if (remoteConfigValuesPromise) {
    return remoteConfigValuesPromise;
  }

  const parseConfigValue = (value: firebase.remoteConfig.Value) => {
    const stringValue = value.asString();
    try {
      return JSON.parse(stringValue);
    } catch (e) {
      return stringValue;
    }
  };

  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
    fetchTimeoutMillis: 3600000,
  };

  remoteConfigValuesPromise = remoteConfig.fetchAndActivate().then(() => {
    const config = remoteConfig.getAll();
    const processedConfig = Object.entries(config).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: parseConfigValue(value),
      }),
      {},
    );
    return processedConfig as RemoteConfig;
  });
  return remoteConfigValuesPromise;
};

export type Messaging = firebase.messaging.Messaging;
export const withMessaging = <T>(wrapped: (messaging: Messaging) => T) => {
  let messaging;
  try {
    messaging = firebase.messaging();
  } catch (e) {
    // ignore browsers that do not support webpush
  }

  if (messaging != null) {
    return wrapped(messaging);
  }
};
