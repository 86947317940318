import { HttpClient, ApiResponse } from '../../../HttpClient';
import {
  InstagramGraphFields,
  InstagramGraphResponse,
} from '../../dashboard/types';
import { AxiosResponse } from 'axios';
import { SpacePaths, IntegrationPaths } from '../../../urls';
import {
  FacebookPageInformationResponse,
  FacebookPersonalProfileInformationResponse,
  FacebookGroupsResponse,
  ValidateFacebookAccessTokenResponse,
} from '../types';

const integrationFields: InstagramGraphFields = {
  id: '',
  thumbnail_url: '',
  media_url: '',
  caption: '',
};

export class ExternalIntegrationClient extends HttpClient {
  async getPhotosFromInstagram(
    accessToken: string,
  ): Promise<AxiosResponse<InstagramGraphResponse>> {
    return await this.get(
      SpacePaths.getPhotosFromInstagram(accessToken, integrationFields),
      {
        baseURL: process.env.REACT_APP_INSTAGRAM_API_URL,
      },
    );
  }

  async getPhotosFromFacebook(
    accountId: string,
    accessToken: string,
  ): Promise<AxiosResponse<InstagramGraphResponse>> {
    return await this.get(
      SpacePaths.getPhotosFromInstagram(
        accessToken,
        integrationFields,
        accountId,
      ),
      {
        baseURL: process.env.REACT_APP_FACEBOOK_API_URL,
      },
    );
  }

  async getInstagramBusinessAccounts(accessToken: string) {
    const result = await this.get<
      AxiosResponse<ApiResponse<FacebookPageInformationResponse[]>>
    >(IntegrationPaths.getInstagramBusinessAccountsUrl(accessToken), {
      baseURL: process.env.REACT_APP_FACEBOOK_V9_API_URL,
    });
    return result.data.data;
  }

  async getFacebookPages(accessToken: string) {
    const result = await this.get<
      AxiosResponse<ApiResponse<FacebookPageInformationResponse[]>>
    >(IntegrationPaths.getFacebookPagesUrl(accessToken), {
      baseURL: process.env.REACT_APP_FACEBOOK_V9_API_URL,
    });
    return result.data.data;
  }

  async getFacebookPersonalProfile(accessToken: string) {
    const result = await this.get<
      AxiosResponse<FacebookPersonalProfileInformationResponse>
    >(IntegrationPaths.getFacebookPersonalProfileUrl(accessToken), {
      baseURL: process.env.REACT_APP_FACEBOOK_V9_API_URL,
    });
    return result.data;
  }

  async getFacebookGroups(accessToken: string) {
    const result = await this.get<AxiosResponse<FacebookGroupsResponse>>(
      IntegrationPaths.getFacebookGroupsUrl(accessToken),
      {
        baseURL: process.env.REACT_APP_FACEBOOK_V9_API_URL,
      },
    );
    return result.data;
  }

  async getPhotosPage(
    url: string,
  ): Promise<AxiosResponse<InstagramGraphResponse>> {
    return this.get(url);
  }

  async validateFacebookAccessToken(
    accessToken: string,
  ): Promise<ValidateFacebookAccessTokenResponse> {
    const response = await this.get<
      AxiosResponse<ApiResponse<ValidateFacebookAccessTokenResponse>>
    >(IntegrationPaths.getValidateFacebookTokenUrl(accessToken));
    return response.data.data;
  }
}
