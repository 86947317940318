import React, { FunctionComponent, useState } from 'react';
import {
  BillingDetails,
  countTeamAcceptedMembersSelector,
  paywallOpenedAtom,
  SnackbarTypes,
  teamTrialSelector,
} from '../../../../state';
import {
  PaywallTileBillingPlan,
  SelectedBillingPlan,
} from '../../../payment/components/PaywallTile/PaywallTile';
import { DependencyContainer } from '../../../../DependencyContainer';
import Paywall from '../../../payment/components/Paywall/Paywall';
import { useSnackbar } from '../../../../utils/hooks/useSnackbar';
import { useIntl } from '../../../../utils/hooks/useIntl';
import { useSyncedState } from '../../../../synced-state/synced-state';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useGoToRoute } from '../../../../utils/hooks/useGoToRoute';
import { Routes } from '../../../../config';
import { CheckoutSessionModes } from '../../../payment/PaymentClient';
import ProrationModal from './ProrationModal';
import { useTeams } from '../../../../utils/hooks/useTeams';
import { StripeSubscriptionStatus } from '../../../payment/types';

export type TeamPaywallProps = {
  teamId: string;
  billingDetails: BillingDetails | null;
  isLoading: boolean;
  forceLoading?: boolean;
};

type ProrationModalData = {
  newPlan: SelectedBillingPlan;
  prorationPrice: string;
  regularPrice: string;
  onAcceptProration: () => void;
  onRequestClose: () => void;
};

const { teamService, paymentService } = new DependencyContainer();

const TeamPaywall: FunctionComponent<TeamPaywallProps> = ({
  billingDetails,
  teamId,
  isLoading,
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const [prorationModalOpened, setProrationModalOpened] = useState(false);
  const [
    prorationModalData,
    setProrationModalData,
  ] = useState<ProrationModalData | null>(null);
  const [, setPaymentCache] = useSyncedState('paymentCache');
  const teamTrial = useRecoilValue(teamTrialSelector);
  const { setSnackbar } = useSnackbar();
  const goToRoute = useGoToRoute();
  const countTeamAcceptedMembers = useRecoilValue<number>(
    countTeamAcceptedMembersSelector,
  );
  const [countTeamIntegrations, setCountTeamIntegrations] = useState<number>();
  const currentPlanName = billingDetails ? billingDetails.plan : '';
  const setPaywallOpened = useSetRecoilState(paywallOpenedAtom);
  const { fetchTeams } = useTeams(false);

  const loadPlans = async (): Promise<PaywallTileBillingPlan[]> => {
    const isSubscriptionActive =
      billingDetails?.status === StripeSubscriptionStatus.Active;
    const currentPlanName = billingDetails?.plan || '';

    const [plans, teamIntegrations] = await Promise.all([
      paymentService.getGroupBillingPlan(
        isSubscriptionActive ? currentPlanName : '',
      ),
      teamService.countTeamIntegrations(teamId),
    ]);

    setCountTeamIntegrations(teamIntegrations);

    return plans;
  };

  const updateBillingPlan = async (priceId: string) => {
    await paymentService.changeGroupBillingPlan(teamId, priceId);
    setPaywallOpened(false);
    await setTimeout(
      () =>
        goToRoute(Routes.Team, {
          teamId,
          tab: 'membership',
          param: 'success',
        }),
      200,
    );
  };

  const onSelectPlan = async (plan: SelectedBillingPlan) => {
    const priceId = plan.price.id || '';
    setPaymentCache({
      relation: 'team',
      plan: plan.name,
      type: plan.type,
      mode: CheckoutSessionModes.Subscription,
    });

    const isSubscriptionActive =
      billingDetails?.status === StripeSubscriptionStatus.Active;

    // no need to use stripe's checkout
    if (!teamTrial.isTrial && isSubscriptionActive) {
      try {
        // In order to not introduce any backend changes we need to uncancel
        // subscription on every change. This is workaround until we move all
        // subscription logic to the backend.
        await paymentService.uncancelTeamSubscription(teamId);

        if (plan.type === 'upgrade') {
          const prorationAmount = await paymentService.getProration(
            teamId,
            priceId,
          );
          const promiseToNotResolveUntilProrationModalClosed = new Promise<
            void
          >((resolve, reject) => {
            setProrationModalData({
              newPlan: plan,
              prorationPrice: formatNumber(Number(prorationAmount) / 100, {
                style: 'currency',
                currency: 'usd',
              }),
              regularPrice: formatNumber(plan.price.value, {
                style: 'currency',
                currency: plan.price.currency,
              }),
              onAcceptProration: async () => {
                setProrationModalOpened(false);
                await updateBillingPlan(priceId)
                  .then(() => resolve())
                  .catch(() => reject());
              },
              onRequestClose: () => {
                setProrationModalData(null);
                setProrationModalOpened(false);
                resolve();
              },
            });
            setProrationModalOpened(true);
          });

          await promiseToNotResolveUntilProrationModalClosed;
        } else {
          await updateBillingPlan(priceId);
        }
        fetchTeams(true);
      } catch (error) {
        setSnackbar(
          formatMessage({ id: 'errorsSomethingWentWrong' }),
          SnackbarTypes.Error,
        );
      }
      return;
    }
    try {
      const session = await paymentService.getTeamSubscriptionCheckoutSession(
        teamId,
        priceId,
      );
      await paymentService.goToCheckout(session);
    } catch (error) {
      setSnackbar(
        formatMessage({ id: 'errorsSomethingWentWrong' }),
        SnackbarTypes.Error,
      );
    }
  };

  const checkBillingPlanExceeded = (item: PaywallTileBillingPlan) => {
    if (item.limits === undefined || item.title === 'Team Premium') {
      return false;
    }

    return (
      countTeamAcceptedMembers > item.limits.members ||
      countTeamIntegrations! > item.limits.integrations
    );
  };

  return (
    <>
      <Paywall
        title={formatMessage({ id: 'teamTeamMembershipPayWallTitle' })}
        subtitle={formatMessage({ id: 'teamTeamMembershipPayWallSubtitle' })}
        onSelectPlan={onSelectPlan}
        checkBillingPlanExceeded={checkBillingPlanExceeded}
        loadPlans={loadPlans}
        preventLoadingPlans={isLoading}
      />
      {prorationModalData && (
        <ProrationModal
          isOpen={prorationModalOpened}
          onRequestClose={prorationModalData.onRequestClose}
          currentPlanName={currentPlanName}
          newPlan={prorationModalData.newPlan}
          prorationPrice={prorationModalData.prorationPrice}
          regularPrice={prorationModalData.regularPrice}
          onAcceptProration={prorationModalData.onAcceptProration}
        />
      )}
    </>
  );
};

export default TeamPaywall;
