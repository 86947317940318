import { chunk } from './helpers';
import firebase from 'firebase/compat/app';

const maxInOperatorArrayLength = 10;

export async function firestoreBatchedInQuery(
  query: firebase.firestore.Query<firebase.firestore.DocumentData>,
  field: string | firebase.firestore.FieldPath,
  inArray: string[],
) {
  const chunks = chunk(inArray, maxInOperatorArrayLength);
  const chunkedQueries = chunks.map((chunk) =>
    query.where(field, 'in', chunk).get(),
  );

  const results = await Promise.all(chunkedQueries);

  return results
    .flat()
    .map((result) => result.docs)
    .flat();
}
