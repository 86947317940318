import { ActivityType, TrackingEvent, EventChannel } from './activityTracker';

export const activityTypeSegmentSeparator = '.';

export const buildEventName = (type: ActivityType, separator: string): string =>
  type.split(activityTypeSegmentSeparator).join(separator);

export const isEventOnChannel = (
  event: TrackingEvent,
  channelType: EventChannel,
) => {
  if (event.channel === EventChannel.All) {
    return true;
  }

  return Array.isArray(event.channel)
    ? event.channel.includes(channelType)
    : event.channel === channelType;
};
