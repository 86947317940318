import { HttpClient, ApiResponse } from '../../../HttpClient';
import { AxiosResponse } from 'axios';
import { DefaultDashboard } from '../types';
import { InsightsPath } from '../../../urls';
import { DateRange } from '../../../components/DateRangePicker/DateRangePicker';

export class InsightsClient extends HttpClient {
  async getDefaultDashboard(
    integrationId: string,
    dateRange: DateRange,
  ): Promise<AxiosResponse<ApiResponse<DefaultDashboard>>> {
    // @TODO: Add default fallback
    const params = {
      from: dateRange.start.toISOString(),
      to: dateRange.end.toISOString(),
    };
    return await this.get(InsightsPath.getDefaultDashboard(integrationId), {
      params,
    });
  }
}
