import { DependencyContainer } from '../../../DependencyContainer';
import { integrationsConfig } from '../../../sets/integration/integrations.config';
import {
  IntegrationsTypes,
  SocialAccount,
  SocialAccountTypes,
} from '../../../sets/integration/types';
import { ResourceOwner } from '../../../state';
import { SocialAccountToCreate, IntegrationErrorCodes } from '../types';
import {
  CreateCodeFlowIntegrationRequest,
  CreateImplicitFlowIntegrationRequest,
  UpdateCodeFlowIntegrationRequest,
} from './IntegrationV3Client';
import { SpaceIntegration } from '../../dashboard/types';
import moment from 'moment';

export type UpdateOAuthSocialAccountDetails = {
  socialAccountId: string;
  code: string;
  integrationType: IntegrationsTypes;
};

type SpaceIntegrationResponseWithError = Partial<{
  spaceIntegration: SpaceIntegration;
  error: IntegrationErrorCodes;
}>;

export class IntegrationService {
  constructor(private readonly factory: DependencyContainer) {}

  async createImplicitFlowIntegration(
    socialAccount: SocialAccountToCreate,
  ): Promise<SocialAccount> {
    const response = await this.factory.integrationV3Client.createIntegration(
      socialAccount as CreateImplicitFlowIntegrationRequest,
    );

    return response.data.data;
  }

  async createCodeFlowIntegration(
    owner: ResourceOwner,
    integrationType: IntegrationsTypes,
    code: string,
  ): Promise<SocialAccount> {
    const response = await this.factory.integrationV3Client.createIntegration(
      this.makeCreateCodeFlowIntegrationRequest(integrationType, code, owner),
    );

    return response.data.data;
  }

  async updateImplicitFlowIntegration(
    integrationId: string,
    socialAccount: SocialAccount,
  ): Promise<SocialAccount> {
    const response = await this.factory.integrationV3Client.updateIntegration(
      integrationId,
      socialAccount,
    );

    return response.data.data;
  }

  async updateCodeFlowIntegration(
    integrationId: string,
    integrationType: IntegrationsTypes,
    code: string,
  ): Promise<SocialAccount> {
    const response = await this.factory.integrationV3Client.updateIntegration(
      integrationId,
      this.makeUpdateCodeFlowIntegrationRequest(integrationType, code),
    );

    return response.data.data;
  }

  async withSpaceIntegration(
    spaceId: string,
    integration: SocialAccount,
  ): Promise<SocialAccount> {
    await this.factory.integrationV3Client.updateSpaceIntegration(
      spaceId,
      integration._id,
    );

    return integration;
  }

  async getSpaceIntegration(
    spaceId: string,
  ): Promise<SpaceIntegrationResponseWithError> {
    try {
      const response = await this.factory.integrationV3Client.getSpaceIntegration(
        spaceId,
      );

      const { data } = response.data;

      if (data.integration == null) {
        return {
          spaceIntegration: data,
          error: IntegrationErrorCodes.NotFound,
        };
      }

      // @TODO: do not rely on expirationDate to decide if token is expired
      const isExpired =
        moment().diff(
          moment.unix(Number.parseInt(data.integration.expirationDate)),
        ) > 0;
      if (isExpired) {
        return {
          spaceIntegration: data,
          error: IntegrationErrorCodes.TokenExpired,
        };
      }

      return {
        spaceIntegration: data,
      };
    } catch (error) {
      if (error.response?.status === 404) {
        return {
          error: IntegrationErrorCodes.NotFound,
        };
      }

      return {
        error: IntegrationErrorCodes.Unknown,
      };
    }
  }

  async getSocialAccounts(
    resourceOwner: ResourceOwner,
  ): Promise<SocialAccount[]> {
    const response = await this.factory.integrationV3Client.getIntegrations({
      ...resourceOwner,
    });

    return response.data.data;
  }

  async getSocialAccountsForGroup(groupId: string): Promise<SocialAccount[]> {
    const response = await this.factory.integrationV3Client.getIntegrations({
      groupId,
    });

    return response.data.data;
  }

  async getPersonalSocialAccounts(userId: string): Promise<SocialAccount[]> {
    const response = await this.factory.integrationV3Client.getIntegrations({
      userId,
    });

    return response.data.data;
  }

  async deleteIntegration(integrationId: string): Promise<void> {
    return this.factory.integrationV3Client.deleteIntegration(integrationId);
  }

  private makeCreateCodeFlowIntegrationRequest(
    integrationType: IntegrationsTypes,
    code: string,
    owner: ResourceOwner,
  ): CreateCodeFlowIntegrationRequest {
    return this.makeCodeFlowIntegrationRequest(
      integrationType,
      code,
      owner,
    ) as CreateCodeFlowIntegrationRequest;
  }

  private makeUpdateCodeFlowIntegrationRequest(
    integrationType: IntegrationsTypes,
    code: string,
  ): UpdateCodeFlowIntegrationRequest {
    return this.makeCodeFlowIntegrationRequest(
      integrationType,
      code,
    ) as UpdateCodeFlowIntegrationRequest;
  }

  private makeCodeFlowIntegrationRequest(
    integrationType: IntegrationsTypes,
    code: string,
    owner: ResourceOwner | {} = {},
  ): CreateCodeFlowIntegrationRequest | UpdateCodeFlowIntegrationRequest {
    const { redirectUri } = integrationsConfig[integrationType];
    if (integrationType === 'instagram:basic_display') {
      return {
        ...owner,
        type: SocialAccountTypes.Instagram,
        code,
        redirectUri,
        properties: {
          tokenType: 'basic_display',
        },
      };
    }

    if (integrationType === 'linkedin') {
      return {
        ...owner,
        type: SocialAccountTypes.Linkedin,
        code,
        redirectUri,
      };
    }

    if (integrationType === 'pinterest') {
      return {
        ...owner,
        type: SocialAccountTypes.Pinterest,
        code,
        redirectUri,
      };
    }

    throw new Error('Unsupported code flow integration type');
  }
}
