import React, { FunctionComponent } from 'react';
import Button, {
  ButtonSize,
  ButtonVariant,
} from '../../../components/Button/Button';
import { PremiumIcon } from '../../../components/Icons/Icons';
import {
  useWebviewMessageDispatcher,
  WebviewMessages,
} from '../../../WebviewMessageDispatcher';
import QuoteCard from './QuoteCard/QuoteCard';
import FeatureCard from './FeatureCard/FeatureCard';
import FeatureSection from './FeatureSection/FeatureSection';

type FreeMemberViewProps = {
  productData: [];
  featureData: [];
  socialProofData: [];
};

const FreeMemberView: FunctionComponent<FreeMemberViewProps> = ({
  productData = [],
  featureData = [],
  socialProofData = [],
}) => {
  const { dispatch } = useWebviewMessageDispatcher();

  const startPurchaseOnClick = () => {
    dispatch({
      name: WebviewMessages.ShowPaywall,
      payload: {},
    });
  };

  return (
    <div className="FreeMemberView">
      <div className="header">
        <div className="product-icon">
          {productData.map((product) => product.icon)}
        </div>
        <h1>Level up your brand</h1>
        <p>All the tools you need, one place.</p>
        <a
          style={{ color: 'white' }}
          href="https://unum28lo0-alternate.test-app.link/?action=viewPaywall"
        >
          alksjdfal;skjdflasjkdf
        </a>
      </div>

      <div className="FreeMemberView__feature-container">
        {featureData.map((data) => (
          <FeatureCard id={data.id} title={data.title} />
        ))}
      </div>

      {productData.map((product) => {
        return (
          <FeatureSection
            title={product.title}
            icon={product.icon}
            description={product.description}
            iconName={product.toolName}
            graphic={product.graphic}
          />
        );
      })}

      <img
        className="Featured-featured-img"
        src="https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/featured-app.png"
      />

      {socialProofData.map((data) => {
        return (
          <QuoteCard
            quote={data.quote}
            quoteCardUser={{
              userAvatarUrl: data.user.avatarUrl,
              userName: data.user.title,
              userDescription: data.user.description,
            }}
          />
        );
      })}

      <div className="FixedButtonContainer">
        <Button
          icon={<PremiumIcon />}
          onClick={startPurchaseOnClick}
          size={ButtonSize.Large}
          variant={ButtonVariant.Secondary}
          styles={(current) => ({
            ...current,
            root: `${current.root} StartTrialButton`,
          })}
        >
          Choose Your Plan
        </Button>
      </div>
    </div>
  );
};

export default FreeMemberView;
