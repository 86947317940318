import appboy from '@braze/web-sdk';
import { DependencyList, EffectCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { UserTelemetry, userTelemetrySelector } from '../../state';
import {
  activityTracker,
  EventChannel,
  TrackingEvent,
} from './activityTracker';
import { buildEventName, isEventOnChannel } from './eventUtils';

const brazeSegmentSeparator = '_';

const isInitialized = (() => {
  const key = process.env.REACT_APP_BRAZE_KEY;
  const baseUrl = process.env.REACT_APP_BRAZE_SDK_URL;
  if (key == null || baseUrl == null) {
    return false;
  }

  return appboy.initialize(key, { baseUrl });
})();

export const useBraze = () => {
  const userTelemetry = useRecoilValue(userTelemetrySelector);

  useEffectIfInitialized(() => {
    appboy.display.automaticallyShowNewInAppMessages();
  }, []);

  useEffectIfInitialized(() => {
    if (userTelemetry == null) {
      appboy.changeUser('');
      return;
    }

    appboy.changeUser(userTelemetry.id);

    for (const [key, value] of Object.entries(
      buildUserAttributes(userTelemetry),
    )) {
      appboy.getUser().setCustomUserAttribute(key, value);
    }
  }, [userTelemetry]);

  useEffectIfInitialized(() => {
    const trackerUnsubscribe = activityTracker.subscribe(
      (event: TrackingEvent) => {
        // braze processes events on engagement triggers channel
        if (!isEventOnChannel(event, EventChannel.EngagementTriggers)) {
          return;
        }

        appboy.logCustomEvent(
          buildEventName(event.type, brazeSegmentSeparator),
          event.metadata,
        );
      },
    );

    return trackerUnsubscribe;
  }, []);
};

const useEffectIfInitialized = (
  effect: EffectCallback,
  deps?: DependencyList,
) =>
  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    effect();
  }, deps);

const buildUserAttributes = (
  telemetry: UserTelemetry,
): { [key: string]: string | number | boolean | null } => ({
  membership_type: telemetry.userPlan,
  teams_membership: telemetry.isCurrentlyInTeam,
  team_membership_tier_type: telemetry.teamPlan,
  team_number: telemetry.teamCount,
});
