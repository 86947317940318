import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import imageCompression from 'browser-image-compression';
import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';
import Modal from '../../../../components/Modal/Modal';
import Button, {
  ButtonVariant,
  ButtonSize,
} from '../../../../components/Button/Button';
import { DependencyContainer } from '../../../../DependencyContainer';
import ModalBody, {
  ModalBodyStyles,
} from '../../../../components/Modal/ModalBody';
import ModalFooter, {
  ModalFooterStyles,
} from '../../../../components/Modal/ModalFooter';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import { SnackbarTypes } from '../../../../state';
import { useSnackbar } from '../../../../utils/hooks/useSnackbar';
import teamDefaultAvatar from '../../../../assets/svg/team-default-avatar.svg';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { useIntl } from '../../../../utils/hooks/useIntl';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { allowedImagesTypes } from '../../../../config';
import { fileToDataUrl } from '../../../publish/helpers';

const getImageDimensions = async (src: string): Promise<[number, number]> => {
  return new Promise((resolve, reject) => {
    let height: number = 0;
    let width: number = 0;
    const img = new Image();
    img.onload = () => {
      height = Number(img.naturalHeight);
      width = Number(img.naturalWidth);
      resolve([height, width]);
    };
    img.onerror = (e) => reject(e);
    img.src = src;
  });
};

const calculateZoomToFillSquare = (height: number, width: number): number => {
  if (height > width) {
    return height / width;
  }

  if (width > height) {
    return width / height;
  }

  return 1;
};

const dependencyContainer = new DependencyContainer();
const { uploadService, settingsService } = dependencyContainer;

const AvatarChanger: FunctionComponent = () => {
  const [currentAvatar, setCurrentAvatar] = useState(
    settingsService.getCachedSettings().avatarUrl || teamDefaultAvatar,
  );
  const [avatarCandidate, setAvatarCandidate] = useState('');
  const [modalOpened, setModalOpened] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
  });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const cropperRef = useRef<any>(null);
  const { setSnackbar, resetSnackbar } = useSnackbar();
  const intl = useIntl();
  const dropzone = useDropzone({
    accept: allowedImagesTypes,
    minSize: 1,
    preventDropOnDocument: true,
    noClick: true,
    onDrop: (files: File[]) => {
      processImageFile(files[0]);
    },
  });

  useEffect(() => {
    const getZoomForImage = async () => {
      try {
        const [height, width] = await getImageDimensions(avatarCandidate);
        setZoom(calculateZoomToFillSquare(height, width));
      } catch (e) {
        //catch silently
      }
    };
    getZoomForImage();
  }, [avatarCandidate]);

  useEffect(() => () => resetSnackbar(), []);

  const closeModal = () => {
    setModalOpened(false);
    if (!isUploading) {
      setAvatarCandidate('');
      setUploadProgress(0);
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      processImageFile(files[0]);
    }
  };

  const processImageFile = (file: File) => {
    return fileToDataUrl(file, (event) => {
      if (event && event.target) {
        setAvatarCandidate((event.target as any).result);
        setCrop({
          x: 0,
          y: 0,
        });
      }
    });
  };

  const handleCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    if (canvasRef.current) {
      canvasRef.current.width = croppedAreaPixels.width;
      canvasRef.current.height = croppedAreaPixels.height;
      const ctx = canvasRef.current.getContext('2d');
      ctx!.drawImage(
        cropperRef.current.imageRef,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
      );
    }
  };

  const handleUpdateImage = async () => {
    if (canvasRef.current) {
      try {
        setIsUploading(true);
        const blob = await makeBlobFile(canvasRef.current);
        const compressedImage = await imageCompression(blob, {
          maxWidthOrHeight: 120,
          useWebWorker: true,
        });
        const newAvatarUrl = await uploadService.uploadAvatar(
          compressedImage,
          (progress) => setUploadProgress(progress),
        );
        setIsUploading(false);
        setUploadProgress(0);

        setCurrentAvatar(newAvatarUrl);
        setAvatarCandidate('');
        setModalOpened(false);
        setSnackbar(intl.formatMessage({ id: 'userProfileSuccessfulUpdate' }));
      } catch (e) {
        setIsUploading(false);
        setModalOpened(false);
        setSnackbar(
          intl.formatMessage({ id: 'errorsSomethingWentWrong' }),
          SnackbarTypes.Error,
        );
      }
    }
  };

  const makeBlobFile = (canvas: HTMLCanvasElement): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        blob ? resolve(blob) : reject('Canvas is empty');
      });
    });
  };

  return (
    <>
      <button
        className="AvatarChanger"
        onClick={() => {
          setModalOpened(true);
        }}
      >
        <img
          src={currentAvatar}
          alt="User's profile"
          className="AvatarChanger__image"
        />
      </button>
      <Modal isOpen={modalOpened} onRequestClose={closeModal}>
        <ModalHeader title="Profile picture" />
        <ModalBody
          styles={(current: ModalBodyStyles) => ({
            ...current,
            root: `${current.root} AvatarChanger__modal-content`,
          })}
        >
          <div
            className="AvatarChanger__cropper-wrapper"
            {...dropzone.getRootProps()}
          >
            <Cropper
              image={avatarCandidate}
              ref={cropperRef}
              crop={crop}
              zoom={zoom}
              aspect={1}
              cropShape="round"
              showGrid={false}
              classes={{
                cropAreaClassName: 'AvatarChanger__crop-area',
              }}
              onCropChange={setCrop}
              onCropComplete={handleCropComplete}
            />
            <canvas
              id="cropped-canvas"
              ref={canvasRef}
              style={{ display: 'none' }}
            ></canvas>
            <input {...dropzone.getInputProps()} />
            {avatarCandidate === '' && (
              <div
                className={classNames(
                  'AvatarChanger__modal-dragndrop-indicator',
                  {
                    'AvatarChanger__modal-dragndrop-indicator--active':
                      dropzone.isDragActive,
                  },
                )}
              >
                <FormattedMessage id="userProfileDragAndDrop" />
              </div>
            )}
            {isUploading && (
              <div className="AvatarChanger__upload-progress">
                <ProgressBar completed={uploadProgress} />
              </div>
            )}
          </div>
          <div className="AvatarChanger__cropper-controls">
            <label
              htmlFor="fileUploader"
              className="AvatarChanger__modal-upload-button"
            >
              <input
                id="fileUploader"
                type="file"
                accept={allowedImagesTypes.join(', ')}
                style={{ display: 'none' }}
                onChange={handleImageChange}
              />
              <FormattedMessage id="userProfileReplaceImage" />
            </label>
          </div>
        </ModalBody>

        <ModalFooter
          styles={(current: ModalFooterStyles) => ({
            ...current,
            root: `${current.root} AvatarChanger__modal-footer`,
          })}
        >
          <Button
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Small}
            onClick={closeModal}
          >
            <FormattedMessage id="globalActionsCancel" />
          </Button>
          <Button
            size={ButtonSize.Small}
            styles={(current) => ({
              ...current,
              root: `${current.root} AvatarChanger__modal-update-button`,
            })}
            onClick={handleUpdateImage}
          >
            <FormattedMessage id="globalActionsUpdate" />
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AvatarChanger;
