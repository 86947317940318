import { AuthenticationService } from './features/login/AuthenticationService';
import { AuthenticationClient } from './features/login/AuthenticationClient';
import { SignupService } from './features/signup/SignupService';
import { SignupClient } from './features/signup/SignupClient';
import { SettingsService } from './features/user/SettingsService';
import { SettingsClient } from './features/user/SettingsClient';
import { UploadClient } from './features/UploadClient';
import { UploadService } from './features/UploadService';
import { TeamService } from './features/team/TeamService';
import { TeamClient } from './features/team/TeamClient';
import { ForgotPasswordService } from './features/forgot/ForgotPasswordService';
import { ForgotPasswordClient } from './features/forgot/ForgotPasswordClient';
import { PaymentService } from './features/payment/PaymentService';
import { PaymentClient } from './features/payment/PaymentClient';
import { MarketplaceService } from './features/marketplace/MarketplaceService';
import { MarketplaceClient } from './features/marketplace/MarketplaceClient';
import { PublicProfileService } from './features/publicProfile/PublicProfileService';
import { PublicProfileClient } from './features/publicProfile/PublicProfileClient';
import { SpaceService } from './features/dashboard/services/SpaceService';
import { RanksClient } from './features/dashboard/services/RanksClient';
import { SpaceClient } from './features/dashboard/services/SpaceClient';
import { IntegrationService } from './features/integration/services/IntegrationService';
import { CommentsService } from './features/dashboard/services/CommentsService';
import { NotificationClient } from './features/notifications/services/NotificationClient';
import { TodosService } from './features/todos/services/TodosService';
import { PublishService } from './features/publish/services/PublishService';
import { PublishClient } from './features/publish/services/PublishClient';
import { IntegrationV3Client } from './features/integration/services/IntegrationV3Client';
import { ExternalIntegrationClient } from './features/integration/services/ExternalIntegrationClient';
import { ExternalIntegrationService } from './features/integration/services/ExternalIntegrationService';
import { InsightsClient } from './features/insights/services/InsightsClient';
import { InsightsService } from './features/insights/services/InsightsService';
import { SiteClient } from './features/siteBuilder/services/SiteClient';
import { SitesService } from './features/siteBuilder/services/SiteService';
import { AnalyticsEventClient } from './features/siteAnalytics/services/AnalyticsEventClient';
import { AnalyticsEventService } from './features/siteAnalytics/services/AnalyticsEventService';

export class DependencyContainer {
  // Services
  authenticationService = new AuthenticationService(this);
  signupService = new SignupService(this);
  forgotPasswordService = new ForgotPasswordService(this);
  settingsService = new SettingsService(this);
  uploadService = new UploadService(this);
  teamService = new TeamService(this);
  paymentService = new PaymentService(this);
  marketplaceService = new MarketplaceService(this);
  publicProfileService = new PublicProfileService(this);
  spaceService = new SpaceService(this);
  integrationService = new IntegrationService(this);
  externalIntegrationService = new ExternalIntegrationService(this);
  commentsService = new CommentsService(this);
  todosService = new TodosService(this);
  publishService = new PublishService(this);
  insightsService = new InsightsService(this);
  siteService = new SitesService(this);
  analyticsEventService = new AnalyticsEventService(this);

  // Clients
  authenticationClient = new AuthenticationClient(this);
  signupClient = new SignupClient(this);
  forgotPasswordClient = new ForgotPasswordClient(this);
  settingsClient = new SettingsClient(this);
  uploadClient = new UploadClient(this);
  teamClient = new TeamClient(this);
  paymentClient = new PaymentClient(this);
  marketplaceClient = new MarketplaceClient(this);
  publicProfileClient = new PublicProfileClient(this);
  ranksClient = new RanksClient(this);
  spaceClient = new SpaceClient(this);
  integrationV3Client = new IntegrationV3Client(this);
  externalIntegrationClient = new ExternalIntegrationClient(this);
  notificationClient = new NotificationClient(this);
  publishClient = new PublishClient(this);
  insightsClient = new InsightsClient(this);
  siteClient = new SiteClient(this);
  analyticsEventClient = new AnalyticsEventClient(this);
}
