import { User } from './UserEntity';
import { DependencyContainer } from '../../DependencyContainer';
import { syncedState } from '../../synced-state/synced-state';

export interface AuthenticationServiceProtocol {
  signIn(email: string, password: string): Promise<User>;
  signOut(): void;
  getAuthToken(): Promise<string>;
  isAuthenticated(): Promise<boolean>;
}

export type AuthenticationJwtToken = {
  email: string;
  authToken: string;
  exp: number;
  iat: number;
};

export class AuthenticationService implements AuthenticationServiceProtocol {
  constructor(private readonly factory: DependencyContainer) {}

  async signIn(email: string, password: string): Promise<User> {
    const user = await this.factory.authenticationClient.makeSignIn(
      email,
      password,
    );
    this.cacheUser(user);
    return user;
  }

  async getUserByToken(token: string): Promise<User | null> {
    try {
      const {
        data: { data },
      } = await this.factory.authenticationClient.verifyJWT(token);
      const user = await this.factory.authenticationClient.getUserByEmail(
        data.email,
        {
          headers: {
            Authorization: data.authToken,
          },
        },
      );
      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  signOut(): Promise<void> {
    syncedState.remove('user');

    // we need to wait for user to be updated in synced state
    // synced state does not have a complete callback, hence the hack
    return new Promise((resolve) => setTimeout(resolve, 0));
  }

  cacheUser(user: User) {
    syncedState.set('user', user);
    syncedState.set('activeAccountId', user._id);
  }

  async getCachedUser(): Promise<User> {
    const value = syncedState.get('user');
    if (!value) {
      throw new Error('User does not exist');
    }
    return value;
  }

  async getAuthToken(): Promise<string> {
    try {
      const user = await this.getCachedUser();
      return user.authToken as string;
    } catch (e) {
      return '';
    }
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const user = await this.getCachedUser();
      return user !== null;
    } catch (error) {
      return false;
    }
  }

  async getAuthFromJWT(oauthToken: string): Promise<User> {
    const userData = await this.factory.authenticationClient.getAuthFromJWT(
      oauthToken
    );
    const user = userData.data.data
    this.cacheUser(user);
    return user;
  }
}
