import { HttpClient, ApiResponse } from '../HttpClient';
import { UploadPaths } from '../urls';

type UploadDestination = {
  type: string;
  size: number;
  url: string;
  thumbnailUrl: string;
  key: string;
  subpath?: string;
  meta: {
    uploadUrl: string;
    policy: {
      key: string;
      AWSAccessKeyId: string;
      acl: string;
      policy: string;
      signature: string;
      'Content-Type': string;
    };
  };
  acl: string;
};

type CreateUploadDestinationResponse = ApiResponse<{
  _id: '1593699687251_unum';
  createdDate: '2020-07-02T14:21:27.251Z';
  files: UploadDestination[];
}>;

type UploadPath = typeof UploadPaths[keyof typeof UploadPaths];

const appendFormFields = (
  formData: FormData,
  fields: { [key: string]: string | Blob },
) => {
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
};

export interface UploadClientProtocol {
  createUploadDestination(
    endpoint: UploadPath,
    payload: {
      size: number;
      type: string;
      subPath?: 'avatar';
    },
  ): Promise<UploadDestination>;
  uploadFile(
    file: Blob,
    uploadDestination: UploadDestination,
    onUploadProgress?: UploadProgressHandler,
  ): void;
}

export type UploadProgressHandler = (percentage: number) => void;

export class UploadClient extends HttpClient implements UploadClientProtocol {
  async createUploadDestination(
    endpoint: UploadPath,
    payload: {
      size: number;
      type: string;
      subPath?: 'avatar';
    },
  ): Promise<UploadDestination> {
    try {
      const result = await this.post<CreateUploadDestinationResponse>(
        endpoint,
        payload,
      );
      return Promise.resolve(result.data.files[0]);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async uploadFile(
    file: Blob,
    uploadDestination: UploadDestination,
    onUploadProgress?: UploadProgressHandler,
  ) {
    const formData = new FormData();
    appendFormFields(formData, {
      key: uploadDestination.meta.policy.key,
      AWSAccessKeyId: uploadDestination.meta.policy.AWSAccessKeyId,
      acl: uploadDestination.meta.policy.acl,
      policy: uploadDestination.meta.policy.policy,
      signature: uploadDestination.meta.policy.signature,
      'Content-Type': uploadDestination.meta.policy['Content-Type'],
      file,
    });
    return this.post(uploadDestination.meta.uploadUrl, formData, {
      headers: {},
      timeout: 60 * 1000,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        onUploadProgress && onUploadProgress(percentage);
      },
    });
  }
}
