import { HttpClient, ApiResponse } from '../../../HttpClient';
import { AxiosResponse } from 'axios';
import { PublishPath } from '../../../urls';
import {
  ArchivedMediaResponse,
  PublishScheduleResponse,
  SchedulePostDto,
  SchedulePostResponse,
} from '../types';
import { Pagination } from '../../../types';
import { Moment } from 'moment';
import { XOR } from '../../../utils/types';

export type ScheduledPostParams = Partial<{
  limit: number;
  cursor: string;
  integrationIds: string[];
}>;

export type PagedPostsResponse = ApiResponse<{
  result: PublishScheduleResponse[];
  cursor: string | null;
}>;

export class PublishClient extends HttpClient {
  async getScheduledPosts(
    groupId: string,
    params: ScheduledPostParams = {},
  ): Promise<AxiosResponse<PagedPostsResponse>> {
    const paramsWithDefaults = { limit: 'disabled', ...params };
    const queryParams = new URLSearchParams();
    queryParams.append('groupId', groupId);

    for (const [key, value] of Object.entries(paramsWithDefaults)) {
      if (value && value.toString() !== '') {
        queryParams.append(key, value.toString());
      }
    }

    return this.get(`${PublishPath.scheduledPosts}?${queryParams.toString()}`);
  }

  async schedulePost<T extends SchedulePostDto>(
    schedulePostDto: T,
    config?: {
      callApiV1?: boolean;
    },
  ): Promise<AxiosResponse<ApiResponse<SchedulePostResponse>>> {
    const callApiV1 = config?.callApiV1 || false;

    return this.post(
      callApiV1 ? PublishPath.publishListV1 : PublishPath.publishListV2,
      schedulePostDto,
    );
  }
  async facebookInstantPublish<
    T extends Omit<SchedulePostDto, 'scheduledDate'>
  >(
    schedulePostDto: T,
  ): Promise<AxiosResponse<ApiResponse<SchedulePostResponse>>> {
    return this.post(PublishPath.facebookInstantPublish, schedulePostDto);
  }

  async getArchivedMedia(
    groupId: string,
    pagination: Pagination,
  ): Promise<AxiosResponse<ApiResponse<ArchivedMediaResponse>>> {
    const skip = pagination.page * pagination.limit;
    return await this.get(
      PublishPath.readAssetsUrl(groupId, pagination.limit, skip),
    );
  }

  async deleteScheduledPost(postId: string): Promise<AxiosResponse<void>> {
    return this.delete(PublishPath.getScheduledPost(postId));
  }

  async reschedulePost(
    postId: string,
    parent: XOR<{ spaceId: string }, { groupId: string }>,
    newDate: Moment | 'now',
  ): Promise<AxiosResponse<void>> {
    const params = new URLSearchParams();

    if (parent.spaceId != null) {
      params.set('spaceId', parent.spaceId);
    } else {
      params.set('groupId', parent.groupId);
    }

    return this.patch(
      `${PublishPath.publishItemV2(postId)}?${params.toString()}`,
      {
        scheduledDate: newDate === 'now' ? 'now' : newDate.toISOString(),
      },
    );
  }

  async unscheduleScheduledPost(body: {
    itemId: string;
    groupId: string;
    spaceId?: string;
  }): Promise<AxiosResponse<void>> {
    return this.delete(PublishPath.publishListV2, body);
  }
}
