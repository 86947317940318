import React from 'react';
import { FunctionComponent } from 'react';

type QuoteCardUser = {
  userAvatarUrl: string;
  userName: string;
  userDescription: string;
};
type QuoteCardProps = {
  quote: string;
  quoteCardUser: QuoteCardUser;
};
const QuoteCard: FunctionComponent<QuoteCardProps> = ({
  quote,
  quoteCardUser,
}) => {
  return (
    <div className="QuoteCard">
      <div className="QuoteCard__quote">{quote}</div>
      <div className="QuoteCard__user">
        <div className="QuoteCard__user-img">
          <img src={quoteCardUser.userAvatarUrl}></img>
        </div>
        <div className="QuoteCard__user-title-container">
          <div className="QuoteCard__user-title">{quoteCardUser.userName}</div>
          <div className="QuoteCard__user-description">
            {quoteCardUser.userDescription}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteCard;
