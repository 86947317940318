import { AxiosResponse } from "axios";
import { DependencyContainer } from "../../../DependencyContainer";
import { ApiResponse } from "../../../HttpClient";
import { AnalyticsEvent, LifetimeAnalytics } from "../../../types";

export class AnalyticsEventService {
    constructor(private readonly factory: DependencyContainer) {}

    async createAnalyticsEvent(analyticsPayload:AnalyticsEvent) : Promise<AxiosResponse<ApiResponse<AnalyticsEvent>>> {
        return await this.factory.analyticsEventClient.createAnalyticsEvent(analyticsPayload)
    }
    
    async calculateLifetimeAnalytics(page: string, groupBy: string) : Promise<AxiosResponse<ApiResponse<LifetimeAnalytics[]>>> {
        return await this.factory.analyticsEventClient.calculateLifetimeAnalytics(page,groupBy)
    }

    async calculateDateRangeAnalytics(payload) : Promise<AxiosResponse<ApiResponse<{xValues: string[],yValues: number[]}[]>>> {
        return await this.factory.analyticsEventClient.calculateDateRangeAnalytics(payload)
    }
}