import { ApiResponse, HttpClient } from '../../HttpClient';
import { UserPaths } from '../../urls';
import { AxiosResponse } from 'axios';
import { User } from '../login/UserEntity';
export class SignupClient extends HttpClient {
  async makeSignUp(
    email: string,
    password: string,
  ): Promise<AxiosResponse<ApiResponse<User>>> {
    return this.post<AxiosResponse<ApiResponse<User>>>(UserPaths.register, {
      email,
      password,
      type: 'web',
    });
  }
}
