import { FunctionComponent, useEffect, useState } from 'react';
import { DependencyContainer } from '../../DependencyContainer';
import { useLocation } from 'react-router';
import { JWT_PROPERTY_NAME_IN_QUERY_STRING, Routes } from '../../config';
import { useGoToRoute } from '../hooks/useGoToRoute';
import { useSyncedState } from '../../synced-state/synced-state';
import { useSetRecoilState } from 'recoil';
import { desiredLocationAfterLoginAtom } from '../../state';

const service = new DependencyContainer().authenticationService;

const JwtHandover: FunctionComponent<any> = ({ children }) => {
  const location = useLocation();
  const [shouldRender, setShouldRender] = useState(false);
  const goToRoute = useGoToRoute();
  const [, setUser] = useSyncedState('user');
  const setDesiredLocationAfterLogin = useSetRecoilState(
    desiredLocationAfterLoginAtom,
  );

  const getToken = () =>
    location.search
      ? new URLSearchParams(location.search).get(
          JWT_PROPERTY_NAME_IN_QUERY_STRING,
        ) || ''
      : '';

  useEffect(() => {
    const token = getToken();
    if (token) {
      service
        .getAuthFromJWT(token)
        .then((user) => {
          if (user) {
            setUser(user);
            setTimeout(() => {
              setShouldRender(true);
            }, 200);
          }
        })
        .catch((err) => {
          console.log(err)
          setUser(null);
          setDesiredLocationAfterLogin(location.pathname);
          goToRoute(Routes.SignIn);
        });
    } else {
      setShouldRender(true);
    }
  }, []);

  return shouldRender && children;
};

export default JwtHandover;
