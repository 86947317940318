import { Translation, TranslationKey } from './translations/types';
import { en } from './translations/en';
import { pl } from './translations/pl';
import { AspectRatio } from './features/dashboard/types';
import { IntegrationsTypes } from './sets/integration/types';

export const defaultLanguage = 'en';
export type SupportedLanguage = 'en' | 'pl';

export type Workspace = {
  name: string;
  type: 'grid' | 'workspace';
  slug: string;
  icon?: string;
};

export const topBarLinks = [
  {
    pathname: '/create',
    label: 'Create',
  },
  {
    pathname: '/plan',
    label: 'Plan',
  },
  {
    pathname: '/brand',
    label: 'Brand',
  },
];

export enum Routes {
  Index = '/:handle?',
  SignIn = '/login',
  SignUp = '/signup',
  SignUpSuccess = '/signup/success',
  ForgotPassword = '/forgot',
  ResetPassword = '/forgot/reset/:token?',
  Terms = 'https://unum.la/terms-and-conditions/',
  PrivacyPolicy = 'https://www.unum.la/privacy-policy',
  Dashboard = '/dashboard/:spaceId?',
  ExpiredTeam = '/expired-team',
  Integration = '/integration',
  Publish = '/publish',
  Create = '/create',
  Brand = '/brand',
  Settings = '/user/:tab?',
  SettingsWithAction = '/user/:tab/:action',
  Team = '/team/:teamId/:tab/:param?',
  AcceptTeamInvitation = '/accept-team-invitation/:token',
  Marketplace = '/marketplace',
  StoryboardPreview = '/marketplace/:storyboardId',
  PublicProfile = '/u/:publicProfileIdOrHandle/:tab?',
  AndroidTest = '/android',
  AndroidTestLogged = '/android/test',
  StudentPricingLandingPage = '/landing/student-pricing',
  Insights = '/insights',
  PostToPrivateGroup = 'https://unumdesign.zendesk.com/hc/en-us/articles/4407427886100-Troubleshoot-Error-in-Auto-Posting-to-Facebook-Groups',
  WebBuilderSite = '/s/:handle?',
  WebBuilder = '/b/:pageId?',
  AddTemplate = '/create',
  Today = '/today',
  Membership = '/membership',
}

export const messages = {
  en,
  pl,
};

export const allowedImagesTypes = ['.jpg', '.jpeg', '.png'];

export const GLOBAL_MEDIA_QUERIES = {
  small: '(max-width: 769px)',
  medium: '(min-width: 770px) and (max-width: 1199px)',
  large: '(min-width: 1200px)',
};

export const teamNameMaximumLengthInTableCell = {
  small: 25,
  mediumUp: 65,
};
export const teamNameMaximumLengthInAccountSwitcher = 12; //we want to have length=12 for all viewports

export const defaultLocationAfterLogin = '/';

export const payWallSliderSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  variableWidth: true,
  initialSlide: 1,
};
export const dateFormats = {
  trialExpirationAlertDateFormat: 'MMM D YY',
  teamBillingDetailsDateFormat: 'MMMM DD YYYY',
  invoiceCreatedDate: 'DD-MM-YYYY',
  billingDetailsDateFormat: 'MMMM DD YYYY',
  scheduledPostDate: 'MMMM DD, YYYY',
  toDoTaskDueDateFormat: 'MMMM D, Y',
  datePickerSelectedDateTimeFormat: 'DD/MM/YY H:mm',
  datePickerSelectedDateFormat: 'DD/MM/YY',
  chartTooltipDateFormat: 'ddd, MMMM D, Y',
  chartXAxisLabelDateFormat: 'MMM D',
  insightsWidgetPeriodDateFormat: 'MM/DD/Y',
  insightsReloadTimeFormat: 'h:mm A',
  insightsReloadDayFormat: 'dddd',
};
export const JWT_PROPERTY_NAME_IN_QUERY_STRING = 'token';

export const paywallHighlightedPlanName = 'Team Premium';
export const snackbarAutoHideDelay = 3000;
export const marketPlaceSettings = {
  tilesLimitPerPage: 9,
  maxTilesInPreviewGrid: 12,
  tilesInRow: 3,
  downloadAppUrl: 'https://www.unum.la/download',
};

export const acceptedUploadFileTypes = ['image/x-png', 'image/jpeg'];
export const defaultTitleTemplate = '%s | Today Page';
export const metaTags: {
  [key: string]: {
    title: TranslationKey;
    description?: TranslationKey;
  };
} = {
  default: {
    title: 'defaultMetaTitle',
    description: 'defaultMetaDescription',
  },
  signIn: {
    title: 'signInMetaTitle',
  },
  signUp: {
    title: 'signUpMetaTitle',
  },
  forgotPassword: {
    title: 'forgotPassword',
  },
  user: {
    title: 'userProfileMetaTitle',
  },
  team: {
    title: 'teamMetaTitle',
  },
  marketplace: {
    title: 'marketPlaceMetaTitle',
  },
  publish: {
    title: 'publishMetaTitle',
  },
  studentPricing: {
    title: 'studentPricingMetaTitle',
  },
  insights: {
    title: 'insightsMetaTitle',
  },
};

export const spaceNameExcerptLength = 3;
export const spaceNameMaxLength = 67;

export const spaceGridItemPreviewSliderSettings = {
  accessibility: true,
  adaptiveHeight: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: false,
  variableWidth: false,
  initialSlide: 0,
};
export const imagesCompressionConfig: Partial<Compressor.Options> = {
  maxWidth: 1080,
  quality: 0.8,
};

export const defaultGridLayoutConfig = {
  spaceGridWidth: 450,
  columnCount: 3,
  aspectRatio: [1, 1] as AspectRatio,
  marginSize: 2,
};

export const instagramAuthorizationRedirectUri = `${process.env.REACT_APP_PUBLIC_URL}/integration`;
export const linkedInAuthorizationRedirectUri = `${process.env.REACT_APP_PUBLIC_URL}/integration`;
export const facebookAuthorizationRedirectUri = `${process.env.REACT_APP_PUBLIC_URL}`;
export const pinterestAuthorizationRedirectUri = `${process.env.REACT_APP_PUBLIC_URL}/integration`;

export const numberOfInstagramLoadingTiles = 12;

export const overlayOpacity = 0.7;
export const addInstagramAccountFirstStepInfoLink =
  'https://www.facebook.com/business/help/502981923235522?helpref=uf_permalink';
export const notifications = {
  displayLimit: 10,
  fetchLimit: 30,
};

export const publishingContentDefaultLimits = {
  mentions: 10,
  hashtags: 10,
  characters: 200,
};
export const publishingContentNotifySettings = {
  notifyBefore: '60',
  notifyOnError: 'true' as const,
  notifyVia: 'email' as const,
};

export const featureTogglesRemoteConfigKey = 'web_feature_toggles';
export const paginationLimitInMediaArchive = 9;
export const aspectRatioDeviation = 0.00075; // due to bug in Pintura image editor, we need to add this value to the aspect ratio
export const supportedVideoExtensions = ['.mp4'];
export const helpCenterUrl = 'https://unumdesign.zendesk.com/hc/en-us';
export const instagramFeedConfigId = '5';

export const implementedSpaceIntegrations: IntegrationsTypes[] = [
  'instagram:basic_display',
  'instagram:business',
];

export const supportedPublishIntegrations: IntegrationsTypes[] = [
  'instagram:business',
  'facebook:page',
  'facebook:personal',
  'linkedin',
  'pinterest',
];

export enum Products {
  Design = 'Design',
  Publish = 'Publish',
  Insights = 'Insights',
  WebBuilder = 'Today.Page',
}

export const supportedInsightsIntegrations: IntegrationsTypes[] = [
  'instagram:business',
];

export const defaultTargetRouteAfterThirdPartyLogin = Routes.Publish;

export const hashtagsFontSize = {
  minSize: 16,
  maxSize: 24,
};

export const hashtagRowsConfig = {
  firstRowMaxHashtags: 2,
  secondRowMaxHashtags: 4,
  thirdRowMaxHashtags: 4,
};
export const scheduledPostOriginIndicatorMaxSpaceNameChars = 20;
export const dayLabelsIds: Array<keyof Translation> = [
  'mon',
  'tue',
  'wed',
  'thu',
  'fri',
  'sat',
  'sun',
];

export const handleNameMinLength = 3;
export const handleNameMaxLength = 30;
export const handleRegex = /^(?=[a-zA-Z0-9._]{1,100}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
export const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
export const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
