import axios from 'axios';

export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
  headers: { Accept: 'application/json' },
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: true,
    clarifyTimeoutError: true,
  },
});
