import { DependencyContainer } from '../DependencyContainer';
import { UploadPaths } from '../urls';
import { UploadProgressHandler } from './UploadClient';
import { imagesCompressionConfig } from '../config';
import Compressor from 'compressorjs';

type UploadCallbacks = {
  onUploadProgress?: UploadProgressHandler;
  onSuccessfulUpload?: () => Promise<void>;
};

export interface UploadServiceProtocol {
  upload(
    file: Blob,
    callbacks?: {
      onUploadProgress?: UploadProgressHandler;
      onSuccessfulUpload?: (response: any) => void;
    },
  ): Promise<{ url: string; thumbnailUrl: string }>;
  uploadAvatar(
    file: Blob,
    onUploadProgress?: UploadProgressHandler,
  ): Promise<string>;
  compressImage(file: Blob): Promise<Blob>;
}

export class UploadService implements UploadServiceProtocol {
  constructor(private readonly factory: DependencyContainer) {}

  async upload(file: Blob, callbacks?: UploadCallbacks) {
    try {
      const uploadDestination = await this.factory.uploadClient.createUploadDestination(
        UploadPaths.resources,
        { size: file.size, type: file.type },
      );
      this.factory.uploadClient
        .uploadFile(file, uploadDestination, callbacks?.onUploadProgress)
        .then(callbacks?.onSuccessfulUpload)
        .catch(Promise.reject);
      const secureUrl = uploadDestination.url.replace('http://', 'https://');
      const secureThumbnailUrl = uploadDestination.thumbnailUrl.replace(
        'http://',
        'https://',
      );
      return Promise.resolve({
        url: secureUrl,
        thumbnailUrl: secureThumbnailUrl,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async uploadAndWaitForImage(
    file: Blob,
    callbacks?: UploadCallbacks,
  ): Promise<{
    url: string;
    thumbnailUrl: string;
  }> {
    return new Promise((resolve, reject) => {
      let result = {
        url: '',
        thumbnailUrl: '',
      };
      this.upload(file, {
        ...callbacks,
        onSuccessfulUpload: async () => {
          callbacks?.onSuccessfulUpload && callbacks.onSuccessfulUpload();
          resolve(result);
        },
      })
        .then((response) => (result = response))
        .catch((e) => reject(e));
    });
  }

  async compressImage(file: Blob): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      new Compressor(file, {
        ...imagesCompressionConfig,
        success(file) {
          resolve(file);
        },
        error(error) {
          reject(error);
        },
      });
    });
  }

  async uploadAvatar(file: Blob, onUploadProgress?: UploadProgressHandler) {
    try {
      const uploadDestination = await this.factory.uploadClient.createUploadDestination(
        UploadPaths.avatar,
        { size: file.size, type: file.type, subPath: 'avatar' },
      );
      await this.factory.uploadClient.uploadFile(
        file,
        uploadDestination,
        onUploadProgress,
      );

      const secureUrl = uploadDestination.url.replace('http://', 'https://');
      await this.factory.settingsService.set('avatarUrl', secureUrl);
      return Promise.resolve(secureUrl);
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
