import { LimitConfig } from '../features/dashboard/components/SpaceGrid/hooks/useApplyPlanLimits';
import { TeamPlans, UserPlans } from '../features/payment/PaymentService';
import { Account, AccountType } from '../state';
import {
  SpaceGridItem,
  SpaceGridMediaTypes,
} from '../features/dashboard/types';
import { getExperimentValueFor, ExperimentValues } from './experiments';

const maxFreeIntegrations = 1;

export const integrationsLimit = async (
  getCurrentCount: () => Promise<number> | number,
  currentAccount?: Account | null,
): Promise<LimitConfig> => {
  const currentCount = await getCurrentCount();

  const setIntegrationLimitStatusForTeamPlan = (teamPlan: TeamPlans) => {
    if (!currentAccount || currentAccount.type !== AccountType.Team) {
      return false;
    }
    const subscriptionDetails = currentAccount.subscriptionDetails;
    if (!subscriptionDetails) {
      return false;
    }
    const integrationsLimit =
      subscriptionDetails.limitations?.default.integrations;

    return Boolean(integrationsLimit && currentCount >= integrationsLimit);
  };

  return {
    [UserPlans.Free]: () =>
      (!currentAccount || currentAccount.type === AccountType.Personal) &&
      currentCount >= maxFreeIntegrations,
    [TeamPlans.Essentials]: () =>
      setIntegrationLimitStatusForTeamPlan(TeamPlans.Essentials),
    [TeamPlans.Standard]: () =>
      setIntegrationLimitStatusForTeamPlan(TeamPlans.Standard),
    [TeamPlans.Premium]: () =>
      setIntegrationLimitStatusForTeamPlan(TeamPlans.Premium),
  };
};

export const gridItemsLimit = async (
  getItems: () => SpaceGridItem[],
  elements: number = 1,
): Promise<LimitConfig> => {
  const currentCount = getItems().filter(
    (item) => item.mediaType !== SpaceGridMediaTypes.InstagramPhoto,
  ).length;

  return {
    [UserPlans.Free]: () =>
      currentCount + elements >
      getExperimentValueFor(ExperimentValues.DesignGridLimitation),
  };
};
export const colorMapLimit = {
  [UserPlans.Free]: () => true,
};

export const insightsDaysAgoLimit = getExperimentValueFor(
  ExperimentValues.PlanLimitationInsightsMaxDaysAgo,
);
