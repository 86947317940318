import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { StylableComponent, useStyles } from '../../utils/hooks/useStyles';
import { omit } from '../../utils/omit';
import cx from 'classnames';
import Input, { InputStyles, InputVariants } from '../Input/Input';
import { useToggleByClickOutside } from '../../utils/hooks/useToggleByClickOutside';
import * as yup from 'yup';
import { spaceNameMaxLength } from '../../config';

export type ChipStyles = {
  root: string;
  wrapper: string;
};

export enum ChipVariants {
  Default = 'default',
  Info = 'info',
  Warning = 'warning',
}

export type ChipProps = JSX.IntrinsicElements['div'] &
  Partial<{
    small: boolean;
    variant: ChipVariants;
    icon: ReactNode;
    transparent: boolean;
    onUpdate: (oldValue: string, newValue: string) => void;
    value: string;
    validationSchema: yup.StringSchema<string | undefined>;
  }>;

const adjustInput = (current: InputStyles) => ({
  ...current,
  root: `${current.root} Chip__input`,
  label: `Chip__input-label`,
  input: `${current.input} Chip__input-input`,
  message: `${current.message} Chip__input-message`,
});

const Chip: StylableComponent<ChipProps, ChipStyles> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState();
  const [inputValue, setInputValue] = useState();
  const toggleIsEditing = () => setIsEditing((prev) => !prev);

  const callOnUpdateIfPossible = useCallback(() => {
    if (
      props.onUpdate &&
      props.value &&
      inputValue &&
      props.value !== inputValue
    ) {
      props.onUpdate(props.value, inputValue.trim());
      toggleIsEditing();
    }
  }, [props.onUpdate, props.value, inputValue]);

  const toggleByClickOutside = useToggleByClickOutside({
    toggler: () => {
      setInputValue(props.value);
      setIsEditing(false);
      makeUpdate();
    },
    state: isEditing,
  });
  const propsToPass = omit(
    props,
    'className',
    'children',
    'styles',
    'icon',
    'transparent',
    'onUpdate',
    'variant',
    'small',
    'validationSchema',
  );
  const isEditable = props.onUpdate !== undefined;
  const classes = useStyles(
    {
      root: cx('Chip', {
        'Chip--transparent': Boolean(props.transparent),
        [`Chip--${props.variant}`]: Boolean(props.variant),
        'Chip--icon': Boolean(props.icon),
        'Chip--editable': Boolean(isEditable),
        'Chip--editing': Boolean(isEditing),
      }),
      wrapper: cx('Chip__wrapper', {
        'Chip__wrapper--small': Boolean(props.small),
      }),
    },
    props.styles,
  );

  const onClick = () => {
    setIsEditing(isEditable);
  };

  const makeUpdate = () => {
    try {
      validateInput();
      callOnUpdateIfPossible();
      setError(undefined);
      return true;
    } catch (error) {
      setError(error.errors.join(' '));
      return false;
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      makeUpdate();
    }
  };

  useEffect(() => {
    if (isEditing && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const validateInput = () => {
    if (!props.validationSchema) {
      return;
    }
    props.validationSchema.validateSync(inputValue, { abortEarly: true });
  };

  const getInputSize = useCallback(() => {
    if (!inputValue) {
      return 1;
    }
    return inputValue.length > spaceNameMaxLength
      ? spaceNameMaxLength
      : inputValue.length;
  }, [inputValue]);

  return (
    <div {...propsToPass} className={classes.root}>
      {props.icon}
      <div
        ref={toggleByClickOutside.ref}
        className={classes.wrapper}
        onClick={onClick}
      >
        {isEditing ? (
          <Input
            name="name"
            innerRef={inputRef}
            styles={adjustInput}
            size={getInputSize()}
            variant={InputVariants.Outlined}
            value={inputValue}
            errorMessage={error}
            onKeyDown={onKeyDown}
            onFocus={() => setError(undefined)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setInputValue(e.currentTarget.value);
            }}
          />
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};

export default Chip;
