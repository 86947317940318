import React, { useState, ReactElement, ReactNode } from 'react';
import cx from 'classnames';
import {
  StylableComponent,
  useStyles,
  StyleModifier,
} from '../../utils/hooks/useStyles';
import { omit } from '../../utils/omit';
import {
  DropdownToggleIcon,
  DropdownToggleHorizontalIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '../Icons/Icons';
import SelectableList, {
  SelectableListStyles,
} from '../SelectableList/SelectableList';
import SelectableListItem, {
  SelectableListItemStyles,
} from '../SelectableList/SelectableListItem';
import DropdownWindow, {
  DropdownWindowStyles,
} from '../Dropdown/DropdownWindow';
import DropdownContent from '../Dropdown/DropdownContent';
import Dropdown, { DropdownStyles } from '../Dropdown/Dropdown';
import DropdownToggle, {
  DropdownToggleStyles,
} from '../Dropdown/DropdownToggle';

export type DropdownButtonStyles = {
  root: string;
  dropdown: string;
  icon: string;
  window: string;
  trigger: string;
  stripPaddings: string;
  dropdownToggle: string;
};

export type DropdownButtonOption = {
  label: ReactNode;
  subtitle?: string;
  onSelect?: (option: DropdownButtonOption) => void;
  icon?: ReactElement;
  hasIndicator?: boolean;
  metadata?: { [key: string]: string };
  stripHorizontalPaddings?: boolean;
  separator?: boolean;
  stripPaddings?: boolean;
  value?: string;
};

export enum DropdownButtonIconDirections {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export enum DropdownButtonVariant {
  Secondary = 'secondary',
}

export type DropdownButtonProps = {
  options: DropdownButtonOption[];
  iconDirection?: DropdownButtonIconDirections;
  listStyles?: StyleModifier<SelectableListItemStyles>;
  variant?: DropdownButtonVariant;
  expansionDirection?: 'top' | 'bottom' | 'right';
  toggleFromOutside?: () => void;
  selectedIcon?: ReactElement;
  toggleIconStart?: ReactElement;
} & JSX.IntrinsicElements['div'];

const DropdownButton: StylableComponent<
  DropdownButtonProps,
  DropdownButtonStyles
> = (props) => {
  const [open, setOpen] = useState(false);
  const propsToPass = omit(
    props,
    'listStyles',
    'className',
    'children',
    'styles',
    'options',
    'iconDirection',
    'variant',
    'expansionDirection',
    'selectedIcon',
    'toggleIconStart',
  );
  const expansionDirection = props.expansionDirection || 'bottom';

  const classes = useStyles(
    {
      root: 'DropdownButton',
      dropdown: 'DropdownButton__dropdown',
      window: 'DropdownButton__dropdown-window',
      icon: 'DropdownButton__icon',
      trigger: cx('DropdownButton__trigger', {
        [`DropdownButton__trigger--${props.variant}`]: props.variant != null,
      }),
      stripPaddings: '',
      dropdownToggle: '',
    },
    props.styles,
  );

  const onSelectedOption = (option: DropdownButtonOption) => {
    if (typeof option.onSelect !== 'undefined') {
      option.onSelect(option);
    }
    setOpen(false);
  };

  const toggleDropdown = () => {
    if (typeof props.toggleFromOutside !== 'undefined') {
      props.toggleFromOutside();
    }
    setOpen((prev) => !prev);
  };

  const DropdownIcon =
    props.iconDirection === DropdownButtonIconDirections.Horizontal
      ? DropdownToggleHorizontalIcon
      : DropdownToggleIcon;

  const renderToggleInnerContent = () => {
    if (props.toggleIconStart) {
      return (
        <>
          <span className="Dropdown__toggle__icon--left">
            {props.toggleIconStart}
          </span>
          {props.children}
          <span className="Dropdown__toggle__icon--right">
            {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </span>
        </>
      );
    }
    return (
      props.children || (
        <div className={classes.trigger}>
          <DropdownIcon className={classes.icon} />
        </div>
      )
    );
  };
  return (
    <div {...propsToPass} className={classes.root}>
      <Dropdown
        styles={(current: DropdownStyles) => ({
          ...current,
          root: `${current.root} ${classes.dropdown}`,
        })}
        isOpen={open}
        toggle={() => toggleDropdown()}
      >
        <DropdownToggle
          styles={(current: DropdownToggleStyles) => ({
            ...current,
            root: `${current.root} ${classes.dropdownToggle}`,
          })}
        >
          {renderToggleInnerContent()}
        </DropdownToggle>
        <DropdownWindow
          styles={(current: DropdownWindowStyles) => ({
            ...current,
            root: `${current.root} ${classes.window}`,
          })}
          expansionDirection={expansionDirection}
        >
          <DropdownContent>
            <SelectableList
              options={props.options}
              styles={(current: SelectableListStyles) => ({
                ...current,
                option: `DropdownButton__list-option`,
              })}
              onSelectedOption={onSelectedOption}
              renderOption={(tab, isSelected) => {
                return (
                  <SelectableListItem
                    image={tab.icon}
                    title={tab.label}
                    subTitle={tab.subtitle}
                    hasIndicator={tab.hasIndicator}
                    isSelected={isSelected}
                    styles={(current: SelectableListItemStyles) => ({
                      ...current,
                      root: cx(current.root, 'DropdownButton__item', {
                        [classes.stripPaddings]: classes.stripPaddings,
                        'p-x-0': tab.stripHorizontalPaddings,
                        'DropdownButton__item--border-top': tab.separator,
                      }),
                      description: `${current.description} Tabs__list-item-description`,
                      ...(props.listStyles != null
                        ? props.listStyles(current)
                        : {}),
                    })}
                    selectedIcon={props.selectedIcon}
                  />
                );
              }}
            />
          </DropdownContent>
        </DropdownWindow>
      </Dropdown>
    </div>
  );
};

export default DropdownButton;
