import {
  firestore,
  SnapshotHandler,
  Snapshot,
  getRemoteConfigValues,
} from '../../../utils/firebase';
import { Account, AccountType } from '../../../state';
import {
  IntegratedPlatforms,
  PublishInfoStatus,
  Space,
  SpaceGridItem,
  SpaceGridItemPublishInfo,
  SpaceGridMediaTypes,
  SpaceTypes,
  StoryboardConfig,
} from '../types';
import { DependencyContainer } from '../../../DependencyContainer';
import firebase from 'firebase/compat';
import { rotateArrayClockwise } from '../../../utils/helpers';
import {
  activityTracker,
  ActivityType,
} from '../../../utils/activity/activityTracker';
import { bsonIdToTimestamp } from '../../../utils/bsonIdToTimestamp';
import { firestoreBatchedInQuery } from '../../../utils/firebaseHelpers';
import { instagramFeedConfigId } from '../../../config';

export type FileUploadCallbacks = {
  onTargetUrlAvailable?: (payload: {
    url: string;
    thumbnailUrl: string;
  }) => void;
};

type EmptySpaceContent = {
  createdDate: Date;
  itemType: SpaceGridMediaTypes;
  spaceID: string;
  isUploading: boolean;
  rank: string;
};

type WithPublishInfoSpaceContent = {
  publishInfo?: SpaceGridItemPublishInfo;
};

export type SpaceContent = WithPublishInfoSpaceContent &
  EmptySpaceContent & {
    caption: string;
    assetURL: string;
    thumbnailURL: string;
    notes?: string;
  };

export type SpaceData = {
  createdDate: Date;
  lastUpdated: number;
  liveGridEnabled: boolean;
  spaceType: SpaceTypes;
  timezone: string;
  title: string;
  thumbnailUrl?: string;
  storyboardConfig?: StoryboardConfig;
  shiftOffset?: number;
};

export type NewSpaceData = {
  groupId?: string;
  title: string;
  storyboardConfig?: StoryboardConfig;
  spaceType?: SpaceTypes;
};

export class ResourceNotFoundError extends Error {}

export class SpaceService {
  constructor(private readonly factory: DependencyContainer) {}

  async addSpace(newSpace: NewSpaceData): Promise<{ spaceId: string }> {
    const spaceType = newSpace.spaceType || SpaceTypes.Storyboard;
    const { storyboardConfig } = newSpace;
    const storyboardConfigExcerpt = storyboardConfig
      ? this.makeStoryboardConfigExcerpt(storyboardConfig)
      : undefined;
    const response = await this.factory.spaceClient.addSpace({
      groupId: newSpace.groupId,
      title: newSpace.title,
      storyboardConfig: storyboardConfigExcerpt,
      integratedPlatform: IntegratedPlatforms.None,
      spaceType,
    });
    activityTracker.log({
      type: ActivityType.AddedSpace,
      metadata: {
        group_id: newSpace.groupId,
        space_type:
          spaceType === SpaceTypes.Storyboard
            ? storyboardConfig?.id || 'unknown_storyboard_layout'
            : 'ig_feed_pro',
      },
    });
    return response.data.data;
  }

  onSpaceContents(id: string, handler: SnapshotHandler) {
    return firestore
      .collection('spaces')
      .doc(id)
      .collection('contents')
      .orderBy('rank')
      .onSnapshot(handler);
  }

  onScheduledSpaceContents(
    groupId: string,
    integrationIds: string[],
    handler: SnapshotHandler,
  ) {
    let query = firestore
      .collectionGroup('contents')
      .where('groupId', '==', groupId)
      .where('publishInfo.status', '==', PublishInfoStatus.Ready);

    query =
      integrationIds.length > 0
        ? query.where('publishInfo.integrationId', 'in', integrationIds)
        : query;

    return query.orderBy('publishInfo.date').onSnapshot(handler);
  }

  onAlbumContents(spaceId: string, albumId: string, handler: SnapshotHandler) {
    return firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('contents')
      .doc(albumId)
      .collection('albumContents')
      .orderBy('rank')
      .onSnapshot(handler);
  }

  onAccountSpaces(account: Account, handler: SnapshotHandler) {
    const ownershipField =
      account.type === AccountType.Personal ? 'owner' : 'groupId';
    return firestore
      .collection('spaces')
      .where(ownershipField, '==', account._id)
      .where('spaceType', '!=', SpaceTypes.InstagramStory)
      .onSnapshot(handler);
  }

  getRanksBefore(lastRank: string, quantity?: number): Promise<string[]> {
    return this.factory.ranksClient.getRanksBefore(lastRank, quantity);
  }

  getInitialRanks(quantity: number): Promise<string[]> {
    return this.factory.ranksClient.getInitialRanks(quantity);
  }

  getRanksBetween(
    lhs: string,
    rhs: string,
    quantity?: number,
  ): Promise<string[]> {
    return this.factory.ranksClient.getRanksBetween(lhs, rhs, quantity);
  }

  async reRankSpace(spaceId: string) {
    const snapshot = await firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('contents')
      .orderBy('rank')
      .get();

    const newRanks = await this.getInitialRanks(snapshot.size);
    const batch = firestore.batch();
    snapshot.docs.forEach((doc, index) => {
      batch.update(doc.ref, { rank: newRanks[index] });
    });
    await batch.commit();
    return newRanks;
  }

  async addSpaceContentItem(
    uploadDetails: {
      spaceId: string;
      item: Omit<
        SpaceContent,
        'assetURL' | 'thumbnailURL' | 'isUploading' | 'spaceID'
      >;
      file: Blob;
    },
    callbacks?: FileUploadCallbacks,
  ) {
    let content: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    > | null = null;
    const compressedImage = await this.factory.uploadService.compressImage(
      uploadDetails.file,
    );
    const media = await this.factory.uploadService.upload(compressedImage, {
      onSuccessfulUpload: async () => {
        if (content) {
          this.updateSpaceContentsDoc({
            spaceId: uploadDetails.spaceId,
            contentId: content.id,
            updateObject: {
              itemType: SpaceGridMediaTypes.Photo,
              isUploading: false,
            },
          });
        }
      },
    });

    callbacks?.onTargetUrlAvailable && callbacks.onTargetUrlAvailable(media);

    const spaceContentItem: SpaceContent = {
      ...uploadDetails.item,
      isUploading: true,
      assetURL: media.url,
      thumbnailURL: media.thumbnailUrl,
      spaceID: uploadDetails.spaceId,
    };

    content = await firestore
      .collection('spaces')
      .doc(uploadDetails.spaceId)
      .collection('contents')
      .add(spaceContentItem);

    return content;
  }

  async updateSpaceContentMedia(
    uploadDetails: {
      contentId: string;
      spaceId: string;
      file: Blob;
    },
    callbacks?: FileUploadCallbacks,
  ) {
    const media = await this.factory.uploadService.upload(uploadDetails.file, {
      onSuccessfulUpload: () => {
        return this.updateSpaceContentsDoc({
          spaceId: uploadDetails.spaceId,
          contentId: uploadDetails.contentId,
          updateObject: {
            isUploading: false,
            itemType: SpaceGridMediaTypes.Photo,
          },
        });
      },
    });

    callbacks?.onTargetUrlAvailable && callbacks.onTargetUrlAvailable(media);

    const updateObject: Partial<SpaceContent> = {
      thumbnailURL: media.thumbnailUrl,
      assetURL: media.url,
      isUploading: true,
    };

    const content = await this.updateSpaceContentsDoc({
      spaceId: uploadDetails.spaceId,
      contentId: uploadDetails.contentId,
      updateObject,
    });

    return content;
  }

  async addPlaceholders(spaceId: string, newRanks: string[]) {
    const placeholders: EmptySpaceContent[] = newRanks.map((rank) => ({
      isUploading: false,
      spaceID: spaceId,
      createdDate: new Date(),
      itemType: SpaceGridMediaTypes.Placeholder,
      rank,
    }));

    const batch = firestore.batch();
    const collection = firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('contents');

    for (const spaceContentItem of placeholders) {
      batch.set(collection.doc(), spaceContentItem);
    }

    return batch.commit();
  }

  async swapSpaceContentRanks(spaceId: string, contentIds: string[]) {
    const docsToSwap = await firestoreBatchedInQuery(
      firestore.collection('spaces').doc(spaceId).collection('contents'),
      firebase.firestore.FieldPath.documentId(),
      contentIds,
    );

    const batch = firestore.batch();
    const newRanks = rotateArrayClockwise(
      docsToSwap.map((element) => element.data().rank),
    );

    docsToSwap.forEach((curr, index) => {
      batch.update(curr.ref, { rank: newRanks[index] });
    });

    return batch.commit();
  }

  async deleteSpaceContentItem(spaceId: string, contentIds: string[]) {
    const batch = firestore.batch();
    const contents = firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('contents');
    contentIds.forEach((id: string) => {
      const ref = contents.doc(id);
      batch.delete(ref);
    });
    return batch.commit();
  }

  async updateSpaceContentsDoc(details: {
    spaceId: string;
    contentId: string;
    updateObject: Partial<SpaceContent>;
  }) {
    const updateDoc = firestore
      .collection('spaces')
      .doc(details.spaceId)
      .collection('contents')
      .doc(details.contentId);

    if (!(await updateDoc.get()).exists) {
      throw new ResourceNotFoundError();
    }

    return updateDoc.update(details.updateObject);
  }

  async updateSpace(spaceId: string, updateObject: Partial<Space>) {
    return firestore.collection('spaces').doc(spaceId).update(updateObject);
  }

  async deleteSpace(spaceId: string) {
    return this.factory.spaceClient.deleteSpace(spaceId);
  }

  async renameSpace(spaceId: string, newName: string) {
    return await this.factory.spaceClient.renameSpace(spaceId, newName);
  }

  async updateCaption(params: {
    spaceId: string;
    spaceGridItem: SpaceGridItem;
    caption?: string;
  }): Promise<void> {
    if (params.caption === undefined) {
      return;
    }
    const spaceDoc = firestore
      .collection('spaces')
      .doc(params.spaceId)
      .collection('contents')
      .doc(params.spaceGridItem.id);
    if (params.spaceGridItem.mediaType === SpaceGridMediaTypes.Album) {
      this.updateCaptionInAlbum(spaceDoc, params.caption);
    }
    return spaceDoc.update({ caption: params.caption, notes: params.caption });
  }

  private async updateCaptionInAlbum(
    spaceDoc: firebase.firestore.DocumentReference<
      firebase.firestore.DocumentData
    >,
    caption: string,
  ): Promise<void> {
    const batch = firestore.batch();
    return spaceDoc
      .collection('albumContents')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((albumContent) => {
          batch.update(albumContent.ref, { caption, notes: caption });
        });
        batch
          .commit()
          .then(() => Promise.resolve())
          .catch((err) => {
            Promise.reject(err);
          });
      });
  }

  async cloneSpace(
    space: Space,
    newTitle: string,
    account: {
      type: AccountType;
      _id: string;
    },
  ) {
    const payload =
      account.type === AccountType.Team
        ? { title: newTitle, groupId: account._id }
        : { title: newTitle, userId: account._id };

    return (await this.factory.spaceClient.cloneSpace(space.id, payload)).data
      .data;
  }

  sortSpacesByIdTimestamp(spaces: Space[]) {
    const copy = [...spaces];
    copy.sort((first, second) => {
      const firstTimestamp = bsonIdToTimestamp(first.id) || -1;
      const secondTimestamp = bsonIdToTimestamp(second.id) || -1;
      return secondTimestamp - firstTimestamp;
    });
    return copy;
  }

  async convertSnapshotToSpaces(snapshot: Snapshot | Snapshot[]) {
    let fetchedSpaces: Space[] = [];
    const remoteConfig = await getRemoteConfigValues();
    const getSpaceTypeConfigById = (id: string) =>
      remoteConfig.storyboard_types_v3.find((item) => item.id === id);

    snapshot.forEach((doc: firebase.firestore.DocumentData) => {
      const data = doc.data() as SpaceData;
      const configId = data.storyboardConfig?.id || '';
      const configAdditionalParams = getSpaceTypeConfigById(configId);
      let storyboardConfig = configAdditionalParams
        ? {
            ...data.storyboardConfig,
            ...configAdditionalParams,
          }
        : data.storyboardConfig;

      // This is legacy space type that doesn't have storyboardConfig.
      // in order to fix existing spaces of that type we have to load such
      // config here. ID 5 means Instagram Feed.
      if (data.spaceType === SpaceTypes.InstagramGrid) {
        storyboardConfig = getSpaceTypeConfigById(instagramFeedConfigId);
      }

      const space: Space = {
        id: doc.id,
        name: data.title,
        type: data.spaceType,
        thumbnailUrl: data.thumbnailUrl,
        storyboardConfig,
        shiftOffset: data.shiftOffset,
      };
      fetchedSpaces.push(space);
    });

    return fetchedSpaces;
  }

  async hasItemsInUploadState(spaceIds: string[]) {
    if (spaceIds.length === 0) {
      return false;
    }

    const docsInUpload = await firestoreBatchedInQuery(
      firestore.collectionGroup('contents').where('isUploading', '==', true),
      'spaceID',
      spaceIds,
    );

    return docsInUpload.length > 0;
  }

  private makeStoryboardConfigExcerpt(storyboardConfig: StoryboardConfig) {
    const config = { ...storyboardConfig };
    const keysToRemove: Array<keyof StoryboardConfig> = [
      'platform',
      'showsFeed',
      'supportedIntegrations',
    ];
    keysToRemove.forEach((key) => {
      delete config[key];
    });
    return config;
  }
}
