import { DependencyContainer } from '../../../DependencyContainer';
import moment from 'moment';
import {
  ArchivedMediaResponse,
  ScheduledPost,
  ScheduledPostToCreate,
  SchedulePostResponse,
} from '../types';
import { ScheduledPostParams, PagedPostsResponse } from './PublishClient';
import { PagedResponse } from '../../../utils/hooks/usePagedResponses';
import { Pagination } from '../../../types';
import { firestore, SnapshotHandler } from '../../../utils/firebase';
import { PublishInfoStatus } from '../../dashboard/types';
import { removeUndefinedKeys } from '../../../utils/helpers';
import { XOR } from '../../../utils/types';

export class PublishService {
  constructor(private readonly factory: DependencyContainer) {}

  async getScheduledPosts(
    groupId: string,
    params: ScheduledPostParams = {},
  ): Promise<PagedResponse<ScheduledPost[]>> {
    const response = (
      await this.factory.publishClient.getScheduledPosts(groupId, params)
    ).data;

    if (response.status !== 'success') {
      throw new Error(`Api call responded with status ${response.status}`);
    }
    const { cursor } = response.data;

    return {
      currentPage: this.mapScheduledPostListResponse(response),
      hasNextPage: Boolean(cursor),
      getNextPage: () =>
        cursor !== null
          ? this.getScheduledPosts(groupId, { ...params, cursor })
          : Promise.resolve(),
    };
  }

  async schedulePost(
    post: ScheduledPost | ScheduledPostToCreate,
    config?: Partial<{
      publishNow: boolean;
      shouldDeleteExistingPost: boolean;
    }>,
  ): Promise<SchedulePostResponse> {
    const publishNow = config?.publishNow || false;
    const shouldDeleteExistingPost = config?.shouldDeleteExistingPost || false;

    const callApiV1 = shouldDeleteExistingPost && 'id' in post;

    const schedule = await this.factory.publishClient.schedulePost(
      {
        assetUrl: post.assetUrl,
        thumbnailUrl: post.thumbnailUrl || '',
        caption: post.caption || '',
        groupId: post.groupId,
        scheduledDate: publishNow ? 'now' : post.scheduledAt.toString(),
        notifyVia: 'email',
        notifyBefore: '0',
        notifyOnError: 'true',
        ...(callApiV1
          ? {
              integrationIds: post.integrationIds,
              type: 'image',
              properties: post.properties,
            }
          : {
              integrationId: post.integrationIds[0],
              itemType: 'photo',
              platformProperties: post.properties,
            }),
      },
      { callApiV1 },
    );

    if (callApiV1) {
      await this.deleteScheduledPost(post.id);
    }

    return schedule.data.data;
  }

  onScheduledNonSpacePosts(
    groupId: string,
    integrationIds: string[],
    handler: SnapshotHandler,
  ) {
    let query = firestore
      .collection('groups')
      .doc(groupId)
      .collection('schedule')
      .where('publishInfo.status', '==', PublishInfoStatus.Ready);

    query =
      integrationIds.length > 0
        ? query.where('publishInfo.integrationId', 'in', integrationIds)
        : query;

    return query.onSnapshot(handler);
  }

  async facebookInstantPublish(
    scheduledPost: ScheduledPost | ScheduledPostToCreate,
  ): Promise<void> {
    await this.factory.publishClient.facebookInstantPublish({
      assetUrl: scheduledPost.assetUrl,
      thumbnailUrl: scheduledPost.thumbnailUrl || '',
      caption: scheduledPost.caption || '',
      integrationIds: scheduledPost.integrationIds,
      groupId: scheduledPost.groupId,
      notifyVia: 'email',
      notifyBefore: '0',
      notifyOnError: 'true',
      ...(scheduledPost.properties
        ? { properties: scheduledPost.properties }
        : {}),
    });
  }

  async updateScheduledPost(
    postChanges: Partial<ScheduledPost> &
      Pick<ScheduledPost, 'id'> &
      XOR<{ spaceId: string }, { groupId: string }>,
  ): Promise<void> {
    const promises: Promise<unknown>[] = [];

    if (postChanges.scheduledAt != null) {
      promises.push(
        this.factory.publishClient.reschedulePost(
          postChanges.id,
          postChanges.spaceId
            ? { spaceId: postChanges.spaceId }
            : { groupId: postChanges.groupId! },
          postChanges.scheduledAt!,
        ),
      );
    }

    const publishItemPath = postChanges.spaceId
      ? firestore
          .collection('spaces')
          .doc(postChanges.spaceId)
          .collection('contents')
          .doc(postChanges.id)
      : firestore
          .collection('groups')
          .doc(postChanges.groupId)
          .collection('schedule')
          .doc(postChanges.id);

    const updateObject = this.mapPostUpdatesToFirestore(postChanges);
    if (Object.keys(updateObject).length > 0) {
      promises.push(publishItemPath.update(updateObject));
    }

    await Promise.all(promises);
  }

  async deleteScheduledPost(postId: string): Promise<void> {
    await this.factory.publishClient.deleteScheduledPost(postId);
  }

  async getArchivedMedia(
    groupId: string,
    pagination: Pagination,
  ): Promise<ArchivedMediaResponse> {
    const response = await this.factory.publishClient.getArchivedMedia(
      groupId,
      pagination,
    );
    return response.data.data;
  }

  private mapPostUpdatesToFirestore(post: Partial<ScheduledPost>) {
    const payload = {
      assetURL: post.assetUrl,
      thumbnailURL: post.thumbnailUrl,
      caption: post.caption,
    };

    return removeUndefinedKeys(payload);
  }

  private mapScheduledPostListResponse(response: PagedPostsResponse) {
    return response.data.result.map((response) => ({
      id: response._id,
      groupId: response.data.groupId,
      scheduledBy: response.data.scheduledBy,
      scheduledAt: moment(response.nextRunAt),
      assetUrl: response.data.assetUrl,
      thumbnailUrl: response.data.thumbnailUrl,
      caption: response.data.caption || undefined,
      integrationIds: response.data.integrationIds || [],
      type: response.data.type,
      notifyVia: response.data.notifyVia || undefined,
      notifyBefore:
        response.data.notifyBefore != null
          ? moment(response.data.notifyBefore)
          : undefined,
      notificationJobId: response.data.notificationJobId || undefined,
      failCount: response.failCount,
      failReason: response.failReason,
      failedAt: response.failedAt,
      lastFinishedAt: response.lastFinishedAt,
      properties: response.data.properties,
    }));
  }

  onDeleteScheduledNonSpacePost(groupId: string, postId: string) {
    return firestore
      .collection('groups')
      .doc(groupId)
      .collection('schedule')
      .doc(postId)
      .delete();
  }

  async unschedulePost(body: {
    itemId: string;
    groupId: string;
    spaceId?: string;
  }) {
    await this.factory.publishClient.unscheduleScheduledPost(body);
  }
}
