import { HttpClient } from '../../../HttpClient';
import { NotificationPath } from '../../../urls';

export class NotificationClient extends HttpClient {
  initToken(token: string) {
    return this.post(NotificationPath.token, {
      token,
    });
  }
}
