import moment, { Moment } from 'moment';
import { SocialAccountTypes } from '../../sets/integration/types';

/**
 * When adding new events here, separate name segments by dots.
 * Each sink will format names downstream, according to platform's standards
 */
export enum ActivityType {
  // Teams
  CreatedTeam = 'created.team',
  StartedTeamTrial = 'started.team.trial',
  InviteTeamMember = 'invite.team.member',
  ViewedPaywall = 'viewed.paywall',
  CancelTeamMembership = 'cancel.team.membership',
  AcceptedTeamInvitation = 'accepted.team.invitation',
  // Click events
  ClickedGridImport = 'clicked.grid.import',
  ClickedGridDelete = 'clicked.grid.delete',
  ClickedAddMoreGrids = 'clicked.add.more.grids',
  ClickedCaption = 'clicked.caption',
  ClickedWelcomeStart = 'clicked.welcome.start',
  ClickedGridCreate = 'clicked.grid.create',
  ClickedGridReplace = 'clicked.grid.replace',
  ClickedAddPlaceholder = 'selected.add.placeholder',
  ClickedForgotPassword = 'forgot.password',
  SelectedGridColormap = 'selected.grid.colormap',
  ClickedNotificationBell = 'clicked.notification.bell',
  ClickedAddIntegration = 'clicked.add.integration',
  ClickedDeleteScheduledPost = 'clicked.delete.scheduled.post',
  ClickedUnscheduleScheduledPost = 'clicked.unschedule.scheduled.post',
  ClickedSocialAccountFilterOption = 'clicked.social.account.filter.option',
  ClickedSwap = 'clicked.swap',
  LoginUser = 'login.user',
  SignupUser = 'signup.user',
  LogoutUser = 'logout.user',
  AddedSpace = 'added.space',
  AddedSpaceIntegration = 'added.space.integration',
  DeletedSpace = 'deleted.space',
  ImportedMedia = 'imported.media',
  SubmittedCancellationForm = 'submitted.cancellation.form',
  AddedComment = 'added.comment',
  DeletedComment = 'deleted.comment',
  AddedTodo = 'added.todo',
  DeletedTodo = 'deleted.todo',
  EditedTodo = 'edited.todo',
  UndidTodo = 'undid.todo',
  ConfirmedDeleteScheduledPost = 'confirmed.delete.scheduled.post',
  ConfirmedUnscheduleScheduledPost = 'confirmed.unschedule.scheduled.post',
  ClickedShiftGrid = 'design.clicked.shift.grid',
  // Clone
  ClonedMarketplaceStoryboard = 'cloned.marketplace.storyboard',
  ClickedCloneSpace = 'clicked.clone.space',
  ClickedCloneSpaceEditName = 'clicked.clone.space.edit.name',
  ClickedCloneSpaceChangeTeam = 'clicked.clone.space.change.team',
  // Completion events
  CompletedPurchase = 'completed.purchase',
  CompletedPurchaseTeam = 'completed.purchase.team',
  CompletedAddIntegration = 'completed.add.integration',
  CompletedCloneSpace = 'completed.clone.space',
  CompletedCloneSpaceEditName = 'completed.clone.space.edit.name',
  CompletedCloneSpaceChangeTeam = 'completed.clone.space.change.team',
  CompletedTodo = 'completed.todo',
  CompletedDragDrop = 'completed.drag.drop',
  // Publish Events
  PublishDashboardClickedSchedulePost = 'publish.dashboard.clicked.schedule.post',
  PublishClickedDuplicatePost = 'publish.clicked.duplicate.post',
  PublishCompletedAddMediaToScheduledPost = 'publish.completed.add.media.to.scheduled.post',
  PublishCompletedAddVideoToScheduledPost = 'publish.completed.add.video.to.scheduled.post',
  PublishCompletedSchedulePost = 'publish.completed.schedule.post',
  PublishCompletedDuplicatePost = 'publish.completed.duplicate.post',
  PublishSchedulePostCompletedEditingCaption = 'publish.schedule.post.completed.editing.caption',
  PublishChangedImportTypeArchive = 'publish.changed.import.type.archive',
  PublishChangedImportTypeDevice = 'publish.changed.import.type.device',
  PublishChangedImportTypeSpace = 'publish.changed.import.type.space',
  PublishClickedPostTo = 'publish.createPost.clicked.select.group',
  PublishClickedPostToProfile = 'publish.createPost.groupSelection.selected.facebook.profile',
  PublishClickedPostToPage = 'publish.createPost.groupSelection.selected.facebook.page',
  PublishClickedPostToGroup = 'publish.createPost.groupSelection.selected.facebook.group',
  PublishCreatePostClickedPublishNow = 'publish.createPost.clicked.publish.now',
  PublishCreatePostConfirmedPublishNow = 'publish.createPost.confirmed.publish.now',
  PublishCreatePostCancelledPublishNow = 'publish.createPost.cancelled.publish.now',
  PublishQueueClickedPublishNow = 'publish.queue.clicked.publish.now',
  PublishQueueConfirmedPublishNow = 'publish.queue.confirmed.publish.now',
  PublishQueueCancelledPublishNow = 'publish.queue.cancelled.publish.now',
  DesignDashboardClickedExistingSpace = 'design.dashboard.clicked.existing.space',
  DesignCreateSpaceClickedType = 'design.create.space.clicked.type',
  DesignCompletedCreateSpace = 'design.completed.create.space',
  DesignSpaceClickedPublishIcon = 'design.space.clicked.publishIcon',
  DesignCreateNewSpaceFromPlaceholder = 'design_dashboard_clicked_quickCreate_space',
  PublishPersonalDashboardClickedSwitchToBusiness = 'publish.personalDashboard.clicked.switch.to.business',
  PublishPersonalDashboardClickedCreateBusinessAccount = 'publish.personalDashboard.clicked.create.business.account',
  PublishPersonalDashboardSwitchAccountClickedBusiness = 'publish.personalDashboard.switchAccount.clicked.business',
  PublishPersonalDashboardSwitchAccountClickedCreateBusiness = 'publish.personalDashboard.switchAccount.clicked.create.business',
  PublishAccountSidebarClickedConvertIGBasic = 'publish.accountSidebar.clicked.convert.ig.basic',
  PublishAccountSidebarClickedConvertIGUpgradeInfo = 'publish.accountSidebar.clicked.convert.ig.upgrade.info',
  PublishQueueItemClickedAssociatedSpace = 'publish.queueItem.clicked.associatedSpace',
  PublishIGBasicUpgradeInfoClickedContinue = 'ig.basic.upgrade.info.clicked.continue',
  ExpiredTeamDashboardClickedUpgrade = 'expiredTeamDashboard.clicked.upgrade',
  ExpiredTeamBannerClickedUpgrade = 'expiredTeamBanner.clicked.upgrade',
  NavigationBarClickedUpgrade = 'navigationBar.clicked.upgrade',
  AccountMenuClickedSettings = 'accountMenu.clicked.settings',
  AccountMenuClickedSwitchToPersonal = 'accountMenu.clicked.switch.to.personal',
  AccountMenuClickedSwitchToBusiness = 'accountMenu.clicked.switch.to.business',
  AccountMenuClickedCreateBusiness = 'accountMenu.clicked.create.business',
  AccountMenuClickedLogOut = 'accountMenu.clicked.log.out',
  NavigationProductSelectClickedDesign = 'navigation.productSelect.clicked.design',
  NavigationProductSelectClickedPublish = 'navigation.productSelect.clicked.publish',
  PurchaseOptionsToggledBillingMonthly = 'purchaseOptions.toggled.billing.monthly',
  PurchaseOptionsToggledBillingAnnual = 'purchaseOptions.toggled.billing.annual',
  PurchaseOptionsClickedPlanEssentials = 'purchaseOptions.clicked.plan.essentials',
  PurchaseOptionsClickedPlanStandard = 'purchaseOptions.clicked.plan.standard',
  PurchaseOptionsClickedPlanPremium = 'purchaseOptions.clicked.plan.premium',
  PurchaseOptionsClickedPlanElite = 'purchaseOptions.clicked.plan.elite',
  PurchaseOptionsClickedPlanBasic = 'purchaseOptions.clicked.plan.basic',

  // Insights events
  InsightsPersonalDashboardClickedSwitchToBusiness = 'insights.personalDashboard.clicked.switch.to.business',
  InsightsPersonalDashboardClickedCreateBusinessAccount = 'insights.personalDashboard.clicked.create.business.account',
  InsightsWidgetLineGraphToggledDisplay = 'insights.widget.lineGraph.toggled.display',
  InsightsDateClicked = 'insights.dashboard.clicked.date.filter',
  InsightsDateSelectedPreset = 'insights.dashboard.dateSelection.selected.presetDateRange',
  InsightsDateSelectedCustomRange = 'insights.dashboard.dateSelection.selected.customDateRange',
  InsightsAccountSidebarClickedSocialAccount = 'insights.accountSidebar.clicked.social.account',
  InsightsAccountSidebarClickedConvertIGBasic = 'insights.accountSidebar.clicked.convert.ig.basic',
  InsightsAccountSidebarClickedConvertIGUpgradeInfo = 'insights.accountSidebar.clicked.convert.ig.upgrade.info',

  //Today Page Events
  TodayPageCreateSite = 'today.created.site',

  TodayPageCreatePage = 'today.created.page',
  TodayClickedCreatePage = 'today.clicked.create.page',

  TodayPagePublishSite = 'today.publish.site',
  TodayPageUpdateHandle = 'today.update.handle',
  TodayPageSaveChanges = 'today.save.changes',
  TodayPageViews = 'today.site.viewed',
  TodayAnalyticsLifetimeClick = 'today.viewed.lifetime.analytics',
  TodayAnalyticsBlockClick = 'today.viewed.block.analytics',

  TodayPageClickedEditBlock = 'today.clicked.edit.block',
  TodayPageClickedAddBlock = 'today.clicked.add.block',

  TodayEditClickedDeleteBlock = 'today.edit.clicked.delete.block',

  TodayLayoutClickedDeleteBlock = 'today.layout.clicked.delete.block',


  TodayPageAddedBlock = 'today.added.block',
  TodayPageDeletedBlock = 'today.deleted.block',

  TodayLayoutRearrangeBlocks = 'today.layout.rearrange.blocks'
    
}

export enum EventChannel {
  All,
  Analytics,
  EngagementTriggers,
}

export type TrackingEventMetadata =
  | { [key: string]: string | number | boolean }
  | {};

export type GenericTrackingEvent = {
  type: ActivityType;
  channel: EventChannel | EventChannel[];
  timestamp: Moment;
  metadata: TrackingEventMetadata;
};

export type PublishCompletedSchedulePostEvent = GenericTrackingEvent & {
  type: ActivityType.PublishCompletedSchedulePost;
  metadata: {
    platforms: Record<SocialAccountTypes, number>;
    source: 'publish' | 'space';
    type: 'auto_post';
  };
};
export type PublishCompletedAddMediaToScheduledPostEvent = GenericTrackingEvent & {
  type: ActivityType.PublishCompletedAddMediaToScheduledPost;
  metadata: {
    source: 'archive' | 'upload' | 'space';
  };
};

export type TrackingEvent =
  | GenericTrackingEvent
  | PublishCompletedAddMediaToScheduledPostEvent
  | PublishCompletedSchedulePostEvent;
export type TrackingEventInput = {
  type: ActivityType;
} & Partial<TrackingEvent>;

type Handler = (event: TrackingEvent) => void;

let handlers: Handler[] = [];

export const activityTracker = {
  log(input: ActivityType | TrackingEventInput) {
    if (typeof input === 'string') {
      return fromString(input);
    }

    return fromEvent(input);
  },

  subscribe(handler: Handler) {
    handlers.push(handler);

    const unsubscribe = () => {
      handlers = handlers.filter((listHandler) => listHandler !== handler);
    };

    return unsubscribe;
  },

  clearHandlers() {
    handlers = [];
  },
};

const fromString = (type: ActivityType) => {
  fromEvent({ type });
};

const fromEvent = (eventInput: TrackingEventInput) => {
  const event = buildEvent(eventInput);

  for (const handler of handlers) {
    handler(event);
  }
};

const buildEvent = (eventInput: TrackingEventInput): TrackingEvent => {
  return {
    type: eventInput.type,
    timestamp: eventInput.timestamp || moment(),
    channel: eventInput.channel || EventChannel.All,
    metadata: eventInput.metadata || {},
  };
};
