import { AxiosResponse } from "axios";
import { DependencyContainer } from "../../../DependencyContainer";
import { ApiResponse } from "../../../HttpClient";
import { Page, PageLayout, PageTemplate, Site, SitePublic, SitePublicWebResponse, WebBuilderLayoutElement } from "../types";
import { UpdateSitePayload } from "./SiteClient";


export class SitesService {
  constructor(private readonly factory: DependencyContainer) {}

  async createSite(sitePayload?:Site) : Promise<AxiosResponse<ApiResponse<Site>>> {
    return this.factory.siteClient.createSite(sitePayload)
  }
  async getSite(): Promise<AxiosResponse<ApiResponse<Site>>> {
    return this.factory.siteClient.getSite()
  }
  async updateSite(siteId:string, sitePayload:UpdateSitePayload) : Promise<AxiosResponse<ApiResponse<Site>>> {
    return this.factory.siteClient.updateSite(siteId, sitePayload)
  }

  async getPages(siteId: string): Promise<AxiosResponse<ApiResponse<Page[]>>> {
    return this.factory.siteClient.getPages(siteId)
  }
  
  async deletePage(id: string): Promise<void> {
    return await this.factory.siteClient.deletePage(id)
  }
  async createPage(site: string, title: string, pageLayout?: PageLayout): Promise<AxiosResponse<ApiResponse<Page>>> {
    return this.factory.siteClient.createPage(site,title,pageLayout)
  }
  async updatePage(pagePayload:Page) : Promise<AxiosResponse<ApiResponse<Page>>> {
    return this.factory.siteClient.updatePage(pagePayload)
  }

  async createPageLayoutByTemplateId(page:string, templateId: string): Promise<AxiosResponse<ApiResponse<PageLayout>>> {
    return this.factory.siteClient.createPageLayoutByTemplateId(page,templateId)
  }
  async createPageLayout(page:string, staticElement: WebBuilderLayoutElement[], element:WebBuilderLayoutElement[]): Promise<AxiosResponse<ApiResponse<PageLayout>>> {
    return this.factory.siteClient.createPageLayout(page, staticElement, element)
  }
  async getPageLayout(pageId: string ): Promise<AxiosResponse<ApiResponse<PageLayout>>> {
    return this.factory.siteClient.getPageLayout(pageId)
  }
  async updatePageLayout(pageLayoutId: string, pageLayoutPayload: PageLayout): Promise<AxiosResponse<ApiResponse<PageLayout>>> {
    return this.factory.siteClient.updatePageLayout(pageLayoutId,pageLayoutPayload)
  }

  async getPublicPageLayout(handle: string): Promise<AxiosResponse<ApiResponse<SitePublicWebResponse>>> {
    return this.factory.siteClient.getPublicPageLayout(handle)
  }

  async getPublicPageTemplates(category?: string): Promise<AxiosResponse<ApiResponse<PageTemplate[]>>> {
    return this.factory.siteClient.getPublicPageTemplates(category)
  }

  async getPageElements(name: string): Promise<AxiosResponse<ApiResponse<WebBuilderLayoutElement[]>>> {
    return this.factory.siteClient.getPageElements(name)
  }
}
