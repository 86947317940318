export const makeTextExcerpt = (
  text: string,
  length: number,
  suffix: string = '…',
) => (text.length > length ? text.substring(0, length) + suffix : text);

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const randomNumber = () => Math.random();
export const randomNaturalNumber = (min = 1, max = 100) =>
  Math.floor(Math.random() * (max - min + 1)) + min;
export const randomString = () => randomNumber().toString(36).substring(7);

export const stripEmojis = (input: string) =>
  input
    .replace(
      new RegExp(
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
        'g',
      ),
      '',
    )
    .trim();

export const moveElement = <T>(
  array: Array<T>,
  fromIndex: number,
  toIndex: number,
) => {
  const element = array[fromIndex];
  const diff = fromIndex - toIndex;
  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, toIndex),
      element,
      ...array.slice(toIndex, fromIndex),
      ...array.slice(fromIndex + 1, array.length),
    ];
  } else if (diff < 0) {
    // move right
    return [
      ...array.slice(0, fromIndex),
      ...array.slice(fromIndex + 1, toIndex + 1),
      element,
      ...array.slice(toIndex + 1, array.length),
    ];
  }

  return [...array];
};

export const rotateArrayClockwise = <T>(array: Array<T>) => {
  const copy = [...array];
  copy.push(copy.shift()!);
  return copy;
};

export const removeUndefinedKeys = <T extends Object>(obj: T) =>
  Object.keys(obj).reduce(
    (acc, key) =>
      obj[key as keyof T] === undefined
        ? { ...acc }
        : { ...acc, [key]: obj[key as keyof T] },
    {},
  );

export const getQueryVariable = (
  variable: string,
  source?: string,
): string | undefined => {
  const query = source || window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

export const urlWithTimeStamp = (url: string) =>
  url.concat('?').concat(new Date().getTime().toString());

export const stripTimeStampFromUrl = (url: string) =>
  url.replace(/\?\d{13}$/, '');

export const getFilenameFromImageUrl = (url: string) =>
  url.substring(url.lastIndexOf('/') + 1);

export const createFile = async (url: string) => {
  const response = await fetch(urlWithTimeStamp(url));
  const data = await response.blob();
  const metadata = {
    type: data.type,
  };
  return new File([data], getFilenameFromImageUrl(url), metadata);
};

export function chunk<T>(input: T[], chunkSize: number) {
  function* chunksGenerator() {
    for (let i = 0; i < input.length; i += chunkSize) {
      yield input.slice(i, i + chunkSize);
    }
  }

  return Array.from(chunksGenerator());
}

export const formatPercentage = (value: number, total: number) =>
  `${Math.round((value / total) * 100)}%`;
export const roundNumber = (number: number) =>
  Math.round((number + Number.EPSILON) * 100) / 100;

export const hasHttp = (value:string) => {
    var pattern = /^((http|https|ftp):\/\/)/;

    if(!pattern.test(value)) {
        return false
    } else {
        return true
    }
}

export const hasMailTo = (value:string) => {
  var pattern = /^((mailto):)/;

  if(!pattern.test(value)) {
      return false
  } else {
      return true
  }
}