import { useIntl } from './useIntl';
import { SnackbarTypes, userTeamsAtom } from '../../state';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useSnackbar } from './useSnackbar';
import { useSyncedState } from '../../synced-state/synced-state';
import { DependencyContainer } from '../../DependencyContainer';

const service = new DependencyContainer().teamService;

export const useTeams = (initialLoad = true) => {
  const [teams, setTeams] = useRecoilState(userTeamsAtom);
  const { formatMessage } = useIntl();
  const { setSnackbar, resetSnackbar } = useSnackbar();
  const [user] = useSyncedState('user');

  const fetchTeams = useCallback((silentMode = false) => {
    service
      .getUserTeams()
      .then((response) => {
        const teams = response.data.data;
        setTeams(teams);
      })
      .catch(() => {
        if (silentMode) {
          return;
        }
        setSnackbar(
          formatMessage({ id: 'userTeamsLoadingError' }),
          SnackbarTypes.Error,
        );
      });
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    if (initialLoad) {
      fetchTeams();
    }
    return () => resetSnackbar();
  }, [user]);

  return {
    teams,
    fetchTeams,
  };
};
