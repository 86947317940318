import { AxiosResponse } from "axios";
import { ApiResponse, HttpClient } from "../../../HttpClient";
import { AnalyticsEvent, LifetimeAnalytics } from "../../../types";
import { AnalyticsEventPath } from "../../../urls";

export class AnalyticsEventClient extends HttpClient {

    async createAnalyticsEvent(analyticsPayload:AnalyticsEvent) : Promise<AxiosResponse<ApiResponse<AnalyticsEvent>>> {
        return await this.post(AnalyticsEventPath.path(), analyticsPayload)
    }

    async calculateLifetimeAnalytics(page: string, groupBy: string) : Promise<AxiosResponse<ApiResponse<LifetimeAnalytics[]>>> {
        return await this.post(AnalyticsEventPath.lifetime(), {page, groupBy})
    }

    async calculateDateRangeAnalytics(payload) : Promise<AxiosResponse<ApiResponse<{xValues: string[],yValues: number[]}[]>>> {
        return await this.post(AnalyticsEventPath.calculate(), payload)
    }
}