import moment from 'moment';
import firebase from 'firebase/compat';
import { RefObject } from 'react';

export enum TodoTaskStatus {
  Created = 'created',
  Completed = 'completed',
}

export type TodoTask = {
  id: string;
  title: string;
  dueDate?: moment.Moment;
  createdAt: moment.Moment;
  status: TodoTaskStatus;
  userId: string;
};

export type FirestoreDocumentTodoTask = {
  id: string;
  title: string;
  dueDate?: firebase.firestore.Timestamp;
  createdAt: moment.Moment;
  status: TodoTaskStatus;
  userId: string;
};

export type UpdateTodoTaskDto = {
  title: string;
  dueDate: firebase.firestore.Timestamp | null;
  status: TodoTaskStatus;
};

export type TodoActions = {
  deleteTask: (task: TodoTask) => void;
  toggleTask: (task: TodoTask) => void;
  addTask: (
    values: { title: string },
    inputRef: RefObject<HTMLInputElement>,
  ) => void;
  updateTask: (values: Partial<TodoTask>, task: TodoTask) => void;
  goBack: () => void;
  goToTaskDetails: (id: string) => void;
};

export enum TodosViews {
  List = 'list',
  Details = 'details',
}
export type TodosPanelView = {
  view: TodosViews;
  params?: any;
};
