import {
  NotificationType,
  Notification,
  PublishingNotification,
  TodoTaskNotification,
} from './types';
import { IntlShapeWithTranslation } from '../../utils/hooks/useIntl';
import { TranslationKey } from '../../translations/types';
import { removeUndefinedKeys } from '../../utils/helpers';

const defaultParams = {
  strong: (text: string) => text,
};

/**
 * buildMessage is independent from React stack, so that it can be used
 * inside of a service worker
 */
export const buildMessage = (...rest: Parameters<typeof getTranslation>) =>
  rest[0].formatMessage(...getTranslation(...rest));

const getTranslation = (
  intlShape: Pick<IntlShapeWithTranslation, 'formatMessage'>,
  notification: Notification,
  paramOverrides: Record<any, any> = {},
): [{ id: TranslationKey }, Record<any, any>] => {
  switch (notification.notificationType) {
    case NotificationType.NewComment:
      return [
        { id: 'notificationNewComment' },
        getSpaceParams(notification, paramOverrides),
      ];
    case NotificationType.UploadMedia:
      return [
        { id: 'notificationUploadMedia' },
        getSpaceParams(notification, paramOverrides),
      ];
    case NotificationType.TodoTaskCreated:
      return [
        { id: 'notificationTodoTaskCreated' },
        getTodoParams(notification, paramOverrides),
      ];
    case NotificationType.TodoTaskDeleted:
      return [
        { id: 'notificationTodoTaskDeleted' },
        getTodoParams(notification, paramOverrides),
      ];
    case NotificationType.TodoTaskUpdated:
      return [
        { id: 'notificationTodoTaskUpdated' },
        getTodoParams(notification, paramOverrides),
      ];
    case NotificationType.TodoTaskToggled:
      return getTodoTaskToggled(intlShape, notification, paramOverrides);
    case NotificationType.PublishingPostScheduled:
      return [
        { id: 'notificationSchedulePost' },
        getScheduligParams(notification, paramOverrides),
      ];
    case NotificationType.PublishingPostEdited:
      return [
        { id: 'notificationEditPost' },
        getScheduligParams(notification, paramOverrides),
      ];
    case NotificationType.PublishingPostDeleted:
      return [
        { id: 'notificationDeletePost' },
        getScheduligParams(notification, paramOverrides),
      ];
    default:
      return [{ id: 'unknownNotification' }, {}];
  }
};

const getScheduligParams = (
  notification: PublishingNotification,
  paramOverrides: Record<any, any>,
) => {
  const { userName, socialAccountNames } =
    notification.computedProperties || {};
  return {
    ...defaultParams,
    user: userName,
    socialAccounts: socialAccountNames.map((name) => `@${name}`).join(', '),
    ...removeUndefinedKeys(paramOverrides),
  };
};

const getTodoParams = (
  notification: TodoTaskNotification,
  paramOverrides: Record<any, any>,
) => {
  const { userName, spaceName, taskTitle } =
    notification.computedProperties || {};
  return {
    ...defaultParams,
    user: userName,
    targetSpaceName: spaceName,
    targetTaskName: taskTitle,
    ...removeUndefinedKeys(paramOverrides),
  };
};

const getSpaceParams = (
  notification: Notification,
  paramOverrides: Record<any, any>,
) => {
  const { userName, spaceName } = notification.computedProperties || {};
  return {
    ...defaultParams,
    user: userName,
    targetSpaceName: spaceName,
    ...removeUndefinedKeys(paramOverrides),
  };
};

const getTodoTaskToggled = (
  intlShape: Pick<IntlShapeWithTranslation, 'formatMessage'>,
  notification: Notification,
  paramOverrides: Record<any, any> = {},
): ReturnType<typeof getTranslation> => {
  const { userName, spaceName, taskTitle, newStatusMessageId } =
    notification.computedProperties || {};
  return [
    {
      id: newStatusMessageId
        ? 'notificationTodoTaskToggled'
        : 'notificationTodoTaskToggledUnknownStatus',
    },
    {
      ...defaultParams,
      user: userName,
      targetSpaceName: spaceName,
      targetTaskName: taskTitle,
      newStatus: newStatusMessageId
        ? intlShape.formatMessage({
            id: newStatusMessageId as TranslationKey,
          })
        : '',
      ...removeUndefinedKeys(paramOverrides),
    },
  ];
};
