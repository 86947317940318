import React, { useEffect, useState } from 'react';
import Dropdown, { DropdownStyles } from '../Dropdown/Dropdown';
import DropdownToggle from '../Dropdown/DropdownToggle';
import { SettingsIcon, SignOutIcon } from '../Icons/Icons';
import DropdownWindow from '../Dropdown/DropdownWindow';
import DropdownContent from '../Dropdown/DropdownContent';
import FormattedMessage from '../../utils/components/FormattedMessage';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  activeAccountSelector,
  groupIdSelector,
  SnackbarTypes,
  userPaymentsAtom,
  userTeamsAtom,
} from '../../state';
import { Routes } from '../../config';
import List from '../List/List';
import ListItem, { ListItemStyles } from '../List/ListItem';
import { useIntl } from '../../utils/hooks/useIntl';
import { DependencyContainer } from '../../DependencyContainer';
import { useSnackbar } from '../../utils/hooks/useSnackbar';
import { useRedirectAccountSwitch } from '../../utils/hooks/useRedirectAccountSwitch';
import { useGoToRoute } from '../../utils/hooks/useGoToRoute';
import { ActivityType } from '../../utils/activity/activityTracker';
import { useWithActivityTracker } from '../../utils/activity/useWithActivityTracker';
import cx from 'classnames';
import AccountToggle from './AccountToggle';
import AccountsListDropdown from './AccountsListDropdown';
import { useActivityTrackerLog } from '../../utils/activity/useActivityTrackerLog';
import { StylableComponent, useStyles } from '../../utils/hooks/useStyles';

const { teamService, authenticationService } = new DependencyContainer();

type AccountDropdownProps = {
  isExpanded?: boolean;
};
type AccountDropdownStyles = {
  userBadge: string;
};

const addStylesToProfileListItem = (extraRootClassName?: string) => (
  current: ListItemStyles,
) => ({
  ...current,
  root: `${current.root} AccountDropdown__option p-l-s ${
    extraRootClassName || ''
  }`,
  wrapper: `${current.wrapper} AccountDropdown__option-wrapper`,
});

const AccountDropdown: StylableComponent<
  AccountDropdownProps,
  AccountDropdownStyles
> = ({ styles, isExpanded = true }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAccountsDropdown, setShowAccountsDropdown] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(false);

  const setTeams = useSetRecoilState(userTeamsAtom);
  const activeAccount = useRecoilValue(activeAccountSelector);
  const { formatMessage } = useIntl();
  const { setSnackbar } = useSnackbar();
  const goToRoute = useGoToRoute();
  const setUserPayments = useSetRecoilState(userPaymentsAtom);
  const groupId = useRecoilValue(groupIdSelector);
  const activityTrackerLog = useActivityTrackerLog();
  const withActivityTracker = useWithActivityTracker();

  const redirectAccountSwitch = withActivityTracker(
    {
      type: ActivityType.AccountMenuClickedSettings,
      metadata: { groupId },
    },
    useRedirectAccountSwitch({
      forceRedirectionToAccountSettings: true,
    }),
  );

  const classes = useStyles(
    {
      userBadge: 'AccountDropdown__user-badge',
    },
    styles,
  );

  useEffect(() => {
    if (showDropdown) {
      setTeamsLoading(true);
      teamService
        .getUserTeams()
        .then((response) => {
          setTeams(response.data.data);
          setTeamsLoading(false);
        })
        .catch(() => {
          setSnackbar(
            formatMessage({ id: 'errorsSomethingWentWrong' }),
            SnackbarTypes.Error,
          );
          setTeamsLoading(false);
        });
    }
  }, [showDropdown]);

  if (activeAccount == null) {
    return null;
  }

  const makeSignOut = async () => {
    activityTrackerLog(ActivityType.AccountMenuClickedLogOut);

    setUserPayments(null);
    await authenticationService.signOut();
    goToRoute(Routes.Index,  { handle:"" });

    activityTrackerLog(ActivityType.LogoutUser);
  };

  const toggleDropdown = () => {
    setShowDropdown((v) => !v);
  };

  return (
    <>
      <Dropdown
        isOpen={showDropdown}
        toggle={toggleDropdown}
        styles={(current: DropdownStyles) => ({
          ...current,
          root: cx(`${current.root} AccountDropdown__toggle`, {
            'AccountDropdown__toggle--shrunk': !isExpanded,
          }),
        })}
      >
        <DropdownToggle
          styles={(current) => ({
            root: `${current.root} AccountDropdown__toggle p-t-s`,
          })}
        >
          <AccountToggle
            activeAccount={activeAccount}
            isExpanded={isExpanded}
            styles={(current) => ({
              ...current,
              userBadge: `${current.userBadge} ${classes.userBadge}`,
            })}
          />
        </DropdownToggle>
        <DropdownWindow
          styles={(current) => ({
            root: `${current.root} AccountDropdown__window`,
          })}
          expansionDirection="top"
          isContentLoading={teamsLoading}
        >
          <DropdownContent
            styles={(current) => ({
              root: `${current.root} AccountDropdown__dropdown-content`,
            })}
          >
            <AccountsListDropdown
              showDropdown={showAccountsDropdown}
              isContentLoading={teamsLoading}
              setShowDropdown={setShowAccountsDropdown}
              activeAccount={activeAccount}
              onChangeAccount={() => setShowDropdown(false)}
              styles={(current) => ({
                ...current,
                dropdownWindow: `${current.dropdownWindow} AccountDropdown__accounts-list-dropdown-window`,
              })}
            />

            <List>
              <ListItem
                title={formatMessage({ id: 'settings' })}
                onClick={() => redirectAccountSwitch(activeAccount)}
                icon={<SettingsIcon />}
                styles={addStylesToProfileListItem()}
                withWrapper
              >
                <FormattedMessage id="settings" />
              </ListItem>
              <ListItem
                title={formatMessage({ id: 'signOut' })}
                onClick={makeSignOut}
                icon={<SignOutIcon />}
                styles={addStylesToProfileListItem('AccountDropdown__logout')}
                withWrapper
              >
                <FormattedMessage id="signOut" />
              </ListItem>
            </List>
          </DropdownContent>
        </DropdownWindow>
      </Dropdown>
    </>
  );
};

export default AccountDropdown;
