import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import {
  DesignPageIcon,
  InsightsPageIcon,
  PublishPageIcon,
  TodayPageIcon,
} from '../../../components/Icons/Icons';
import FreeMemberView from '../component/FreeMemberView';
import PaidMemberView from '../component/PaidMemberView';

import designPageGraphic from '../../../assets/images/membership/design-page-desc-graphic.png';
import insightPageGraphic from '../../../assets/images/membership/insights-page-desc-graphic.png';
import schedulePageGraphic from '../../../assets/images/membership/schedule-page-desc-graphic.png';
import todayPageGraphic from '../../../assets/images/membership/today-page-desc-graphic.png';

const productData = [
  {
    title: 'Be Everywhere',
    description:
      'One place for all your photos, videos, hashtags, captions, ideas, and posts.',
    toolName: 'Design Tool',
    icon: <DesignPageIcon />,
    graphic: <img src={designPageGraphic} width="320" height="auto" />,
  },
  {
    title: 'Post it now (or later)',
    description:
      'Schedule all your posts and plan out your week, or month - in minutes.',
    toolName: 'Publish Tool',
    icon: <PublishPageIcon />,
    graphic: <img src={schedulePageGraphic} width="320" height="auto" />,
  },
  {
    title: "Know what's working",
    description:
      'Discover your top posts, best times, top hashtags, follower count, and more.',
    toolName: 'Insights Tool',
    icon: <InsightsPageIcon />,
    graphic: <img src={insightPageGraphic} width="320" height="auto" />,
  },
  {
    title: "Know what's working",
    description:
      'Discover your top posts, best times, top hashtags, follower count, and more.',
    toolName: 'Today.Page',
    icon: <TodayPageIcon />,
    graphic: <img src={todayPageGraphic} width="320" height="auto" />,
  },
];

const featureData = [
  {
    id: 'color-map',
    title: 'Color Map',
  },
  {
    id: 'collage-tool',
    title: 'Collage',
  },
  {
    id: 'auto-posting',
    title: 'Auto-Posting',
  },
  {
    id: 'hashtag',
    title: 'Hashtag Generator',
  },
  {
    id: 'unlimited-posts',
    title: 'Unlimited Posts',
  },
];

const socialProofData = [
  {
    quote:
      '"UNUM has helped present his work as he shares his portfolio. Curating colors, flow, and content has allowed his clients to get an instant understanding of the scope of his work."',
    user: {
      avatarUrl:
        'https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/reviews/dave-herring.jpg',
      title: 'Dave Herring @fatherinthewild',
      description: 'Author, Leader, Creator',
    },
  },
  {
    quote:
      '"For brands who frequently create and post content across different social media platforms, UNUM will save you a significant amount of time and keep you extremely organized! On top of that, the whole team is amazing to work with - Highly recommend!"',
    user: {
      avatarUrl:
        'https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/reviews/adam-meghji.jpg',
      title: 'Adam Meghji  @ The Saree Room',
      description: 'CEO',
    },
  },
  {
    quote:
      '“We definitely use UNUM everyday. From planning for the day-to-day and for posts in the future, it helps our feeds stay strategic and aesthetic”',
    user: {
      avatarUrl:
        'https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/reviews/yasmine.jpg',
      title: 'Yasmine Khosrowshahi Blue Tick Social',
      description: 'CEO',
    },
  },
  {
    quote:
      '“We use it for freaking everything. Use it for ideas, use it for new concepts; use it for promoting and raising money. We literally use it for everything you can possibly think of as it relates to social media. It\'s really like our compass. It has been invaluable."',
    user: {
      avatarUrl:
        'https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/reviews/teo-hunter.jpg',
      title: 'Teo Hunter @crownsandhops',
      description: 'COO',
    },
  },
  {
    quote:
      '“Every day I use UNUM, an app where you can visualize how harmonic the images in your feed will look before you post them.”',
    user: {
      avatarUrl:
        'https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/reviews/forbes-logo.jpg',
      title: 'Forbes @eriksantos',
      description: 'Press',
    },
  },
];

const MembershipContainer: FunctionComponent = () => {
  const location = useLocation();

  const getPurchaseStatus = () =>
    location.search
      ? new URLSearchParams(location.search).get('status') || ''
      : '';

  const isPaid = getPurchaseStatus() === 'paid';

  return isPaid ? (
    <PaidMemberView
      productData={productData}
      featureData={featureData}
      socialProofData={socialProofData}
    />
  ) : (
    <FreeMemberView
      productData={productData}
      featureData={featureData}
      socialProofData={socialProofData}
    />
  );
};

export default MembershipContainer;
