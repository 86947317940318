import React, { FunctionComponent, useEffect, useCallback } from 'react';
import { useIntl } from '../../../../utils/hooks/useIntl';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import ReadOnlyInput from '../../../../components/ReadOnlyInput/ReadOnlyInput';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  hasUserEliteSubscriptionSelector,
  userBillingDetailsAtom,
  userSelector,
  userPaymentsAtom,
  SnackbarTypes,
  AccountType,
  activeAccountSelector,
  paywallTargetAccountAtom,
} from '../../../../state';
import UserMembershipBillingInfo from './UserMembershipBillingInfo';
import { useSyncedState } from '../../../../synced-state/synced-state';
import { useParams } from 'react-router';
import { CheckoutSessionModes } from '../../../payment/PaymentClient';
import PaymentSuccessModal from '../../../team/components/TeamMembership/PaymentSuccessModal';
import PaymentCancelModal from '../../../team/components/TeamMembership/PaymentCancelModal';
import {
  PaymentStatusRouteParams,
  PaymentChannels,
} from '../../../payment/PaymentService';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useSnackbar } from '../../../../utils/hooks/useSnackbar';
import { usePaywall } from '../../../../utils/hooks/usePaywall';
import { ActivityType } from '../../../../utils/activity/activityTracker';
import { useActivityTrackerLog } from '../../../../utils/activity/useActivityTrackerLog';

const paymentService = new DependencyContainer().paymentService;

const UserMembership: FunctionComponent = () => {
  const { formatMessage } = useIntl();
  const { action } = useParams();
  const { setSnackbar } = useSnackbar();
  const hasUserEliteSubscription = useRecoilValue(
    hasUserEliteSubscriptionSelector,
  );
  const payments = useRecoilValue(userPaymentsAtom);

  const user = useRecoilValue(userSelector);
  const userBillingDetails = useRecoilValue(userBillingDetailsAtom);
  const [, setPaywallOpened] = usePaywall();
  const [paymentCache, setPaymentCache] = useSyncedState('paymentCache');
  const activeAccount = useRecoilValue(activeAccountSelector);
  const setPaywallTargetAccount = useSetRecoilState(paywallTargetAccountAtom);
  const activityTrackerLog = useActivityTrackerLog();

  const isPaymentForPaidSubscription =
    paymentCache.relation === 'profile' &&
    paymentCache.mode === CheckoutSessionModes.Subscription;

  const notPurchasedViaNativeApp =
    payments?.purchased_via !== PaymentChannels.Apple &&
    payments?.purchased_via !== PaymentChannels.Google;
  const shouldShowBillingFields =
    hasUserEliteSubscription && user && notPurchasedViaNativeApp;

  useEffect(() => {
    const isPaymentMethodChanged =
      paymentCache.mode === CheckoutSessionModes.Setup &&
      paymentCache.relation === 'profile';
    const isAfterUpgrade =
      paymentCache.mode === CheckoutSessionModes.Subscription &&
      paymentCache.type === 'upgrade' &&
      paymentCache.relation === 'profile';

    if (isAfterUpgrade && action === PaymentStatusRouteParams.Success) {
      activityTrackerLog(ActivityType.CompletedPurchase);
    }

    if (isPaymentMethodChanged) {
      setTimeout(() => {
        if (action === PaymentStatusRouteParams.Success) {
          setSnackbar(
            formatMessage({ id: 'globalPaymentChangeSuccess' }),
            SnackbarTypes.Success,
          );
        }
        resetPaymentCache();
      }, 1000);
    }
  }, []);

  const renderPlanName = () => {
    const copyId = hasUserEliteSubscription
      ? 'userMembershipTabPlanElite'
      : 'userMembershipTabPlanFree';
    return formatMessage({ id: copyId });
  };

  const handleChangePlan = useCallback(() => {
    if (!activeAccount) {
      return;
    }
    setPaywallTargetAccount(activeAccount);
    setPaywallOpened(true);
  }, [activeAccount]);

  const resetPaymentCache = () => {
    setPaymentCache({
      plan: 'unknown',
      mode: CheckoutSessionModes.Subscription,
    });
  };

  const handleManagePaymentMethod = async () => {
    if (!payments) {
      return;
    }

    if (
      payments.purchased_via !== PaymentChannels.StripeCheckout &&
      payments.purchased_via !== PaymentChannels.Stripe
    ) {
      return;
    }

    try {
      const sessionId = await paymentService.getUserSetupCheckoutSession(
        user!._id,
      );
      setPaymentCache({
        relation: 'profile',
        plan: '',
        mode: CheckoutSessionModes.Setup,
      });
      await paymentService.goToCheckout(sessionId);
    } catch (e) {
      setSnackbar(
        formatMessage({ id: 'errorsSomethingWentWrong' }),
        SnackbarTypes.Error,
      );
    }
  };

  const handleSubmitBillingEmail = async (values: any) => {
    try {
      if (!user) {
        return;
      }

      await paymentService.updateBillingEmail({
        accountType: AccountType.Personal,
        accountId: user._id,
        email: values.billingEmail,
      });
      setSnackbar(formatMessage({ id: 'userProfileSuccessfulUpdate' }));
    } catch (e) {
      setSnackbar(
        formatMessage({ id: 'errorsSomethingWentWrong' }),
        SnackbarTypes.Error,
      );
    }
  };

  return (
    <div className="UserMembership">
      <div className="UserMembership__fields">
        <ReadOnlyInput
          label={formatMessage({
            id: 'userMembershipTabPlanName',
          })}
          value={renderPlanName()}
          buttonLabel={formatMessage({
            id: 'userMembershipTabChangePlan',
          })}
          onButtonClick={() => handleChangePlan()}
        />
        {shouldShowBillingFields && (
          <UserMembershipBillingInfo
            userId={user!._id}
            userBillingDetails={userBillingDetails}
            onClickViewAllReceipts={() => {}}
            onSubmitBillingEmail={handleSubmitBillingEmail}
            onManagePaymentMethod={handleManagePaymentMethod}
          />
        )}
      </div>
      {!hasUserEliteSubscription && (
        <div className="UserMembership__free-plan-info">
          <p>
            <FormattedMessage id="userMembershipTabFreePlanInfoLine1" />
          </p>
          <p>
            <FormattedMessage id="userMembershipTabFreePlanInfoLine2" />
          </p>
          <p>
            <FormattedMessage id="userMembershipTabFreePlanInfoLine3" />
          </p>
          <p>
            <FormattedMessage id="userMembershipTabFreePlanInfoLine4" />
          </p>
        </div>
      )}
      {user && (
        <>
          <PaymentSuccessModal
            planName={paymentCache.plan}
            planType={paymentCache.type || 'upgrade'}
            open={
              isPaymentForPaidSubscription &&
              action === PaymentStatusRouteParams.Success
            }
            onClose={resetPaymentCache}
          />
          <PaymentCancelModal
            planName={paymentCache.plan}
            planType={paymentCache.type || 'upgrade'}
            open={
              isPaymentForPaidSubscription &&
              action === PaymentStatusRouteParams.Cancel
            }
            onClose={resetPaymentCache}
          />
        </>
      )}
    </div>
  );
};

export default UserMembership;
