import { ApiResponse, HttpClient } from '../../HttpClient';
import {
  CloneStoryboardTarget,
  Storyboard,
  StoryboardFilters,
  StoryboardsResponse,
  ClonedSpace,
} from './types';
import { MarketplacePaths } from '../../urls';
import { AxiosResponse } from 'axios';
import { Pagination } from '../../types';

export class MarketplaceClient extends HttpClient {
  async getStoryBoards(
    pagination: Pagination,
    filters?: StoryboardFilters,
  ): Promise<StoryboardsResponse> {
    const skip = pagination.page * pagination.limit;
    const url =
      filters && filters.publicProfileIdOrHandle
        ? MarketplacePaths.getAllFromPublicProfile(
            filters.publicProfileIdOrHandle,
            skip,
            pagination.limit,
          )
        : MarketplacePaths.getAll(skip, pagination.limit);
    const response = await this.get<
      AxiosResponse<ApiResponse<StoryboardsResponse>>
    >(url);
    return response.data.data;
  }

  async getStoryboard(storyboardId: string): Promise<Storyboard> {
    const response = await this.get<AxiosResponse<ApiResponse<Storyboard>>>(
      MarketplacePaths.getStoryboard(storyboardId),
    );
    return response.data.data;
  }

  async cloneStoryboard(
    storyboardId: string,
    target: CloneStoryboardTarget,
  ): Promise<ClonedSpace> {
    const payload =
      target.type === 'group' ? { groupId: target.id } : { userId: target.id };
    const response = await this.post<AxiosResponse<ApiResponse<ClonedSpace>>>(
      MarketplacePaths.cloneStoryboard(storyboardId),
      payload,
    );

    return response.data.data;
  }
}
