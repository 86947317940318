import React from 'react';
import { FunctionComponent } from 'react';

type FeatureCardProps = {
  id: String;
  title: String;
};
const FeatureCard: FunctionComponent<FeatureCardProps> = ({ id, title }) => {
  return (
    <div className="FeatureCard">
      <div className="FeatureCard__hero">
        <img
          style={{
            objectFit: 'cover',
          }}
          src={`https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/features/${id}.png`}
          width="100%"
        />
      </div>
      <div className="FeatureCard__text">{title}</div>
    </div>
  );
};

export default FeatureCard;
