import { InstagramGraphFields } from './features/dashboard/types';

export const UserPaths = {
  login: '/v1/login',
  register: '/v1/register',
  userData: (id: string) => `/v1/emails/${id}`,
  updateEmail: '/v2/email/update_email',
  updatePassword: '/v1/email/reset_password/', //@TODO: change backend so that path doesn't have ending slash
  forgotPassword: '/v1/email/forgot_password/', //@TODO: change backend so that path doesn't have ending slash
  getBillingPlans: `/v1/billing-plans?type=user`,
  getUserPayments: (userId: string) => `/v2/users/${userId}/payments`,
  getUserBillings: (userId: string) => `/v1/users/${userId}/billing`,
  getUserByEmail: (email: string) => `/v1/emails?email=${email}`,
  cancelSubscription: (userId: string) => `/v1/users/${userId}/billing/cancel`,
  verifyJWT: '/v1/token/verify',
  authWithJWT: '/v2/auth?type=jwt',
  billingDetails: (userId: string) => `/v1/users/${userId}/billing`,
} as const;

export const UploadPaths = {
  resources: '/v1/resources?addPolicy=true&acl=public-read',
  avatar: '/v2/media/upload?addPolicy=true&acl=public-read',
} as const;

export const RankPaths = {
  between: (rhs: string, lhs: string, quantity: number) =>
    `/v1/lexorank-between?quantity=${encodeURIComponent(
      quantity,
    )}&lhs=${encodeURIComponent(lhs)}&rhs=${encodeURIComponent(rhs)}`,
  initial: (quantity: number) =>
    `/v1/lexorank-initial?quantity=${encodeURIComponent(quantity)}`,
};

export const TeamPaths = {
  create: '/v1/groups',
  getAll: '/v1/groups',
  get: (id: string) => `/v1/groups/${id}`,
  acceptInvitation: '/v1/groups/invitations/accept',
  sendInvitation: (groupId: string) => `/v1/groups/${groupId}/invitations`,
  cancelInvitation: (groupId: string) => `/v1/groups/${groupId}/invitations`,
  removeMember: (groupId: string, userId: string) =>
    `/v1/groups/${groupId}/members/${userId}`,
  getBillingPlans: `/v1/billing-plans?type=group`,
  getCheckoutSession: `/v1/checkout-session`,
  getTeamBillingDetails: (teamId: string) => `/v1/groups/${teamId}/billing`,
  getTeamInvoices: (teamId: string) => `/v1/groups/${teamId}/billing/invoices`,
  getProration: (teamId: string, priceId: string) =>
    `/v1/groups/${teamId}/billing/proration/${priceId}`,
  uncancelSubscription: (teamId: string) =>
    `/v1/groups/${teamId}/billing/uncancel`,
} as const;

export const MarketplacePaths = {
  getAll: (skip: number, limit: number) =>
    `/v1/storyboards?skip=${skip}&limit=${limit}`,
  getAllFromPublicProfile: (
    publicProfileIdOrHandle: string,
    skip: number,
    limit: number,
  ) =>
    `/v1/storyboards?publicProfileIdOrHandle=${publicProfileIdOrHandle}&skip=${skip}&limit=${limit}`,
  getStoryboard: (storyboardId: string) => `/v1/storyboards/${storyboardId}`,
  cloneStoryboard: (storyboardId: string) =>
    `/v1/storyboards/${storyboardId}/clone`,
  getStoryboardUrl: (id: string) =>
    `${process.env.REACT_APP_PUBLIC_URL}/marketplace/${id}`,
} as const;

export const PublicProfilePaths = {
  get: (publicProfileId: string) => `/v1/public-profiles/${publicProfileId}`,
  getByType: (type: 'user' | 'group', typeId: string) =>
    type === 'user'
      ? `/v1/emails/${typeId}/public-profiles`
      : `/v1/groups/${typeId}/public-profiles`,
  update: (type: 'user' | 'group', typeId: string) =>
    type === 'user'
      ? `/v1/emails/${typeId}/public-profiles`
      : `/v1/groups/${typeId}/public-profiles`,
  create: `/v1/public-profiles`,
} as const;

export const SpacePaths = {
  add: '/v1/spaces',
  delete: (spaceId: string) => `/v2/spaces/${spaceId}`,
  update: (spaceId: string) => `/v1/spaces/${spaceId}`,
  clone: (spaceId: string) => `/v1/spaces/${spaceId}/clone`,
  getSpaceIntegrations: (spaceId: string) =>
    `/v3/spaces/${spaceId}/integrations`,
  getPhotosFromInstagram: (
    accessToken: string,
    fields: InstagramGraphFields,
    accountId?: string,
  ) =>
    `/${accountId || 'me'}/media?fields=${Object.keys(fields).join(
      ',',
    )}&access_token=${accessToken}`,
} as const;

export const PermissionScopes = {
  InstagramBusinessAccount: [
    'instagram_basic',
    'instagram_content_publish',
    'pages_show_list',
    'instagram_manage_insights',
    'pages_read_engagement',
    'pages_show_list',
  ] as const,
  FacebookPage: [
    'pages_show_list',
    'pages_manage_posts',
    'pages_read_engagement',
  ] as const,
  FacebookProfile: ['publish_to_groups', 'publish_video'] as const,
};

type PermissionScope = typeof PermissionScopes[keyof typeof PermissionScopes];

export const IntegrationPaths = {
  getInstagramAuthorizationUrl: (redirectUri: string) =>
    `https://api.instagram.com/oauth/authorize?client_id=${process.env.REACT_APP_INSTAGRAM_CLIENT_ID}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`,
  getFacebookAuthorizationUrl: (
    redirectUri: string,
    scope: PermissionScope,
    state?: any,
  ) =>
    `${process.env.REACT_APP_FACEBOOK_V10_LOGIN_URL}/dialog/oauth?client_id=${
      process.env.REACT_APP_FACEBOOK_CLIENT_ID
    }&redirect_uri=${redirectUri}&state=${
      state ? JSON.stringify(state) : '{}'
    }&response_type=token&scope=${scope.join()}`,
  getInstagramBusinessAccountsUrl: (accessToken: string) =>
    `/me/accounts?fields=instagram_business_account{id,name,username,profile_picture_url,followers_count},access_token&access_token=${accessToken}`,
  getFacebookPagesUrl: (accessToken: string) =>
    `/me/accounts?fields=id,name,access_token,picture&access_token=${accessToken}`,
  getFacebookGroupsUrl: (accessToken: string) =>
    `/me/groups?access_token=${accessToken}`,
  getFacebookPersonalProfileUrl: (accessToken: string) =>
    `/me?fields=id,name,picture&access_token=${accessToken}`,
  integrations: '/v3/integrations',
  singleIntegration: (integrationId: string) =>
    `/v3/integrations/${integrationId}`,
  getValidateFacebookTokenUrl: (accessToken: string) =>
    `/v1/facebook/validate_token?token=${accessToken}`,
  getLinkedInAuthorizationUrl: (redirectUri: string) =>
    `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${process.env.REACT_APP_LINKEDIN_CLIENT_ID}&redirect_uri=${redirectUri}&scope=w_member_social,r_liteprofile`,
  getPinterestAuthorizationUrl: (redirectUri: string) =>
    `https://www.pinterest.com/oauth/?consumer_id=${process.env.REACT_APP_PINTEREST_CLIENT_ID}&redirect_uri=${redirectUri}&scope=user_accounts:read,pins:read,pins:write,boards:write,boards:read,boards:read_secret,boards:write_secret,pins:read_secret,pins:write_secret&response_type=code`,
};

export const NotificationPath = {
  token: '/v1/notification/token',
};

export const PublishPath = {
  scheduledPosts: '/v1/publish/schedules',
  getScheduledPost: (postId: string) => `/v1/publish/schedules/${postId}`,
  readAssetsUrl: (groupId: string, limit: number, skip: number) =>
    `/v1/publish/assets?groupId=${groupId}&limit=${limit}&skip=${skip}`,
  publishListV2: '/v2/publish',
  publishListV1: '/v1/publish',
  publishItemV2: (id: string) => `/v2/publish/${id}`,
  facebookInstantPublish: '/testing/publish-now-sync',
};

export const InsightsPath = {
  getDefaultDashboard: (integrationId: string) =>
    `/v1/insights-dashboard/default/${integrationId}`,
};

export const SitePath = {
  getSitePath: () =>
    `v1/sites`,
  updatePath: (id: string) =>
    `v1/sites/${id}`,

};

export const SitePublicPath = {
  getSitePath: (handle: string) =>
    `v1/public/site?handle=${handle}`,
};

export const PageTemplatePublicPath = {
  getTemplates: () => 
    `v1/public/page-templates`
}

export const PagesPath = {
  getPages: (siteId: string) =>
    `v1/pages?site=${siteId}`,
  updatePage: (pageId: string) =>
  `v1/pages/${pageId}`,
  base: () => `v1/pages`,

};

export const PageLayoutPath = {
  createPageLayout: () =>
  `v1/page-layouts`,
  getPageLayout: (pageId: string) =>
    `v1/page-layouts?page=${pageId}`,
  updatePageLayout: (pageLayoutId: string) =>
    `v1/page-layouts/${pageLayoutId}`,

};

export const PageElementPath = {
  pageElementByName: (name: string) =>
  `v1/page-elements?name=${name}`,
}

export const AnalyticsEventPath = {
  path: () => 
    'v1/analytics-events' ,
  lifetime: () => 
    '/v1/analytics-events-lifetime',
  calculate: () => 
    '/v1/analytics-events-calculate'
}