import React from 'react';
import classNames from 'classnames';
import { StylableComponent, useStyles } from '../../utils/hooks/useStyles';

export enum ButtonGroupAlignment {
  Left = 'left',
  Right = 'right',
  Center = 'center',
  Between = 'between',
}

type ButtonGroupProps = {
  align?: ButtonGroupAlignment;
};

type ButtonGroupStyles = {
  root: string;
};

const ButtonGroup: StylableComponent<ButtonGroupProps, ButtonGroupStyles> = ({
  align = 'left',
  styles,
  children,
}) => {
  const rootClassName = classNames('ButtonGroup', {
    [`ButtonGroup--${align}`]: true,
  });
  const classes = useStyles({ root: rootClassName }, styles);
  return <div className={classes.root}>{children}</div>;
};

export default ButtonGroup;
