import { AxiosResponse } from 'axios';
import { ApiResponse, HttpClient } from '../../../HttpClient';
import { ResourceOwner } from '../../../state';
import { IntegrationPaths, SpacePaths } from '../../../urls';
import { SpaceIntegration } from '../../dashboard/types';
import {
  SocialAccount,
  SocialAccountPlatformDetails,
} from '../../../sets/integration/types';

export type CreateImplicitFlowIntegrationRequest = {
  title: string;
  avatarImageUrl: string;
  accessToken: string;
  expirationDate: string;
} & SocialAccountPlatformDetails &
  ResourceOwner;

export type CreateCodeFlowIntegrationRequest = {
  code: string;
  redirectUri: string;
} & SocialAccountPlatformDetails &
  ResourceOwner;

type CreateIntegrationRequest =
  | CreateImplicitFlowIntegrationRequest
  | CreateCodeFlowIntegrationRequest;

type UpdateImplicitFlowIntegrationRequest = SocialAccountPlatformDetails &
  Partial<CreateImplicitFlowIntegrationRequest>;

export type UpdateCodeFlowIntegrationRequest = Omit<
  CreateCodeFlowIntegrationRequest,
  'userId' | 'groupId'
>;

type UpdateIntegrationRequest =
  | UpdateImplicitFlowIntegrationRequest
  | UpdateCodeFlowIntegrationRequest;

export class IntegrationV3Client extends HttpClient {
  createIntegration(
    payload: CreateIntegrationRequest,
  ): Promise<AxiosResponse<ApiResponse<SocialAccount>>> {
    return this.post(IntegrationPaths.integrations, payload);
  }

  updateSpaceIntegration(
    spaceId: string,
    integrationId: string,
  ): Promise<AxiosResponse<ApiResponse<{ spaceId: string }>>> {
    return this.put(SpacePaths.getSpaceIntegrations(spaceId), {
      integration: integrationId,
    });
  }

  updateIntegration(
    integrationId: string,
    payload: UpdateIntegrationRequest,
  ): Promise<AxiosResponse<ApiResponse<SocialAccount>>> {
    return this.put(IntegrationPaths.singleIntegration(integrationId), payload);
  }

  async deleteIntegration(integrationId: string): Promise<void> {
    await this.delete(IntegrationPaths.singleIntegration(integrationId));
  }

  getSpaceIntegration(
    spaceId: string,
  ): Promise<AxiosResponse<ApiResponse<SpaceIntegration>>> {
    return this.get(SpacePaths.getSpaceIntegrations(spaceId));
  }

  getIntegrations(
    owner: ResourceOwner,
  ): Promise<AxiosResponse<ApiResponse<SocialAccount[]>>> {
    const params = new URLSearchParams();

    if (owner.groupId != null) {
      params.append('groupId', owner.groupId);
    } else {
      params.append('userId', owner.userId);
    }

    return this.get(IntegrationPaths.integrations, { params });
  }
}
