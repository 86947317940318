import { DependencyContainer } from '../../DependencyContainer';

export interface ForgotPasswordServiceProtocol {
  requestChangePasswordLink(email: string): Promise<void>;
  resetPassword(token: string, newPassword: string): Promise<void>;
}

export class ForgotPasswordService implements ForgotPasswordServiceProtocol {
  constructor(private readonly factory: DependencyContainer) {}

  async requestChangePasswordLink(email: string): Promise<void> {
    return this.factory.forgotPasswordClient.makeRequestChangePasswordLink(
      email,
      `${process.env.REACT_APP_PUBLIC_URL}/forgot/reset/`,
    );
  }

  async resetPassword(token: string, newPassword: string): Promise<void> {
    return this.factory.forgotPasswordClient.resetPassword(token, newPassword);
  }
}
