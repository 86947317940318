import React, { useState, ReactNode, useEffect } from 'react';
import { StyleModifier, useStyles } from '../../utils/hooks/useStyles';
import cx from 'classnames';

export type SelectableListProps<T, S> = {
  options: Array<T>;
  onSelectedOption?: (option: T, index: number) => void;
  initiallySelectedIndex?: number;
  renderOption: (option: T, isSelected: boolean) => ReactNode | ReactNode[];
  allowUnselected?: boolean;
  styles?: StyleModifier<S>;
};

export type SelectableListStyles = {
  root: string;
  option: string;
};

function SelectableList<T>(
  props: SelectableListProps<T & { visible?: boolean }, SelectableListStyles>,
) {
  const [selectedIndex, setSelectedIndex] = useState(
    props.initiallySelectedIndex || (props.allowUnselected ? undefined : 0),
  );

  useEffect(() => {
    props.initiallySelectedIndex !== undefined &&
      setSelectedIndex(props.initiallySelectedIndex);
  }, [props.initiallySelectedIndex]);

  const handleOptionSelection = (index: number) => {
    setSelectedIndex(index);
    props.onSelectedOption &&
      props.onSelectedOption(props.options[index], index);
  };

  const classes = useStyles<SelectableListStyles>(
    {
      root: 'SelectableList',
      option: 'SelectableList__option',
    },
    props.styles,
  );

  return (
    <div className={classes.root}>
      {props.options.map((option, index) => (
        <button
          key={`option_${index}`}
          type="button"
          className={cx(classes.option, {
            hidden: option.visible === undefined ? false : !option.visible,
          })}
          onClick={() => handleOptionSelection(index)}
        >
          <>{props.renderOption(option, index === selectedIndex)}</>
        </button>
      ))}
    </div>
  );
}

export default SelectableList;
