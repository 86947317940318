import { AxiosResponse, CancelTokenSource } from 'axios';
import moment, { Moment } from 'moment';
import userDefaultAvatar from '../../assets/svg/user-default-avatar.svg';
import { DependencyContainer } from '../../DependencyContainer';
import { ApiResponse } from '../../HttpClient';
import { UserRoles, UserTeam } from '../../state';
import { User } from '../login/UserEntity';
import {
  TeamBillingDetailsResponse,
  TeamSubscriptionResponse,
} from './TeamClient';

export type UpdatableTeamInformation =
  | 'teamName'
  | 'teamOwner'
  | 'teamAvatarUrl';
export type TeamInformationProperty =
  | UpdatableTeamInformation
  | 'teamId'
  | 'subscription'
  | 'freeTrialEnd';

export type TeamInformationsType = {
  teamId: string;
  teamName: string;
  teamOwner: string;
  teamAvatarUrl: string;
  teamMembers: TeamMemberType[];
  subscription?: TeamSubscriptionResponse;
  freeTrialEnd: Moment;
};

export type TeamMemberType = User & {
  role: UserRoles;
  isPending: boolean;
};

type SettingToUpdater = {
  [key in UpdatableTeamInformation]: (
    teamId: string,
    newValue: string,
  ) => Promise<void>;
};

export class TeamService {
  private readonly settingToUpdaterMap: SettingToUpdater;

  constructor(private readonly factory: DependencyContainer) {
    this.settingToUpdaterMap = {
      teamName: (teamId: string, newValue: string) =>
        this.updateTeamName(teamId, newValue),
      teamOwner: (teamId: string, newValue: string) =>
        this.updateTeamOwner(teamId, newValue),
      teamAvatarUrl: (teamId: string, newValue: string) =>
        this.updateTeamAvatarUrl(teamId, newValue),
    };
  }

  async getTeamInformation(
    teamId: string,
    cancelToken?: CancelTokenSource,
  ): Promise<TeamInformationsType> {
    try {
      const information = await this.factory.teamClient.getTeamInformation(
        teamId,
        cancelToken,
      );
      const {
        _id,
        name,
        members,
        pending,
        subscription,
        freeTrialEnd,
        avatarUrl,
      } = information.data.data;

      return {
        teamId: _id,
        teamName: name,
        teamOwner: this.getTeamOwnerEmail(members),
        teamAvatarUrl: avatarUrl || '',
        teamMembers: this.parseTeamMembers(members, pending),
        subscription: subscription as any,
        freeTrialEnd: moment.unix(freeTrialEnd),
      };
    } catch (error) {
      // @TODO: Check if response status is correct and if there are more response codes
      const messageId =
        error.response?.status === 400
          ? 'teamTeamInformationNotFoundError'
          : 'errorsSomethingWentWrong';
      return Promise.reject(messageId);
    }
  }

  private parseTeamMembers(
    membersData: TeamMemberType[],
    pendingData: TeamMemberType[],
  ): TeamMemberType[] {
    const pending = pendingData.map((invitation) => ({
      _id: invitation._id || '',
      fullName: invitation.fullName || '',
      avatarUrl: invitation.avatarUrl || userDefaultAvatar,
      email: invitation.email,
      role: invitation.role,
      isPending: true,
    }));
    const members = membersData.map((member) => ({
      ...member,
      avatarUrl: member.avatarUrl || userDefaultAvatar,
      isPending: false,
    }));
    return [...pending, ...members];
  }

  private getTeamOwnerEmail(members: TeamMemberType[]): string {
    const found = members.find((member) => member.role === UserRoles.Owner);
    return found ? found.email : '';
  }

  async createTeam(
    name: string,
  ): Promise<AxiosResponse<ApiResponse<UserTeam>>> {
    return this.factory.teamClient.createTeam(name);
  }

  async deleteTeam(id: string): Promise<void> {
    return this.factory.teamClient.deleteTeam(id);
  }

  async getUserTeams(): Promise<AxiosResponse<ApiResponse<UserTeam[]>>> {
    return this.factory.teamClient.getUserTeams();
  }

  async updateTeamName(teamId: string, newValue: string): Promise<void> {
    return this.factory.teamClient.updateTeamName(teamId, newValue);
  }

  async updateTeamOwner(teamId: string, newValue: string): Promise<void> {
    return Promise.resolve();
  }

  async updateTeamAvatarUrl(teamId: string, newValue: string): Promise<void> {
    return Promise.resolve();
  }

  async set(
    teamId: string,
    setting: UpdatableTeamInformation,
    newValue: string,
  ) {
    return this.settingToUpdaterMap[setting](teamId, newValue);
  }

  async sendInvitation(teamId: string, email: string): Promise<string> {
    try {
      await this.factory.teamClient.sendInvitation(teamId, email);
      return email;
    } catch (error) {
      throw error;
    }
  }

  async acceptInvitation(token: string) {
    return this.factory.teamClient.acceptInvitation(token);
  }

  async cancelInvitation(teamId: string, email: string) {
    return this.factory.teamClient.cancelInvitation(teamId, email);
  }

  async removeMember(teamId: string, userId: string) {
    return this.factory.teamClient.removeMember(teamId, userId);
  }

  async getTeamBillingDetails(
    teamId: string,
    cancelToken?: CancelTokenSource,
  ): Promise<TeamBillingDetailsResponse> {
    return this.factory.teamClient
      .getTeamBillingDetails(teamId, cancelToken)
      .then((response) => Promise.resolve(response.data.data))
      .catch((error) => Promise.reject(error));
  }

  async countTeamIntegrations(groupId: string): Promise<number> {
    // As per WEB2-1600 we don't want to take care about limitations
    return Promise.resolve(0);
  }
}
