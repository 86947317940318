import React, { FunctionComponent, useEffect } from 'react';
import syncedState from './synced-state';
import { useSetRecoilState } from 'recoil';
import { SupportedLanguage } from '../config';
import { User } from '../features/login/UserEntity';
import { PaymentCache } from '../features/payment/types';
import {
  ScheduledPost,
  ScheduledPostStrategies,
} from '../features/publish/types';
import {
  SocialAccountConnectionCache,
  SocialAccountReconnectionCache,
} from '../sets/integration/types';

const SyncedStateRoot: FunctionComponent = (props) => {
  const setLanguage = useSetRecoilState(syncedState.getAtom('language'));
  const setUser = useSetRecoilState(syncedState.getAtom('user'));
  const setPaymentCache = useSetRecoilState(
    syncedState.getAtom('paymentCache'),
  );
  const setActiveAccountId = useSetRecoilState(
    syncedState.getAtom('activeAccountId'),
  );
  const setSocialAccountReconnectionCache = useSetRecoilState(
    syncedState.getAtom('socialAccountReconnectionCache'),
  );
  const setSocialAccountConnectionCache = useSetRecoilState(
    syncedState.getAtom('socialAccountConnectionCache'),
  );
  const setSchedulePostCache = useSetRecoilState(
    syncedState.getAtom('scheduledPostCache'),
  );
  const setScheduledPostStrategy = useSetRecoilState(
    syncedState.getAtom('scheduledPostStrategy'),
  );
  const setGridShift = useSetRecoilState(syncedState.getAtom('gridShifts'));
  const setShowFacebookPrivateGroupWarning = useSetRecoilState(
    syncedState.getAtom('showFacebookPrivateGroupWarning'),
  );

  useEffect(() => {
    const unsubscribe = syncedState.subscribe((key, value) => {
      switch (key) {
        // @TODO: fix subscriber typing discrimination
        case 'language':
          setLanguage(value as SupportedLanguage);
          break;
        case 'user':
          setUser(value as User);
          break;
        case 'paymentCache':
          setPaymentCache(value as PaymentCache);
          break;
        case 'activeAccountId':
          setActiveAccountId(value as string);
          break;
        case 'socialAccountConnectionCache':
          setSocialAccountConnectionCache(
            value as SocialAccountConnectionCache,
          );
          break;
        case 'socialAccountReconnectionCache':
          setSocialAccountReconnectionCache(
            value as SocialAccountReconnectionCache,
          );
          break;
        case 'scheduledPostCache':
          setSchedulePostCache(value as ScheduledPost);
          break;
        case 'scheduledPostStrategy':
          setScheduledPostStrategy(value as ScheduledPostStrategies);
          break;
        case 'gridShifts':
          setGridShift(value as number);
          break;
        case 'showFacebookPrivateGroupWarning':
          setShowFacebookPrivateGroupWarning(value as boolean);
          break;
      }
    });
    return unsubscribe;
  }, []);

  return <>{props.children}</>;
};

export default SyncedStateRoot;
