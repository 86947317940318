import { ApiResponse, HttpClient } from '../../HttpClient';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { TeamPaths } from '../../urls';
import { UserTeam } from '../../state';
import { TeamMemberType } from './TeamService';
import { PaymentMethod } from '../payment/PaymentService';
import { PublicProfileFields } from '../publicProfile/types';

export type TeamBillingDetailsResponse = {
  name: string;
  status: string;
  currentPeriodStart?: number;
  currentPeriodEnd?: number;
  email?: string;
  paymentMethods?: PaymentMethod[];
  gateway?: string;
  gatewaySpecificData?: { customer: string; subscription: string };
};

export interface TeamInformationClientProtocol {
  getTeamInformation(teamId: string): Promise<any>;
  getTeamBillingDetails(
    teamId: string,
  ): Promise<AxiosResponse<ApiResponse<TeamBillingDetailsResponse>>>;
}

export type TeamSubscriptionResponse = {
  _id: string;
  currentPeriodEnd: number;
  currentPeriodStart: number;
  gateway: string;
  limitations?: { default: { integrations: number; members: number } };
  name: string;
  status: string;
};

export type TeamInformationResponse = {
  _id: string;
  name: string;
  freeTrialEnd: number;
  avatarUrl: string;
  members: TeamMemberType[];
  pending: TeamMemberType[];
  publicProfile: PublicProfileFields;
  subscription?: TeamSubscriptionResponse;
};

export class TeamClient extends HttpClient
  implements TeamInformationClientProtocol {
  async getTeamInformation(
    teamId: string,
    cancelToken?: CancelTokenSource,
  ): Promise<AxiosResponse<ApiResponse<TeamInformationResponse>>> {
    const configOverride = cancelToken
      ? { cancelToken: cancelToken.token }
      : {};
    return this.get<AxiosResponse<ApiResponse<TeamInformationResponse>>>(
      TeamPaths.get(teamId),
      configOverride,
    );
  }

  async createTeam(
    name: string,
  ): Promise<AxiosResponse<ApiResponse<UserTeam>>> {
    return this.post<AxiosResponse<ApiResponse<UserTeam>>>(TeamPaths.create, {
      name,
      avatarUrl: '',
    });
  }

  async deleteTeam(id: string): Promise<void> {
    return this.delete(TeamPaths.get(id));
  }

  async getUserTeams(): Promise<AxiosResponse<ApiResponse<UserTeam[]>>> {
    return this.get<AxiosResponse<ApiResponse<UserTeam[]>>>(TeamPaths.getAll);
  }

  async updateTeamName(teamId: string, name: string): Promise<void> {
    return this.patch(TeamPaths.get(teamId), {
      name,
    });
  }

  async acceptInvitation(token: string): Promise<void> {
    return this.post(TeamPaths.acceptInvitation, {
      token,
    });
  }

  async sendInvitation(teamId: string, email: string): Promise<void> {
    return this.post(TeamPaths.sendInvitation(teamId), {
      email,
    });
  }

  async cancelInvitation(teamId: string, email: string): Promise<void> {
    return this.delete(TeamPaths.cancelInvitation(teamId), {
      email,
    });
  }

  async removeMember(teamId: string, userId: string) {
    return this.delete(TeamPaths.removeMember(teamId, userId));
  }

  async getTeamBillingDetails(
    teamId: string,
    cancelToken?: CancelTokenSource,
  ): Promise<AxiosResponse<ApiResponse<TeamBillingDetailsResponse>>> {
    const configOverride = cancelToken
      ? { cancelToken: cancelToken.token }
      : {};
    return this.get<AxiosResponse<ApiResponse<TeamBillingDetailsResponse>>>(
      TeamPaths.getTeamBillingDetails(teamId),
      configOverride,
    );
  }
}
