import React, { useState, ReactElement, Ref } from 'react';
import classNames from 'classnames';
import { omit } from '../../utils/omit';
import { useStyles, StylableComponent } from '../../utils/hooks/useStyles';
import { EyeIcon, EyeOffIcon } from '../Icons/Icons';

export type InputProps = {
  label?: ReactElement | string;
  variant?: InputVariants;
  errorMessage?: string;
  caption?: string;
  allowDisclose?: boolean;
  type?: InputType;
  labelVariant?: LabelVariant;
  innerRef?: Ref<HTMLInputElement>;
} & JSX.IntrinsicElements['input'];

export type InputStyles = {
  root: string;
  label: string;
  inputWrapper: string;
  input: string;
  showIcon: string;
  message: string;
  caption: string;
};

export enum InputVariants {
  Outlined = 'outlined',
  Default = 'default',
  Gray = 'gray',
}

export enum LabelVariant {
  Black = "black"
}
export type InputType =
  | 'text'
  | 'email'
  | 'select'
  | 'file'
  | 'radio'
  | 'checkbox'
  | 'textarea'
  | 'button'
  | 'reset'
  | 'submit'
  | 'date'
  | 'datetime-local'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'range'
  | 'search'
  | 'tel'
  | 'url'
  | 'week'
  | 'password'
  | 'datetime'
  | 'time'
  | 'color';

const Input: StylableComponent<InputProps, InputStyles> = (props) => {
  const [type, setType] = useState(props.type || 'text');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const inputPropsToPass = omit(
    props,
    'type',
    'label',
    'errorMessage',
    'allowDisclose',
    'styles',
    'variant',
    'innerRef',
  );

  const toggleDiscloseValue = () => {
    setType((prev) => (prev === 'password' ? 'text' : 'password'));
    setPasswordVisible((prev) => !prev);
  };

  const isInputShowIconActive = Boolean(props.value);
  const rootClassNames = classNames('Input', {
    'Input--error': Boolean(props.errorMessage),
    [`Input--${props.variant || 'default'}`]: true,
  });
  const inputShowClassNames = classNames('Input__show', {
    'Input__show--active': isInputShowIconActive,
  });
  const labelClassName = `Input__label${ props.labelVariant ? `-${props.labelVariant}` : "" }`

  const classes = useStyles<InputStyles>(
    {
      root: rootClassNames,
      label: labelClassName,
      inputWrapper: 'Input__input-wrapper',
      input: 'Input__input',
      message: 'Input__message',
      caption: 'Input__caption',
      showIcon: inputShowClassNames,
    },
    props.styles,
  );

  const passwordIconProps = {
    className: classes.showIcon,
    onClick: () => toggleDiscloseValue(),
    alt: passwordVisible ? 'hide' : 'show',
  };

  return (
    <div className={classes.root} data-name={props.name}>
      <label htmlFor={props.name} className={classes.label}>
        {props.label}
      </label>
      <div className={classes.inputWrapper}>
        <input
          ref={props.innerRef}
          type={type}
          className={classes.input}
          {...inputPropsToPass}
        />
        {props.type === 'password' && props.allowDisclose && (
          <>
            {passwordVisible ? (
              <EyeOffIcon {...passwordIconProps} />
            ) : (
              <EyeIcon {...passwordIconProps} />
            )}
          </>
        )}
      </div>

      {props.errorMessage && (
        <div className={classes.message}>{props.errorMessage}</div>
      )}

      {props.caption && <div className={classes.caption}>{props.caption}</div>}
    </div>
  );
};

export default Input;
