import React, { FunctionComponent, useEffect } from 'react';
import './App.css';
import { RecoilRoot, useRecoilState } from 'recoil';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom';
import PublishContainer from './features/publish/PublishContainer';
import './globalStyles.scss';
import LoginContainer from './features/login/LoginContainer';
import BrandContainer from './features/brand/BrandContainer';
import CreateContainer from './features/create/CreateContainer';
import { initializeAccounts, locationAtom } from './state';
import { defaultTitleTemplate, metaTags, Routes } from './config';
import ForgotPasswordContainer from './features/forgot/containers/ForgotPasswordContainer';
import ForLoggedIn from './guards/ForLoggedIn';
import ForNotExpiredAccounts from './guards/ForNotExpiredAccounts';
import AppLayoutContainer from './layouts/AppLayoutContainer/AppLayoutContainer';
import UserContainer from './features/user/UserContainer';
import SignupContainer from './features/signup/SignupContainer';
import MessagesProvider from './utils/components/MessagesProvider';
import SignupSuccessContainer from './features/signup/SignupSuccessContainer';
import TeamContainer from './features/team/TeamContainer';
import SyncedStateRoot from './synced-state/SyncedStateRoot';
import { initializeSyncedState } from './synced-state/synced-state';
import ResetPasswordContainer from './features/forgot/containers/ResetPasswordContainer';
import JwtHandover from './utils/components/JwtHandover';
import MarketplaceContainer from './features/marketplace/containers/MarketplaceContainer';
import StoryboardPreviewContainer from './features/marketplace/containers/StoryboardPreviewContainer';
import {
  useWebviewMessageDispatcher,
  WebviewMessages,
} from './WebviewMessageDispatcher';
import DashboardContainer from './features/dashboard/containers/DashboardContainer';
import SpacesLayoutContainer from './layouts/SpacesLayoutContainer/SpacesLayoutContainer';
import Helmet from 'react-helmet';
import { useIntl } from './utils/hooks/useIntl';
import IntegrationContainer from './features/integration/containers/IntegrationContainer';
import ExpiredTeamContainer from './features/team/ExpiredTeamContainer';
import { useAmplitude } from './utils/activity/useAmplitude';
import { useBraze } from './utils/activity/useBraze';
import { useImpact } from './utils/activity/useImpact';
import { useWebPushNotifications } from './utils/hooks/useWebPushNotifications';
import { useApptimize } from './utils/hooks/useApptimize';
import { useSentry } from './utils/hooks/useSentry';
import { useGTM } from './utils/hooks/useGTM';
import InsightsContainer from './features/insights/containers/InsightsContainer';
import { useDefaultToPersonalAccount } from './utils/hooks/useDefaultToPersonalAccount';
import { AddSocialAccountModalContextProvider } from './sets/integration/contexts/AddSocialAccountModalContext';
import SvgDefs from './components/SvgDefs/SvgDefs';
import ImpactScript from './utils/components/ImpactScript';
import ImpactIdentifyParent from './utils/components/ImpactIdentifyParent';
import SiteBuilderContainer from './features/siteBuilder/containers/SiteBuilderContainer';
import SitePublicContainer from './features/sitetest/SitePublicContainer';
import LandingPageContainer from './features/homepage/LandingPageContainer';
import FontPicker from 'font-picker-react';
import TodayContainer from './features/today/TodayContainer';
import MembershipContainer from './features/membership/containers/MembershipContainer';

export const AppWrapper = withRouter((props: any) => {
  const history = useHistory();
  const [, setLocation] = useRecoilState<any>(locationAtom);
  const { formatMessage } = useIntl();

  useAmplitude();
  useBraze();
  // useWebPushNotifications();
  // useApptimize();
  // useSentry();
  // useGTM();
  // useImpact();

  // Ensure top scroll position of the page after refresh
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () =>
      history.listen(() => {
        setLocation(history.location);
      }),
    [history, setLocation],
  );

  useDefaultToPersonalAccount();

  return (
    <>
      <Helmet titleTemplate={defaultTitleTemplate}>
        <title>{formatMessage({ id: metaTags.default.title })}</title>
        <meta name="description" content={metaTags.default.description} />
      </Helmet>
      {props.children}
    </>
  );
});

const App: FunctionComponent = () => {
  const { dispatch } = useWebviewMessageDispatcher();

  const userContainer = (
    <JwtHandover>
      <ForLoggedIn>
        <AppLayoutContainer>
          <UserContainer />
        </AppLayoutContainer>
      </ForLoggedIn>
    </JwtHandover>
  );

  return (
    <RecoilRoot
      initializeState={({ set }) => {
        initializeSyncedState(set);
        initializeAccounts(set);
        dispatch({
          name: WebviewMessages.ApplicationLoaded,
          payload: {},
        });
      }}
    >
      <SyncedStateRoot>
        <FontPicker
          pickerId="LoadFontPicker"
          apiKey="AIzaSyAR89dkdFVDrldCWjytOdHCbTqil7ooyyo"
          variants={['regular', 'italic', '700', '700italic']}
          limit={100}
        />
        <Router>
          <MessagesProvider>
            <AppWrapper>
              <SvgDefs />
              <Switch>
                <Route exact path={Routes.Index}>
                  <JwtHandover>
                    <MembershipContainer />
                  </JwtHandover>
                </Route>
              </Switch>
            </AppWrapper>
          </MessagesProvider>
        </Router>
      </SyncedStateRoot>
    </RecoilRoot>
  );
};

export default App;
