import { DependencyContainer } from '../../../DependencyContainer';
import {
  InstagramGraphResponse,
  InstagramGraphSinglePhoto,
} from '../../dashboard/types';
import { ValidateFacebookAccessTokenResponse, FacebookGroup } from '../types';
import { PagedResponse } from '../../../utils/hooks/usePagedResponses';
import { ExternalFacebookAccount } from '../../../sets/integration/types';

export type PhotosFetcher = (
  accountId: string,
  accessToken: string,
) => Promise<PagedResponse<InstagramGraphSinglePhoto[]>>;

export class ExternalIntegrationService {
  constructor(private readonly factory: DependencyContainer) {}

  getPhotosFetcher(tokenType: 'basic_display' | 'business'): PhotosFetcher {
    if (tokenType === 'business') {
      return this.getPhotosFromFacebook.bind(this);
    }

    return this.getPhotosFromInstagram.bind(this);
  }

  private async getPhotosFromInstagram(_: string, accessToken: string) {
    const response = await this.factory.externalIntegrationClient.getPhotosFromInstagram(
      accessToken,
    );

    return this.createPagedResponse(response.data);
  }

  private async getPhotosFromFacebook(accountId: string, accessToken: string) {
    const response = await this.factory.externalIntegrationClient.getPhotosFromFacebook(
      accountId,
      accessToken,
    );

    return this.createPagedResponse(response.data);
  }

  private createPagedResponse(
    response: InstagramGraphResponse,
  ): PagedResponse<InstagramGraphSinglePhoto[]> {
    const hasNextPage = response.paging?.next != null;
    const getNextPage = async () => {
      if (!hasNextPage) {
        return;
      }

      const {
        data: nextResponse,
      } = await this.factory.externalIntegrationClient.getPhotosPage(
        response.paging.next!,
      );
      return this.createPagedResponse(nextResponse);
    };

    return {
      currentPage: response.data,
      hasNextPage,
      getNextPage,
    };
  }

  async getInstagramBusinessAccounts(
    accessToken: string,
  ): Promise<ExternalFacebookAccount[]> {
    const accounts = await this.factory.externalIntegrationClient.getInstagramBusinessAccounts(
      accessToken,
    );
    return accounts
      .filter((r) => r.instagram_business_account !== undefined)
      .map((a) => ({
        id: a.instagram_business_account.id,
        accessToken: a.access_token,
        name: a.instagram_business_account.name,
        username: a.instagram_business_account.username,
        avatarImageUrl: a.instagram_business_account.profile_picture_url,
      }));
  }

  async getFacebookPages(
    accessToken: string,
  ): Promise<ExternalFacebookAccount[]> {
    const pages = await this.factory.externalIntegrationClient.getFacebookPages(
      accessToken,
    );
    return pages.map((p) => ({
      id: p.id,
      accessToken: p.access_token,
      name: p.name,
      username: p.name,
      avatarImageUrl: p.picture.data.url,
    }));
  }

  async getFacebookGroups(accessToken: string): Promise<FacebookGroup[]> {
    const response = await this.factory.externalIntegrationClient.getFacebookGroups(
      accessToken,
    );
    return response.data;
  }

  async getFacebookPersonalProfile(
    accessToken: string,
  ): Promise<ExternalFacebookAccount[]> {
    const profile = await this.factory.externalIntegrationClient.getFacebookPersonalProfile(
      accessToken,
    );
    return [
      {
        id: profile.id,
        accessToken: accessToken,
        name: profile.name,
        username: profile.name,
        avatarImageUrl: profile.picture.data.url,
      },
    ];
  }

  async validateFacebookAccessToken(
    accessToken: string,
  ): Promise<ValidateFacebookAccessTokenResponse> {
    return this.factory.externalIntegrationClient.validateFacebookAccessToken(
      accessToken,
    );
  }
}
