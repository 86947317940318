import { AxiosResponse } from "axios";
import { ApiResponse, HttpClient } from "../../../HttpClient";
import { PageElementPath, PageLayoutPath, PagesPath, PageTemplatePublicPath, SitePath, SitePublicPath } from "../../../urls";
import { Page, PageLayout, PageTemplate, Site, SitePublicWebResponse, WebBuilderLayoutElement } from "../types";

export type UpdateSitePayload = {
    handle?: string;
    liveSite?: string;
};

export class SiteClient extends HttpClient {

    async createSite(sitePayload?:Site) : Promise<AxiosResponse<ApiResponse<Site>>> {
        return await this.post(SitePath.getSitePath(),{})
    }
    async getSite() : Promise<AxiosResponse<ApiResponse<Site>>> {
        return await this.get(SitePath.getSitePath())
    }

    async updateSite(siteId: string, updateSitePayload:UpdateSitePayload) : Promise<AxiosResponse<ApiResponse<Site>>> {
        return await this.put(SitePath.updatePath(siteId), updateSitePayload)
    }

    async getPages(siteId: string): Promise<AxiosResponse<ApiResponse<Page[]>>> {
        return await this.get(PagesPath.getPages(siteId))
    }
    async deletePage(id: string): Promise<void> {
        return await this.delete(PagesPath.updatePage(id))
    }
    async updatePage(pagePayload:Page) : Promise<AxiosResponse<ApiResponse<Page>>> {
        return await this.put(PagesPath.updatePage(pagePayload._id),pagePayload)
    }

    async createPage(site: string, title: string, pageLayout?: PageLayout): Promise<AxiosResponse<ApiResponse<Page>>> {
        return await this.post(PagesPath.base(), {site, title, pageLayout})
    } 

    async createPageLayout(page:string, staticElements: WebBuilderLayoutElement[], elements:WebBuilderLayoutElement[]): Promise<AxiosResponse<ApiResponse<PageLayout>>> {
        return this.post(PageLayoutPath.createPageLayout(), {page, staticElements, elements})
    }

    async createPageLayoutByTemplateId(page:string, templateId: string): Promise<AxiosResponse<ApiResponse<PageLayout>>> {
        return this.post(PageLayoutPath.createPageLayout(), {page, templateId})
    }

    async getPageLayout(pageId: string): Promise<AxiosResponse<ApiResponse<PageLayout>>> {
        return await this.get(PageLayoutPath.getPageLayout(pageId))
    }

    async updatePageLayout(pageLayoutId: string, pageLayoutPayload: PageLayout): Promise<AxiosResponse<ApiResponse<PageLayout>>> {
        return this.put(PageLayoutPath.updatePageLayout(pageLayoutId), pageLayoutPayload);
    }



    async getPublicPageLayout(handle: string): Promise<AxiosResponse<ApiResponse<SitePublicWebResponse>>> {
        return await this.get(SitePublicPath.getSitePath(handle))
    }

    async getPublicPageTemplates(category?: string): Promise<AxiosResponse<ApiResponse<PageTemplate[]>>> {
        return await this.get(PageTemplatePublicPath.getTemplates())
    }

    async getPageElements(name: string): Promise<AxiosResponse<ApiResponse<WebBuilderLayoutElement[]>>> {
        return await this.get(PageElementPath.pageElementByName(name))
    }

}