import { HttpClient } from '../../HttpClient';
import { UserPaths } from '../../urls';

export interface ForgotPasswordClientProtocol {
  makeRequestChangePasswordLink(
    email: string,
    destination: string,
  ): Promise<void>;
  resetPassword(token: string, newPassword: string): Promise<void>;
}

export class ForgotPasswordClient extends HttpClient
  implements ForgotPasswordClientProtocol {
  makeRequestChangePasswordLink(
    email: string,
    destination: string,
  ): Promise<void> {
    return this.post(UserPaths.forgotPassword, { email, destination });
  }

  resetPassword(token: string, newPassword: string): Promise<void> {
    return this.post(UserPaths.updatePassword, { token, newpass: newPassword });
  }
}
