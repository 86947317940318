import { DependencyContainer } from '../../DependencyContainer';
import { loadStripe } from '@stripe/stripe-js/pure';
import {
  Lines,
  TeamInvoice,
  StripeSubscriptionStatus,
  RevenueCatSubscriptionStatus,
} from './types';
import moment from 'moment';
import {
  AvailableCheckoutDiscounts,
  CheckoutSessionModes,
} from './PaymentClient';
import { BillingDetails, AccountType } from '../../state';
import { PaywallTileBillingPlan } from './components/PaywallTile/PaywallTile';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { paywallHighlightedPlanName } from '../../config';
import { getTranslationMessage } from '../../translations/getTranslationMessage';
import { uppercaseFirst } from '../../utils/uppercaseFirst';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
);

export enum PaymentStatusRouteParams {
  Success = 'success',
  Cancel = 'cancel',
}

export type PaymentMethodChecks = {
  address_line1_check: any;
  address_postal_code_check: any;
  cvc_check: any;
};

export type PaymentMethodNetworks = {
  available: string[];
  preferred: any;
};

export type PaymentMethodThreeDSecureUsage = {
  supported: boolean;
};

export type PaymentMethod = {
  brand: string;
  checks: PaymentMethodChecks;
  country: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  generated_from: string | null;
  last4: string;
  networks: PaymentMethodNetworks;
  three_d_secure_usage: PaymentMethodThreeDSecureUsage;
  wallet: any;
};

export enum UserPlans {
  Free = 'free',
  Elite = 'elite',
}

export enum TeamPlans {
  None = 'none',
  Essentials = 'Team Essentials',
  Standard = 'Team Standard',
  Premium = 'Team Premium',
}

export type AvailablePlans = UserPlans | TeamPlans;

export enum PaymentChannels {
  Stripe = 'stripe',
  StripeCheckout = 'stripe_checkout',
  Apple = 'apple',
  Web = 'web',
  Google = 'google',
  Lifetime = 'Lifetime',
  RevenueCat = 'RevenueCat',
}

const subscriptionStatuses = {
  ...StripeSubscriptionStatus,
  ...RevenueCatSubscriptionStatus,
};

type SubscriptionStatus = typeof subscriptionStatuses[keyof typeof subscriptionStatuses];

export type UserPayments = {
  _id: string;
  plan: UserPlans;
  purchased_via: PaymentChannels;
  subscription_status: SubscriptionStatus;
  user: string;
  subscriptionId: string;
  createdDate: string;
  __v: number;
  user_info: any;
};

export class PaymentService {
  constructor(private readonly factory: DependencyContainer) {}

  getGroupBillingPlan(
    currentPlan: string,
    customBillingPlans: PaywallTileBillingPlan[] = [],
  ): Promise<PaywallTileBillingPlan[]> {
    const billingPlans = this.factory.paymentClient.getGroupBillingPlans();
    return billingPlans
      .then((response) =>
        this.parseBillingPlans({
          response,
          planFor: 'team',
          customBillingPlans,
          currentPlan,
        }),
      )
      .catch((error) => Promise.reject(error));
  }

  changeGroupBillingPlan(groupId: string, priceId: string): Promise<any> {
    const response = this.factory.paymentClient.changeGroupBillingPlan(
      groupId,
      priceId,
    );
    return response;
  }

  getUserBillingPlans(
    currentPlan: string,
    customBillingPlans: PaywallTileBillingPlan[] = [],
  ): Promise<PaywallTileBillingPlan[]> {
    const billingPlans = this.factory.paymentClient.getUserBillingPlans();
    return billingPlans
      .then((response) =>
        this.parseBillingPlans({
          response,
          planFor: 'user',
          customBillingPlans,
          currentPlan,
        }),
      )
      .catch((error) => Promise.reject(error));
  }

  getUserBillingDetails(
    userId: string,
    cancelToken?: CancelTokenSource,
  ): Promise<BillingDetails> {
    return this.factory.paymentClient
      .getUserBillingDetails(userId, cancelToken)
      .then((response) =>
        Promise.resolve({
          plan: response.data.data.plan || '',
          isTrial: false,
          status: response.data.data.status || '',
          currentPeriodStart: response.data.data.currentPeriodStart
            ? moment.unix(response.data.data.currentPeriodStart)
            : undefined,
          currentPeriodEnd: response.data.data.currentPeriodEnd
            ? moment.unix(response.data.data.currentPeriodEnd)
            : undefined,
          paymentMethod:
            response.data.data.paymentMethods &&
            response.data.data.paymentMethods.length
              ? response.data.data.paymentMethods[0]
              : undefined,
          billingEmail: response.data.data.email || '',
        }),
      )
      .catch((error) => Promise.reject(error));
  }

  getUserPayments(
    userId: string,
    cancelToken?: CancelTokenSource,
  ): Promise<UserPayments> {
    return this.factory.paymentClient
      .getUserPayments(userId, cancelToken)
      .then((response) => Promise.resolve(response.data.data))
      .catch((error) => Promise.reject(error));
  }

  async getTeamSubscriptionCheckoutSession(
    teamId: string,
    priceId: string,
  ): Promise<string> {
    return this.factory.paymentClient
      .getCheckoutSession({
        mode: CheckoutSessionModes.Subscription,
        groupId: teamId,
        priceId,
      })
      .then((response) => Promise.resolve(response.data.data.id))
      .catch((error) => Promise.reject(error));
  }

  async getUserSubscriptionCheckoutSession(
    userId: string,
    priceId: string,
    discount?: AvailableCheckoutDiscounts,
  ): Promise<string> {
    const basePayload = {
      mode: CheckoutSessionModes.Subscription as const,
      userId,
      priceId,
    };
    const payload = discount ? { ...basePayload, discount } : basePayload;
    return this.factory.paymentClient
      .getCheckoutSession(payload)
      .then((response) => Promise.resolve(response.data.data.id))
      .catch((error) => Promise.reject(error));
  }

  async getTeamSetupCheckoutSession(teamId: string): Promise<string> {
    return this.factory.paymentClient
      .getCheckoutSession({
        mode: CheckoutSessionModes.Setup,
        groupId: teamId,
      })
      .then((response) => Promise.resolve(response.data.data.id))
      .catch((error) => Promise.reject(error));
  }

  async getUserSetupCheckoutSession(userId: string): Promise<string> {
    return this.factory.paymentClient
      .getCheckoutSession({
        mode: CheckoutSessionModes.Setup,
        userId,
      })
      .then((response) => Promise.resolve(response.data.data.id))
      .catch((error) => Promise.reject(error));
  }

  async getInvoicesForTeam(teamId: string): Promise<TeamInvoice[]> {
    return this.factory.paymentClient
      .getTeamInvoices(teamId)
      .then((response) => {
        const data = response.data.data;
        return Promise.resolve(
          data.map((invoice) => ({
            id: invoice.id,
            amount_paid: invoice.amount_paid,
            currency: invoice.currency,
            description: this.getInvoiceDescription(invoice.lines),
            date: moment.unix(invoice.created),
            number: invoice.number,
            pdfUrl: invoice.invoice_pdf,
          })),
        );
      })
      .catch((error) => Promise.reject(error));
  }

  // @TODO: Think how we can translate this
  private getPerksForUsers(): string[] {
    return [
      getTranslationMessage('elitePerkGridPosts'),
      getTranslationMessage('elitePerkSocialAccounts'),
      getTranslationMessage('elitePerkPresets'),
      getTranslationMessage('elitePerkTools'),
    ];
  }

  private getPerksForTeams(
    membersLimit: number | null,
    integrationsLimit: number | null,
  ): string[] {
    const defaultValue = uppercaseFirst(
      getTranslationMessage('teamPerkUnlimited'),
    );
    return [
      getTranslationMessage('teamPerkSpaces'),
      `${membersLimit || defaultValue} ${getTranslationMessage(
        'teamPerkMembers',
      )}`,
      `${integrationsLimit || defaultValue} ${getTranslationMessage(
        'teamPerkSocialAccounts',
      )}`,
    ];
  }

  private parseBillingPlans({
    response,
    planFor,
    customBillingPlans,
    currentPlan,
  }: {
    response: AxiosResponse<any>;
    planFor: 'team' | 'user';
    currentPlan: string;
    customBillingPlans?: PaywallTileBillingPlan[];
  }) {
    const data = response.data.data;

    const reducedBillingPlans = data.reduce((acc: any, item: any) => {
      const limitations = {
        members: item.metadata['limitations.default.members'],
        integrations: item.metadata['limitations.default.integrations'],
      };
      const currentPlanName = currentPlan.toLowerCase();
      const prices = item.prices.map((price: any) => ({
        title: item.name,
        subtitle: item.description,
        price: {
          id: price.id,
          value: price.unit_amount / 100,
          currency: price.currency,
        },
        period: price.recurring.interval,
        highlighted: item.name === paywallHighlightedPlanName,
        perks:
          planFor === 'team'
            ? this.getPerksForTeams(
                limitations.members
                  ? Number.parseInt(limitations.members, 10)
                  : null,
                limitations.integrations
                  ? Number.parseInt(limitations.integrations, 10)
                  : null,
              )
            : this.getPerksForUsers(),
        limits:
          planFor === 'team'
            ? {
                members: Number.parseInt(limitations.members, 10),
                integrations: Number.parseInt(limitations.integrations, 10),
              }
            : undefined,
        isSelectable: true,
        type:
          currentPlanName === item.name.toLowerCase()
            ? 'current'
            : this.isDowngrade({
                processedPlanNameFromIteration: item.name.toLowerCase(),
                currentPaidPlanName: currentPlanName,
                data: response.data.data,
              })
            ? 'downgrade'
            : 'upgrade',
      }));
      return [...acc, ...prices];
    }, []);
    reducedBillingPlans.sort(
      (a: PaywallTileBillingPlan, b: PaywallTileBillingPlan) =>
        a.price.value - b.price.value,
    );
    const customBillingPlansToAdd = customBillingPlans || [];
    const billingPlans =
      planFor === 'team'
        ? [...reducedBillingPlans, ...customBillingPlansToAdd]
        : [...customBillingPlansToAdd, ...reducedBillingPlans];
    return Promise.resolve(billingPlans);
  }

  private isDowngrade(params: {
    currentPaidPlanName: string;
    processedPlanNameFromIteration: string;
    data: any;
  }): boolean {
    const currentPaidPlan = params.data.find(
      (p: any) => p.name.toLowerCase() === params.currentPaidPlanName,
    );
    if (!currentPaidPlan) {
      return false;
    }

    const isCurrentPlan = (plan: any) =>
      plan.name.toLowerCase() === params.processedPlanNameFromIteration;

    const hasNoLimitations = !(
      currentPaidPlan.metadata['limitations.default.members'] &&
      currentPaidPlan.metadata['limitations.default.integrations']
    );

    if (isCurrentPlan(currentPaidPlan)) {
      return false;
    } else if (hasNoLimitations) {
      // comparing any other plan to no-limits current plan
      return true;
    }

    const currentPlanLimitations = {
      integrations: Number.parseInt(
        currentPaidPlan.metadata['limitations.default.integrations'],
      ),
      members: Number.parseInt(
        currentPaidPlan.metadata['limitations.default.members'],
      ),
    };

    const lowerPlans = params.data.find((plan: any) => {
      const anotherPlanLimitations = {
        members: Number.parseInt(plan.metadata['limitations.default.members']),
        integrations: Number.parseInt(
          plan.metadata['limitations.default.integrations'],
        ),
      };

      return (
        currentPlanLimitations.integrations >
          anotherPlanLimitations.integrations &&
        currentPlanLimitations.members > anotherPlanLimitations.members &&
        isCurrentPlan(plan)
      );
    });
    return Boolean(lowerPlans);
  }

  private getInvoiceDescription(lines: Lines): string {
    if (!lines.data.length) {
      return '';
    }
    return lines.data[0].description;
  }

  private getInvoiceParsedAmount(amount: number, currency: string): string {
    const amountToDisplay = amount / 100;
    if (currency.toLowerCase() === 'usd') {
      return `$${amountToDisplay}`;
    }
    return `${amountToDisplay} ${currency}}`;
  }

  async goToCheckout(sessionId: string) {
    const stripe = await stripePromise;
    await stripe!.redirectToCheckout({
      sessionId,
    });
  }

  async getProration(groupId: string, priceId: string): Promise<number> {
    const response = await this.factory.paymentClient.getProration(
      groupId,
      priceId,
    );

    return response.data.data.amount;
  }

  async uncancelTeamSubscription(teamId: string) {
    return this.factory.paymentClient.uncancelTeamSubscription(teamId);
  }

  async cancelUsersSubscription(userId: string) {
    return this.factory.paymentClient.cancelUsersSubscription(userId);
  }

  async updateBillingEmail(update: {
    accountType: AccountType;
    accountId: string;
    email: string;
  }) {
    const { accountType, accountId, email } = update;
    if (accountType === AccountType.Team) {
      return this.factory.paymentClient.updateTeamBillingEmail(
        email,
        accountId,
      );
    }

    if (accountType === AccountType.Personal) {
      return this.factory.paymentClient.updateUserBillingEmail(
        email,
        accountId,
      );
    }
  }
}
