import { DependencyContainer } from '../../../DependencyContainer';
import { DefaultDashboard } from '../types';
import { DateRange } from '../../../components/DateRangePicker/DateRangePicker';
import moment from 'moment';

export class InsightsService {
  constructor(private readonly factory: DependencyContainer) {}

  async getDefaultDashboard(
    integrationId: string,
    dateRange: DateRange,
  ): Promise<DefaultDashboard> {
    const dateRangeToSend: DateRange = {
      start: moment(dateRange.start.toISOString()).utc().startOf('day'),
      end: moment(dateRange.end.toISOString()).utc().endOf('day'),
    };
    const response = await this.factory.insightsClient.getDefaultDashboard(
      integrationId,
      dateRangeToSend,
    );
    return response.data.data;
  }
}
