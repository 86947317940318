import { SocialAccount } from '../../sets/integration/types';

export type FacebookGraphResponse<T> = {
  data: T;
  paging: {
    cursor: {
      after: string;
      before: string;
    };
  };
};

export type InstagramExchangeCodeForTokenResponse = {
  access_token: string;
  user_id: string;
};

export enum IntegrationErrorCodes {
  NotFound = 'not_found',
  TokenExpired = 'token_expired',
  Unknown = 'unknown',
}

export type SaveBasicDisplayIntegrationResponse = {
  expirationDate: number;
  longLivedToken: string;
};

export type FacebookPicture = {
  data: {
    height: number;
    is_silhouette: boolean;
    url: string;
    width: number;
  };
};

export type FacebookPageInformationResponse = {
  id: string;
  name: string;
  access_token: string;
  picture: FacebookPicture;
  instagram_business_account: {
    id: string;
    name: string;
    profile_picture_url: string;
    username: string;
    followers_count: number;
  };
};

export type FacebookPersonalProfileInformationResponse = {
  id: string;
  name: string;
  access_token: string;
  picture: FacebookPicture;
};

export type FacebookGroupsResponse = {
  data: { name: string; id: string }[];
};

export type FacebookGroup = {
  name: string;
  id: string;
  privacy: 'SECRET' | 'OPEN' | 'CLOSED';
};

export type SocialAccountToCreate = Omit<
  SocialAccount,
  '_id' | 'createdAt' | 'updatedAt'
>;

export type ValidateFacebookAccessTokenResponse = {
  app_id: string;
  type: string;
  application: string;
  data_access_expires_at: number;
  expires_at: number;
  is_valid: boolean;
  profile_id: string;
  scopes: string[];
  granular_scopes: Array<{ scope: string }>;
  user_id: string;
};
