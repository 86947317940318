import { Moment } from 'moment';
import { TranslationKey } from '../../translations/types';

export enum NotificationType {
  NewComment = 'new_comment',
  UploadMedia = 'upload_media',
  TodoTaskDeleted = 'todo_task_deleted',
  TodoTaskToggled = 'todo_task_toggled',
  TodoTaskCreated = 'todo_task_created',
  TodoTaskUpdated = 'todo_task_updated',
  PublishingPostScheduled = 'publishing_post_scheduled',
  PublishingPostEdited = 'publishing_post_edited',
  PublishingPostDeleted = 'publishing_post_deleted',
  PublishingPostUnscheduled = 'publishing_post_unscheduled',
}

type BaseNotification = {
  id: string;
  groupId: string;
  postingUserId: string;
  notificationType: NotificationType;
  createdAt: Moment;
  seenBy: { [userId: string]: true };
  // Properties object contain reference values (i.e. ids) that should
  // be parsed before being shown
  properties: { [key: string]: any };

  // Computed properties contain values precomputed at the time
  // the notification is sent. This is useful for interim notifications
  // i.e. web push
  computedProperties: { [key: string]: any };
};

export type CommentNotification = BaseNotification & {
  notificationType: NotificationType.NewComment;
  properties: {
    spaceId: string;
  };
  computedProperties: {
    userName: string;
    spaceName: string;
  };
};

export type UploadMediaNotification = BaseNotification & {
  notificationType: NotificationType.UploadMedia;
  properties: {
    spaceId: string;
  };
  computedProperties: {
    userName: string;
    spaceName: string;
  };
};

export type TodoTaskNotification = BaseNotification & {
  notificationType:
    | NotificationType.TodoTaskDeleted
    | NotificationType.TodoTaskToggled
    | NotificationType.TodoTaskCreated
    | NotificationType.TodoTaskUpdated;
  properties: {
    spaceId: string;
  };
  computedProperties: {
    userName: string;
    spaceName: string;
    taskTitle: string;
    newStatusMessageId?: TranslationKey;
  };
};

export type PublishingNotification = BaseNotification & {
  notificationType:
    | NotificationType.PublishingPostScheduled
    | NotificationType.PublishingPostEdited
    | NotificationType.PublishingPostDeleted
    | NotificationType.PublishingPostUnscheduled;
  properties: {
    socialAccountIds: string[];
  };
  computedProperties: {
    userName: string;
    socialAccountNames: string[];
  };
};

// This will join all possible notification types
export type Notification =
  | CommentNotification
  | UploadMediaNotification
  | TodoTaskNotification
  | PublishingNotification;
