import { DependencyContainer } from '../../DependencyContainer';
import {
  CloneStoryboardTarget,
  Storyboard,
  StoryboardContent,
  StoryboardFilters,
  StoryBoardsWithTotal,
  ClonedSpace,
} from './types';

import userDefaultAvatar from '../../assets/svg/user-default-avatar.svg';
import teamDefaultAvatar from '../../assets/svg/team-default-avatar.svg';
import { Pagination } from '../../types';

export class MarketplaceService {
  constructor(private readonly factory: DependencyContainer) {}

  async getStoryBoards(
    pagination: Pagination,
    filters?: StoryboardFilters,
  ): Promise<StoryBoardsWithTotal> {
    const response = await this.factory.marketplaceClient.getStoryBoards(
      pagination,
      filters,
    );

    if (response.storyboards.length === 0) {
      return {
        storyboards: [],
        total: 0,
      };
    }

    const storyboards = response.storyboards.map((s) => ({
      _id: s._id,
      name: s.name,
      description: s.description,
      tags: s.tags,
      coverImageUrl: s.coverImageUrl,
      publicProfile: {
        ...s.publicProfile,
        name: s.publicProfile.name || '',
        avatarUrl:
          s.publicProfile.avatarUrl ||
          this.getDefaultAvatar(s.publicProfile.type),
      },
      createdAt: s.createdAt,
      updatedAt: s.updatedAt,
    }));

    return {
      storyboards,
      total: response.total,
    };
  }

  async getStoryboard(storyboardId: string): Promise<Storyboard> {
    const response = await this.factory.marketplaceClient.getStoryboard(
      storyboardId,
    );
    return {
      ...response,
      content: this.serveAssetsViaSecuredConnection(response.content || []),
      publicProfile: {
        ...response.publicProfile,
        name: response.publicProfile.name,
        avatarUrl:
          response.publicProfile.avatarUrl ||
          this.getDefaultAvatar(response.publicProfile.type),
      },
    };
  }

  async cloneStoryboard(
    storyboardId: string,
    target: CloneStoryboardTarget,
  ): Promise<ClonedSpace> {
    return this.factory.marketplaceClient.cloneStoryboard(storyboardId, target);
  }

  private serveAssetsViaSecuredConnection(
    content: StoryboardContent[],
  ): StoryboardContent[] {
    return content.map((item) => ({
      ...item,
      thumbnailURL: item.thumbnailURL
        ? item.thumbnailURL.replace('http://', 'https://')
        : undefined,
    }));
  }

  private getDefaultAvatar(type: 'user' | 'group') {
    return type === 'user' ? userDefaultAvatar : teamDefaultAvatar;
  }
}
