import { useRecoilValue } from 'recoil';
import { userTeamsAtom } from '../../state';
import { useEffect } from 'react';
import { useSyncedState } from '../../synced-state/synced-state';

export const useDefaultToPersonalAccount = () => {
  const [activeAccountId, setActiveAccountId] = useSyncedState(
    'activeAccountId',
  );
  const [user] = useSyncedState('user');
  const userTeams = useRecoilValue(userTeamsAtom);

  useEffect(() => {
    if (userTeams && user) {
      const noActiveAccount = [...userTeams, user].every(
        (account) => account._id !== activeAccountId,
      );
      if (noActiveAccount) {
        setActiveAccountId(user._id);
      }
    }
  }, [activeAccountId, user, userTeams]);
};
