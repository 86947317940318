import firebase from 'firebase/compat';
import { firestore, SnapshotHandler } from '../../../utils/firebase';
import { DependencyContainer } from '../../../DependencyContainer';
import { omit } from '../../../utils/omit';
import { SpaceGridItem } from '../types';

export class CommentsService {
  constructor(private readonly factory: DependencyContainer) {}

  addComment(
    spaceId: string,
    contentId: string,
    comment: { userId: string; content: string },
  ) {
    const contentDoc = firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('contents')
      .doc(contentId);
    const commentDoc = contentDoc.collection('comments').doc();

    const batch = firestore.batch();
    batch.update(contentDoc, {
      [`commentsSeenBy.${commentDoc.id}.${comment.userId}`]: true,
    });
    batch.set(commentDoc, {
      ...comment,
      createdAt: firebase.firestore.Timestamp.now(),
    });

    return batch.commit();
  }

  async deleteComment(spaceId: string, contentId: string, commentId: string) {
    await firestore.runTransaction(async (transaction) => {
      const contentRef = firestore
        .collection('spaces')
        .doc(spaceId)
        .collection('contents')
        .doc(contentId);
      const contentSnapshot = await transaction.get(contentRef);
      const content = contentSnapshot.data();
      if (content?.commentsSeenBy) {
        const updatedSeenBy = omit(content.commentsSeenBy, commentId);
        await transaction.update(contentRef, {
          commentsSeenBy: updatedSeenBy,
        });
      }

      const commentDoc = firestore
        .collection('spaces')
        .doc(spaceId)
        .collection('contents')
        .doc(contentId)
        .collection('comments')
        .doc(commentId);
      await transaction.delete(commentDoc);
    });
  }

  onComments(spaceId: string, contentId: string, handler: SnapshotHandler) {
    return firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('contents')
      .doc(contentId)
      .collection('comments')
      .orderBy('createdAt')
      .onSnapshot(handler);
  }

  async countComments(spaceId: string, contentId: string) {
    const snapshot = await firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('contents')
      .doc(contentId)
      .collection('comments')
      .get();

    return snapshot.size;
  }

  async markCommentsAsSeen(spaceId: string, contentId: string, userId: string) {
    await firestore.runTransaction(async (transaction) => {
      const contentRef = firestore
        .collection('spaces')
        .doc(spaceId)
        .collection('contents')
        .doc(contentId);
      const contentSnapshot = await transaction.get(contentRef);
      const content = contentSnapshot.data();
      if (content?.commentsSeenBy) {
        const updatedSeenBy = Object.keys(content.commentsSeenBy).reduce(
          (acc, commentId) => {
            acc[commentId] = {
              ...content.commentsSeenBy[commentId],
              [userId]: true,
            };
            return acc;
          },
          {} as any,
        );
        transaction.update(contentRef, {
          commentsSeenBy: updatedSeenBy,
        });
      }
    });
  }

  async handleBackwardsCompatibility(spaceId: string, items: SpaceGridItem[]) {
    firestore.runTransaction(async (transaction) => {
      items.forEach((item) => {
        if (
          item.commentsSeenBy &&
          Object.values(item.commentsSeenBy).some((v) => typeof v === 'boolean')
        ) {
          const contentRef = firestore
            .collection('spaces')
            .doc(spaceId)
            .collection('contents')
            .doc(item.id);

          transaction.update(contentRef, {
            commentsSeenBy: {
              initial: {
                ...item.commentsSeenBy,
              },
            },
          });
        }
      });
    });
  }
}
