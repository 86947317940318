import firebase from 'firebase/compat/app';
import {
  InstagramSocialAccount,
  IntegrationsTypes,
} from '../../sets/integration/types';
import moment from 'moment';
import { WebBuilderLayoutElement } from '../siteBuilder/types';
import { ElementPropContent, ElementPropTypes } from '../siteBuilder/state';

export type Space = {
  id: string;
  name: string;
  type: SpaceTypes;
  storyboardConfig?: StoryboardConfig;
  thumbnailUrl?: string;
  shiftOffset?: number;
};

export enum SpaceTypes {
  Default = 'grid',
  /**
   * @deprecated legacy type. Use Storyboard instead
   */
  InstagramGrid = 'InstagramGrid',
  InstagramStory = 'InstagramStory',
  Storyboard = 'Storyboard',
}

export enum SpaceGridMediaTypes {
  /* For images, in order to make them present on iOS app,
   * we should use mediaType = 'photo' instead of 'image'.
   * However we are still able to display both
   */
  Photo = 'photo',
  InstagramPhoto = 'instagramphoto',
  Album = 'album',
  Video = 'video',
  Placeholder = 'placeholder',
  UnmovablePlaceholder = 'unmovableplaceholder',
}
export enum PublishInfoStatus {
  Ready = 'ready',
  Published = 'published',
  Failed = 'failed',
  Pending = 'pending',
}

export type SpaceGridItemPublishInfo = {
  date: moment.Moment;
  jobId: string;
  status: PublishInfoStatus;
  internalError?: string;
};

export type SpaceGridItem = {
  id: string;
  assetUrl: string;
  caption: string;
  properties?: Record<string, string>;
  thumbnailUrl?: string;
  mediaSrc: string;
  mediaType: SpaceGridMediaTypes;
  selected: boolean;
  uploading: boolean;
  rank: string;
  commentsSeenBy?: {
    [commentId: string]: {
      [userId: string]: boolean;
    };
  };
  publishInfo?: SpaceGridItemPublishInfo;
};

export type SpaceGridActions = {
  onToggleTile: (tileId: string) => void;
  onAddMediaClick: () => void;
  onMediaDoubleClick: (item: SpaceGridItem) => void;
  onReplaceMediaClick: () => void;
  onDeselectAllClick: () => void;
  onDeleteClick: () => void;
  onAddPlaceholderClick: () => Promise<void>;
  onAddRowClick: () => Promise<void>;
  onSwapClick: () => Promise<void>;
  onEditImageCaptionClick: () => void;
  onToggleColorMapClick: () => void;
  onToggleComments: (active: boolean) => void;
  onShiftGridClick: () => void;
};

export enum OwnerTypes {
  User = 'user',
  Group = 'group',
}

export type AspectRatio = [number, number];

export type SpaceConfigLayout = {
  aspectRatio?: AspectRatio;
  columnCount?: number;
  isGrid: boolean;
};

export type StoryboardConfig = {
  id: string;
  name: string;
  description: string;
  spaceType: string;
  iconURL: string;
  layout: SpaceConfigLayout;
  isAvailable: boolean;
  platform?: string;
  showsFeed?: boolean;
  supportedIntegrations?: IntegrationsTypes[];
};

export enum IntegratedPlatforms {
  Instagram = 'instagram',
  None = 'none',
}

export type BaseSpaceIntegrationsResponse = {
  _id: string;
  __v: number;
};

export type SpaceIntegration = BaseSpaceIntegrationsResponse & {
  spaceId: string;
  integration: InstagramSocialAccount;
};

export type InstagramGraphFields = {
  id: string;
  caption: string;
  media_url: string;
  thumbnail_url: string;
};

export type InstagramGraphSinglePhoto = {
  [k in keyof InstagramGraphFields]: string;
};

export type InstagramGraphPaging = {
  next?: string;
  previous?: string;
  cursors: {
    before: string;
    after: string;
  };
};

export type InstagramGraphResponse = {
  data: InstagramGraphSinglePhoto[];
  paging: InstagramGraphPaging;
};

export type Comment = {
  id: string;
  userId: string;
  content: string;
  createdAt: firebase.firestore.Timestamp;
  seenBy: {
    [userId: string]: true;
  };
};

export type CommentsUnseenByUser = {
  [contentId: string]: boolean;
};
