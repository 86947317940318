import React, { ReactNode, useState } from 'react';
import { StyleModifier, useStyles } from '../../utils/hooks/useStyles';
import { toggleArrayElement } from '../../utils/toggleArrayElement';

export type OnSelectedOptionCallback<T> = (
  option: T,
  index: number,
  selectedIndexes: number[],
) => void;

type MultiSelectableListProps<T, S> = {
  options: T[];
  onSelectedOption?: OnSelectedOptionCallback<T>;
  onSelectionLimitExceeded?: () => void;
  selectionLimit?: number;
  renderOption: (option: T, isSelected: boolean) => ReactNode | ReactNode[];
  initiallySelectedIndexes?: number[];
  styles?: StyleModifier<S>;
};

export type MultiSelectableListStyles = {
  root: string;
  option: string;
};

function MultiSelectableList<T>(
  props: MultiSelectableListProps<T, MultiSelectableListStyles>,
) {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>(
    props.initiallySelectedIndexes || [],
  );

  const classes = useStyles<MultiSelectableListStyles>(
    {
      root: 'SelectableList',
      option: 'SelectableList__option',
    },
    props.styles,
  );

  const handleOptionSelection = (index: number) => {
    const updatedSelectedIndexes = toggleArrayElement(selectedIndexes, index);

    const { selectionLimit, options, onSelectionLimitExceeded } = props;

    if (selectionLimit && selectionLimit < updatedSelectedIndexes.length) {
      onSelectionLimitExceeded && onSelectionLimitExceeded();
    } else {
      setSelectedIndexes(updatedSelectedIndexes);

      props.onSelectedOption &&
        props.onSelectedOption(options[index], index, updatedSelectedIndexes);
    }
  };

  return (
    <div className={classes.root}>
      {props.options.map((option, index) => (
        <button
          type="button"
          key={JSON.stringify(option)}
          className={classes.option}
          onClick={() => handleOptionSelection(index)}
        >
          <>{props.renderOption(option, selectedIndexes.includes(index))}</>
        </button>
      ))}
    </div>
  );
}
export default MultiSelectableList;
