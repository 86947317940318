import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Account,
  AccountType,
  BillingDetails,
  userBillingDetailsAtom,
  teamBillingDetailsAtom,
  userPaymentsAtom,
  teamInformationAtom,
} from '../../state';
import TeamPaywall from '../../features/team/components/TeamPaywall/TeamPaywall';
import UserPaywall from '../../features/user/components/UserPaywall/UserPaywall';
import { DependencyContainer } from '../../DependencyContainer';
import moment from 'moment';
import { useSetRecoilState } from 'recoil';
import Axios from 'axios';

const { paymentService, teamService } = new DependencyContainer();

export const PaywallRenderer: FunctionComponent<{
  activeAccount: Account | undefined;
}> = ({ activeAccount }) => {
  const [billingDetails, setBillingDetails] = useState<BillingDetails | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const setUserBillingDetails = useSetRecoilState(userBillingDetailsAtom);
  const setTeamBillingDetails = useSetRecoilState(teamBillingDetailsAtom);
  const setUserPayments = useSetRecoilState(userPaymentsAtom);
  const setTeamInformation = useSetRecoilState(teamInformationAtom);

  const cancelationTokens = {
    getUserBillingDetailsCancelToken: Axios.CancelToken.source(),
    getUserPaymentsCancelToken: Axios.CancelToken.source(),
    getTeamBillingDetailsCancelToken: Axios.CancelToken.source(),
    getTeamInformationCancelToken: Axios.CancelToken.source(),
  };

  useEffect(() => {
    if (!activeAccount) {
      return;
    }

    setIsLoading(true);

    if (activeAccount.type === AccountType.Personal) {
      paymentService
        .getUserBillingDetails(
          activeAccount._id,
          cancelationTokens.getUserBillingDetailsCancelToken,
        )
        .then((details) => {
          setBillingDetails(details);
          setUserBillingDetails(details);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
      paymentService
        .getUserPayments(
          activeAccount._id,
          cancelationTokens.getUserPaymentsCancelToken,
        )
        .then((payments) => setUserPayments(payments))
        .catch(() => {
          setIsLoading(false);
        });
    }
    if (activeAccount.type === AccountType.Team) {
      teamService
        .getTeamBillingDetails(
          activeAccount._id,
          cancelationTokens.getTeamBillingDetailsCancelToken,
        )
        .then((details) => {
          const billingDetailsToSet = {
            plan: details.name,
            status: details.status,
            isTrial: details.name === 'Free trial',
            billingEmail: details.email,
            paymentMethod:
              details.paymentMethods && details.paymentMethods.length
                ? details.paymentMethods[0]
                : undefined,
            currentPeriodStart: details.currentPeriodStart
              ? moment.unix(details.currentPeriodStart)
              : undefined,
            currentPeriodEnd: details.currentPeriodEnd
              ? moment.unix(details.currentPeriodEnd)
              : undefined,
          };
          setTeamBillingDetails(billingDetailsToSet);
          setBillingDetails(billingDetailsToSet);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
      teamService
        .getTeamInformation(
          activeAccount._id,
          cancelationTokens.getTeamInformationCancelToken,
        )
        .then((information) => {
          setTeamInformation(information);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }

    return () => {
      Object.values(cancelationTokens).forEach((token) => token.cancel());
    };
  }, [activeAccount]);

  if (!activeAccount || billingDetails === undefined) {
    return null;
  }

  return (
    <>
      {activeAccount.type === AccountType.Team && (
        <>
          <TeamPaywall
            teamId={activeAccount._id}
            billingDetails={billingDetails}
            isLoading={isLoading}
          />
        </>
      )}
      {activeAccount.type === AccountType.Personal && (
        <UserPaywall
          userId={activeAccount._id}
          billingDetails={billingDetails}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
