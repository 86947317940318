import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import Button, {
  ButtonSize,
  ButtonStyles,
} from '../../../../components/Button/Button';
import { GLOBAL_MEDIA_QUERIES } from '../../../../config';
import { useMedia } from 'react-media';
import SpaceChooser from '../../../../components/SpaceChooser/SpaceChooser';
import { Form, Field, Formik } from 'formik';
import { AccountType, Executable, ExecutableStatus } from '../../../../state';
import { Space } from '../../../dashboard/types';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useIntl } from '../../../../utils/hooks/useIntl';
import cx from 'classnames';

const { spaceService } = new DependencyContainer();

type CloneSpacesModalBodyProps = {
  onSubmit: (executables: Array<Executable<{ spaceId: string }>>) => void;
  onRequestClose: () => void;
  personalSpaces: Space[];
};

export type CloneStepResult = {
  spaceIds: string[];
  teamId: string;
};

const CloneSpacesModalBody: FunctionComponent<CloneSpacesModalBodyProps> = ({
  onSubmit,
  onRequestClose,
  personalSpaces,
}) => {
  const { formatMessage } = useIntl();
  const breakpoint = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const [selectedSpaces, setSelectectedSpaces] = useState<Space[]>([]);
  const [hasItemsInUploadState, setHasItemsInUploadState] = useState(false);
  const addTopMarginToSubmit = (current: ButtonStyles) => ({
    ...current,
    root: cx(current.root, 'CloneSpacesModalBody__submit', {
      'CloneSpacesModalBody__submit--fixed': !breakpoint.small,
    }),
  });

  useEffect(() => {
    spaceService
      .hasItemsInUploadState(selectedSpaces.map((spaces) => spaces.id))
      .then((inUpload) => setHasItemsInUploadState(inUpload));
  }, [selectedSpaces]);

  return (
    <>
      <h2 className="text-center m-b-s">
        <FormattedMessage id="cloneSpaces" />
      </h2>
      <p className="text-center m-t-0 m-b-xl">
        <FormattedMessage id="cloneSpacesDescription" />
      </p>
      <Formik
        initialValues={{ spaces: [] }}
        validationSchema={yup.object().shape({
          spaces: yup
            .array()
            .required(formatMessage({ id: 'noCloneSelectionError' })),
        })}
        onSubmit={({ spaces }) => {
          const executables = makeExecutables(spaces);
          onSubmit(executables);
        }}
      >
        {({ handleSubmit, errors }) => (
          <>
            <Form>
              <Field
                component={SpaceChooser}
                spaces={personalSpaces}
                onChange={(selectedSpaces: Space[]) => {
                  setSelectectedSpaces(selectedSpaces);
                }}
                name="spaces"
                uploadStateWarning={hasItemsInUploadState}
                errorMessage={errors.spaces}
              />
            </Form>
            <Button
              fullWidth={breakpoint.small}
              type="submit"
              size={ButtonSize.Large}
              styles={addTopMarginToSubmit}
              onClick={() => handleSubmit()}
            >
              <FormattedMessage id="spaceCloneAction" />
              {selectedSpaces.length > 0 ? ` (${selectedSpaces.length})` : null}
            </Button>
          </>
        )}
      </Formik>
      <button className="button-link" onClick={() => onRequestClose()}>
        <FormattedMessage id="skipCloningSpaces" />
      </button>
    </>
  );
};

const makeExecutables = (
  spaces: Space[],
): Array<Executable<{ spaceId: string }>> =>
  spaces.map((space) => ({
    execute: (teamId: string) =>
      spaceService.cloneSpace(space, space.name, {
        type: AccountType.Team,
        _id: teamId,
      }),
    status: ExecutableStatus.NotExecuted,
  }));

export default CloneSpacesModalBody;
