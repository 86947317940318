import Apptimize from '@apptimize/apptimize-web-sdk';

export enum ExperimentValues {
  DesignGridLimitation,
  PlanLimitationInsightsMaxDaysAgo,
}

type ExperimentValueConfiguration = {
  name: string;
  type: 'int' | 'boolean' | 'string';
  default: any;
};

const experimentConfiguration: Record<
  ExperimentValues,
  ExperimentValueConfiguration
> = {
  [ExperimentValues.DesignGridLimitation]: {
    name: 'design_grid_limitation',
    type: 'int',
    default: 18,
  },
  [ExperimentValues.PlanLimitationInsightsMaxDaysAgo]: {
    name: 'plan_limitation_insights_max_days_ago',
    type: 'int',
    default: 30,
  },
};

const isApptimizeEnabled = () => process.env.REACT_APP_APPTIMIZE_KEY != null;

export const getExperimentValueFor = (type: ExperimentValues) => {
  const config = experimentConfiguration[type];
  if (!isApptimizeEnabled()) {
    return config.default;
  }

  switch (config.type) {
    case 'int':
      return Apptimize.getInt(config.name, config.default);
    case 'string':
      return Apptimize.getString(config.name, config.default);
    case 'boolean':
      return Apptimize.getBool(config.name, config.default);
  }
};
