import { Moment } from 'moment';
import { CheckoutSessionModes } from './PaymentClient';
import { PaymentMethod } from './PaymentService';
import { PaywallTileBillingPlanType } from './components/PaywallTile/PaywallTile';

export type Period = {
  end: number;
  start: number;
};

export type Plan = {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage?: any;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: string;
  interval_count: number;
  livemode: boolean;
  metadata: any;
  nickname?: any;
  product: string;
  tiers?: any;
  tiers_mode?: any;
  transform_usage?: any;
  trial_period_days?: any;
  usage_type: string;
};

export type Recurring = {
  aggregate_usage?: any;
  interval: string;
  interval_count: number;
  trial_period_days?: any;
  usage_type: string;
};

export type Price = {
  id: string;
  object: string;
  active: boolean;
  billing_scheme: string;
  created: number;
  currency: string;
  livemode: boolean;
  lookup_key?: any;
  metadata: any;
  nickname?: any;
  product: string;
  recurring: Recurring;
  tiers_mode?: any;
  transform_quantity?: any;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
};

export type Datum = {
  id: string;
  object: string;
  amount: number;
  currency: string;
  description: string;
  discount_amounts: any[];
  discountable: boolean;
  discounts: any[];
  livemode: boolean;
  metadata: any;
  period: Period;
  plan: Plan;
  price: Price;
  proration: boolean;
  quantity: number;
  subscription: string;
  subscription_item: string;
  tax_amounts: any[];
  tax_rates: any[];
  type: string;
};

export type Lines = {
  object: string;
  data: Datum[];
  has_more: boolean;
  total_count: number;
  url: string;
};

export type StatusTransitions = {
  finalized_at: number;
  marked_uncollectible_at?: any;
  paid_at: number;
  voided_at?: any;
};

export type TeamInvoicesResponseInvoice = {
  id: string;
  object: string;
  account_country: string;
  account_name: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  application_fee_amount?: any;
  attempt_count: number;
  attempted: boolean;
  auto_advance: boolean;
  billing_reason: string;
  charge: string;
  collection_method: string;
  created: number;
  currency: string;
  custom_fields?: any;
  customer: string;
  customer_address?: any;
  customer_email: string;
  customer_name?: any;
  customer_phone?: any;
  customer_shipping?: any;
  customer_tax_exempt: string;
  customer_tax_ids: any[];
  default_payment_method?: any;
  default_source?: any;
  default_tax_rates: any[];
  description?: any;
  discount?: any;
  discounts: any[];
  due_date?: any;
  ending_balance: number;
  footer?: any;
  hosted_invoice_url: string;
  invoice_pdf: string;
  lines: Lines;
  livemode: boolean;
  metadata: any;
  next_payment_attempt?: any;
  number: string;
  paid: boolean;
  payment_intent: string;
  period_end: number;
  period_start: number;
  post_payment_credit_notes_amount: number;
  pre_payment_credit_notes_amount: number;
  receipt_number?: any;
  starting_balance: number;
  statement_descriptor?: any;
  status: string;
  status_transitions: StatusTransitions;
  subscription: string;
  subtotal: number;
  tax?: any;
  tax_percent?: any;
  total: number;
  total_discount_amounts: any[];
  total_tax_amounts: any[];
  transfer_data?: any;
  webhooks_delivered_at: number;
};

export type TeamInvoicesResponse = {
  status: string;
  code: number;
  data: TeamInvoicesResponseInvoice[];
};

export type TeamInvoice = {
  id: string;
  amount_paid: number;
  currency: string;
  description: string;
  date: Moment;
  number: string;
  pdfUrl: string;
};

export type ProrationResponse = {
  amount: number;
};

export type PaymentCache = {
  mode: CheckoutSessionModes;
  relation?: 'team' | 'profile';
  plan: string;
  type?: PaywallTileBillingPlanType;
};

export type UserBillingDetailsResponse = {
  plan: string;
  productName: string;
  purchasedVia: string;
  status: string;
  currentPeriodStart?: number;
  currentPeriodEnd?: number;
  email?: string;
  paymentMethods?: PaymentMethod[];
};

export enum StripeSubscriptionStatus {
  Active = 'active',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  Trialing = 'trialing',
  PastDue = 'past_due',
  Canceled = 'canceled',
  Unpaid = 'unpaid',
}

export enum RevenueCatSubscriptionStatus {
  Trial = 'Trial',
}

export type TeamBillingPlan = {
  active: boolean;
  attributes?: any[];
  created: number;
  description: string;
  id: string;
  images?: string[];
  livemode: boolean;
  metadata: any;
  name: string;
  object: string;
  package_dimensions?: string;
  prices: Price[];
  shippable?: boolean;
  statement_descriptor?: string;
  type: string;
  unit_label?: string;
  updated: number;
  url?: string;
};
