import React, { useState } from 'react';
import Button, { ButtonVariant } from '../../../../components/Button/Button';
import List, { ListStyles } from '../../../../components/List/List';
import ListItem, { ListItemStyles } from '../../../../components/List/ListItem';
import { TickIcon } from '../../../../components/Icons/Icons';
import PaywallTilePrice from './PaywallTilePrice';
import {
  StylableComponent,
  useStyles,
} from '../../../../utils/hooks/useStyles';
import cx from 'classnames';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { Translation } from '../../../../translations/types';
import PaywallTileUnavailableModal from '../PaywallTileUnavailableModal/PaywallTileUnavailableModal';

type PaywallTilePriceProps = {
  id?: string;
  value: number;
  currency: string; //@TODO should be type literal
};

export type PaywallTileBillingPlanType = 'downgrade' | 'current' | 'upgrade';
export type BillingPeriod = 'month' | 'year';
export enum BillingPlansNames {
  Basic = 'Basic',
  Elite = 'Elite',
  TeamEssentials = 'Team Essentials',
  TeamStandard = 'Team Standard',
  TeamPremium = 'Team Premium',
}

export type PaywallTileBillingPlan = {
  type: PaywallTileBillingPlanType;
  title: BillingPlansNames;
  subtitle: string;
  price: PaywallTilePriceProps;
  period: BillingPeriod;
  perks: string[];
  limits?: {
    members: number;
    integrations: number;
  };
  isSelectable: boolean;
  highlighted: boolean;
};

export type SelectedBillingPlan = {
  name: BillingPlansNames;
  type: PaywallTileBillingPlanType;
  price: PaywallTilePriceProps;
  period: string;
};

export type PaywallTileProps = {
  billingPlan: PaywallTileBillingPlan;
  onSelect: (plan: SelectedBillingPlan) => void;
  currentPlanName?: string;
  billingPlanExceeded: boolean;
};

export enum PaywallPeriods {
  Month = 'month',
  Year = 'year',
}

export type PaywallTileStyles = {
  root: string;
  title: string;
  subtitle: string;
  price: string;
  priceAmount: string;
  pricePeriod: string;
  perks: string;
  perksTitle: string;
  perk: string;
  perkIcon: string;
  footer: string;
};

const adjustMarginsInList = (rootClassName: string) => (
  current: ListStyles,
) => ({
  ...current,
  root: `${current.root} ${rootClassName}`,
});
const adjustMarginsInListItem = (
  rootClassName: string,
  iconClassName: string,
) => (current: ListItemStyles) => ({
  ...current,
  root: `${current.root} ${rootClassName}`,
  icon: `${current.icon} ${iconClassName}`,
});

const ctaCopies: { [key in PaywallTileBillingPlanType]: keyof Translation } = {
  downgrade: 'downgrade',
  current: 'currentPlan',
  upgrade: 'select',
};

const PaywallTile: StylableComponent<PaywallTileProps, PaywallTileStyles> = ({
  billingPlan,
  billingPlanExceeded,
  onSelect,
  styles,
}) => {
  const classes = useStyles(
    {
      root: cx('PaywallTile', {
        'PaywallTile--highlighted': billingPlan.highlighted,
      }),
      title: 'PaywallTile__title',
      subtitle: 'PaywallTile__subtitle',
      price: 'PaywallTile__price',
      priceAmount: 'PaywallTile__price-amount',
      pricePeriod: 'PaywallTile__price-period',
      perks: 'PaywallTile__perks',
      perksTitle: 'PaywallTile__perks-title',
      perk: 'PaywallTile__perk',
      perkIcon: 'PaywallTile__perk-icon',
      footer: 'PaywallTile__footer',
    },
    styles,
  );

  const getTranslationIdForCta = () => ctaCopies[billingPlan.type];
  const [exceededModalOpen, setExceededModalOpen] = useState(false);

  const renderActionButton = () => {
    if (billingPlanExceeded) {
      return (
        <>
          <PaywallTileUnavailableModal
            billingPlan={billingPlan}
            isOpen={exceededModalOpen}
            onClose={() => {
              setExceededModalOpen(false);
            }}
          />
          <Button
            variant={ButtonVariant.Secondary}
            fullWidth
            onClick={() => setExceededModalOpen(true)}
          >
            <FormattedMessage id={getTranslationIdForCta()} />
          </Button>
        </>
      );
    }

    if (billingPlan.isSelectable) {
      return (
        <Button
          fullWidth
          variant={
            billingPlan.type === 'current'
              ? ButtonVariant.Secondary
              : ButtonVariant.Primary
          }
          disabled={billingPlan.type === 'current'}
          onClick={() =>
            onSelect({
              name: billingPlan.title,
              type: billingPlan.type,
              price: billingPlan.price || {
                id: '',
                value: '',
                currency: '',
              },
              period: billingPlan.period,
            })
          }
        >
          <FormattedMessage id={getTranslationIdForCta()} />
        </Button>
      );
    }

    return (
      <Button fullWidth variant={ButtonVariant.Secondary}>
        <FormattedMessage id="teamTeamMembershipPayWallContactUs" />
      </Button>
    );
  };

  return (
    <div className={classes.root} style={{ width: 220 }}>
      <h3 className={classes.title}>{billingPlan.title}</h3>
      {billingPlan.subtitle && (
        <span className={classes.subtitle}>{billingPlan.subtitle}</span>
      )}
      <PaywallTilePrice
        value={billingPlan.price.value}
        period={billingPlan.period}
        isSelectable={billingPlan.isSelectable}
      />
      <h4 className={classes.perksTitle}>
        <FormattedMessage id="teamTeamMembershipPayWallPerksTitle" />
      </h4>
      <List styles={adjustMarginsInList(classes.perks)}>
        {billingPlan.perks &&
          billingPlan.perks.map((perk) => (
            <ListItem
              styles={adjustMarginsInListItem(classes.perk, classes.perkIcon)}
              key={perk}
              icon={<TickIcon />}
            >
              {perk}
            </ListItem>
          ))}
      </List>
      <div className={classes.footer}>{renderActionButton()}</div>
    </div>
  );
};

export default PaywallTile;
