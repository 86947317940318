import { useState, useEffect } from 'react';

import { Space } from '../types';
import { DependencyContainer } from '../../../DependencyContainer';
import { Account, spacesAtom } from '../../../state';
import { useSetRecoilState } from 'recoil';

const { spaceService } = new DependencyContainer();

export const useFetchSpacesLocalState = (account: Account | null) => {
  const [spaces, setSpaces] = useState<Space[]>([]);
  const { isLoading } = useFetchSpaces(account, setSpaces);

  return { isLoading, spaces };
};

export const useFetchSpacesRecoil = (account: Account | null) => {
  const setSpaces = useSetRecoilState(spacesAtom);

  return useFetchSpaces(account, setSpaces);
};

const useFetchSpaces = (
  account: Account | null,
  setter: (spaces: Space[]) => void,
) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSpaces();
  }, [account]);

  const getSpaces = () => {
    if (account == null) {
      return;
    }
    const unsubscribe = spaceService.onAccountSpaces(
      account,
      async (snapshot) => {
        setIsLoading(true);
        const fetchedSpaces = await spaceService.convertSnapshotToSpaces(
          snapshot,
        );

        setter(spaceService.sortSpacesByIdTimestamp(fetchedSpaces));
        setIsLoading(false);
      },
    );
    return unsubscribe;
  };

  return { isLoading };
};
