import { createSyncedState } from './core';
import { SupportedLanguage } from '../config';
import { User } from '../features/login/UserEntity';
import { PaymentCache } from '../features/payment/types';
import { CheckoutSessionModes } from '../features/payment/PaymentClient';
import {
  ScheduledPost,
  ScheduledPostToCreate,
  ScheduledPostToDuplicate,
  ScheduledPostStrategies,
} from '../features/publish/types';
import {
  SocialAccountConnectionCache,
  SocialAccountReconnectionCache,
} from '../sets/integration/types';

type InitialState = {
  language: SupportedLanguage;
  user: User | null;
  activeAccountId: string;
  paymentCache: PaymentCache;
  socialAccountConnectionCache: SocialAccountConnectionCache;
  socialAccountReconnectionCache: SocialAccountReconnectionCache;
  scheduledPostCache:
    | ScheduledPost
    | ScheduledPostToCreate
    | ScheduledPostToDuplicate
    | null;
  scheduledPostStrategy: ScheduledPostStrategies | null;
  showFacebookPrivateGroupWarning: boolean;
  gridShifts: number;
  highlightedScheduledPostId: string | undefined;
};

const initialState: InitialState = {
  language: 'en',
  user: null,
  activeAccountId: '',
  paymentCache: {
    plan: 'unknown',
    mode: CheckoutSessionModes.Subscription,
  },
  socialAccountConnectionCache: {
    strategy: undefined,
  },
  socialAccountReconnectionCache: {
    externalAccountIdForReconnection: undefined,
    strategy: undefined,
    socialAccountId: undefined,
  },
  scheduledPostCache: null,
  scheduledPostStrategy: null,
  showFacebookPrivateGroupWarning: true,
  gridShifts: 0,
  highlightedScheduledPostId: undefined,
};
const syncedStateWrapper = createSyncedState(initialState);

export const {
  syncedState,
  useSyncedState,
  getAtom,
  initializeSyncedState,
} = syncedStateWrapper;
export default syncedStateWrapper;
