import jwt from 'jsonwebtoken';
import { HttpClient } from '../../HttpClient';
import { UserPaths } from '../../urls';

type ChangeableUserData = 'fullName' | 'avatarUrl';

export interface SettingsClientProtocol {
  changeUserData(
    id: string,
    key: ChangeableUserData,
    value: string,
  ): Promise<void>;
  changeEmail(id: string, value: string): Promise<void>;
  changePassword(id: string, value: string): Promise<void>;
}

export class SettingsClient extends HttpClient
  implements SettingsClientProtocol {
  async changeUserData(id: string, key: ChangeableUserData, value: string) {
    try {
      await this.put(UserPaths.userData(id), {
        [key]: value,
      });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
  async changeEmail(value: string) {
    try {
      await this.post(UserPaths.updateEmail, {
        email: value,
      });
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
  async changePassword(id: string, value: string) {
    try {
      await this.post(UserPaths.updatePassword, {
        token: jwt.sign({ _id: id }, 'unum', { expiresIn: '1h' }),
        newpass: value,
      });
    } catch (error) {
      return Promise.reject(error.response);
    }
  }
}
