import React from 'react';
import logo from '../../assets/svg/logo.svg';
import logoInverted from '../../assets/svg/logo-inverted.svg';
import classNames from 'classnames';
import { StylableComponent, useStyles } from '../../utils/hooks/useStyles';
import { omit } from '../../utils/omit';
import { useGoToRoute } from '../../utils/hooks/useGoToRoute';
import { Routes } from '../../config';

export enum LogoVariants {
  Small = 'sm',
  Large = 'l',
}
export enum LogoTypes {
  Normal = 'normal',
  Inverted = 'inverted',
}

export type LogoProps = {
  type?: LogoTypes;
  variant?: LogoVariants;
} & JSX.IntrinsicElements['img'];

export type LogoStyles = {
  root: string;
};

const Logo: StylableComponent<LogoProps, LogoStyles> = (props) => {
  const propsToPass = omit(props, 'className', 'children', 'styles', 'type');
  const goToRoute = useGoToRoute();
  const logoSrc =
    props.type && props.type === LogoTypes.Inverted ? logoInverted : logo;

  const classes = useStyles(
    {
      root: classNames('Logo', {
        'Logo--large': props.variant === LogoVariants.Large,
      }),
    },
    props.styles,
  );

  return (
    <img
      onClick={() => goToRoute(Routes.Index,  { handle:"" }) }
      className={classes.root}
      {...propsToPass}
      src={logoSrc}
      alt="UNUM"
    />
  );
};

export default Logo;
