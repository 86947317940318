import { HttpClient, ApiResponse } from '../../../HttpClient';
import { RankPaths } from '../../../urls';
import { AxiosResponse } from 'axios';

export class RanksClient extends HttpClient {
  async getInitialRanks(quantity: number): Promise<string[]> {
    const result = await this.get<AxiosResponse<ApiResponse<string[]>>>(
      RankPaths.initial(quantity),
    );
    return result.data.data;
  }
  async getRanksBefore(lastRank: string, quantity?: number): Promise<string[]> {
    const quantityWithDefault = typeof quantity === 'undefined' ? 1 : quantity;
    const result = await this.get<AxiosResponse<ApiResponse<string[]>>>(
      RankPaths.between(lastRank, '', quantityWithDefault),
    );
    return result.data.data;
  }

  async getRanksBetween(
    lhs: string,
    rhs: string,
    quantity?: number,
  ): Promise<string[]> {
    const quantityWithDefault = typeof quantity === 'undefined' ? 1 : quantity;
    const result = await this.get<AxiosResponse<ApiResponse<string[]>>>(
      RankPaths.between(lhs, rhs, quantityWithDefault),
    );
    return result.data.data;
  }
}
