import { firestore, Snapshot } from '../../../utils/firebase';
import { DependencyContainer } from '../../../DependencyContainer';
import firebase from 'firebase/compat';
import { TodoTask, TodoTaskStatus, UpdateTodoTaskDto } from '../types';

export type FileUploadCallbacks = {
  onTargetUrlAvailable?: (payload: {
    url: string;
    thumbnailUrl: string;
  }) => void;
};

type SnapshotHandler = (snapshot: Snapshot | Snapshot[]) => void;

export class TodosService {
  constructor(private readonly factory: DependencyContainer) {}

  onTodosContents(spaceId: string, handler: SnapshotHandler) {
    return firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('todos')
      .orderBy('createdAt', 'desc')
      .onSnapshot(handler);
  }

  async addTask(task: Partial<TodoTask>, spaceId: string) {
    return firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('todos')
      .add({
        title: task.title,
        userId: task.userId,
        status: TodoTaskStatus.Created,
        createdAt: task.createdAt
          ? firebase.firestore.Timestamp.fromMillis(task.createdAt.valueOf())
          : firebase.firestore.Timestamp.now(),
        dueDate: task.dueDate
          ? firebase.firestore.Timestamp.fromMillis(task.dueDate.valueOf())
          : '',
      });
  }

  async updateTask(
    taskId: string,
    spaceId: string,
    content: Partial<UpdateTodoTaskDto>,
  ) {
    return firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('todos')
      .doc(taskId)
      .update(content);
  }

  async deleteTask(taskId: string, spaceId: string) {
    return firestore
      .collection('spaces')
      .doc(spaceId)
      .collection('todos')
      .doc(taskId)
      .delete();
  }

  getFirestoreTimestamp(millis: number) {
    return firebase.firestore.Timestamp.fromMillis(millis);
  }
}
