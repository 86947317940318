export const en = {
  // meta tags
  defaultMetaTitle: 'Dashboard',
  defaultMetaDescription: 'Know before you post',
  signInMetaTitle: 'Sign In',
  signUpMetaTitle: 'Sign Up',
  forgotPassword: 'Forgot password',
  userProfileMetaTitle: 'Profile Settings',
  teamMetaTitle: 'Team: {teamName}',
  marketPlaceMetaTitle: 'Marketplace',

  dashboardNoSpaceHeader: 'No space created',
  dashboardNoSpaceParagraph:
    'There’s no space in this dashboard. Please start creating on our mobile App. ',
  dashboardDownloadApp: 'Get UNUM Mobile App',
  dashboardAddNewSpace: 'Add New Space',
  signupPageTitle: 'Sign Up',
  signupFormEmailLabel: 'E-mail',
  signupFormEmailError: 'Please enter a valid email',
  signupFormPasswordLabel: 'Password',
  signupFormPasswordError: 'Password must be longer than 8 characters',
  signupFormSubmit: 'CREATE ACCOUNT',
  signupFormProcessing: 'PROCESSING',
  signupInfo:
    'By signing up, you agree to our <TermsOfServiceLink>Terms of Service</TermsOfServiceLink> and <PrivacyPolicyLink>Privacy Policy</PrivacyPolicyLink>',
  signupLoginInfo: 'Already have an account? <SignInLink>Sign In</SignInLink>',
  signupSuccessPageTitle: 'Thanks for signing up!',
  signupSuccessPageContent:
    'Welcome to UNUM, {email}. Click the button below to enter the UNUM web app and start designing your story.',
  signupSuccessPageButton: 'Start designing',
  signupErrorConflict: 'The email is already in use',
  signupErrorInternal: 'Something went wrong',
  signinPageTitle: 'Sign In',
  signinFormEmailLabel: 'E-mail',
  signinFormEmailError: 'Please enter a valid email',
  signinFormPasswordLabel: 'Password',
  signinFormPasswordError: 'Password must be longer than 8 characters',
  signinFormSubmit: 'LOG IN',
  signinFormProcessing: 'PROCESSING',
  signinForgot: '<ForgotPasswordLink>Forgot password?</ForgotPasswordLink>',
  signinSignupInfo: 'Not registered? <SignUpLink>Sign Up</SignUpLink>',
  forgotPasswordPageTitle: 'Forgot password?',
  forgotPasswordPageCopy:
    'Type in your UNUM account email and we’ll send you a link to reset your password.',
  forgotPasswordConfirmationPageTitle: 'Email sent!',
  forgotPasswordConfirmationPageCopy:
    'Follow the instructions in the email to reset your password.',
  forgotPasswordConfirmationPageButtonResend: 'Resend email',
  forgotPasswordConfirmationPageButtonSubmitting: 'Sending...',
  forgotPasswordConfirmationPageResendSuccess: 'E-mail has been sent',
  resetPasswordPageTitle: 'Reset your password',
  resetPasswordNewPasswordInputLabel: 'New password',
  resetPasswordConfirmNewPasswordInputLabel: 'Confirm password',
  resetPasswordConfirmNewPasswordInputLabelMatchError: "Passwords don't match",
  resetPasswordTokenExpiredError:
    'Your password has not been changed. Token expired.',
  resetPasswordSubmitButtonLabel: 'Reset your password',
  resetPasswordConfirmationTitle: 'Your password has been reset',
  resetPasswordConfirmationCopy:
    'You can now log in with your updated password.',
  resetPasswordConfirmationSignInButton: 'Log in',
  navbarCollapse: 'Collapse navbar',
  navbarExpand: 'Expand navbar',
  navbarUpgrade: 'Upgrade',
  navbarHelp: 'Help',
  accountTogglerPlaceholder: 'My dashboard',
  modalCloseAlt: 'close modal',
  signinErrorUnauthorized: 'Wrong email or password',
  signinErrorInternal: 'Something went wrong',
  userProfileTabName: 'Profile Information',

  userProfileAccountSectionTitle: 'Account Info',
  userProfileAvatarAlt: "User's avatar",
  userProfileReplaceImage: 'Replace image',
  userProfileNameInput: 'Name',
  userProfileNameInputCaption:
    'This will not be displayed on your public profile page',
  userProfileNameInputError: 'Please enter name',
  userNameMinLengthError: 'User name must have at least {minLength} character',
  userProfileEmailInput: 'Email',
  userProfileEmailInputErrorRequired: 'Please enter email address',
  userProfileEmailInputError: 'Please enter valid email',
  userProfilePasswordInput: 'Password',
  userProfilePasswordInputError: 'Password must be longer than 8 characters',
  userProfilePasswordInputErrorRequired: 'Please enter password',
  userProfilePasswordCannotHaveSpaces: 'Password cannot have spaces',
  userProfileSuccessfulUpdate:
    'Your profile information has successfully been updated.',
  userProfileDragAndDrop: 'Drag and drop here',

  userProfilePublicProfileSectionTitle: 'Profile Info',
  userProfilePublicProfileSectionSubtitle:
    'This will be displayed on your public profile page',

  userProfilePublished: 'Public profile',
  userProfilePublishedTrue: 'Your profile is <strong>public</strong>',
  userProfilePublishedFalse: 'Your profile is <strong>private</strong>',
  userProfileGenericError: 'Field {field} has not been updated',

  userProfileCity: 'City',
  userProfileCountry: 'Country',
  userProfileOccupation: 'Occupation',
  userProfileWebsite: 'Website',
  userProfileWebsiteInputError: 'Please provide correct URL',

  userMembershipTabName: 'Membership',
  userMembershipTabPlanFree: 'Basic',
  userMembershipTabPlanElite: 'Elite',
  userMembershipTabPlanName: 'Current plan',
  userMembershipTabChangePlan: 'Change plan',

  userMembershipTabFreePlanInfoLine1: 'Your account is under our Free plan.',
  userMembershipTabFreePlanInfoLine2:
    'Any design space that you create will benefit from all the perks associated with your current plan.',
  userMembershipTabFreePlanInfoLine3:
    'When you access a shared design space, you benefit from all the perks associated with the creator of that space.',
  userMembershipTabFreePlanInfoLine4:
    'If you upgraded an individual design space or project to one of our premium plans, that individual resource will retain the benefits it received at the time of upgrade, regardless of your account plan. Upgrade your account to receive those benefits across all your spaces at no additional cost!',
  userMembershipTabsBillingDataLoadError: 'Unable to load user billing data',

  incompatibleSubscriptionNotificationModalHeading:
    'Looks like your Elite membership was purchased from {purchaseChannel}.',
  incompatibleSubscriptionNotificationModalBody:
    'To make any changes to your UNUM membership plan, please do so through your {whereToGo} account.',

  userTeamsTabName: 'Teams',
  cloneSpaces: 'Clone your spaces',
  cloneSpacesDescription:
    'Clone your space from your personal dashboard and start collaborating with your teammates.',
  myDashboardSpaces: 'My dashboard spaces',
  skipCloningSpaces: `No thanks, I'll start from scratch`,
  cloningStepSuccess: 'Your spaces have been cloned',
  cloningStepProgress: 'Cloning spaces',
  noCloneSelectionError: 'Select at least 1 space to clone',
  userTeamsCtaParagraph:
    'Create teams to easily collaborate and share designs.',
  userTeamsCtaButton: 'Create business account',
  userTeamsCreateTeamModalTitle: 'Create your team',
  userTeamsCreateTeamModalBodyHeading: 'Add your team name',
  userTeamsChooseName: 'This will be the name of your Team dashboard.',
  userTeamsCreateTeamModalBody:
    'Start your team free trial today for unlimited access to all of our features for 14 days. No credit card is required, no commitments.',
  userTeamsCreateTeamModalInputPlaceholder: 'Name your team',
  userTeamsLoadingError: 'Unable to load your teams due to error.',
  userTeamsCreateTeamModalInputError: 'Please use at least 1 character',
  userTeamsCreateTeamMinLengthError:
    'Team name must have at least {minLength} character',
  userTeamsCreateTeamModalSubmitButton: 'Create new team',
  userTeamsCreateTeamSnackbarSuccess: 'New Team has been created',
  userTeamsCreateTeamSnackbarError:
    'We are sorry, but your new team has not been added due to error.',
  userTeamsTableTeamName: 'Team name',
  userTeamsTableMyRole: 'My role',
  teamTeamInformationTabName: 'Team Information',
  teamTeamTrialAlert:
    'Your trial will end on { trialDateEnd}. To stay on team plan, <UpgradePlan>upgrade your plan</UpgradePlan>.',
  teamTeamTrialExpiredAlert:
    'Your trial has expired. To stay on team plan, <UpgradePlan>upgrade your plan</UpgradePlan>.',
  teamTeamTrialAlertForEditors: 'Your trial will end on {trialDateEnd}.',
  teamTeamTrialExpiredAlertForEditors: 'Your trial has expired.',
  teamTeamInformationTabFieldTeamName: 'Team name',
  teamTeamInformationTabFieldTeamNameError:
    'Please enter at least {minLength} character',
  teamNameMinLengthError: 'Team name must have at least {minLength} character',
  teamTeamInformationTabFieldTeamOwner: 'Team owner',
  teamTeamInformationTabFieldTeamOwnerError:
    'Please enter at least 1 character',
  teamTeamInformationNotFoundError: 'Selected team does not exist',
  teamTeamInformationNameUpdated: 'Team name has been updated',
  teamTeamMembershipTabName: 'Membership',
  teamTeamMembershipCurrentPlanFieldLabel: 'Current plan',
  teamTeamMembershipCurrentPlanTrial: '14 days free trial',
  teamTeamMembershipCurrentPlanTrialExpired: '(Expired)',
  teamTeamMembershipUpgradeNowButton: 'Upgrade now',
  teamTeamMembershipBillingHeading: 'Billing',
  teamTeamMembershipBillingUpdateMessage:
    'Your membership information has succesfully been updated.',
  teamTeamMembershipBillingEmail: 'Billing email',
  teamTeamMembershipBillingEmailErrorEmail:
    'Please provide correct billing email address',
  teamTeamMembershipBillingEmailErrorRequired: 'Billing email is required',
  teamTeamMembershipBillingPaymentMethod: 'Payment method',
  teamTeamMembershipBillingPaymentMethodManage: 'Manage',
  teamTeamMembershipBillingPaymentMethodExpires: 'Expires',
  teamTeamMembershipBillingUpcomingCharges: 'Upcoming charges',
  teamTeamMembershipBillingLastReceipt: 'Last receipt',
  teamTeamMembershipBillingViewAllReceipts: 'View all',
  teamTeamMembershipBillingMonthlyMembership: 'Monthly Membership',
  teamTeamMembershipBillingAnnualMembership: 'Annual Membership',
  teamTeamMembershipBillingCustomMembership: 'Custom Membership',

  teamTeamMembershipInvoicesModalGoBack: 'Return to settings',
  teamTeamMembershipInvoicesModalHeading: 'Completed Purchases',
  teamTeamMembershipInvoicesModalExportAsPdf: 'Export as PDF',
  teamTeamMembershipInvoicesModalDate: 'Date',
  teamTeamMembershipInvoicesModalDescription: 'Description',
  teamTeamMembershipInvoicesModalInvoiceNumber: 'Invoice number',
  teamTeamMembershipInvoicesModalAmount: 'Amount (USD)',

  deleteTeamCTA: 'Delete Team',
  deleteTeamModalTitle:
    'Are you sure you want to delete the team "{teamName}"?',
  deleteTeamModalBody:
    '<p>Things following will be deleted: </p>' +
    '<ul><li>All content in your spaces</li>' +
    '<li>Billing & invoices data</li>' +
    '<li>Team members from accessing this team</li>' +
    '<li>Any other integrations/information uploaded and stored to team</li></ul>',
  deleteTeamModalConfirmButton: 'Delete anyway',
  deleteTeamSuccessMessage: 'Team "{teamName}" is deleted',

  teamTeamMembershipTrialInfoLine1:
    'Your account is under our 14 day free trial plan.',
  teamTeamMembershipTrialInfoLine2:
    'Any design space that you create will benefit from all the perks associated with your current plan.',
  teamTeamMembershipTrialInfoLine3:
    'When you access a shared design space, you benefit from all the perks associated with the creator of that space.',
  teamTeamMembershipTrialInfoLine4:
    'If you upgraded an individual design space or project to one of our premium plans, that individual resource will retain the benefits it received at the time of upgrade, regardless of your account plan. Upgrade your account to receive those benefits across all your spaces at no additional cost!',
  teamTeamMembershipPayWallTitle: 'Grow your way',
  teamTeamMembershipPayWallSubtitle: 'Get 2 months free with annual billing',
  teamTeamMembershipPayWallFilterMonthly: 'Monthly',
  teamTeamMembershipPayWallFilterAnnual: 'Annual',
  teamTeamMembershipPayWallNoPlans: 'No plans',
  teamTeamMembershipPayWallPriceCustom: 'Custom',
  teamTeamMembershipPayWallPerksTitle: "What you'll get",
  teamTeamMembershipPayWallPerksMembers: 'team members',
  teamTeamMembershipPayWallPerksSocialAccounts: 'social accounts',

  teamTeamMembershipPayWallContactUs: 'Contact us',
  teamTeamMembershipPayWallProrationTitle:
    'Upgrade your plan from ‘{currentPlanName}’ to ‘{newPlanName}’',
  teamTeamMembershipPayWallProrationBody:
    'Upgrade your plan from {currentPlanName} to {newPlanName}, you only need to pay {prorationPrice} for current period, and then {regularPrice}/{newPlanPeriod}.',
  teamPerkSpaces: 'Unlimited spaces',
  teamPerkMembers: 'team members',
  teamPerkSocialAccounts: 'social accounts',
  teamPerkUnlimited: 'unlimited',
  globalPayWallSuccessModalTitle:
    'Your account has successfully been {action} to the',
  globalPayWallSuccessModalBody:
    ' Please check your email for your purchase confirmation and detailed plan information.',
  globalPayWallCancelModalTitle: 'You cancelled {action} process',
  globalPayWallCancelModalBody: 'Your plan has not been {action}.',
  globalPaymentChangeSuccess:
    'Your payment method has successfully been updated.',
  globalNoPermission: 'UNUM doesn’t have permission',
  userMembershipPayWalCancellationModalTitle:
    'Are you sure you want to cancel your Elite membership?',
  userMembershipPayWalFinalCancellationConfirmationModalTitle:
    'Your spaces will be downgraded to free.',
  userMembershipPayWalFinalCancellationConfirmationModalBody:
    'All benefits exclusive to the Elite membership plan will be removed from the spaces such as unlimited placeholders, premium presets, exclusive templates, and more.',
  userMembershipPayWallTitle: 'Change Your Plan',
  userMembershipPayWallBasicPlanTitle: 'Basic',
  userMembershipPayWallBasicPlanSubtitle: 'Start growing today',
  userMembershipPayWallBasicPlanPerk1: '18 grid posts',
  userMembershipPayWallBasicPlanPerk2: '1 social account',
  userMembershipPayWallBasicPlanPerk3: 'Basic presets + overlays',
  userMembershipPayWallBasicPlanPerk4: 'Basic tools',
  elitePerkGridPosts: 'Unlimited grid items',
  elitePerkSocialAccounts: 'Unlimited social accounts',
  elitePerkPresets: 'Exclusive presets + overlays (mobile only)',
  elitePerkTools: 'Premium editing tools (mobile only)',
  userMembershipPayWallBasicMembershipIncludes:
    'Your Elite membership includes:',
  userMembershipPayWallBasicPlanReasonNotToCancel1:
    'Access to 200+ premium photo presets',
  userMembershipPayWallBasicPlanReasonNotToCancel2:
    'Access to 500+ exclusive templates',
  userMembershipPayWallBasicPlanReasonNotToCancel3: 'Unlimited placeholders',
  userMembershipPayWalDowngradeSuccessful:
    'Your plan has been successfully downgraded to Basic',
  paywallCannotSelectBillingPlan: `You cannot select the plan ''{planName}'' bacause:`,
  paywallMaxTeamMembersExceeded:
    'You have more than {number} team members added to your team',
  paywallMaxIntegrationsExceeded:
    'You have more than {number} social accounts added to your team',
  paywallRemoveMembersOrIntegrations:
    'Please remove some team members or social account.',
  userMembershipCancellationSurveyTitle: 'May we ask why you cancelled?',
  userMembershipCancellationSurveyBody:
    'Your feedback is important to us. Please let us know below why you canceled your Elite Membership as it’d help up improve our products and services.',
  userMembershipCancellationReasonNotUse: 'Did not use',
  userMembershipCancellationReasonDoesNotMeetNeeds: 'Does not meet my needs',
  userMembershipCancellationReasonDifficultToUse: 'Difficult to use',
  userMembershipCancellationReasonCost: 'Costs too much',
  userMembershipCancellationReasonDuplicateAccount:
    'I have a duplicate account',
  userMembershipCancellationSurveySelectAllReasons: 'Select all that apply.',
  userMembershipCancellationSurveyThankYou: 'Thank you for your feedback',
  teamTeamMembersTabName: 'Members',
  teamJoinedSuccessfully: 'You have successfully joined the team',
  teamInviteMembersTitle: 'Invite team members',
  teamAddAnother: 'Add another',
  teamSendInvites: 'Send invites',
  teamInvitationsHaveBeenSent: 'Your invitations have been sent',
  teamInvitationsHasBeenResent: 'Your invitation has been resent',
  inviteNewMembers: 'Invite new members',
  inviteTeamMembers: 'Invite team members',
  enterEmailsToInvite:
    'Your teammates will get an email that gives them access to your team.',
  sendInvitations: 'Send invites',
  sendInvitationsLater: "No thanks, I'll send invitations later",
  teamTeamMembersSummaryCopy:
    'You have used {teamSeatsUsed} of {availableTeamSeats} team seats. Upgrade your plan for more seats.',
  teamTeamMembersTrialExpired:
    'Your trial expired. Upgrade your plan for more seats.',
  teamTeamMembersTableColumnName: 'Name',
  teamTeamMembersTableColumnEmail: 'E-mail',
  teamTeamMembersTableColumnNameEmail: 'Name / E-mail',
  teamTeamMembersTableColumnRole: 'Role',
  teamTeamMembersTableColumnActionEditProfile: 'Edit your profile',
  teamTeamMembersTableColumnActionChangeRole: 'Change role',
  teamTeamMembersForbidden: 'Only the team owner can remove members',
  editableInputDefaultPlaceholder: 'Type here',
  errorsSomethingWentWrong: 'Something went wrong',
  errorsUnsupportedIntegration: 'This integration type is not supported',
  globalActionsOk: 'OK',
  globalActionsSave: 'Save',
  globalActionsSaveChanges: 'Save changes',
  globalActionsCancel: 'Cancel',
  globalActionsApply: 'Apply',
  globalActionsDelete: 'Delete',
  globalActionsUnschedule: 'Unschedule',
  globalActionsEdit: 'Edit',
  globalActionsEditCaption: 'Caption',
  globalActionsUpdate: 'Update',
  globalActionsLogout: 'Logout',
  globalActionsRemove: 'Remove',
  globalActionsContinue: 'Continue',
  globalActionsDuplicate: 'Duplicate',
  none: 'None',
  personalAccountType: 'Personal account',
  settings: 'Settings',
  teamAccountType: 'Team account',
  upgrade: 'Upgrade',
  upgraded: 'Upgraded',
  submit: 'Submit',
  select: 'Select',
  close: 'Close',
  free: 'Free',
  thumbnail: 'Thumbnail',
  resendInvitation: 'Resend invitation',
  cancelInvitation: 'Cancel invitation',
  invitationCancelled: 'Invitation cancelled',
  currentPlan: 'Current plan',
  downgrade: 'Downgrade',
  downgraded: 'Downgraded',
  share: 'Share',
  clone: 'Clone',
  emailAddress: 'Email address',
  errorsEmailInvalid: 'Please enter a valid email',
  errorsFieldRequired: 'This field is required',
  signOut: 'Log out',
  topBarAccountDropdownOptionUserSettings: 'Account Settings',
  productsBarDesign: 'Design',
  productsBarPublish: 'Publish',
  productsBarInsights: 'Insights',
  productsBarWebBuilder: 'Today.Page',
  topBarNewBadge: 'New!',
  createFirstTeamModalTitle: 'Create a business account!',
  createFirstTeamModalIntroduction:
    ' Create with partners, clients, or team members to craft the perfect story.',
  createFirstTeamModalBenefit1: 'Unlimited Spaces',
  createFirstTeamModalBenefit2: 'Unlimited Seats',
  createFirstTeamModalBenefit3: 'Unlimited social accounts',
  createFirstTeamModalSummary:
    'Create New Business Account & Start 14-day free trial',
  accountSwitcherPersonalType: 'My personal brand',
  accountSwitcherTeamType: 'Business',
  businessMemberCount:
    '∙ {memberCount, plural, one {# member} other {# members}}',

  marketPlaceTitle: 'Discover Storyboards',
  marketPlaceSubtitle:
    'Explore, clone, and remix storyboards made by the UNUM community',
  userDefaultName: 'UNUM User',
  previewUnavailable: 'Preview unavailable',
  marketplaceCreator: 'Creator',
  marketplaceDescription: 'Description',
  marketplaceTags: 'Tags',
  marketplaceCountAssets: 'Instagram Feed • {count} assets',
  storyboardCloned: 'A new space "{name}" is added to your account',
  linkCopied: 'Link copied!',
  linkCopyError: 'Your browser does not support copying',
  publicProfileStoryboardsTab: 'Storyboards <Counter>{counter}</Counter>',
  publicProfileMembersTab: 'Members',
  publicProfileNotFound: 'Profile not found',
  elite: 'Elite',
  team: 'Team',
  space: 'Space',
  error: 'Error',

  gridAddAction: 'Add media',
  gridReplaceImageAction: 'Replace image',
  gridShowAction: 'Show action',
  gridHideAction: 'Hide action',
  gridDeleteWithCounterAction: 'Delete {selectedItemsCount}',
  gridAddPlaceholderAction: 'Add placeholder',
  gridShiftAction: 'Shift grid',
  gridDeselectAction: 'Deselect',
  gridCreateAlbumAction: 'Create album',
  gridSwapAction: 'Swap',
  gridAddRowAction: 'Add row',
  gridItemDeleted: 'Selected items were deleted',
  gridDeleteConfirmationTitle: 'Are you sure?',
  gridDeleteConfirmationBody:
    'Are you sure you want to delete {selectedItemsCount, plural,' +
    '    one {# photo}' +
    '    other {# photos}' +
    '}? This action cannot be undone.',
  gridToggleColorMapAction: 'Color Map',
  gridScheduledPost: 'Scheduled to auto-post on {date}',

  media: 'Add Media',
  placeholder: 'Add Placeholder',
  emptySpaceGridTitle: `<p>Start designing</p><p>for your {where}</p>`,
  emptySpaceGridDescription: 'Add from the options below to begin',

  spaceDeleteAction: 'Delete space',
  spaceCloneAction: 'Clone space',
  cloneSpaceTo: 'Clone space to',
  workspaces: 'Workspaces',
  cloningSuccess: `Cloned to workspace ''{workspaceName}'' successfully.'`,
  viewItNow: 'View it now',
  spaceDeleteConfirmationTitle: `Delete Space ''{spaceName}''?`,
  spaceDeleteConfirmationBody:
    `Are you sure you want to delete the space ''{spaceName}''?` +
    ' This action cannot be undone.',
  spaceDeleteSuccess: `Space ''{spaceName}'' is deleted.`,
  spaceLayout: 'Space layout',
  spaceRenameAction: 'Rename space',
  spaceName: 'Space name',
  spaceNameEmptyError: 'Space name is required',
  spaceNameMinLengthError: 'Space name cannot exceed {maxLength} characters.',
  spaceDefaultNewName: 'Untitled {layoutName}',
  changesAreSaved: 'Changes are saved!',
  igFeedPro: 'IG Feed Pro',
  defaultSpaceName: 'Untitled',
  storyboard: 'Storyboard',
  createNewSpace: 'Create new space',
  planForYourInstagram: 'Plan for your Instagram',
  yourInstagramFeed: 'Your Instagram feed',
  connectWithInstagram: 'Connect with Instagram',
  connectYourSocialAccount: 'Connect your social account',
  connectYourSocialAccountDescription:
    'Connect your social account to see your live posts with your planned content.',
  selectStoryboardLayout: 'Select Storyboard Layout',
  selectStoryboardLayoutIntroduction:
    'Select the type of view in which to display your content. You can change the space layout later.',
  errorLoadingMediaFromIntegrations: 'Unable to load media from integrations',
  spaceIntegrationExpiredTokenAlert:
    'Token for your integration expired. Please <RefreshToken>refresh your token</RefreshToken>',
  spaceMissingIntegrationAlert: 'Integrate with your Instagram account',
  spaceMissingIntegrationAlertPrompt:
    'Connect your Instagram account to see your live posts with your planned content.',
  backToDashboard: 'Back To Dashboard',
  backToInsights: 'Back To Insights',
  backToPublish: 'Back To Publish',
  marketplace: 'Marketplace',
  marketplaceExploreStoryboards: 'Explore Storyboards',
  accountNew: 'New',
  accountExpired: 'Expired',
  expiredTeamContainerTitle: 'Your Team Has Expired',
  expiredTeamContainerText: 'Please upgrade your plan to access this section.',
  expiredTeamContainerTextForEditor:
    'To access this section, please ask the team owner to upgrade your plan.',
  expiredTeamContainerAlert:
    'Your team "{teamName}" has expired. <UpgradePlan>Please upgrade your plan</UpgradePlan>',
  expiredTeamContainerAlertForEditor: '"{teamName}" has expired.',
  month: 'month',
  year: 'year',
  week: 'week',
  billedAnnually: 'billed {amount} annually',
  captionUpdated: 'Caption updated',
  typeYourCaptionHere: 'Type your caption here',
  spaceCounter:
    // eslint-disable-next-line no-useless-concat
    '{spaceCounter, plural,' + 'one {# space}' + 'other {# spaces}}',
  cloneSpaceUploadWarning:
    'Looks like some of your content is being uploaded right now. Please wait or clone without this content.',
  resourceNoLongerExists: 'Resource no longer exists',
  teamConfirmationTitle: 'Your team is almost ready!',
  teamConfirmationTitleCompleted: 'Your team is all set up!',
  teamConfirmationSubtitle: 'We need few seconds to finish your team setup.',
  teamConfirmationSubtitleCompleted:
    'Click the button below to go to your team dashboard.',
  teamConfirmationCta: 'Take me to my team',
  teamConfirmationTrialInfo: 'and start my 14-day free trial',

  teamWizardCreateTeamActionName: 'Creating your team',
  teamWizardCreateTeamDoneActionName: 'Your team has been created',
  teamWizardInviteMembersActionName: 'Inviting members',
  teamWizardInviteMembersDoneActionName: 'Your invites have been sent',
  teamWizardCreateTeamErrorMessage: 'There was an error creating your team.',
  teamWizardInviteMembersErrorMessage:
    'There was an error sending your invites.',
  teamWizardCloneSpacesErrorMessage: 'There was an error cloning your spaces.',
  teamWizardErrorAlertHeader: "We're sorry...",
  tryAgain: 'Try again',
  pleaseTryAgain: 'Please try again!',
  next: 'Next',
  confirmationAlertTitle: 'Are you sure?',
  confirmationAlertCancelTeamCreationDescription:
    'You’re about to exit this Team creation process. This action cannot be undone.',
  confirmationAlertCancelTeamCreationConfirmButton: 'Yes, exit',
  confirmationAlertSkipInvitesTitle: 'Skip inviting teammates?',
  confirmationAlertSkipInvitesDescription:
    'Try inviting executives, collaborators, influencers, and anyone else who should be seeing your story and brand strategy.',
  confirmationAlertSkipInvitesConfirmButton: 'Skip step',

  comments: 'Comments',
  addComment: 'Add comment',
  removedMember: 'Removed member',
  commentsRetrievalError:
    'There was a problem when retrieving comments. Please try again in a moment.',
  notificationNewComment:
    '<strong>{user}</strong> left a comment on <strong>{targetSpaceName}</strong>',
  notificationUploadMedia:
    '<strong>{user}</strong> uploaded new media to <strong>{targetSpaceName}</strong>',
  notificationTodoTaskDeleted:
    '<strong>{user}</strong> deleted task <strong>{targetTaskName}</strong> on <strong>{targetSpaceName}</strong>',
  notificationTodoTaskToggledUnknownStatus:
    '<strong>{user}</strong> changed status of task <strong>{targetTaskName}</strong> on <strong>{targetSpaceName}</strong>',
  notificationTodoTaskToggled:
    '<strong>{user}</strong> marked task <strong>{targetTaskName}</strong> as <strong>{newStatus}</strong> on <strong>{targetSpaceName}</strong>',
  notificationTodoTaskCreated:
    '<strong>{user}</strong> created new to do task <strong>{targetTaskName}</strong> on <strong>{targetSpaceName}</strong>',
  notificationTodoTaskUpdated:
    '<strong>{user}</strong> updated task <strong>{targetTaskName}</strong> on <strong>{targetSpaceName}</strong>',
  notificationSchedulePost:
    '<strong>{user}</strong> scheduled a new auto post to <strong>{socialAccounts}</strong>',
  notificationEditPost:
    '<strong>{user}</strong> edited a post scheduled to <strong>{socialAccounts}</strong>',
  notificationDeletePost:
    '<strong>{user}</strong> deleted a post scheduled to <strong>{socialAccounts}</strong>',
  noNewNotifications: 'No new notifications',
  unknownNotification: 'Unknown',
  emptyImageError: 'Media is required. Please import media',
  selectDateError: 'Please select date and time to schedule.',
  emptyDateError: 'Date and time is required.',

  // TODO
  taskCompleted: 'Completed',
  taskToDo: 'To do',
  taskReverted: 'Task has been reverted',
  taskRevertingError: 'Unable to revert your task',
  taskUpdated: 'Task has been updated',
  taskDeleted: 'Task has been deleted',
  taskDeletedUndo: 'undo',
  addYourTask: 'Add your task',
  toDoHeading: 'To do',
  taskDoesntExist: 'This task does not exist.',
  addDate: 'Add date and time',
  backToTaskList: 'Back to task list',
  completedHeading: '{completedTasks} Completed',
  noTasks: 'There are no tasks to do.',

  // Publish
  unschedule: 'Unschedule',
  on: 'on',
  publishMetaTitle: 'Publish',
  socialAccounts: 'Social Accounts',
  socialAccount: 'Social Account',
  addSocialAccount: 'Add social account',
  publishNoAccessTitle: 'Try UNUM Publish',
  publishNoAccessDescription:
    'Publish is only available for Business accounts. Please switch to or create a new Business account to use this feature.',
  publishNoTeamsDescription:
    'Publish is only available for Business accounts. Please create a new Business account to use this feature.',
  switchToTeamAccount: 'Switch to Business Account',
  switchAccountModalHeader: 'Switch account',
  publishEmptyQueueTitle: 'There’s no scheduled post yet',
  publishEmptyQueueDescription: 'Click the button below to plan your content.',
  publishEmptyQueueCreateNow: 'Create now',
  manageSocialAccountsHint: 'Manage social accounts',
  basicDisplayErrorStart: 'Start',
  basicDisplayErrorLater: 'Maybe later',
  basicDisplayErrorHeaderForPublish: 'Unable to publish',
  basicDisplayErrorHeaderForInsights: 'Insights unavailable',
  basicDisplayErrorText:
    'Please convert this account to an Instagram Business account. <LearnMoreLink>Learn more</LearnMoreLink>',
  basicDisplayInfoTextForPublish:
    '<p>Instagram doesn’t support automatic publishing from personal accounts.</p><p>Please convert this account to a free Instagram business account and reauthorize it to enable publishing.</p>',
  basicDisplayInfoTextForInsights:
    '<p>Instagram doesn’t support insights for personal accounts.</p><p>Please convert this account to a free Instagram business account and reauthorize it to support Insights.</p>',
  basicDisplayConvertModalHeader: 'Connect an Instagram business account',
  basicDisplayConvertModalInfo:
    'Convert your Instagram personal account to a business account to gain access to additional features. Check the list below, then continue to Facebook to reconnect your account to UNUM.',
  basicDisplayChecklistConvert:
    'Convert your Instagram account to a professional account.',
  basicDisplayChecklistLink: 'Choose Instagram business.',
  basicDisplayChecklistAdmin:
    'Make sure you have admin access to a Facebook page.',
  basicDisplayChecklistConnected:
    'Connect your Instagram account to your Facebook page.',
  basicDisplayChecklistTroubleshooting:
    'Troubleshooting Instagram Business Profiles',
  scheduledFrom: 'Scheduled from',
  facebookPrivateGroupWarningModalHeader: '{groupName} is private.',
  facebookPrivateGroupWarningModalText:
    'Before posting to a private group, make sure you’ve added the UNUM app to the group and granted the necessary permissions.',
  facebookPrivateGroupWarningModalTextWithLink:
    'For more information, please read <PostToPrivateGroupLink>this article</PostToPrivateGroupLink>.',
  facebookPrivateGroupWarningModalCheckboxLabel: 'don’t show me again',
  numberOfIntegrationsExceeded:
    'Hi there! You can only filter by 10 accounts at a time.',

  // Add social account
  addInstagramAccountModalTitle: 'Add social account',
  addInstagramAccountModalSubtitle: 'Select a platform to add a profile',
  connect: 'Connect',
  connected: 'Connected',
  instagram: 'Instagram Business',
  facebook: 'Facebook',
  pinterest: 'Pinterest',
  back: 'Back',
  addInstagramAccountFirstStepSubtitle:
    'Have you converted your Instagram account to Business Profile?',
  addInstagramAccountFirstStepInstructionLine1:
    'In order to access certain features, you need to:',
  addInstagramAccountFirstStepInstructionLine2:
    'Convert your Instagram Personal account to a Creator or Business account',
  addInstagramAccountFirstStepInstructionLine3:
    'Link your Instagram Creator or Business account to a Facebook page',
  addInstagramAccountFirstStepInstructionLine4:
    'Authenticate your Facebook account through UNUM',
  addInstagramAccountFirstStepInfo: 'Check out our Help Center for more info.',
  addInstagramAccountFirstStepInfoLinkText:
    'How to set up a Business Account on Instagram',
  addInstagramAccountFirstStepConfirmButton: 'Yes, all set',
  addInstagramAccountSecondStepSubtitle: 'Authorize on Facebook for Instagram',
  addInstagramAccountSecondStepText:
    ' Facebook requires Instagram Business Profiles to be connected to a\n' +
    '        Facebook page. In order for UNUM to share your content from UNUM to your\n' +
    '        Instagram Account, we need to authenticate through Facebook.',
  continueToFacebook: 'Continue to Facebook',
  done: 'Done',
  addInstagramAccountThirdStepSubtitle:
    'Select Business Profile to add to your account',
  noAccountsToChooseFrom: 'There are no available accounts to choose from.',
  manageSocialAccountsModalExpiredTokenInfo:
    'Some of your selected accounts have expired access token. In order to continue you need to Reconnect.',
  manageSocialAccountsModalReconnect: 'Reconnect',
  accountReconnected: 'Your account has been reconnected.',
  accountReconnectionError:
    'Something went wrong with reconnection to your account.',
  accountAlreadyExistsError: 'You have already integrated this social account.',
  igBusinessAccountType: 'Business',
  igBasicDisplayAccountType: 'Basic Display',
  socialAccountIsNotSelected:
    'You need to select at least one account to finish this process.',
  selectedSocialAccountAdded: 'Selected accounts were added',
  manageSocialAccountsModalTitle: 'Manage Social Accounts',
  manageSocialAccountsModalNoAccountsInfo:
    'You have no social accounts added yet. Please add a social account to start scheduling.',
  tokenExpired: 'Token expired',
  publishContent: 'Publish content',
  autoPost: 'Autopost',
  autoPostOn: 'Auto-post on',
  remindMe: 'Remind me',
  postDescription: 'POST DESCRIPTION',
  schedulePostCaptionPlaceholder: 'What would you like to share?',
  schedulePostAddToQueue: 'Add to queue',
  schedulePostPublishNow: 'Publish now',
  schedulePostCaptionMentionsError: 'You exceeded allowed number of mentions.',
  schedulePostCaptionHashtagsError: 'You exceeded allowed number of hashtags.',
  schedulePostCaptionCharactersError:
    'You exceeded allowed number of characters.',
  schedulePostSuccess: 'Post is scheduled successfully!',
  scheduleDuplicatedPostSuccess: 'Your post is duplicated.',
  schedulePostTo: 'Post to',
  saveScheduledPostSuccess: 'Changes have been saved',
  postPublished: 'Your post is published successfully!',
  autoPostAt: 'Auto post at {hour}',
  today: 'Today',
  tomorrow: 'Tomorrow',
  deleteScheduledPostConfirmationBody:
    'Are you sure you want to delete this scheduled post? This action cannot be undone.',
  unscheduleScheduledPostConfirmationBody:
    'Are you sure you want to unschedule this post? The post in the queue will be removed but you can still find the item in your design space.',
  badImageAspectRatio:
    'The aspect ratio has to be minimum {minAspectRatio} and maximum {maxAspectRatio}',
  badFileSize: 'Selected file has to be smaller than {limit} MB',
  badFileType: 'Selected file type is not supported',
  badVideoDuration:
    'The selected video is too long. Please select a video that is under {limitMax} seconds',
  deleteSocialAccountConfirmationHeader:
    'Are you sure you want to remove the account @{accountName}?',
  deleteSocialAccountConfirmationBody:
    'You will no longer be able to post to this account. Existing posts and data will be deleted. <strong>This action cannot be undone.</strong>',
  deleteSocialAccountSuccess: 'The social account @{accountName} is deleted.',
  reconnectWrongOriginAccountModalTitle: 'Wrong origin account',
  reconnectWrongOriginAccountModalDescription:
    'It seems like you attempted to reconnect to account from different Facebook profile. Please make sure you have logged in correct profile.',
  scheduledPostErrorInvalidInstagramToken:
    'Oops! The token for your account (@{username}) has expired. Please walk through the steps to reauthorize UNUM.”',
  scheduledPostErrorPastDate:
    'This post is scheduled for a past date. Please edit the post to reschedule it for a future date.',
  scheduledPostErrorFailedIntegrationRemoved:
    'Oops! The account to which this post is assigned was removed. Please walk through the steps to reauthorize UNUM to publish to that account or choose a different account.',
  retry: 'Retry',
  reconnect: 'Reconnect',
  importMediaModalTitle: 'Import media from',
  device: 'Device',
  archive: 'Archive',
  spaces: 'Spaces',
  dragAndDrop: 'Drag & Drop',
  dropHere: 'Drop your files here',
  or: 'or',
  selectFiles: 'Select files',
  emptyArchive: 'Your archive is empty.',
  mediaAdderNoAssetsInSpace:
    'This space does not contain any assets you can use here. Please select another space.',
  mediaAdderNoSpaces: "You don't have any spaces.",
  unableToScheduleInThePast: 'A post cannot be scheduled in the past',
  postIsAboutToBePublished: 'Publishing in progress...',

  addFacebookModalTitle: 'Add Facebook profile',
  addFacebookProfileHeading: 'Select a Facebook account type',
  facebookPage: 'Facebook Page',
  facebookPageDescription:
    'You can post to your page, a public profile that Facebook users “like”. You can also post as a page to a Facebook Group',
  facebookProfile: 'Facebook Profile',
  facebookProfileDescription:
    'You can post to your Facebook personal profile and Facebook Group that this profile joins as member',
  addFacebookAccountSecondStepSubtitle: 'Choose the page you would like to add',
  unableToLoadImage: 'Unable to load image',

  captionStrategyModalTitle: 'Choose a caption',
  captionStrategyModalText:
    'There’s an existing caption in the post you select from Design Space. Would you like to keep current caption or replace the current caption with it?',
  captionStrategyModalCTAKeepCurrent: 'Keep current caption',
  captionStrategyModalCTAReplaceCurrent: 'Replace current caption',
  captionStrategyModalCTACombine: 'Combine both captions',

  // Student pricing
  studentPricingMetaTitle: 'Students pricing',
  studentPricingHeading: 'Students Exclusive. Take 50% off UNUM Elite Plan',
  studentPricingCheckoutButton: 'Checkout now',
  studentPricingEmailDomainMismatch:
    'The email must be a student email that ends with .edu',

  // Image editing
  imageEditingModalTitle: 'Please resize your image',
  imageEditingOpenEditor: 'Open editor',
  imageEditionBadImageAspectRatio:
    'The aspect ratio has to be minimum {minAspectRatio} and maximum {maxAspectRatio} based on {platform} Requirements. Please <ResizeImageLinkButton>resize your image</ResizeImageLinkButton>.',

  facebookPersonalType: 'Personal',
  facebookPageType: 'Page',
  facebookGroupType: 'Group',
  // Publish now
  publishNowConfirmationHeader:
    'Are you sure you want to publish this post now?',
  publishNowConfirmationBody:
    'Your post will be published to your social platform immediately after you click "Publish Now".',
  submitting: 'Submitting...',
  instagramFeed: 'Instagram Feed',
  helpCenter: 'Help Center',
  notifications: 'Notifications',
  unumCloud: 'UNUM Cloud',
  accounts: 'Accounts',
  availableSoon: 'Available Soon',

  // Insights
  insightsMetaTitle: 'Insights',
  insightsWidgetNoData: 'Unable to load the data',
  insightsNoAccessTitle: 'Try Insights now!',
  insightsNoAccessDescription:
    'Insights is only accessible for Business account. Please switch to or create a new Business account to use this feature.',
  insightsNoTeamsDescription:
    'Insights is only accessible for Business account. Please create a new Business account to use this feature.',
  insightsNoSocialAccountsTitle: 'Connect to your social media',
  impressions: 'Impressions',
  engagements: 'Engagements',
  total_engagements: 'Total Engagements',
  reach: 'Reach',
  profileActivity: 'Profile Activity',
  insightsNotSupportedTitle: 'UNUM Insights feature not available',
  insightsNotSupportedMessage:
    'Some of UNUM features are not available for the social media platform.',
  total_profileActivity: 'Total Activity',
  profile_views: 'Profile Views',
  website_clicks: 'Website Clicks',
  text_message_clicks: 'Email Clicks',
  get_directions_clicks: 'Get Direction Clicks',
  phone_call_clicks: 'Get Phone Clicks',
  like_count: 'Likes',
  comments_count: 'Comments',
  follower_count: 'Followers',
  saves: 'Saves',
  chartCumulative: 'Cumulative',
  chartDaily: 'Daily',
  newFollowers: 'New Followers',
  totalFollowers: 'Total New Followers',
  netGrowth: 'Net Growth',
  totalPosts: 'Posts',
  vs: 'vs {from} - {to}',
  topHashtags: 'Top Hashtags',
  lastUpdate: 'Last update',
  justNow: 'just now',
  yesterday: 'yesterday',
  last: 'last',
  total: 'Total',

  // date ranges
  lastPeriod: 'Last {period}',
  pastPeriod: 'This {period}',
  months: '{months} months',
  customRange: 'Custom',
  bestTimeToPost: 'Best time to post',
  time: 'Time',
  lifetimeEngagements: 'Lifetime engagements',
  unnamed: 'Unnamed',

  // day names
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  sun: 'Sun',

  //Web Assets
  textAsset: 'text',
  siteBuilderAssetOption: 'Asset',
  siteBuilderEditOption: 'Edit',
  siteBuilderPageLayoutTitle: "Page Layout",
  siteBuilderPageAddNewElement: "Blocks",
  pageDeleteSuccess: `Page ''{pageName}'' has been successfully deleted.`,
  pageDeleteError: `There was an error deleting your page. Please try again.`,
  pageRenameAction: 'Rename Page',
  pageCloneAction: 'Clone Page',
  pageDeleteAction: 'Delete Page',
  pageDeleteConfirmationTitle: `Delete Page ''{pageName}''?`,
  pageDeleteConfirmationBody:
  `Are you sure you want to delete the page ''{pageName}''?` +
  ' This action cannot be undone.',

  renameHandle: 'Rename Handle',
  newHandle: 'New Handle',
  handleMinLengthError: 'Usernames must be longer than 3 characters.',
  handleMaxLengthError: 'Username name cannot exceed {maxLength} characters.',
  handleRegexError : 'Usernames may only contain letters, numbers, underscores ("_") and periods (".")',

  elementDeleteConfirmationBody:
  `Are you sure you want to delete this {blockType} block?` +
  ' This action cannot be undone.',
  elementDeleteConfirmationTitle: 'Delete {blockType}',
  urlRegexError: 'Invalid URL',
  emailRegexError: 'Invalid Email'
};
