import {
  atom,
  RecoilRootProps,
  selector,
  selectorFamily,
  waitForAll,
} from 'recoil';
import { defaultLocationAfterLogin, SupportedLanguage } from './config';
import { getAtom, syncedState } from './synced-state/synced-state';
import teamDefaultAvatar from './assets/svg/team-default-avatar.svg';
import { TeamMemberType } from './features/team/TeamService';
import moment from 'moment';
import { User } from './features/login/UserEntity';
import { TeamSubscriptionResponse } from './features/team/TeamClient';
import {
  AvailablePlans,
  PaymentChannels,
  PaymentMethod,
  TeamPlans,
  UserPayments,
  UserPlans,
} from './features/payment/PaymentService';
import { PaywallTileBillingPlan } from './features/payment/components/PaywallTile/PaywallTile';
import { Storyboard } from './features/marketplace/types';
import {
  CommentsUnseenByUser,
  Space,
  SpaceTypes,
} from './features/dashboard/types';
import { StripeSubscriptionStatus } from './features/payment/types';
import { ReactElement } from 'react';
import {
  TodosPanelView,
  TodosViews,
  TodoTask,
  TodoTaskStatus,
} from './features/todos/types';
import { SchedulePostsBySource } from './features/publish/types';
import { isSocialAccountExpired } from './features/integration/utils/isSocialAccountExpired';
import { XOR } from './utils/types';
import { ReloadRegistry } from './utils/hooks/useContentReload';
import {
  IntegrationPostConnectionStrategy,
  SocialAccount,
} from './sets/integration/types';
import { DefaultDashboard } from './features/insights/types';
import { DateRange } from './components/DateRangePicker/DateRangePicker';
import { insightsDaysAgoLimit } from './utils/limitFactories';
import { Page, PageLayout, Site, WebBuilderLayoutElement } from './features/siteBuilder/types';

export const locationAtom = atom({
  key: 'locationAtom',
  default: '',
});

export const languageAtom = atom<SupportedLanguage>({
  key: 'languageAtom',
  default: 'en',
});

export type RegisteredEmailAtom = string | null;
export type Account = {
  _id: string;
  avatarUrl: string;
  name: string;
  type: AccountType;
  role?: UserRoles;
  billingDetails: BillingDetails | null;
  subscriptionDetails?: UserTeamSubscriptionDetails;
  memberCount?: number;
  freeTrialEnd?: number;
};

export type UserTelemetry = {
  id: string;
  email: string;
  userPlan: UserPlans;
  teamPlan: TeamPlans;
  teamCount: number;
  isCurrentlyInTeam: boolean;
  currentAccountSpaceIds: {
    storyboard: string[];
    grid: string[];
  };
  currentSpaceType?: SpaceTypes;
};

export enum AccountType {
  Personal = 'personal',
  Team = 'team',
}

export const personalAccountSelector = selector<Account>({
  key: 'personalAccount',
  get: ({ get }) => {
    const user = get(getAtom('user'));
    const billingDetails = get(userBillingDetailsAtom);
    return {
      _id: (user && user._id) || '',
      avatarUrl: (user && user.avatarUrl) || teamDefaultAvatar,
      name: (user && user.fullName) || '',
      type: AccountType.Personal,
      billingDetails,
    };
  },
});

export enum UserRoles {
  Owner = 'owner',
  Editor = 'editor',
  Viewer = 'viewer',
}
export type UserTeamSubscriptionLimitations = {
  default: { integrations: number; members: number };
};
export type UserTeamSubscriptionDetails = {
  name: TeamPlans;
  status: StripeSubscriptionStatus;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  limitations?: UserTeamSubscriptionLimitations;
  gateway: 'stripe';
  gatewaySpecificData?: { customer: string; subscription: string };
};

export type UserTeam = {
  _id: string;
  name: string;
  avatarUrl: string;
  role: UserRoles;
  freeTrialEnd: number;
  memberCount: number;
  billingDetails: BillingDetails | null;
  subscriptionDetails?: UserTeamSubscriptionDetails;
};

export const userTeamsAtom = atom<UserTeam[] | null>({
  key: 'userTeamsAtom',
  default: null,
});

export const registeredEmailAtom = atom<RegisteredEmailAtom>({
  key: 'registeredEmailAtom',
  default: null,
});

export const teamAccountsSelector = selector<Account[]>({
  key: 'teamAccountsSelector',
  get: ({ get }) => {
    const userTeams = get(userTeamsAtom);
    if (userTeams === null) {
      return [];
    }
    return userTeams.map((t) => ({
      ...t,
      type: AccountType.Team,
      avatarUrl: t.avatarUrl || teamDefaultAvatar,
      role: t.role,
      limitations: t.subscriptionDetails?.limitations,
    }));
  },
});

export const hasRoleInTeamSelector = selectorFamily({
  key: 'hasRoleInTeamSelector',
  get: (role: UserRoles) => ({ get }) => {
    const userTeams = get(userTeamsAtom);
    const activeAccount = get(activeAccountSelector);
    if (userTeams === undefined || userTeams === null || !activeAccount) {
      return false;
    }
    const team = userTeams.find(
      (t) => t._id === activeAccount._id && t.role === role,
    );
    return Boolean(team);
  },
});

export const allAccountsSelector = selector<Account[]>({
  key: 'allAccountsSelector',
  get: ({ get }) => {
    const personal = get(personalAccountSelector);
    const teams = get(teamAccountsSelector);
    return [personal, ...teams];
  },
});

export const activeAccountSelector = selector<Account | null>({
  key: 'activeAccountSelector',
  get: ({ get }) => {
    const accounts = get(allAccountsSelector);
    const activeAccountId = get(getAtom('activeAccountId'));
    return accounts.find((account) => account._id === activeAccountId) || null;
  },
});

export type ResourceOwner = XOR<{ groupId: string }, { userId: string }>;
export const activeResourceOwnerSelector = selector<ResourceOwner | null>({
  key: 'activeResourceOwner',
  get: ({ get }) => {
    const activeAccount = get(activeAccountSelector);
    if (!activeAccount) {
      return null;
    }
    if (activeAccount.type === AccountType.Personal) {
      return { userId: activeAccount._id };
    }
    if (activeAccount.type === AccountType.Team) {
      return { groupId: activeAccount._id };
    }
    return null;
  },
});

export const currentTeamAccountSelector = selector<UserTeam | null>({
  key: 'currentTeamAccountSelector',
  get: ({ get }) => {
    const activeAccountId = get(getAtom('activeAccountId'));
    const userTeams = get(userTeamsAtom);
    return userTeams?.find((acc) => acc._id === activeAccountId) || null;
  },
});

export const isActiveTeamAccountExpiredSelector = selector<boolean | null>({
  key: 'isActiveTeamAccountExpiredSelector',
  get: ({ get }) => {
    const activeUserTeam = get(currentTeamAccountSelector);
    if (!activeUserTeam) {
      return null;
    }

    return isAccountExpired({ ...activeUserTeam, type: AccountType.Team });
  },
});

export const targetAccountPlanSelector = selector<AvailablePlans | null>({
  key: 'targetAccountPlanSelector',
  get: ({ get }) => {
    const selectedAccount = get(paywallTargetAccountAtom);
    const activeAccount = get(activeAccountSelector);
    const targetAccount = selectedAccount || activeAccount;

    if (targetAccount == null) {
      return null;
    }

    if (targetAccount.type === AccountType.Personal) {
      const payments = get(userPaymentsAtom);
      return payments?.plan || UserPlans.Free;
    } else {
      if (targetAccount?.subscriptionDetails == null) {
        return TeamPlans.None;
      }

      return targetAccount.subscriptionDetails.name;
    }
  },
});

export const personalPlanSelector = selector<UserPlans>({
  key: 'personalPlanSelector',
  get: ({ get }) => {
    const payments = get(userBillingDetailsAtom);
    return (payments?.plan as UserPlans) || UserPlans.Free;
  },
});

export const teamPlanSelector = selector<TeamPlans>({
  key: 'teamPlanSelector',
  get: ({ get }) => {
    const activeUserTeam = get(currentTeamAccountSelector);
    if (activeUserTeam?.subscriptionDetails == null) {
      return TeamPlans.None;
    }

    return activeUserTeam.subscriptionDetails.name;
  },
});

export const activeAccountPlanSelector = selector<AvailablePlans | null>({
  key: 'activeAccountPlanSelector',
  get: ({ get }) => {
    const activeAccount = get(activeAccountSelector);

    if (activeAccount == null) {
      return null;
    }

    if (activeAccount.type === AccountType.Personal) {
      return get(personalPlanSelector);
    } else {
      return get(teamPlanSelector);
    }
  },
});

export enum SnackbarTypes {
  Success = 'success',
  Error = 'error',
}
export type SnackbarAtom = {
  type?: SnackbarTypes;
  message: string | ReactElement;
} | null;
export const snackbarAtom = atom<SnackbarAtom>({
  key: 'snackbarAtom',
  default: null,
});

export const teamWizardOpenedAtom = atom({
  key: 'teamWizardOpenedAtom',
  default: false,
});

export const paywallOpenedAtom = atom({
  key: 'paywallOpenedAtom',
  default: false,
});

export const paywallTargetAccountAtom = atom<Account | undefined>({
  key: 'paywallTargetAccountAtom',
  default: undefined,
});

export type TeamInformationAtom = {
  teamId: string;
  teamName: string;
  teamOwner: string;
  teamAvatarUrl: string;
  freeTrialEnd?: moment.Moment;
  subscription?: TeamSubscriptionResponse;
} | null;
export const teamInformationAtom = atom<TeamInformationAtom>({
  key: 'teamInformationAtom',
  default: null,
});

export type BillingDetails = {
  plan: string;
  isTrial: boolean;
  status: string;
  billingEmail?: string;
  paymentMethod?: PaymentMethod;
  currentPeriodStart?: moment.Moment;
  currentPeriodEnd?: moment.Moment;
};

export const teamBillingDetailsAtom = atom<BillingDetails | null>({
  key: 'teamBillingDetailsAtom',
  default: null,
});

export const teamTrialSelector = selector<{
  isTrial: boolean;
  isTeamTrialActive: boolean;
}>({
  key: 'isTeamTrialSelector',
  get: ({ get }) => {
    const teamInformation = get(teamInformationAtom);
    const teamBillingDetails = get(teamBillingDetailsAtom);

    const isTrial = Boolean(
      teamInformation && teamBillingDetails && teamBillingDetails.isTrial,
    );

    const freeTrialEnd =
      teamInformation && teamInformation.freeTrialEnd
        ? teamInformation.freeTrialEnd
        : moment().subtract(1, 'd');
    const isTeamTrialActive = !freeTrialEnd.isBefore(moment());

    return {
      isTrial,
      isTeamTrialActive,
    };
  },
});
export const teamMembersAtom = atom<TeamMemberType[]>({
  key: 'teamMembersAtom',
  default: [],
});

export const isTeamOwnerSelector = selector<boolean>({
  key: 'isTeamOwnerSelector',
  get: ({ get }) => {
    const user = get(getAtom('user'));
    if (!user) {
      return false;
    }
    const teamMembers = get(teamMembersAtom);
    const ownerMember = teamMembers.find(
      (member: TeamMemberType) =>
        member._id === user._id && member.role === UserRoles.Owner,
    );
    return Boolean(ownerMember);
  },
});

export const countTeamAcceptedMembersSelector = selector<number>({
  key: 'countTeamAcceptedMembersSelector',
  get: ({ get }) => {
    const teamMembers = get(teamMembersAtom);
    const acceptedMembers = teamMembers.filter((m) => m.isPending === false);
    return acceptedMembers.length;
  },
});

// @TODO: remove in separate PR and use synced state hook
export const userSelector = selector<User | null>({
  key: 'userSelector',
  get: ({ get }) => get(getAtom('user')),
});

export const userPaymentsAtom = atom<UserPayments | null>({
  key: 'userPaymentsAtom',
  default: null,
});

export const userBillingDetailsAtom = atom<BillingDetails | null>({
  key: 'userBillingDetailsAtom',
  default: null,
});

export const hasUserEliteSubscriptionSelector = selector<boolean>({
  key: 'hasUserEliteSubscriptionSelector',
  get: ({ get }) => {
    const payments = get(userPaymentsAtom);
    return Boolean(payments && payments.plan === UserPlans.Elite);
  },
});

export const hasUserEliteSubscriptionBoughtViaSelector = selectorFamily({
  key: 'hasUserEliteSubscriptionBoughtViaSelector',
  get: (channel: PaymentChannels) => ({ get }) => {
    const [payment] = get(waitForAll([userPaymentsAtom]));
    return Boolean(
      payment &&
        payment.purchased_via === channel &&
        payment.plan !== UserPlans.Free,
    );
  },
});

export const initializeAccounts = (
  set: Parameters<NonNullable<RecoilRootProps['initializeState']>>[0]['set'],
) => {
  const user = syncedState.get('user');
  const activeAccountId = syncedState.get('activeAccountId');
  if (user && !activeAccountId) {
    syncedState.set('activeAccountId', user._id);
    set(getAtom('activeAccountId'), user._id);
  }
};

export const desiredLocationAfterLoginAtom = atom<string>({
  key: 'desiredLocationAfterLogin',
  default: defaultLocationAfterLogin,
});

export const billingPlansAtom = atom<PaywallTileBillingPlan[]>({
  key: 'billingPlansAtom',
  default: [],
});

export const storyboardsAtom = atom<Storyboard[]>({
  key: 'storyBoardsAtom',
  default: [],
});

export const spacesAtom = atom<Space[] | undefined>({
  key: 'spacesAtom',
  default: undefined,
});

export const selectedSpaceIdAtom = atom<string | undefined>({
  key: 'selectedSpaceIdAtom',
  default: undefined,
});

export const selectedSpaceSelector = selector<Space | undefined>({
  key: 'selectedSpaceSelector',
  get: ({ get }) => {
    const spaces = get(spacesAtom);
    const selectedSpaceId = get(selectedSpaceIdAtom);
    return spaces && spaces.find(({ id }) => id === selectedSpaceId);
  },
});

export const nextSelectedSpaceSelector = selector<Space | undefined>({
  key: 'nextSelectedSpaceSelector',
  get: ({ get }) => {
    const spaces = get(spacesAtom);
    if (!spaces || spaces.length < 2) {
      return;
    }

    const selectedSpaceId = get(selectedSpaceIdAtom);
    const selectedSpaceIndex = spaces.findIndex(
      (el) => el.id === selectedSpaceId,
    );
    const nextSpaceIndex = selectedSpaceIndex + 1;
    const hasNextSpace = nextSpaceIndex < spaces.length;

    return hasNextSpace ? spaces[nextSpaceIndex] : spaces[0];
  },
});

export const groupIdSelector = selector<string | undefined>({
  key: 'groupId',
  get: ({ get }) => {
    const activeAccount = get(activeAccountSelector);

    const groupId =
      activeAccount?.type === AccountType.Team ? activeAccount._id : undefined;

    return groupId;
  },
});

export const instagramGridSpacesSelector = selector<Space[]>({
  key: 'instagramGridSpacesSelector',
  get: ({ get }) =>
    get(spacesAtom)?.filter(
      (space) => space.type === SpaceTypes.InstagramGrid,
    ) || [],
});

export const selectedSpaceGridItemsAtom = atom<string[]>({
  key: 'selectedSpaceGridItemsAtom',
  default: [],
});

export const addNewSpaceModalOpenAtom = atom({
  key: 'addNewSpaceModalOpenAtom',
  default: false,
});

export const captionToolTextAtom = atom<string | undefined>({
  key: 'captionToolTextAtom',
  default: undefined,
});

export const userTelemetrySelector = selector<UserTelemetry | null>({
  key: 'userTelemetrySelector',
  get: ({ get }) => {
    const user = get(getAtom('user'));
    const spaces = get(spacesAtom) || [];
    const selectedSpace = get(selectedSpaceSelector);
    const activeAccount = get(activeAccountSelector);

    if (user == null) {
      return null;
    }

    return {
      id: user._id,
      email: user.email,
      userPlan: get(personalPlanSelector),
      teamPlan: get(teamPlanSelector),
      teamCount: get(teamAccountsSelector).length,
      isCurrentlyInTeam: activeAccount?.type === AccountType.Team,
      currentAccountSpaceIds: {
        storyboard: spaces
          .filter((space) => space.type === SpaceTypes.Storyboard)
          .map((space) => space.id),
        grid: spaces
          .filter((space) => space.type === SpaceTypes.InstagramGrid)
          .map((space) => space.id),
      },
      currentSpaceType: selectedSpace?.type,
    };
  },
});

export type TeamWizardAction = {
  name: TeamWizardActions;
  label: string;
  labelDone?: string;
  executables: Executable<any>[];
  executionResult?: any;
  loading: boolean;
  isDone?: boolean;
  error: any;
};

export enum ExecutableStatus {
  NotExecuted = 'notExecuted',
  Done = 'done',
  Error = 'error',
}

export type Executable<T> =
  | {
      execute: (params?: any) => Promise<T>;
      status: ExecutableStatus.NotExecuted;
    }
  | {
      execute: (params?: any) => Promise<T>;
      status: ExecutableStatus.Done;
      result: T;
    }
  | {
      execute: (params?: any) => Promise<T>;
      status: ExecutableStatus.Error;
      error: Error;
    };

export enum TeamWizardSteps {
  CreateTeam = 'createTeam',
  CloneSpaces = 'cloneSpaces',
  InviteMembers = 'inviteMembers',
  Confirmation = 'confirmation',
}

export enum TeamWizardActions {
  CreateTeam = 'createTeam',
  CloneSpaces = 'cloneSpaces',
  InviteMembers = 'inviteMembers',
}
export const teamWizardStepAtom = atom({
  key: 'teamWizardStepAtom',
  default: TeamWizardSteps.CreateTeam,
});

export const teamWizardActionsAtom = atom<TeamWizardAction[]>({
  key: 'teamWizardActionsAtom',
  default: [],
});

export const haveAllActionsBeenCompletedSelector = selector<boolean>({
  key: 'haveAllActionsBeenCompleted',
  get: ({ get }) => {
    const teamWizardActions = get(teamWizardActionsAtom);
    const completed = teamWizardActions.filter((action) => action.isDone);
    return teamWizardActions.length === completed.length;
  },
});

export const isAccountExpired = (account: Account) => {
  if (account.type === AccountType.Personal) {
    return false;
  }

  const isFreeTrialExpired = moment(account.freeTrialEnd! * 1000).isBefore(
    moment(),
  );
  const hasNoActivePlan =
    account.subscriptionDetails?.status !== StripeSubscriptionStatus.Active;

  return isFreeTrialExpired && hasNoActivePlan;
};

export const todosPanelOpenedAtom = atom({
  key: 'todosPanelOpenedAtom',
  default: false,
});

// To do tasks module
export const todosPanelOpenedSelector = selector({
  key: 'todosPanelOpenedSelector',
  get: ({ get }) => {
    const todosPanelOpened = get(todosPanelOpenedAtom);
    const activeAccount = get(activeAccountSelector);

    return activeAccount?.type === AccountType.Team && todosPanelOpened;
  },
});

export const todosPanelViewAtom = atom<TodosPanelView>({
  key: 'todosPanelViewAtom',
  default: {
    view: TodosViews.List,
  },
});

export const todosTasksAtom = atom<TodoTask[]>({
  key: 'todosTasksAtom',
  default: [],
});

export const todosTasksByStatusSelector = selectorFamily({
  key: 'todosTasksByStatusSelector',
  get: (status: TodoTaskStatus) => ({ get }) => {
    const tasks = get(todosTasksAtom);
    return tasks.filter((task) => task.status === status);
  },
});

export const todosSelectedTaskSelector = selector<TodoTask | undefined>({
  key: 'todosSelectedTaskSelector',
  get: ({ get }) => {
    const view = get(todosPanelViewAtom);
    const tasks = get(todosTasksAtom);
    return tasks.find((task) => task.id === view?.params?.id);
  },
});
// End to do task module

export const commentsUnseenByUserAtom = atom<CommentsUnseenByUser>({
  key: 'commentsUnseenByUser',
  default: {},
});

export const socialAccountsAtom = atom<SocialAccount[]>({
  key: 'socialAccountsAtom',
  default: [],
});

export const socialAccountsLoadingAtom = atom<boolean>({
  key: 'socialAccountsLoadingAtom',
  default: true,
});

export const selectedSocialAccountIdForInsightsAtom = atom<string[]>({
  key: 'selectedSocialAccountIdForInsightsAtom',
  default: [],
});

export const socialAccountsAccessTokenExpirationTimeAtom = atom<number>({
  key: 'socialAccountsAccessTokenExpirationTimeAtom',
  default: 0,
});

export const selectedSocialAccountIdsAtom = atom<string[]>({
  key: 'selectedSocialAccountIdsAtom',
  default: [],
});

export const scheduledPostsAtom = atom<SchedulePostsBySource>({
  key: 'scheduledPosts',
  default: {
    api: [],
    firestoreSpaces: [],
    firestoreNonSpaces: [],
  },
});

export const hasExpiredSocialAccountSelector = selector<boolean>({
  key: 'hasExpiredSocialAccountSelector',
  get: ({ get }) => {
    const socialAccounts = get(socialAccountsAtom);
    if (!socialAccounts.length) {
      return false;
    }
    const expiredAccounts = socialAccounts.filter((account) =>
      isSocialAccountExpired(account),
    );
    return Boolean(expiredAccounts.length);
  },
});

export const invalidAccessTokensForAccountsAtom = atom<string[]>({
  key: 'invalidAccessTokensForAccountsAtom',
  default: [],
});

export const integrationPostConnectionStrategyAtom = atom<
  IntegrationPostConnectionStrategy | undefined
>({
  key: 'integrationPostConnectionStrategyAtom',
  default: undefined,
});

export const productsBarExpandedAtom = atom<boolean>({
  key: 'productsBarExpandedAtom',
  default: false,
});

export const reloadRegistryAtom = atom<ReloadRegistry>({
  key: 'reloadRegistryAtom',
  default: {},
});

export const defaultDashboardAtom = atom<DefaultDashboard | undefined>({
  key: 'defaultDashboardAtom',
  default: undefined,
});

export const addNewSpaceLoaderAtom = atom<boolean>({
  key: 'addNewSpaceLoaderAtom',
  default: false,
});
export const defaultInsightsDashboardDateRange = atom<DateRange>({
  key: 'defaultInsightsDashboardDateRange',
  default: {
    start: moment().subtract(30, 'days'),
    end: moment().subtract(1, 'days'),
  },
});
export const insightsLastUpdateAtom = atom<moment.Moment>({
  key: 'insightsLastUpdateAtom',
  default: moment().subtract(insightsDaysAgoLimit, 'd'),
});

export const siteAtom = atom<Site | undefined>({
  key: 'siteAtom',
  default: undefined,
});

export const selectedPageIdAtom = atom<string | undefined>({
  key: 'selectedPageIdAtom',
  default: undefined,
});

export const pagesAtom = atom<Page[] | undefined>({
  key: 'pagesAtom',
  default: undefined,
});

export const selectedPageSelector = selector<Page | undefined>({
  key: 'selectedPageSelector',
  get: ({ get }) => {
    const pages = get(pagesAtom);
    const selectedPageId = get(selectedPageIdAtom);
    return pages && pages.find(({ _id }) => _id === selectedPageId);
  },
});

export const pageLayoutAtom = atom<PageLayout | undefined>({
  key: 'pageLayoutAtom',
  default: undefined,
});

export const pageLayoutUpdateAtom = atom<PageLayout[] | undefined>({
  key: 'pagesLayoutUpdateAtom',
  default: undefined,
});

export const originalPagesLayoutAtom = atom<PageLayout[] | undefined>({
  key: 'originalPagesLayoutAtom',
  default: undefined,
});

export const templateElementsAtom = atom<WebBuilderLayoutElement[] | undefined>({
  key: 'templateElementsAtom',
  default: undefined,
});

export const livePageAtom = atom<Page | undefined>({
  key: 'livePageAtom',
  default: undefined,
});

export const isCreatorAtom = atom<Boolean>({
  key: 'isCreatorAtom',
  default: false,
});