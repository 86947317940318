import React, { FunctionComponent, useState } from 'react';
import { ResizeObserver } from '@juggle/resize-observer';
import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody';
import ModalHeader from '../../../../components/Modal/ModalHeader';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  teamWizardOpenedAtom,
  teamWizardActionsAtom,
  TeamWizardAction,
  TeamWizardSteps,
  TeamWizardActions,
  teamWizardStepAtom,
  personalAccountSelector,
} from '../../../../state';
import { useIntl } from '../../../../utils/hooks/useIntl';
import { ButtonStyles } from '../../../../components/Button/Button';
import SummaryModalBody from './SummaryModalBody';
import CreateTeamModalBody from './CreateTeamModalBody';
import InviteMembersModalBody from './InviteMembersModalBody';
import CloneSpacesModalBody from './CloneSpacesModalBody';
import ConfirmationModalRenderer from './ConfirmationModalsRenderer';
import useMeasure from 'react-use-measure';
import ModalProgressBar from '../../../../components/Modal/ModalProgressBar';
import {
  useWebviewMessageDispatcher,
  WebviewMessages,
  ModalClosedSources,
} from '../../../../WebviewMessageDispatcher';
import { useFetchSpacesLocalState } from '../../../dashboard/hooks/useFetchSpaces';

export enum TeamWizardAlerts {
  CloseAlertConfirmation = 'CloseAlertConfirmation',
  SkipInvitationsAlertConfirmation = 'SkipInvitationsAlertConfirmation',
}

export const addTopMarginToSubmit = (current: ButtonStyles) => ({
  ...current,
  root: `${current.root} TeamWizard__submit`,
});

const TeamWizard: FunctionComponent = () => {
  const setWizardActions = useSetRecoilState(teamWizardActionsAtom);
  const [step, setStep] = useRecoilState<TeamWizardSteps>(teamWizardStepAtom);
  const [teamWizardOpened, setTeamWizardOpened] = useRecoilState(
    teamWizardOpenedAtom,
  );

  const personalAccount = useRecoilValue(personalAccountSelector);

  const { spaces } = useFetchSpacesLocalState(personalAccount);

  const [visibleAlert, setVisibleAlert] = useState();
  const { formatMessage } = useIntl();
  const [modalRef, modalDimensions] = useMeasure({ polyfill: ResizeObserver });
  const { dispatch } = useWebviewMessageDispatcher();

  const closeModal = () => {
    setTeamWizardOpened(false);
    setWizardActions([]);
    setStep(TeamWizardSteps.CreateTeam);

    dispatch({
      name: WebviewMessages.ModalClosed,
      payload: {
        source: ModalClosedSources.TeamWizardModal,
      },
    });
  };

  const onRequestClose = () => {
    if (step === TeamWizardSteps.Confirmation) {
      closeModal();
      return;
    }
    setVisibleAlert(TeamWizardAlerts.CloseAlertConfirmation);
  };

  return (
    <Modal
      isOpen={teamWizardOpened}
      onRequestClose={() => onRequestClose()}
      overlayRef={modalRef}
      fullScreen
    >
      <ModalProgressBar
        allSteps={Object.values(TeamWizardSteps)}
        currentStep={step}
      />
      <ModalHeader />
      <ModalBody>
        {(() => {
          switch (step) {
            case TeamWizardSteps.CreateTeam:
              return (
                <CreateTeamModalBody
                  onSubmit={(executable) => {
                    setWizardActions([
                      {
                        name: TeamWizardActions.CreateTeam,
                        label: formatMessage({
                          id: 'teamWizardCreateTeamActionName',
                        }),
                        labelDone: formatMessage({
                          id: 'teamWizardCreateTeamDoneActionName',
                        }),
                        executables: [executable],
                        loading: false,
                        error: undefined,
                      },
                    ]);

                    setStep(
                      spaces.length > 0
                        ? TeamWizardSteps.CloneSpaces
                        : TeamWizardSteps.InviteMembers,
                    );
                  }}
                />
              );
            case TeamWizardSteps.CloneSpaces:
              return (
                <CloneSpacesModalBody
                  personalSpaces={spaces}
                  onRequestClose={() => {
                    setStep(TeamWizardSteps.InviteMembers);
                  }}
                  onSubmit={(executables) => {
                    setWizardActions((prev: TeamWizardAction[]) => [
                      ...prev,
                      {
                        name: TeamWizardActions.CloneSpaces,
                        label: formatMessage({ id: 'cloningStepProgress' }),
                        labelDone: formatMessage({ id: 'cloningStepSuccess' }),
                        executables,
                        loading: false,
                        error: undefined,
                      },
                    ]);
                    setStep(TeamWizardSteps.InviteMembers);
                  }}
                />
              );
            case TeamWizardSteps.InviteMembers:
              return (
                <InviteMembersModalBody
                  onRequestClose={() =>
                    setVisibleAlert(
                      TeamWizardAlerts.SkipInvitationsAlertConfirmation,
                    )
                  }
                  onSubmit={(executables) => {
                    setWizardActions((prev: TeamWizardAction[]) => {
                      return [
                        ...prev,
                        {
                          name: TeamWizardActions.InviteMembers,
                          label: formatMessage({
                            id: 'teamWizardInviteMembersActionName',
                          }),
                          labelDone: formatMessage({
                            id: 'teamWizardInviteMembersDoneActionName',
                          }),
                          executables,
                          loading: false,
                          error: undefined,
                        },
                      ];
                    });
                    setStep(TeamWizardSteps.Confirmation);
                  }}
                />
              );
            case TeamWizardSteps.Confirmation:
              return (
                <SummaryModalBody
                  closeModal={closeModal}
                  modalDimensions={modalDimensions}
                />
              );
          }
        })()}
      </ModalBody>

      <ConfirmationModalRenderer
        type={visibleAlert}
        onConfirm={() => {
          if (visibleAlert === TeamWizardAlerts.CloseAlertConfirmation) {
            closeModal();
          } else if (
            visibleAlert === TeamWizardAlerts.SkipInvitationsAlertConfirmation
          ) {
            setStep(TeamWizardSteps.Confirmation);
          }
        }}
        onRequestClose={() => setVisibleAlert(undefined)}
      />
    </Modal>
  );
};

export default TeamWizard;
