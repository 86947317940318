import React, { useEffect } from 'react';
import ProfileInformation from './components/ProfileInformation/ProfileInformation';
import { useParams } from 'react-router';
import { useGoToRoute } from '../../utils/hooks/useGoToRoute';
import { GLOBAL_MEDIA_QUERIES, metaTags, Routes } from '../../config';
import Teams from './components/Teams/Teams';
import Card, { CardStyles } from '../../components/Card/Card';
import TabsResponsive from '../../components/Tabs/TabsResponsive';
import { useMedia } from 'react-media';
import { useIntl } from '../../utils/hooks/useIntl';
import { DependencyContainer } from '../../DependencyContainer';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  SnackbarTypes,
  userBillingDetailsAtom,
  userPaymentsAtom,
  userSelector,
  userTeamsAtom,
} from '../../state';
import UserMembership from './components/UserMembership/UserMembership';
import { useSnackbar } from '../../utils/hooks/useSnackbar';
import { useAccounts } from '../../utils/hooks/useAccounts';
import { Helmet } from 'react-helmet';

const dp = new DependencyContainer();
const paymentService = dp.paymentService;
const teamService = dp.teamService;

const options = [
  {
    labelMessageId: 'userProfileTabName',
    content: <ProfileInformation />,
    slug: 'profile',
  },
  {
    labelMessageId: 'userMembershipTabName',
    content: <UserMembership />,
    slug: 'membership',
  },
  {
    labelMessageId: 'userTeamsTabName',
    content: <Teams />,
    slug: 'teams',
  },
] as const;

const UserContainer = () => {
  const { formatMessage } = useIntl();
  const match = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const user = useRecoilValue(userSelector);
  const setTeams = useSetRecoilState(userTeamsAtom);
  const setUserPayments = useSetRecoilState(userPaymentsAtom);
  const setUserBillingDetails = useSetRecoilState(userBillingDetailsAtom);
  const { setSnackbar } = useSnackbar();
  const { setActiveAccountId } = useAccounts();

  const goToRoute = useGoToRoute();
  let { tab } = useParams();
  if (!tab) {
    tab = 'profile';
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    paymentService
      .getUserPayments(user._id)
      .then((payments) => setUserPayments(payments))
      .catch((error) => console.error(error));

    setActiveAccountId(user._id);

    return () => {
      setUserPayments(null);
      setUserBillingDetails(null);
    };
  }, [user]);

  useEffect(() => {
    if (tab === 'teams') {
      teamService
        .getUserTeams()
        .then((response) => {
          setTeams(response.data.data);
        })
        .catch(() => {
          setSnackbar(
            formatMessage({ id: 'errorsSomethingWentWrong' }),
            SnackbarTypes.Error,
          );
        });
    }
  }, [tab]);

  return (
    <div className="UserContainer">
      <Helmet>
        <title>{formatMessage({ id: metaTags.user.title })}</title>
      </Helmet>
      <Card
        transparent={Boolean(match.small)}
        styles={(current: CardStyles) => ({
          ...current,
          root: `${current.root} UserContainer__content`,
        })}
      >
        <TabsResponsive
          onSelectTab={(option, index) => {
            goToRoute(Routes.Settings, { tab: options[index].slug });
          }}
          options={options.map((option) => ({
            id: option.slug,
            label: formatMessage({
              id: option.labelMessageId,
            }),
            content: option.content,
          }))}
          active={options.findIndex((option) => option.slug === tab)}
        />
      </Card>
    </div>
  );
};

export default UserContainer;
