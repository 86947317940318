import React from 'react';
import { StylableComponent, useStyles } from '../../utils/hooks/useStyles';
import { omit } from '../../utils/omit';
import classNames from 'classnames';

export type SpinnerStyles = {
  root: string;
};

export enum SpinnerSizes {
  ExtraSmall = 'extra-small',
  Small = 'small',
  Normal = 'normal',
}
export enum SpinnerVariants {
  Primary = 'primary',
  Inverted = 'inverted',
}

export type SpinnerProps = JSX.IntrinsicElements['div'] & {
  show?: boolean;
  size?: SpinnerSizes;
  variant?: SpinnerVariants;
};

const Spinner: StylableComponent<SpinnerProps, SpinnerStyles> = (props) => {
  const propsToPass = omit(
    props,
    'className',
    'children',
    'styles',
    'show',
    'size',
  );
  const size = props.size || SpinnerSizes.Normal;
  const variant = props.variant || SpinnerVariants.Primary;
  const classes = useStyles(
    {
      root: classNames('Spinner', `Spinner--${size}`, `Spinner--${variant}`, {
        'Spinner--visible': Boolean(props.show),
      }),
    },
    props.styles,
  );

  return <div {...propsToPass} className={classes.root} />;
};

export default Spinner;
