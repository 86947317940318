import React, { ReactNode, FunctionComponent } from 'react';

type FeatureSectionProps = {
  title?: String;
  description?: String;
  icon?: ReactNode;
  iconName?: String;
  graphic: ReactNode;
};

const FeatureSection: FunctionComponent<FeatureSectionProps> = ({
  title,
  description,
  icon,
  iconName,
  graphic,
}) => {
  return (
    <div className="FeatureSection">
      {!icon && !iconName ? null : (
        <div className="FeatureSection__content flex-row">
          {icon}
          <h2>{iconName}</h2>
        </div>
      )}
      <div className="FeatureSection__content-graphic">{graphic}</div>
      {!title && !description ? null : (
        <div className="FeatureSection__text">
          <h2>{title}</h2>
          <div className="FeatureSection__text-description">{description}</div>
        </div>
      )}
    </div>
  );
};

export default FeatureSection;
