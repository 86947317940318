import { integrationsConfig } from './integrations.config';
import { TranslationKey } from '../../translations/types';
import { ReactElement } from 'react';
import { XOR } from '../../utils/types';
import { Routes } from '../../config';
import { StoryboardConfig } from '../../features/dashboard/types';
import { Moment } from 'moment';

export type IntegrationsConfig = typeof integrationsConfig;
export type IntegrationsTypes = keyof IntegrationsConfig;

export enum SocialAccountTypes {
  Facebook = 'facebook',
  Instagram = 'instagram',
  Linkedin = 'linkedin',
  Pinterest = 'pinterest',
}

export enum FacebookAccountTypes {
  Page = 'page',
  Profile = 'profile',
}

export type ExternalFacebookAccount = {
  id: string;
  accessToken: string;
  name: string;
  username: string;
  avatarImageUrl: string;
};

export enum IntegrationPostConnectionStrategies {
  DisplayAccountChooserModal = 'DisplayAccountChooserModal',
  SilentRefreshToken = 'SilentRefreshToken',
  DisplayWrongAccountOrigin = 'DisplayWrongAccountOrigin',
  ShowAddedIntegrationSnackbar = 'ShowAddedIntegrationSnackbar',
  ShowReconnectedSuccessfullySnackbar = 'ShowReconnectedSuccessfullySnackbar',
  ShowFailedIntegrationSnackbar = 'ShowFailedIntegrationSnackbar',
}

export type AvailableSocialAccount = {
  name: SocialAccountTypes;
  labelId: TranslationKey;
  icon: ReactElement;
  enabled: boolean;
};

export enum SocialAccountConnectionStrategies {
  AddFacebookPage = 'add_facebook_page',
  AddFacebookProfile = 'add_facebook_profile',
  AddInstagramBusinessAccount = 'add_instagram_business_account',
  AddLinkedInProfile = 'add_linked_in_profile',
  AddInstagramBasicDisplay = 'add_instagram_basic_display',
  AddInstagramBasicDisplayToSpace = 'add_instagram_basic_display_to_space',
  AddPinterestProfile = 'add_pinterest_profile',
}

export type ResourceOwner = XOR<{ groupId: string }, { userId: string }>;

type SocialAccountBase = {
  _id: string;
  title: string;
  avatarImageUrl: string;
  createdAt: string;
  updatedAt: string;
  accessToken: string;
  expirationDate: string;
} & ResourceOwner;

export type InstagramSocialAccount = SocialAccountBase & {
  type: SocialAccountTypes.Instagram;
  properties: {
    instagramAccountId: string;
    tokenType: 'basic_display' | 'business';
  };
};

export type FacebookSocialAccount = SocialAccountBase & {
  type: SocialAccountTypes.Facebook;
  properties: {
    facebookAccountId: string;
    tokenType: 'page' | 'personal';
  };
};

type LinkedinSocialAccount = SocialAccountBase & {
  type: SocialAccountTypes.Linkedin;
  properties: {
    linkedinUrn: string;
  };
};

type PinterestSocialAccount = SocialAccountBase & {
  type: SocialAccountTypes.Pinterest;
  properties: {
    pinterestUsername: string;
  };
};

export type SocialAccount =
  | InstagramSocialAccount
  | FacebookSocialAccount
  | LinkedinSocialAccount
  | PinterestSocialAccount;

export enum ExternalAccountTypes {
  InstagramBusinessAccount = 'instagram_business_account',
  FacebookPage = 'facebook_page',
  FacebookProfile = 'facebook_profile',
  Pinterest = 'pinterest',
}

export type FacebookConnectionStrategies = Exclude<
  SocialAccountConnectionStrategies,
  | SocialAccountConnectionStrategies.AddLinkedInProfile
  | SocialAccountConnectionStrategies.AddPinterestProfile
  | SocialAccountConnectionStrategies.AddInstagramBasicDisplay
  | SocialAccountConnectionStrategies.AddInstagramBasicDisplayToSpace
>;

export enum SocialAccountReconnectionStrategies {
  ReconnectFacebookPage = 'reconnect_facebook_page',
  ReconnectFacebookProfile = 'reconnect_facebook_profile',
  ReconnectInstagramBusinessAccount = 'reconnect_instagram_business_account',
  ReconnectLinkedInProfile = 'reconnect_linked_in_profile',
  ReconnectInstagramBasicDisplay = 'reconnect_instagram_basic_display',
  ReconnectPinterestProfile = 'reconnect_pinterest_profile',
}

export type SocialAccountPlatformDetails =
  | {
      type: SocialAccountTypes.Instagram;
      properties: {
        instagramAccountId?: string;
        tokenType: 'basic_display' | 'business';
      };
    }
  | {
      type: SocialAccountTypes.Facebook;
      properties: {
        facebookAccountId: string;
        tokenType: 'page' | 'personal';
      };
    }
  | {
      type: SocialAccountTypes.Linkedin;
    }
  | {
      type: SocialAccountTypes.Pinterest;
    };

export type SocialAccountConnectionCache = {
  strategy: SocialAccountConnectionStrategies | undefined;
  connectToSpaceId?: string;
  targetRoute?: Routes;
  storyboardConfig?: StoryboardConfig;
};

type DisplayAccountChooserModalStrategy = {
  name: IntegrationPostConnectionStrategies.DisplayAccountChooserModal;
  payload: {
    accounts: ExternalFacebookAccount[];
    connectionStrategy: SocialAccountConnectionStrategies;
  };
};

type SilentRefreshTokenStrategy = {
  name: IntegrationPostConnectionStrategies.SilentRefreshToken;
  payload: {
    socialAccount: SocialAccount;
    instagramBusinessAccount: ExternalFacebookAccount;
  };
};

type DisplayWrongAccountOriginStrategy = {
  name: IntegrationPostConnectionStrategies.DisplayWrongAccountOrigin;
};

type ShowAddedIntegrationSnackbar = {
  name: IntegrationPostConnectionStrategies.ShowAddedIntegrationSnackbar;
};

type ShowReconnectedSuccessfullySnackbar = {
  name: IntegrationPostConnectionStrategies.ShowReconnectedSuccessfullySnackbar;
};

type ShowFailedIntegrationSnackbar = {
  name: IntegrationPostConnectionStrategies.ShowFailedIntegrationSnackbar;
  payload: {
    messageId: TranslationKey;
  };
};

export type IntegrationPostConnectionStrategy =
  | DisplayAccountChooserModalStrategy
  | SilentRefreshTokenStrategy
  | DisplayWrongAccountOriginStrategy
  | ShowAddedIntegrationSnackbar
  | ShowFailedIntegrationSnackbar
  | ShowReconnectedSuccessfullySnackbar;

export type SocialAccountReconnectionCache = {
  externalAccountIdForReconnection?: string;
  socialAccountId?: string;
  spaceId?: string;
  strategy: SocialAccountReconnectionStrategies | undefined;
  targetRoute?: Routes;
};

export type SocialMediaItem = {
  id: string;
  caption: string;
  assetUrl: string;
  thumbnailUrl?: string;
  scheduledAt?: Moment;
  properties?: Record<string, string>;
};
