import React, { FunctionComponent } from 'react';
import { InputType } from '../../../../components/Input/Input';
import { Formik, Form, Field } from 'formik';
import EditableInput from '../../../../components/EditableInput/EditableInput';
import { DependencyContainer } from '../../../../DependencyContainer';

type Props = {
  name: any;
  label: string;
  caption?: string;
  initialValue?: string;
  tabIndex?: number;
  type?: InputType;
  validationSchema?: any;
  shouldSaveOnBlur?: boolean;
  enableReinitialize?: boolean;
  onSubmit?: (values: { [key: string]: string }, actions?: any) => void;
  onAfterSubmit?: (values: { [key: string]: string }) => void;
  onError?: (error: any) => void;
};

const service = new DependencyContainer().settingsService;

const SettingInput: FunctionComponent<Props> = ({
  initialValue,
  type,
  name,
  label,
  caption,
  validationSchema,
  onSubmit,
  onAfterSubmit,
  tabIndex,
  shouldSaveOnBlur,
  onError,
  enableReinitialize,
}) => (
  <Formik
    enableReinitialize={enableReinitialize}
    initialValues={{ [name]: initialValue || '' }}
    validateOnChange={false}
    validationSchema={validationSchema}
    onSubmit={async (values, actions) => {
      if (onSubmit) {
        onSubmit(values, actions);
      } else {
        try {
          await service.set(name, values[name]).then(() => {
            onAfterSubmit && onAfterSubmit(values);
          });
        } catch (error) {
          actions.setFieldError(name, error.data.data.message);
          onError && onError(error);
        }
      }
    }}
  >
    {({
      setFieldValue,
      setFieldError,
      handleSubmit,
      errors,
      initialValues,
      values,
    }) => (
      <Form>
        <Field
          name={name}
          type={type || 'text'}
          tabIndex={tabIndex}
          as={EditableInput}
          label={label}
          caption={caption}
          isValid={!errors[name]}
          errorMessage={errors[name]}
          onCancelClick={() => {
            setFieldValue(name, initialValues[name]);
            setFieldError(name, '');
          }}
          onEditClick={() => setFieldValue(name, '')}
          onSaveClick={!shouldSaveOnBlur ? () => handleSubmit() : undefined}
          saveOnBlur={
            shouldSaveOnBlur
              ? () => {
                  if (initialValue !== values[name]) {
                    handleSubmit();
                  }
                }
              : undefined
          }
          maxLength="90"
        />
      </Form>
    )}
  </Formik>
);

export default SettingInput;
