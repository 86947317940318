import React, { FunctionComponent, useEffect, useState } from 'react';
import designPageGraphic from '../../../assets/images/membership/design-page-desc-graphic.png';
import webGraphic from '../../../assets/images/membership/web-desc-graphic.png';
import logoInverted from '../../../assets/svg/logo-inverted.svg';
import QuoteCard from './QuoteCard/QuoteCard';
import FeatureCard from './FeatureCard/FeatureCard';
import FeatureSection from './FeatureSection/FeatureSection';
import Confetti from 'react-confetti';

type PaidMemberViewProps = {
  productData: [];
  featureData: [];
  socialProofData: [];
};
const PaidMemberView: FunctionComponent<PaidMemberViewProps> = ({
  productData = [],
  featureData = [],
  socialProofData = [],
}) => {
  const [confettiPieces, setConfettiPieces] = useState(200);
  useEffect(() => {
    if (confettiPieces > 100) {
      setTimeout(() => {
        setConfettiPieces(0);
      }, 3000);
    }
  }, [confettiPieces]);

  return (
    <>
      <Confetti
        numberOfPieces={confettiPieces}
        width={window.innerWidth}
        height={window.innerHeight}
      />
      <div className="PaidMemberView">
        <div className="header">
          <img width="56" height="56" src={logoInverted} />
          <h1>Welcome to UNUM</h1>
          <div className="product-icon">
            {productData.map((product) => product.icon)}
          </div>
          <p>This is a place where we will provide you with tips and tricks.</p>
        </div>
        <FeatureSection
          graphic={<img src={designPageGraphic} width="320" height="auto" />}
        />

        <div className="PaidMemberView__feature">
          <h3>Explore Pro Features</h3>

          <div className="PaidMemberView__feature-container">
            {featureData.map((data) => (
              <FeatureCard id={data.id} title={data.title} />
            ))}
          </div>
        </div>

        <FeatureSection
          title="UNUM Anywhere"
          description="Collaborate, save, and brand better on any device."
          graphic={<img src={webGraphic} width="320" height="auto" />}
        />

        <img
          className="Featured-featured-img"
          src="https://unum-cloud.s3.us-west-2.amazonaws.com/assets/web/featured-app.png"
        />
        {socialProofData.map((data) => {
          return (
            <QuoteCard
              quote={data.quote}
              quoteCardUser={{
                userAvatarUrl: data.user.avatarUrl,
                userName: data.user.title,
                userDescription: data.user.description,
              }}
            />
          );
        })}
      </div>
    </>
  );
};

export default PaidMemberView;
