import { DependencyContainer } from '../../DependencyContainer';
import { User } from '../login/UserEntity';
export class SignupService {
  constructor(private readonly factory: DependencyContainer) {}

  async signUp(email: string, password: string): Promise<User> {
    const response = await this.factory.signupClient.makeSignUp(
      email,
      password,
    );
    return response.data.data;
  }
}
