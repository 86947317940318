import { DependencyContainer } from '../../DependencyContainer';
import {
  PublicProfileFields,
  PublicProfilePreview,
  PublicProfileType,
} from './types';

export class PublicProfileService {
  constructor(private readonly factory: DependencyContainer) {}

  async getPublicProfile(
    publicProfileId: string,
  ): Promise<PublicProfilePreview> {
    const response = await this.factory.publicProfileClient.getPublicProfile(
      publicProfileId,
    );
    return response.data.data;
  }

  async getPublicProfileByType(
    type: PublicProfileType,
    userId: string,
  ): Promise<PublicProfileFields> {
    const response = await this.factory.publicProfileClient.getPublicProfileByType(
      type,
      userId,
    );
    const {
      city,
      occupation,
      country,
      website,
      published,
    } = response.data.data;

    return {
      city,
      occupation,
      country,
      website,
      published,
    };
  }

  async createPublicProfile(
    type: PublicProfileType,
    typeId: string,
    initialData: Partial<PublicProfileFields>,
  ): Promise<void> {
    await this.factory.publicProfileClient.createPublicProfile(
      type,
      typeId,
      initialData,
    );
  }

  async updatePublicProfile(
    type: PublicProfileType,
    typeId: string,
    fields: any,
  ): Promise<void> {
    await this.factory.publicProfileClient.updatePublicProfile(
      type,
      typeId,
      fields,
    );
  }
}
