import React, { FunctionComponent, useState } from 'react';
import {
  BillingDetails,
  paywallOpenedAtom,
  SnackbarTypes,
  userBillingDetailsAtom,
  userPaymentsAtom,
} from '../../../../state';
import {
  PaywallPeriods,
  PaywallTileBillingPlan,
  SelectedBillingPlan,
  BillingPlansNames,
} from '../../../payment/components/PaywallTile/PaywallTile';
import { DependencyContainer } from '../../../../DependencyContainer';
import Paywall, {
  PaywallStyles,
} from '../../../payment/components/Paywall/Paywall';
import { useSnackbar } from '../../../../utils/hooks/useSnackbar';
import { useIntl } from '../../../../utils/hooks/useIntl';
import { CheckoutSessionModes } from '../../../payment/PaymentClient';
import { useSyncedState } from '../../../../synced-state/synced-state';
import ConfirmCancellationModal from './ConfirmCancellationModal';
import { useRecoilState } from 'recoil';
import SurveyAfterCancellationModal from './SurveyAfterCancellation';
import {
  PaymentChannels,
  UserPlans,
  UserPayments,
} from '../../../payment/PaymentService';
import PurchasedElsewhereModal from './PurchasedElsewhereModal';
import { RevenueCatSubscriptionStatus } from '../../../payment/types';

type UserPaywallProps = {
  userId: string;
  billingDetails: BillingDetails | null;
  isLoading: boolean;
  forceLoading?: boolean;
};

const paymentService = new DependencyContainer().paymentService;

const adjustPaywallTile = (current: PaywallStyles) => ({
  ...current,
  tile: `${current.tile} UserPaywall__tile`,
});

const UserPaywall: FunctionComponent<UserPaywallProps> = (props) => {
  const { formatMessage } = useIntl();
  const [, setPaymentCache] = useSyncedState('paymentCache');
  const [user] = useSyncedState('user');
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [
    surveyAfterCancellationOpen,
    setSurveyAfterCancellationOpen,
  ] = useState(false);
  const [paywallOpened, setPaywallOpened] = useRecoilState(paywallOpenedAtom);
  const [userPayments, setUserPayments] = useRecoilState(userPaymentsAtom);
  const [userBillingDetails, setUserBillingDetails] = useRecoilState(
    userBillingDetailsAtom,
  );
  const { setSnackbar } = useSnackbar();
  const getCurrentPlanName = (
    userBillingDetails: BillingDetails | null,
    userPayments: UserPayments | null,
  ) => {
    if (!userPayments || !userBillingDetails) {
      return UserPlans.Free;
    }
    if (
      userPayments.purchased_via === PaymentChannels.RevenueCat &&
      userPayments.subscription_status === RevenueCatSubscriptionStatus.Trial
    ) {
      return UserPlans.Free;
    }
    return userPayments.plan;
  };
  const currentPlanName = getCurrentPlanName(userBillingDetails, userPayments);

  const loadPlans = (): Promise<PaywallTileBillingPlan[]> => {
    return paymentService.getUserBillingPlans(currentPlanName, [
      getUserCustomPlan(PaywallPeriods.Month),
      getUserCustomPlan(PaywallPeriods.Year),
    ]);
  };

  const onSelectPlan = async (plan: SelectedBillingPlan) => {
    const priceId = plan.price.id || '';

    if (priceId) {
      setPaymentCache({
        relation: 'profile',
        plan: plan.name,
        type: plan.type,
        mode: CheckoutSessionModes.Subscription,
      });
      try {
        const session = await paymentService.getUserSubscriptionCheckoutSession(
          props.userId,
          priceId,
        );
        await paymentService.goToCheckout(session);
      } catch (error) {
        setSnackbar(
          formatMessage({ id: 'errorsSomethingWentWrong' }),
          SnackbarTypes.Error,
        );
      }
    } else {
      try {
        setCancellationModalOpen(true);
      } catch (error) {
        setSnackbar(
          formatMessage({ id: 'errorsSomethingWentWrong' }),
          SnackbarTypes.Error,
        );
      }
    }
  };

  const handleCancellation = async () => {
    try {
      // update user billing data state after cancellation
      if (user) {
        await paymentService.cancelUsersSubscription(user._id);
        setUserPayments(await paymentService.getUserPayments(user._id));
        const details = await paymentService
          .getUserBillingDetails(user._id)
          .then((details) => {
            return details;
          })
          .catch((error) => {
            if (error.response && error.response.status !== 404) {
              throw new Error(error);
            }
            return null;
          });

        setUserBillingDetails(details);
      }
      setCancellationModalOpen(false);
      setPaywallOpened(false);
      setTimeout(() => {
        setSnackbar(
          formatMessage({ id: 'userMembershipPayWalDowngradeSuccessful' }),
        );
      }, 500);
      setTimeout(() => {
        setSurveyAfterCancellationOpen(true);
      }, 750);
    } catch (error) {
      setSnackbar(
        formatMessage({ id: 'errorsSomethingWentWrong' }),
        SnackbarTypes.Error,
      );
    }
  };

  const getUserCustomPlan = (
    period: PaywallPeriods,
  ): PaywallTileBillingPlan => {
    return {
      type: currentPlanName === 'free' ? 'current' : 'downgrade',
      title: formatMessage({
        id: 'userMembershipPayWallBasicPlanTitle',
      }) as BillingPlansNames.Elite,
      subtitle: formatMessage({ id: 'userMembershipPayWallBasicPlanSubtitle' }),
      price: {
        value: 0,
        currency: '$',
      },
      period,
      highlighted: false,
      perks: [
        formatMessage({ id: 'userMembershipPayWallBasicPlanPerk1' }),
        formatMessage({ id: 'userMembershipPayWallBasicPlanPerk2' }),
        formatMessage({ id: 'userMembershipPayWallBasicPlanPerk3' }),
        formatMessage({ id: 'userMembershipPayWallBasicPlanPerk4' }),
      ],
      isSelectable: true,
    };
  };

  // Android app behaves differently to web or ios apps.
  // When user registers it creates a Payment record with free plan,
  // even tough no real payment was made via Google Play.
  const isFreeAndroidUser =
    userPayments &&
    userPayments.purchased_via === PaymentChannels.Google &&
    userPayments.plan === UserPlans.Free;

  const shouldShowPurchasedElsewhereModal =
    !isFreeAndroidUser &&
    userPayments &&
    ![
      PaymentChannels.StripeCheckout,
      PaymentChannels.Lifetime,
      PaymentChannels.Stripe,
      PaymentChannels.RevenueCat,
    ].includes(userPayments.purchased_via);

  if (paywallOpened && shouldShowPurchasedElsewhereModal) {
    return (
      <PurchasedElsewhereModal
        type={userPayments!.purchased_via}
        closeModal={() => setPaywallOpened(false)}
      />
    );
  }

  return (
    <>
      <Paywall
        checkBillingPlanExceeded={() => false}
        title={formatMessage({ id: 'userMembershipPayWallTitle' })}
        onSelectPlan={onSelectPlan}
        loadPlans={loadPlans}
        styles={adjustPaywallTile}
        currentPlanName={currentPlanName}
        preventLoadingPlans={props.isLoading}
        forceLoading={props.forceLoading}
      />
      <ConfirmCancellationModal
        isOpen={cancellationModalOpen}
        onRequestClose={() => setCancellationModalOpen(false)}
        reasonsNotToCancel={[
          formatMessage({
            id: 'userMembershipPayWallBasicPlanReasonNotToCancel1',
          }),
          formatMessage({
            id: 'userMembershipPayWallBasicPlanReasonNotToCancel2',
          }),
          formatMessage({
            id: 'userMembershipPayWallBasicPlanReasonNotToCancel3',
          }),
        ]}
        onCancellationConfirmed={handleCancellation}
      />
      <SurveyAfterCancellationModal
        isOpen={surveyAfterCancellationOpen}
        onRequestClose={() => setSurveyAfterCancellationOpen(false)}
      />
    </>
  );
};

export default UserPaywall;
