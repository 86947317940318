import { Translation } from './types';

export const pl: Translation = {
  // meta tags
  defaultMetaTitle: 'Tablica',
  defaultMetaDescription: 'Zobacz, zanim opublikujesz.',
  signInMetaTitle: 'Zaloguj się',
  signUpMetaTitle: 'Zarejestruj się',
  forgotPassword: 'Odzyskiwania hasła',
  userProfileMetaTitle: 'Podstawowe dane',
  teamMetaTitle: 'Zespół: {teamName}',
  marketPlaceMetaTitle: 'Marketplace',

  dashboardNoSpaceHeader: 'Nie masz żadnej przestrzeni',
  dashboardNoSpaceParagraph:
    'Nie utworzyłeś jeszcze żadnej przestrzeni. Rozpocznij tworzenie przestrzeni w aplikacji mobilnej.',
  dashboardDownloadApp: 'Pobierz aplikację mobilną UNUM',
  dashboardAddNewSpace: 'Dodaj nową przestrzeń',
  signupPageTitle: 'Rejestracja',
  signupFormEmailLabel: 'E-mail',
  signupFormEmailError: 'Podaj poprawny adres email',
  signupFormPasswordLabel: 'Hasło',
  signupFormPasswordError: 'Hasło musi zawierać minimum 8 znaków',
  signupFormSubmit: 'ZAŁÓŻ KONTO',
  signupFormProcessing: 'ZAKŁADANIE KONTA...',
  signupInfo:
    'Rejestrując się akceptujesz <TermsOfServiceLink>Regulamin</TermsOfServiceLink> i <PrivacyPolicyLink>Politykę Prywatności</PrivacyPolicyLink>',
  signupLoginInfo: 'Masz już konto? <SignInLink>Zaloguj się</SignInLink>',
  signupSuccessPageTitle: 'Dziękujemy za rejestrację!',
  signupSuccessPageContent:
    'Witamy w UNUM, {email}. Kliknij w poniższy przycisk aby przejść do panelu i zacznij tworzyć swoje historie.',
  signupSuccessPageButton: 'Zacznij tworzyć',
  signupErrorConflict: 'Ten e-mail już jest używany',
  signupErrorInternal: 'Wystąpił błąd',
  signinPageTitle: 'Zaloguj się',
  signinFormEmailLabel: 'E-mail',
  signinFormEmailError: 'Podaj poprawny adres email',
  signinFormPasswordLabel: 'Hasło',
  signinFormPasswordError: 'Hasło musi mieć minimum 8 znaków',
  signinFormSubmit: 'ZALOGUJ SIĘ',
  signinFormProcessing: 'LOGOWANIE...',
  signinForgot: '<ForgotPasswordLink>Zapomniałeś hasła?</ForgotPasswordLink>',
  signinSignupInfo: 'Nie masz konta? <SignUpLink>Zarejestruj się</SignUpLink>',
  forgotPasswordPageTitle: 'Zapomniałeś hasła?',
  forgotPasswordPageCopy:
    'Podaj adres email, na który masz zarejestrowane konto w UNUM. Na podany przez Ciebie adres wyślemy Ci link do zmiany hasła.',
  forgotPasswordConfirmationPageTitle: 'Email został wysłany',
  forgotPasswordConfirmationPageCopy:
    'Zastosuj się do wskazówek w wysłanej wiadomości, aby zresetować swoje hasło.',
  forgotPasswordConfirmationPageButtonResend: 'Wyślij ponownie',
  forgotPasswordConfirmationPageButtonSubmitting: 'Wysyłanie...',
  forgotPasswordConfirmationPageResendSuccess: 'E-mail został wysłany',

  resetPasswordPageTitle: 'Zmień swoje hasło',
  resetPasswordNewPasswordInputLabel: 'Nowe hasło',
  resetPasswordConfirmNewPasswordInputLabel: 'Potwierdź hasło',
  resetPasswordConfirmNewPasswordInputLabelMatchError:
    'Hasła nie pasują do siebie',
  resetPasswordTokenExpiredError: 'Hasło nie zostało zmienione. Token wygasł.',
  resetPasswordSubmitButtonLabel: 'Zmień hasło',
  resetPasswordConfirmationTitle: 'Twoje hasło zostało zmienione',
  resetPasswordConfirmationCopy:
    'Możesz teraz się zalogować używając wprowadzone hasło',
  resetPasswordConfirmationSignInButton: 'Zaloguj się',

  navbarCollapse: 'Zwiń nawigację',
  navbarExpand: 'Rozwiń nawigację',
  navbarUpgrade: 'Ulepsz konto',
  navbarHelp: 'Pomoc',
  accountTogglerPlaceholder: 'Moja tablica',
  modalCloseAlt: 'Zamknij okno',
  signinErrorUnauthorized: 'Błędny email lub hasło',
  signinErrorInternal: 'Wystąpił bład',

  userProfileAccountSectionTitle: 'Konto',
  userProfileTabName: 'Podstawowe dane',
  userProfileNameInput: 'Imię',
  userProfileNameInputCaption:
    'Nie zostanie udostępniony na profilu publicznym.',
  userNameMinLengthError: 'Imię musi zawierać przynajmniej {minLength} znak',
  userProfileAvatarAlt: 'Avatar użytkownika',
  userProfileReplaceImage: 'Zmień zdjęcie',
  userProfileNameInputError: 'Podaj imię',
  userProfileEmailInput: 'Email',
  userProfileEmailInputErrorRequired: 'Podaj adres email',
  userProfileEmailInputError: 'Podaj poprawny adres email',
  userProfilePasswordInput: 'Hasło',
  userProfilePasswordInputError: 'Hasło musi zawierać minimum 8 znaków',
  userProfilePasswordInputErrorRequired: 'Podaj hasło',
  userProfilePasswordCannotHaveSpaces: 'Hasło nie może posiadać spacji',
  userProfileSuccessfulUpdate: 'Twój profil został zaktualizowany',
  userProfileDragAndDrop: 'Przeciągnij i upuść plik',

  userProfilePublicProfileSectionTitle: 'Profil Publiczny',
  userProfilePublicProfileSectionSubtitle:
    'Te informacje zostaną wyświetlone dla wszystkich, którzy zobaczą Twój profil publiczny',

  userProfilePublished: 'Profil publiczny',
  userProfilePublishedTrue: 'Twój profil jest <strong>publiczny</strong>',
  userProfilePublishedFalse: 'Twój profil jest <strong>prywatny</strong>',
  userProfileGenericError: 'Pole {field} nie zostało zaktualizowane',

  userProfileCity: 'Miejscowość',
  userProfileCountry: 'Kraj',
  userProfileOccupation: 'Zawód',
  userProfileWebsite: 'Strona www',
  userProfileWebsiteInputError: 'Podaj poprawny adres URL',

  userMembershipTabName: 'Członkowstwo',
  userMembershipTabPlanFree: 'Podstawowy',
  userMembershipTabPlanElite: 'Elite',
  userMembershipTabPlanName: 'Aktualny plan',
  userMembershipTabChangePlan: 'Zmień plan',

  userMembershipTabFreePlanInfoLine1:
    'Twoje konto posiada aktualnie darmowy plan.',
  userMembershipTabFreePlanInfoLine2:
    'Każda przestrzeń projektowa, którą utworzysz, będzie korzystać ze wszystkich korzyści związanych z Twoim obecnym planem.',
  userMembershipTabFreePlanInfoLine3:
    'Kiedy uzyskujesz dostęp do wspólnej przestrzeni projektowej, korzystasz ze wszystkich korzyści związanych z twórcą tej przestrzeni.',
  userMembershipTabFreePlanInfoLine4:
    'Jeśli zaktualizowałeś indywidualną przestrzeń projektową lub projekt do jednego z naszych planów premium, ten indywidualny zasób zachowa korzyści, które uzyskał w momencie aktualizacji, niezależnie od planu konta. Uaktualnij swoje konto, aby uzyskać te korzyści we wszystkich swoich przestrzeniach bez dodatkowych kosztów!',
  userMembershipTabsBillingDataLoadError:
    'Nie udało się wczytać danych rozliczeniowych.',

  incompatibleSubscriptionNotificationModalHeading:
    'Wygląda na to, że Twoja subskrypcja Elite została zakupiona porzez konto w {purchaseChannel}.',
  incompatibleSubscriptionNotificationModalBody:
    'Aby dokonać modyfikacji w swoim planie subskrypcji w UNUM, zaloguj się do Twojego konta w {whereToGo}.',

  userTeamsTabName: 'Zespoły',
  cloneSpaces: 'Sklonuj swoje przestrzenie',
  cloneSpacesDescription:
    'Sklonuj przestrzenie ze swojej osobistej tablicy, aby zacząć je współdzielić ze swoim zespołem',
  myDashboardSpaces: 'Przestrzenie z mojej tablicy',
  skipCloningSpaces: 'Chcę tworzyć zespół od zera',
  cloningStepSuccess: 'Twoje przestrzenie zostały sklonowane',
  cloningStepProgress: 'Klonowanie przestrzeni',
  noCloneSelectionError: 'Wybierz co najmniej 1 przestrzeń do sklonowania',
  userTeamsCtaParagraph:
    'Utwórz zespoły, aby z łatwością współpracować i dzielić projekty.',
  userTeamsCtaButton: 'Utwórz konto biznesowe',
  userTeamsCreateTeamModalTitle: 'Utwórz swój zespół',
  userTeamsChooseName: 'To będzie nazwa tablicy Twojego zespołu',
  userTeamsCreateTeamModalBodyHeading: 'Podaj nazwę Twojego zespołu',
  userTeamsCreateTeamModalBody:
    'Rozpocznij bezpłatny okres próbny dla zespołu już dziś, aby uzyskać nieograniczony dostęp do wszystkich naszych funkcji przez 14 dni. Karta kredytowa nie jest wymagana, bez zobowiązań.',
  userTeamsCreateTeamModalInputPlaceholder: 'Nadaj nazwę swojemu zespołowi',
  userTeamsLoadingError:
    'Nie udało się wczytać Twoich zespołów. Wystąpił błąd.',
  userTeamsCreateTeamModalInputError: 'Użyj przynajmniej 1 znaku',
  userTeamsCreateTeamMinLengthError:
    'Nazwa zespołu musi zawierać przynajmniej {minLength} znak',
  userTeamsCreateTeamModalSubmitButton: 'Utwórz nowy zespół',
  userTeamsCreateTeamSnackbarSuccess: 'Nowy zespół został utworzony.',
  userTeamsCreateTeamSnackbarError:
    'Przepraszamy, Twój nowy zespół nie został dodany.',
  userTeamsTableTeamName: 'Nazwa zespołu',
  userTeamsTableMyRole: 'Moja rola',
  teamTeamTrialAlert:
    'Twój okres próbny zakończy się {trialDateEnd}. Aby korzystać z pełnych funkcji Zespołu, <UpgradePlan>ulepsz swój plan</UpgradePlan>.',
  teamTeamTrialExpiredAlert:
    'Twój okres próbny zakończył się {trialDateEnd}. Aby korzystać z pełnych funkcji Zespołu, <UpgradePlan>ulepsz swój plan</UpgradePlan>.',
  teamNameMinLengthError:
    'Nazwa zespołu musi zawierać przynajmniej {minLength} znak',
  teamTeamTrialAlertForEditors:
    'Twój okres próbny zakończy się {trialDateEnd}.',
  teamTeamTrialExpiredAlertForEditors:
    'Twój okres próbny zakończył się {trialDateEnd}.',
  teamTeamInformationTabName: 'Informacje o zespole',
  teamTeamInformationTabFieldTeamName: 'Nazwa zespołu',
  teamTeamInformationTabFieldTeamNameError: 'Użyj przynajmniej 1 znaku',
  teamTeamInformationTabFieldTeamOwner: 'Właściciel',
  teamTeamInformationTabFieldTeamOwnerError: 'Użyj przynajmniej 1 znaku',
  teamTeamInformationNotFoundError: 'Wybrany zespół nie istnieje',
  teamTeamInformationNameUpdated: 'Nazwa zespołu została zaktualizowana',
  teamTeamMembershipTabName: 'Członkowstwo',
  teamTeamMembershipCurrentPlanFieldLabel: 'Aktualny plan',
  teamTeamMembershipCurrentPlanTrial: '14 dniowy okres próbny',
  teamTeamMembershipCurrentPlanTrialExpired: '(wygasł)',
  teamTeamMembershipUpgradeNowButton: 'Ulepsz teraz',
  teamTeamMembershipBillingUpdateMessage:
    'Twoje informacje o członkowstwie zostały zaktualizowane.',
  teamTeamMembershipBillingHeading: 'Rozliczenia',
  teamTeamMembershipBillingEmail: 'Email do rozliczeń',
  teamTeamMembershipBillingEmailErrorEmail:
    'Proszę podać prawidłowy adres email do rozliczeń',
  teamTeamMembershipBillingEmailErrorRequired:
    'Adres email do rozliczeń jest wymagany',
  teamTeamMembershipBillingPaymentMethod: 'Metoda płatności',
  teamTeamMembershipBillingPaymentMethodManage: 'Zarządzaj',
  teamTeamMembershipBillingPaymentMethodExpires: 'Data ważności',
  teamTeamMembershipBillingUpcomingCharges: 'Następna płatność',
  teamTeamMembershipBillingLastReceipt: 'Ostatni rachunek',
  teamTeamMembershipBillingViewAllReceipts: 'Zobacz wszystkie',
  teamTeamMembershipBillingMonthlyMembership: 'Miesięczna Subskrypcja',
  teamTeamMembershipBillingAnnualMembership: 'Roczna Subskrypcja',
  teamTeamMembershipBillingCustomMembership: 'Niestandardowa Subskrypcja',

  teamTeamMembershipInvoicesModalGoBack: 'Powrót do ustawień',
  teamTeamMembershipInvoicesModalHeading: 'Zrealizowane Płatności',
  teamTeamMembershipInvoicesModalExportAsPdf: 'Eksportuj do PDF',
  teamTeamMembershipInvoicesModalDate: 'Data',
  teamTeamMembershipInvoicesModalDescription: 'Opis',
  teamTeamMembershipInvoicesModalInvoiceNumber: 'Numer faktury',
  teamTeamMembershipInvoicesModalAmount: 'Kwota (USD)',

  deleteTeamCTA: 'Usuń zespół',
  deleteTeamModalTitle: 'Czy na pewno chcesz usunąć zespół "{teamName}"?',
  deleteTeamModalBody:
    '<p>Wraz z usunięciem zespołu usunięte zostaną również: </p>' +
    '<ul><li>Wszystkie przestrzenie wraz z elementami</li>' +
    '<li>Informacje o płatności wraz z fakturami</li>' +
    '<li>Dostęp członków do zespołu</li>' +
    '<li>Pozostałe informacje i integracje powiązane z tym zespołem</li></ul>',
  deleteTeamModalConfirmButton: 'Mimo tego usuń',
  deleteTeamSuccessMessage: 'Zespół "{teamName}" został usunięty',

  teamTeamMembershipTrialInfoLine1:
    'Twoje konto jest w trakcie 14 dniowego okresu próbnego.',
  teamTeamMembershipTrialInfoLine2:
    'Każda przestrzeń projektowa, którą utworzysz, będzie korzystać ze wszystkich korzyści związanych z Twoim obecnym planem.',
  teamTeamMembershipTrialInfoLine3:
    'Kiedy uzyskujesz dostęp do wspólnej przestrzeni projektowej, korzystasz ze wszystkich korzyści związanych z twórcą tej przestrzeni.',
  teamTeamMembershipTrialInfoLine4:
    'Jeśli zaktualizowałeś indywidualną przestrzeń projektową lub projekt do jednego z naszych planów premium, ten indywidualny zasób zachowa korzyści, które uzyskał w momencie aktualizacji, niezależnie od planu konta. Ulepsz swoje konto, aby uzyskać te korzyści we wszystkich swoich przestrzeniach bez dodatkowych kosztów!',
  teamTeamMembershipPayWallTitle: 'Rozwijaj się na swój sposób',
  teamTeamMembershipPayWallSubtitle:
    'Uzyskaj 2 miesiące gratis w rocznym planie.',
  teamTeamMembershipPayWallFilterMonthly: 'Miesięcznie',
  teamTeamMembershipPayWallFilterAnnual: 'Rocznie',
  teamTeamMembershipPayWallNoPlans: 'Brak planów',
  teamTeamMembershipPayWallPriceCustom: 'Indywidualnie',
  teamTeamMembershipPayWallPerksTitle: 'Co otrzymasz',
  teamTeamMembershipPayWallPerksMembers: 'członków',
  teamTeamMembershipPayWallPerksSocialAccounts: 'kont społecznościowych',

  teamTeamMembershipPayWallContactUs: 'Skontaktuj się',
  teamTeamMembershipPayWallProrationTitle:
    'Ulepsz swoją subskrypcję zmieniając plan z ‘{currentPlanName}’ na ‘{newPlanName}’',
  teamTeamMembershipPayWallProrationBody:
    'Ulepszając subskrypcję z planu ‘{currentPlanName}’ na ‘{newPlanName}’, zapłacisz teraz jedynie {prorationAmount} za obecny okres rozliczeniowy, a następnie {regularPrice}/{newPlanPeriod}',
  teamPerkSpaces: 'Nielimitowana liczba przestrzeni',
  teamPerkMembers: 'liczba członków zespołu',
  teamPerkSocialAccounts: 'liczba kont social media',
  teamPerkUnlimited: 'Nielimitowana',
  globalPayWallSuccessModalTitle:
    'Twoje konto zostało zaktualizowane do planu ',
  globalPayWallSuccessModalBody:
    'Wysłaliśmy Ci wiadomość e-mail z potwierdzeniem zakupu i szczegółami planu.',
  globalPayWallCancelModalTitle: 'Anulowałeś ulepszanie planu',
  globalPayWallCancelModalBody:
    'Twój plan nie został ulepszony. Płatność została anulowana.',
  globalPaymentChangeSuccess: 'Metoda płatności została zaktualizowana.',
  globalNoPermission: 'UNUM nie ma uprawnień',
  userMembershipPayWalCancellationModalTitle:
    'Czy na pewno chcesz anulować subskrypcję planu Elite?',
  userMembershipPayWalFinalCancellationConfirmationModalTitle:
    'Wszystkie twoje gridy zostaną ograniczone do darmowego planu',
  userMembershipPayWalFinalCancellationConfirmationModalBody:
    'Stracisz wszystkie korzyści z planu Elite ze swoich gridów. Korzyści te to między innymi nielimitowane placeholdery, premium filtry do zdjęć, ekskluzywane szablony, itp.',
  userMembershipPayWallTitle: 'Zmień swój plan',
  userMembershipPayWallBasicPlanTitle: 'Podstawowy',
  userMembershipPayWallBasicPlanSubtitle: 'Zacznij rosnąć dzisiaj',
  userMembershipPayWallBasicPlanPerk1: '18 postów w gridzie',
  userMembershipPayWallBasicPlanPerk2: '1 konto social media',
  userMembershipPayWallBasicPlanPerk3: 'Podstawowe wtyczki i tła',
  userMembershipPayWallBasicPlanPerk4: 'Podstawowe narzędzia',
  elitePerkGridPosts: 'Nielimitowana liczba elementów w gridzie',
  elitePerkSocialAccounts: 'Nielimitowana liczba kont social media',
  elitePerkPresets: 'Specjalne presety + overlaye (tylko w aplikacji mobilnej)',
  elitePerkTools:
    'Narzedzia premium do edytowania (tylko w aplikacji mobilnej)',
  userMembershipPayWallBasicMembershipIncludes: 'Twój obecny plan zawiera:',
  userMembershipPayWallBasicPlanReasonNotToCancel1:
    'Dostęp do ponad 200 premium filtrów do zdjęć',
  userMembershipPayWallBasicPlanReasonNotToCancel2:
    'Dostęp do ponad 500 szablonów',
  userMembershipPayWallBasicPlanReasonNotToCancel3:
    'Nielimitowane placeholdery',
  userMembershipPayWalDowngradeSuccessful:
    'Twoja subskrypcja została obniżona do planu Basic.',
  paywallCannotSelectBillingPlan: `Nie możesz wybrać planu ''{planName}'' ponieważ:`,
  paywallMaxTeamMembersExceeded: 'Masz więcej niż {number} członków w zespole',
  paywallMaxIntegrationsExceeded:
    'Masz więcej niż {number} kont społecznościowych w zespole',
  paywallRemoveMembersOrIntegrations:
    'Usuń członków lub konta społecznościowe z zespołu',
  userMembershipCancellationSurveyTitle:
    'Czy możemy zapytać dlaczego zrezygnowałeś/zrezygnowałaś z subskrypcji?',
  userMembershipCancellationSurveyBody:
    'Twoja opinia jest dla nas ważna. Wybierz proszę powody z listy poniżej, przez które zdecydowałeś/zdecydowałaś się zrezygnować z subskrypcji. Dzięki temu będzie mogli zaoferować lepsze produkty w przyszłości.',
  userMembershipCancellationSurveySelectAllReasons:
    'Wybierz wszystkie powody, które pasują.',
  userMembershipCancellationReasonNotUse: 'Nie korzystam z subskrypcji',
  userMembershipCancellationReasonDoesNotMeetNeeds:
    'Nie spełnia moich oczekiwań',
  userMembershipCancellationReasonDifficultToUse: 'Zbyt trudne w użyciu',
  userMembershipCancellationReasonCost: 'Zbyt dużo kosztuje',
  userMembershipCancellationReasonDuplicateAccount: 'Mam inne konto',
  userMembershipCancellationSurveyThankYou:
    'Dziękujemy za podzielenie się opinią!',
  teamTeamMembersTabName: 'Członkowie',
  teamJoinedSuccessfully: 'Zaproszenie do zespołu zostało zaakceptowane',
  teamInviteMembersTitle: 'Zaproś do zespołu',
  teamAddAnother: 'Dodaj kolejny email',
  teamSendInvites: 'Wyślij zaproszenia',
  teamInvitationsHaveBeenSent: 'Twoje zaproszenia zostały wysłane',
  teamInvitationsHasBeenResent: 'Zaproszenie zostało ponownie wysłane.',
  teamTeamMembersForbidden: 'Tylko owner zespołu moze usuwać członków',
  inviteNewMembers: 'Zaproś nowe osoby do zespołu',
  inviteTeamMembers: 'Zaproś członków zespołu',
  enterEmailsToInvite:
    'Członkowie Twojego zespołu dostaną wiadomość email z linkiem, który umożliwi dostęp do zespołu.',
  sendInvitations: 'Wyślij zaproszenia',
  sendInvitationsLater: 'Wyślę zaproszenia później.',
  teamTeamMembersSummaryCopy:
    'Wykorzystałeś {teamSeatsUsed} z {availableTeamSeats} możliwych miejsc w teamie. Zaktualizuj swój plan, by zapraszać więcej członków.',
  teamTeamMembersTrialExpired:
    'Twój okreś próbny minął. Zaktualizuj swój plan, by zapraszać więcej członków.',
  teamTeamMembersTableColumnName: 'Nazwa',
  teamTeamMembersTableColumnEmail: 'E-mail',
  teamTeamMembersTableColumnNameEmail: 'Nazwa / E-mail',
  teamTeamMembersTableColumnRole: 'Rola',
  teamTeamMembersTableColumnActionEditProfile: 'Edytuj swój profil',
  teamTeamMembersTableColumnActionChangeRole: 'Zmień rolę',
  editableInputDefaultPlaceholder: 'Wprowadź wartość',
  errorsSomethingWentWrong: 'Coś poszło nie tak',
  errorsUnsupportedIntegration: 'Integracja tego typu nie jest obsługiwana',
  errorsEmailInvalid: 'Podaj poprawny adres email',
  errorsFieldRequired: 'To pole jest wymagane',
  globalActionsOk: 'OK',
  globalActionsSave: 'Zapisz',
  globalActionsSaveChanges: 'Zapisz zmiany',
  globalActionsCancel: 'Anuluj',
  globalActionsApply: 'Zastosuj',
  globalActionsDelete: 'Usuń',
  globalActionsUnschedule: 'Anuluj harmonogram',
  globalActionsEdit: 'Edytuj',
  globalActionsEditCaption: 'Podpis',
  globalActionsUpdate: 'Zaktualizuj',
  globalActionsLogout: 'Wyloguj',
  globalActionsRemove: 'Usuń',
  globalActionsContinue: 'Dalej',
  globalActionsDuplicate: 'Duplikuj',
  none: 'Nie ma',
  personalAccountType: 'Konto osobiste',
  settings: 'Ustawienia',
  teamAccountType: 'Konto zespołu',
  upgrade: 'Ulepsz konto',
  upgraded: 'Ulepszony',
  submit: 'Wyślij',
  select: 'Wybierz',
  free: 'Darmowy',
  thumbnail: 'Miniaturka',
  resendInvitation: 'Wyślij zaproszenie ponownie',
  cancelInvitation: 'Anuluj zaproszenie',
  invitationCancelled: 'Zaproszenie zostało anulowane',
  currentPlan: 'Aktualny plan',
  downgrade: 'Zmniejsz',
  downgraded: 'Zmniejszony',
  share: 'Udostępnij',
  clone: 'Sklonuj',
  close: 'Zamknij',
  emailAddress: 'Adres email',
  signOut: 'Wyloguj się',
  topBarAccountDropdownOptionUserSettings: 'Ustawienia konta',
  productsBarDesign: 'Projektowanie',
  productsBarPublish: 'Publikowanie',
  productsBarInsights: 'Statystyki',
  productsBarWebBuilder: 'Today.Page',
  topBarNewBadge: 'Nowość!',
  createFirstTeamModalTitle: 'Utwórz konto biznesowe!',
  createFirstTeamModalIntroduction:
    'Twórz razem z Twoimi partnerami, klientami lub członkami zespołu wspaniałą historię.',
  createFirstTeamModalBenefit1: 'Nielimitowane Przestrzenie',
  createFirstTeamModalBenefit2: 'Nielimitowane Miejsca',
  createFirstTeamModalBenefit3: 'Nielimitowane konta społecznościowe',
  createFirstTeamModalSummary:
    'Utwórz konto biznesowe i rozpocznij 14-dniowy okres próbny.',
  accountSwitcherPersonalType: 'Moje konto osobiste',
  accountSwitcherTeamType: 'Konto biznesowe',
  businessMemberCount:
    '∙ {memberCount, plural, one {# członek} other {# członków}}',

  marketPlaceTitle: 'Odkryj dostępne tablice',
  marketPlaceSubtitle:
    'Przeglądaj, klonuj i remiksuj tablice historii stworzone przez społeczność UNUM',
  userDefaultName: 'Użytkownik UNUM',

  previewUnavailable: 'Podgląd niedostępny',
  marketplaceCreator: 'Autor',
  marketplaceDescription: 'Opis',
  marketplaceTags: 'Tagi',
  marketplaceCountAssets: 'Tablica Instagram • {count} elementów',
  storyboardCloned: 'Nowa przestrzeń "{name}" została dodana do Twojego konta.',
  linkCopied: 'Link skopiowany!',
  linkCopyError: 'Twoja przeglądarka nie obsługuje kopiowania do schowka',
  publicProfileStoryboardsTab: 'Tablice <Counter>{counter}</Counter>',
  publicProfileMembersTab: 'Członkowie',
  publicProfileNotFound: 'Profil nie istnieje',
  elite: 'Elite',
  team: 'Zespół',
  space: 'Przestrzeń',
  error: 'Błąd',

  gridAddAction: 'Dodaj media',
  gridShowAction: 'Pokaż',
  gridHideAction: 'Ukryj',
  gridReplaceImageAction: 'Zmień zdjęcie',
  gridDeleteWithCounterAction: 'Usuń {selectedItemsCount}',
  gridAddPlaceholderAction: 'Dodaj kafelek zastępczy',
  gridShiftAction: 'Przetasuj',
  gridDeselectAction: 'Odznacz',
  gridCreateAlbumAction: 'Utwórz album',
  gridSwapAction: 'Zamień miejscami',
  gridAddRowAction: 'Dodaj wiersz',
  gridItemDeleted: 'Wybrane elementy zostały usunięte',
  gridDeleteConfirmationTitle: 'Jesteś pewien?',
  gridDeleteConfirmationBody:
    'Jesteś pewien, że chcesz usunąć {selectedItemsCount, plural,' +
    '    one {# zdjęcie}' +
    '    few {# zdjęcia}' +
    '    other {# zdjęć}' +
    '}? Tej operacji nie można cofnąć.',
  gridToggleColorMapAction: 'Mapa kolorów',
  gridScheduledPost: 'Zaplanowano publikacje na {date}',

  media: 'Dodaj Media',
  placeholder: 'Dodaj Placeholder',
  emptySpaceGridTitle: `<p>Zacznij projektować</p><p>w {where}</p>`,
  emptySpaceGridDescription: 'Dodaj element używając poniższych przycisków',

  spaceDeleteAction: 'Usuń przestrzeń',
  spaceCloneAction: 'Sklonuj przestrzeń',
  cloneSpaceTo: 'Sklonuj przestrzeń do',
  workspaces: 'Obszary robocze',
  cloningSuccess: `Pomyślnie sklonowano do obszaru roboczego ''{workspaceName}''.`,
  viewItNow: 'Zobacz teraz',
  spaceDeleteConfirmationTitle: `Usunąć przestrzeń ''{spaceName}''?`,
  spaceDeleteConfirmationBody:
    `Jesteś pewien, że chcesz usunąć przestrzeń ''{spaceName}''?` +
    ' Tej operacji nie można cofnąć.',
  spaceDeleteSuccess: `Przestrzeń ''{spaceName}'' została usunięta.`,
  spaceLayout: 'Układ przestrzeni',
  spaceRenameAction: 'Zmień nazwę przestrzeni',
  spaceName: 'Nazwa przestrzeni',
  spaceNameEmptyError: 'Nazwa przestrzeni nie może być pusta',
  spaceNameMinLengthError:
    'Nazwa przestrzeni nie może mieć więcej niż {maxLength} znaków.',
  spaceDefaultNewName: 'Bez tytułu - {layoutName}',
  changesAreSaved: 'Zmiany zostały zapisane!',
  igFeedPro: 'IG Feed Pro',
  defaultSpaceName: 'Bez nazwy',
  storyboard: 'Storyboard',
  createNewSpace: 'Utwórz nową przestrzeń',
  planForYourInstagram: 'Plan dla Twojego Instagrama',
  yourInstagramFeed: 'Twój post z Instagrama',
  connectWithInstagram: 'Połącz z Instagramem',
  connectYourSocialAccount: 'Połącz z kontem społecznościowym',
  connectYourSocialAccountDescription:
    'Połącz swoje konto społecznościowe żeby zobaczyć swoje opublikowane posty wraz z tymi, które zaplanujesz.',
  selectStoryboardLayout: "Wybierz Układ Storyboard'a",
  selectStoryboardLayoutIntroduction:
    'Wybierz układ w jakim chcesz wyświetlać swoją zawartość. Jeśli chcesz, możesz zmienić wybrany układ później',
  errorLoadingMediaFromIntegrations: 'Nie udało się pobrać zdjęć z Instagrama.',
  spaceIntegrationExpiredTokenAlert:
    'Token Twojej integracji wygasł. <RefreshToken>odśwież swój token</RefreshToken>',
  spaceMissingIntegrationAlert: 'Połącz z kontem Instagram',
  spaceMissingIntegrationAlertPrompt:
    'Połącz z kontem Instagram aby zobaczyć jak wyglądją Twoje zaplanowane posty.',
  backToDashboard: 'Powrót do Tablicy',
  backToInsights: 'Powrót do Statystyk',
  backToPublish: 'Powrót do Publikowania',
  marketplace: 'Marketplace',
  marketplaceExploreStoryboards: 'Przeglądaj Storyboardy',
  accountNew: 'Nowe',
  accountExpired: 'Wygasło',
  expiredTeamContainerTitle: 'Subskrypcja Twojego Zespółu Wygasła',
  expiredTeamContainerText: 'Zwiększ swój plan, aby mieć dostęp do tej sekcji.',
  expiredTeamContainerTextForEditor:
    'Aby odzyskać dostęp do tej sekcji, poproś właściciela o zwiększenie planu.',
  expiredTeamContainerAlert:
    'Twój zespół "{teamName}" wygasł. <UpgradePlan>Ulepsz swój plan</UpgradePlan>',
  expiredTeamContainerAlertForEditor: 'Zespół "{teamName}" wygasł.',
  month: 'miesiąc',
  year: 'rok',
  week: 'tydzień',
  billedAnnually: 'przy opłacie {amount} za cały rok.',
  captionUpdated: 'Podpis został zaktualizowany',
  typeYourCaptionHere: 'Wprowadź podpis do zdjęcia',
  spaceCounter:
    '{spaceCounter, plural,' +
    'one {# przestrzeń}' +
    'few {# przestrzenie}' +
    'other {# przestrzeni}}',
  cloneSpaceUploadWarning:
    'Wygląda na to, że pewne elementy są aktualnie wysyłane. Zaczekaj aż wysyłanie zostanie ukończone lub sklonuj przestrzeń bez tych elementów.',
  resourceNoLongerExists: 'Zasób już nie istnieje',
  teamConfirmationTitle: 'Twój zespół jest prawie gotowy!',
  teamConfirmationTitleCompleted: 'Twój zespół został przygotowany!',
  teamConfirmationSubtitle:
    'Potrzebujemy kilka sekund, aby przygotować Twój zespół.',
  teamConfirmationSubtitleCompleted:
    'Kliknij poniższy przycisk, aby przejść do tablicy Twojego zespołu.',
  teamConfirmationCta: 'Prejdź do mojego zespołu',
  teamConfirmationTrialInfo: 'i rozpocznij 14 dniowy okres próbny',

  teamWizardCreateTeamActionName: 'Tworzenie Twojego zespołu',
  teamWizardCreateTeamDoneActionName: 'Twój zespół został utworzony',
  teamWizardInviteMembersActionName: 'Wysyłanie zaproszeń',
  teamWizardInviteMembersDoneActionName: 'Zaproszenia zostały wysłane',
  teamWizardCreateTeamErrorMessage:
    'Wystąpił problem podczas tworzenia Twojego zespołu.',
  teamWizardInviteMembersErrorMessage:
    'Wystąpił problem podczas wysyłania zaproszeń do Twojego zespołu.',
  teamWizardCloneSpacesErrorMessage:
    'Wystąpił problem podczas klonowania przestrzeni do Twojego zespołu.',
  teamWizardErrorAlertHeader: 'Wystąpił problem...',
  tryAgain: 'Spróbuj ponownie',
  pleaseTryAgain: 'Proszę spróbuj ponownie.',
  next: 'Dalej',
  confirmationAlertTitle: 'Jesteś pewien?',
  confirmationAlertCancelTeamCreationDescription:
    'Zamierzasz przerwać proces tworzenia Twojego zespołu. Tej operacji nie można cofnąć.',
  confirmationAlertCancelTeamCreationConfirmButton: 'Tak, przerwij',
  confirmationAlertSkipInvitesTitle: 'Pominąć wysyłanie zaproszeń?',
  confirmationAlertSkipInvitesDescription:
    'Spróbuj zaprosić dyrektorów, współpracowników oraz osoby, które mogą chcieć zobaczyć Twoją historię lub mieć wpływ na strategię Twojej marki.',
  confirmationAlertSkipInvitesConfirmButton: 'Pomiń krok',
  comments: 'Komentarze',
  addComment: 'Dodaj komentarz',
  removedMember: 'Profil usunięty',
  commentsRetrievalError:
    'Wystąpił problem podczas pobierania komentarzy. Spróbuj ponownie za chwilę.',
  notificationNewComment:
    '<strong>{user}</strong> zostawił(a) komentarz w <strong>{targetSpaceName}</strong>',
  notificationUploadMedia:
    '<strong>{user}</strong> dodał obrazek w <strong>{targetSpaceName}</strong>',
  notificationTodoTaskDeleted:
    '<strong>{user}</strong> usunął zadanie <strong>{targetTaskName}</strong> w <strong>{targetSpaceName}</strong>',
  notificationTodoTaskToggledUnknownStatus:
    '<strong>{user}</strong> zmienił status zadania <strong>{targetTaskName}</strong> w <strong>{targetSpaceName}</strong>',
  notificationTodoTaskToggled:
    '<strong>{user}</strong> oznaczył zadanie <strong>{targetTaskName}</strong> jako <strong>{newStatus}</strong> w <strong>{targetSpaceName}</strong>',
  notificationTodoTaskCreated:
    '<strong>{user}</strong> utworzył nowe zadanie <strong>{targetTaskName}</strong> w <strong>{targetSpaceName}</strong>',
  notificationTodoTaskUpdated:
    '<strong>{user}</strong> zaktualizował zadanie <strong>{targetTaskName}</strong> w <strong>{targetSpaceName}</strong>',
  notificationSchedulePost:
    '<strong>{user}</strong> zaplanował nowy post na koncie <strong>{socialAccounts}</strong>',
  notificationEditPost:
    '<strong>{user}</strong> edytował zaplanowany post na koncie <strong>{socialAccounts}</strong>',
  notificationDeletePost:
    '<strong>{user}</string> usunął zaplanowany post na koncie <strong>{socialAccounts}</strong>',
  noNewNotifications: 'Brak nowych powiadomień',
  unknownNotification: 'Brak danych',
  emptyImageError: 'Multimedia są wymagane. Proszę zaimportować multimedia',
  selectDateError: 'Wybierz datę i czas aby zaplanować post.',
  emptyDateError: 'Data i czas są wymagane.',

  // TODO
  taskCompleted: 'Wykonane',
  taskToDo: 'Do zrobienia',
  taskReverted: 'Zadanie zostało przywrócone',
  taskRevertingError: 'Nie udało się przywrócić tego zadania.',
  taskUpdated: 'Zadanie zostało zaktualizowane',
  taskDeleted: 'Zadanie zostało usunięte',
  taskDeletedUndo: 'cofnij',
  addYourTask: 'Dodaj zadanie do wykonania',
  toDoHeading: 'Do wykonania',
  taskDoesntExist: 'To zadanie nie istnieje',
  addDate: 'Wybierz datę i godzinę',
  backToTaskList: 'Powrót do listy zadań',
  completedHeading:
    '{completedTasks, plural,' +
    'one {# wykonane}' +
    'few {# wykonane}' +
    'other {# wykonanych}}',
  noTasks: 'Nie masz żadnych zadań do wykonania.',

  // Publish
  unschedule: 'Wyczyść datę',
  on: 'W dniu',
  publishMetaTitle: 'Publikowanie',
  socialAccounts: 'Konta Społecznościowe',
  socialAccount: 'Konto Społecznościowe',
  addSocialAccount: 'Dodaj nowe konto',
  publishNoAccessTitle: 'Wypróbuj Publikowanie w UNUM',
  publishNoAccessDescription:
    'Publikowanie jest wyłącznie możliwe dla kont biznesowych. Przełacz konto na biznesowe lub utwórz nowe, klikając w poniższy przycisk.',
  publishNoTeamsDescription:
    'Publikowanie jest wyłącznie możliwe dla kont biznesowych. Utwórz nowe konto biznesowe, klikając w poniższy przycisk.',
  switchToTeamAccount: 'Przełącz na konto biznesowe',
  switchAccountModalHeader: 'Przełącz konto',
  publishEmptyQueueTitle: 'Nie masz zaplanowanych postów do publikacji',
  publishEmptyQueueDescription:
    'Kliknij na poniższy przycisk, aby zaplanować Twoje publikacje',
  publishEmptyQueueCreateNow: 'Utwórz teraz',
  manageSocialAccountsHint: 'Zarządzaj zintegrowanymi kontami',
  basicDisplayErrorStart: 'Start',
  basicDisplayErrorLater: 'Może później',
  basicDisplayErrorHeaderForPublish: 'Nie można publikować',
  basicDisplayErrorHeaderForInsights: 'Statystyki niedostępne',
  basicDisplayErrorText:
    'Zmień to konto na Instagram Business. <LearnMoreLink>Więcej</LearnMoreLink>',
  basicDisplayInfoTextForPublish:
    '<p>UNUM potrzebuje uprawnień aby publikować na to konto.</p><p>Zmień swoje konto na konto biznesowe i zaloguj się ponownie aby móc korzystać z tej funkcji.</p>',
  basicDisplayInfoTextForInsights:
    '<p>UNUM potrzebuje uprawnień aby widzieć statystyki.</p><p>Zmień swoje konto na konto biznesowe i zaloguj się ponownie aby móc korzystać z tej funkcji.</p>',
  basicDisplayConvertModalHeader: 'Połącz Twoje konto Instagram Business',
  basicDisplayConvertModalInfo:
    'Aby połączyć Twoje konto Instagram Business sprawdź poniższą listę żeby mieć pewność, że wszystko jest poprawnie ustawione.',
  basicDisplayChecklistConvert:
    'Zmień swoje prywatne konto na Instagramie na konto biznesowe',
  basicDisplayChecklistLink:
    'Połączyłeś Twoje prywatne konto Instagram Business do strony Facebooka',
  basicDisplayChecklistAdmin: 'Jesteś Adminem na stronie Facebooka',
  basicDisplayChecklistConnected:
    'Strona Facebooka i konto Instagram są połączone',
  basicDisplayChecklistTroubleshooting:
    'Pomoc dotycząca biznesowych profili Instagrama',
  scheduledFrom: 'Zaplanowano z ',
  facebookPrivateGroupWarningModalHeader: '{groupName} jest prywatna.',
  facebookPrivateGroupWarningModalText:
    'Przed publikacją do grupy prywatnej należy upewnić się, że aplikacja UNUM została dodana do grupy i nadano jej niezbędne uprawnienia.',
  facebookPrivateGroupWarningModalTextWithLink:
    'Aby uzyskać więcej informacji, proszę przeczytać <PostToPrivateGroupLink>ten artykuł</PostToPrivateGroupLink>.',
  facebookPrivateGroupWarningModalCheckboxLabel: 'nie pokazuj ponownie',
  numberOfIntegrationsExceeded:
    'Hej! Możesz filtrować po maksymalnie 10 kontach na raz',

  // Add social account
  addInstagramAccountModalTitle: 'Dodaj konto społecznościowe',
  addInstagramAccountModalSubtitle:
    'Wybierz platformę, której konto chcesz dodać.',
  connect: 'Połącz',
  connected: 'Połączono',
  instagram: 'Instagram Business',
  facebook: 'Facebook',
  pinterest: 'Pinterest',
  back: 'Wstecz',
  addInstagramAccountFirstStepSubtitle:
    'Dokonałeś już konwersji swojego konta na Instagram Business?',
  addInstagramAccountFirstStepInstructionLine1:
    'W celu uzyskania dostępu do pewnych funkcjonalności, musisz:',
  addInstagramAccountFirstStepInstructionLine2:
    'Dokonać konwersji konta Instagram Personal na Creator lub Instagram Business',
  addInstagramAccountFirstStepInstructionLine3:
    'Połączyć swoje konto Instagram Creator lub Instagram Business z profilem na Facebooku',
  addInstagramAccountFirstStepInstructionLine4:
    'Dokonać uwierzytelnienia aplikacji UNUM',
  addInstagramAccountFirstStepInfo:
    'Sprawdź nasze centrum pomocy, w celu uzyskania dodatkowych informacji.',
  addInstagramAccountFirstStepInfoLinkText:
    'Jak ustawić konto Instagram Business?',
  addInstagramAccountFirstStepConfirmButton: 'Tak, wszystko ustawione',
  addInstagramAccountSecondStepSubtitle:
    'Autoryzacja na Facebooku dla Instagrama',
  addInstagramAccountSecondStepText:
    ' Facebook wymaga, aby Twoje konto Instagram Business było połączone ze stroną na Facebooku\n' +
    'Aby móc publikować materiały poprzez UNUM na Twoim instagramowym koncie, musimy potwierdzić Twoją tożsamość na Facebooku.',
  continueToFacebook: 'Kontynuuj na Facebooku',
  done: 'Zakończ',
  addInstagramAccountThirdStepSubtitle:
    'Wybierz konta, które chcesz dodać do UNUM.',
  noAccountsToChooseFrom:
    'Nie ma zintegrowanych kont, które można powiązać z Twoim kontem.',
  manageSocialAccountsModalExpiredTokenInfo:
    'Niektóre z Twoich kont wymaga ponownego powiązania, w celu uzyskania tokenu dostępowego. Kliknij w przycisk Powiąż ponownie',
  manageSocialAccountsModalReconnect: 'Powiąż ponownie',
  accountReconnected: 'Twoje konto zostało ponownie powiązane.',
  accountReconnectionError:
    'Wystąpił błąd podczas ponownego powiązywania Twojego konta.',
  accountAlreadyExistsError: 'To konto jest już powiązane.',
  igBusinessAccountType: 'Biznesowe',
  igBasicDisplayAccountType: 'Podstawowe Wyświetlanie',
  socialAccountIsNotSelected:
    'Musisz wybrać przynajmniej jedno konto, aby dokończyć ten proces.',
  selectedSocialAccountAdded: 'Wybrane konta zostały dodane',
  manageSocialAccountsModalTitle: 'Zarządzaj kontami',
  manageSocialAccountsModalNoAccountsInfo:
    'Nie dodałeś jeszcze żadnego konta społecznościowego. Dodaj konto, aby zaplanować publikacje.',
  tokenExpired: 'Token wygasł',
  publishContent: 'Publikowanie treści',
  autoPost: 'Post automatyczny',
  autoPostOn: 'Zaplanowano na',
  remindMe: 'Przypomnienie',
  postDescription: 'OPIS POSTU',
  schedulePostCaptionPlaceholder: 'Co chciałbyś opublikować?',
  schedulePostAddToQueue: 'Dodaj do kolejki',
  schedulePostPublishNow: 'Opublikuj teraz',
  schedulePostCaptionMentionsError: 'Przekroczyłeś dozwoloną liczbę oznaczeń.',
  schedulePostCaptionHashtagsError: 'Przekroczyłeś dozwoloną liczbę hashtagów.',
  schedulePostCaptionCharactersError: 'Przekroczyłeś dozwoloną liczbę znaków.',
  schedulePostSuccess: 'Post został zaplanowany!',
  scheduleDuplicatedPostSuccess: 'Twój post został zduplikowany.',
  schedulePostTo: 'Zaplanuj w',
  saveScheduledPostSuccess: 'Zmiany zostały zapisane',
  postPublished: 'Post został opublikowany',
  autoPostAt: 'Automatycznie opublikuj o {hour}',
  today: 'Dziś',
  tomorrow: 'Jutro',
  deleteScheduledPostConfirmationBody:
    'Czy jesteś pewny, że chcesz usunąć ten zaplanowany post? Tej akcji nie można cofnąć.',
  unscheduleScheduledPostConfirmationBody:
    'Czy jesteś pewny, że chcesz anulować harmonogram postu? Post zniknie z listy ale wciaż możesz go odnaleźć w swojej przestrzeni projektowania.',
  badImageAspectRatio:
    'Wybrany obrazek musi mieć proporcje w wymiarze minimum {minAspectRatio} i maksimum {maxAspectRatio}',
  badFileSize: 'Wybrany plik nie może być większy niż {limit} MB',
  badFileType: 'Wybrany typ pliku nie jest wspierany',
  badVideoDuration:
    'Wybrany film ma niewłaściwą długość. Minimalny czas to {limitMin}, a maksymalny to {limitMax} sekund',
  deleteSocialAccountConfirmationHeader:
    'Czy jesteś pewny, że chcesz usunąć konto @{accountName}?',
  deleteSocialAccountConfirmationBody:
    'Stracisz możliwość publikowania z tego konta. Zaplanowane posty oraz dane zostaną usunięte. <strong>Tej akcji nie można cofnąć.</strong>',
  deleteSocialAccountSuccess: 'Konto @{accountName} zostało usunięte.',
  reconnectWrongOriginAccountModalTitle: 'Nieprawidłowe konto',
  reconnectWrongOriginAccountModalDescription:
    'Wygląda na to, że zalogowałeś się na inne konto na Facebooku. Sprawdź czy podane konto przynależy do Twojego profilu.',
  scheduledPostErrorInvalidInstagramToken:
    'Uups! Token do Twojego konta (@{username}) wygasł. Przejdź ponownie przez autoryzację połączenia konta z UNUM.',
  scheduledPostErrorPastDate:
    'Ten post jest zaplanowany na dzień w przeszłości. Wyedytuj post i ustaw przyszłą datę.',
  scheduledPostErrorFailedIntegrationRemoved:
    'Uups! Konto do którego ten post jest przypisany zostało usunięte. Przejdź ponownie przez autoryzację połączenia konta z UNUM lub wybierz inne konto.',
  retry: 'Spróbuj ponownie',
  reconnect: 'Połącz konto ponownie',
  importMediaModalTitle: 'Importuj zdjęcia',
  device: 'Urządzenie',
  archive: 'Archiwum',
  spaces: 'Przestrzenie',
  dragAndDrop: 'Przeciągnij i upuść',
  dropHere: 'Upuść pliki tutaj',
  or: 'lub',
  selectFiles: 'Wybierz pliki',
  emptyArchive: 'Archiwum jest puste.',
  mediaAdderNoAssetsInSpace:
    'Ta przestrzeń nie zawiera żadnych elementów, których możesz tutaj użyć. Wybierz inną przestrzeń',
  mediaAdderNoSpaces: 'Nie masz żadnych przestrzeni.',
  unableToScheduleInThePast: 'Nie można zaplanować postu w przeszłości',
  postIsAboutToBePublished: 'Trwa publikowanie...',

  addFacebookModalTitle: 'Dodaj profil z Facebooka',
  addFacebookProfileHeading: 'Wybierz typ konta',
  facebookPage: 'Strona na Facebooku',
  facebookPageDescription:
    'Możesz publikować na swojej stronie, profilu publicznym, który „lubią” użytkownicy Facebooka. Możesz także publikować jako strona do grupy na Facebooku.',
  facebookProfile: 'Profil na Facebooku',
  facebookProfileDescription:
    'Możesz publikować na swoim osobistym profilu na Facebooku i grupie na Facebooku, do której ten profil dołącza jako członek.',
  addFacebookAccountSecondStepSubtitle: 'Wybierz stronę, którą chcesz dodać',
  unableToLoadImage: 'Nie udało się wczytać obrazka',

  captionStrategyModalTitle: 'Wybierz podpis',
  captionStrategyModalText:
    'W poście, który próbujesz wybrać istnieje już podpis. Chcesz zatrzymać istniejący podpis czy zastąpić go?',
  captionStrategyModalCTAKeepCurrent: 'Zatrzymaj podpis',
  captionStrategyModalCTAReplaceCurrent: 'Zastąp istniejący podpis',
  captionStrategyModalCTACombine: 'Połącz oba podpisy',

  // Student pricing
  studentPricingMetaTitle: 'Oferta dla studentów',
  studentPricingHeading:
    'Wyłącznie dla studentów. Uzyskaj 50% zniżki na plan UNUM Elite',
  studentPricingCheckoutButton: 'Kup teraz',
  studentPricingEmailDomainMismatch:
    'Adres email musi zawierać się w domenie .edu',

  // Image editing
  imageEditingModalTitle: 'Zmień wielkość obrazka',
  imageEditingOpenEditor: 'Otwórz edytor',
  imageEditionBadImageAspectRatio:
    'Wybrany obrazek musi mieć proporcje w wymiarze minimum {minAspectRatio} i maksimum {maxAspectRatio} na podstawie wymogów Platformy Społecznościowej {platform}. <ResizeImageLinkButton>Zmień wielkość obrazka</ResizeImageLinkButton>.',

  facebookPersonalType: 'Osobiste',
  facebookPageType: 'Strona',
  facebookGroupType: 'Grupa',
  // Publish now
  publishNowConfirmationHeader:
    'Jesteś pewien, że chcesz opublikować teraz tego posta?',
  publishNowConfirmationBody:
    'Twój post zostanie automatycznie opublikowany po kliknięciu w przycisk "Opublikuj Teraz".',
  submitting: 'Wysyłanie...',
  instagramFeed: 'Posty z Instagrama',
  helpCenter: 'Centrum Pomocy',
  notifications: 'Powiadomienia',
  unumCloud: 'UNUM Cloud',
  accounts: 'Konta',
  availableSoon: 'Dostępne wkrótce',

  // Insights
  insightsMetaTitle: 'Statystyki',
  insightsWidgetNoData: 'Nie można pobrać danych',
  insightsNoAccessTitle: 'Wypróbuj statystyki!',
  insightsNoAccessDescription:
    'Statystyki są dostępne wyłącznie dla kont biznesowych. Prosimy przełączyć się na konto biznesowe lub utworzyć nowe, aby używać tej funkcjonalności.',
  insightsNoTeamsDescription:
    'Statystyki są dostępne wyłącznie dla kont biznesowych. Prosimy utworzyć konto biznesowe, aby używać tej funkcjonalności',
  insightsNoSocialAccountsTitle: 'Połącz konto społecznościowe',
  impressions: 'Impresje',
  engagements: 'Zaangażowanie',
  total_engagements: 'Całkowite Zaangażowanie',
  reach: 'Zasięg',
  profileActivity: 'Aktywność Profilu',
  insightsNotSupportedTitle: 'Statystyki UNUM niedostępne',
  insightsNotSupportedMessage:
    'Niektóre usługi UNUM nie są dostępne dla wybranego konta społecznościowego',
  total_profileActivity: 'Cała Aktywność',
  profile_views: 'Odsłony Profilu',
  website_clicks: 'Kliki w Stronę Internetową',
  text_message_clicks: 'Kliki w Email',
  get_directions_clicks: 'Kliki we Wskazówki',
  phone_call_clicks: 'Kliki w Numer Telefonu',
  like_count: 'Polubienia',
  comments_count: 'Komentarze',
  follower_count: 'Obserwujący',
  saves: 'Zapisania',
  chartCumulative: 'Kumulacyjny',
  chartDaily: 'Dzienny',
  newFollowers: 'Nowi Obserwujący',
  totalFollowers: 'Wszyscy Nowi Obserwujący',
  netGrowth: '- Przyrost netto',
  totalPosts: 'Posty',
  vs: 'vs {from} - {to}',
  topHashtags: 'Najlepsze Hasztagi',
  lastUpdate: 'Ostatnio odświeżone:',
  justNow: 'teraz',
  yesterday: 'wczoraj',
  last: 'ostatni',
  total: 'Wszyscy',

  // date ranges
  lastPeriod: 'Ostatni {period}',
  pastPeriod: 'Ten {period}',
  months: '{# months} miesiące',
  customRange: 'Własny zakres',
  bestTimeToPost: 'Najlepszy czas na publikacje',
  time: 'Godzina',
  lifetimeEngagements: 'Całościowe zaangażowanie',
  unnamed: 'Bez nazwy',

  // day names
  mon: 'Pon',
  tue: 'Wt',
  wed: 'Śr',
  thu: 'Czw',
  fri: 'Pt',
  sat: 'Sob',
  sun: 'Nie',
};
