import { HttpClient, ApiResponse } from '../../../HttpClient';
import { SpacePaths } from '../../../urls';
import { AxiosResponse } from 'axios';
import { IntegratedPlatforms, StoryboardConfig } from '../types';
import { SpaceTypes } from '../types';
import { XOR } from '../../../utils/types';

export type AddSpacePayload = {
  groupId?: string;
  storyboardConfig?: Partial<StoryboardConfig>;
  title: string;
  integratedPlatform: IntegratedPlatforms;
  spaceType: SpaceTypes;
};

export type CloneSpacePayload = { title?: string } & XOR<
  { groupId: string },
  { userId: string }
>;

export class SpaceClient extends HttpClient {
  async addSpace(
    payload: AddSpacePayload,
  ): Promise<AxiosResponse<ApiResponse<{ spaceId: string }>>> {
    return this.post(SpacePaths.add, payload);
  }

  async deleteSpace(spaceId: string): Promise<void> {
    await this.delete<AxiosResponse<ApiResponse<void>>>(
      SpacePaths.delete(spaceId),
    );
  }

  async cloneSpace(
    spaceId: string,
    payload: CloneSpacePayload,
  ): Promise<AxiosResponse<ApiResponse<{ spaceId: string }>>> {
    return this.post(SpacePaths.clone(spaceId), payload);
  }

  async renameSpace(spaceId: string, newName: string): Promise<void> {
    await this.put<AxiosResponse<ApiResponse<void>>>(
      SpacePaths.update(spaceId),
      {
        title: newName,
      },
    );
  }
}
