import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { StylableComponent, useStyles } from '../../utils/hooks/useStyles';
import { omit } from '../../utils/omit';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Transparent = 'transparent',
  Facebook = 'facebook',
  Apple = 'apple',
  Snapchat = 'snapchat',
  Link = 'link',
  Danger = 'danger',
}
export enum ButtonShape {
  Default = 'default',
  Badge = 'badge',
  Circle = 'circle',
}
export type ButtonType = 'submit' | 'button';
export enum ButtonSize {
  Small = 'sm',
  Large = 'lg',
}

export type ButtonStyles = {
  root: string;
  icon: string;
  button: string;
};

export type ButtonProps = {
  variant?: ButtonVariant;
  shape?: ButtonShape;
  type?: ButtonType;
  pullRight?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  selected?: boolean;
  icon?: ReactNode;
  iconPlacement?: 'left' | 'right';
  size?: ButtonSize;
} & JSX.IntrinsicElements['button'];

const Button: StylableComponent<ButtonProps, ButtonStyles> = (props) => {
  const type = props.type || 'button';
  const size = props.size ? props.size : 'md';
  const variant = props.variant || ButtonVariant.Primary;
  const shape = props.shape || ButtonShape.Default;
  const iconPlacement = props.iconPlacement || 'left';

  const buttonClassNames = classNames('Button', {
    'Button--pullRight': props.pullRight,
    'Button--fullWidth': props.fullWidth,
    'Button--selected': props.selected,
    'Button--icon': Boolean(props.icon),
    [`Button--icon--${iconPlacement}`]: props.iconPlacement,
    [`Button--${variant}`]: true,
    [`Button--${shape}`]: true,
    [`Button--${size}`]: true,
  });

  const iconClassNames = classNames('Button__icon', {
    [`Button__icon--${iconPlacement}`]: iconPlacement,
  });

  const classes = useStyles(
    {
      root: buttonClassNames,
      icon: iconClassNames,
      button: 'Button__button',
    },
    props.styles,
  );
  const propsToPass = omit(
    props,
    'className',
    'styles',
    'variant',
    'shape',
    'pullRight',
    'fullWidth',
    'icon',
    'size',
    'type',
    'onClick',
    'iconPlacement',
  );

  return (
    <button
      type={type}
      onClick={props.onClick}
      className={classes.root}
      {...propsToPass}
    >
      {props.icon && <span className={classes.icon}>{props.icon}</span>}
      {props.children}
    </button>
  );
};

export default Button;
