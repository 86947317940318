import { ApiResponse, HttpClient } from '../../HttpClient';
import { AxiosResponse } from 'axios';
import {
  PublicProfileFields,
  PublicProfilePreview,
  PublicProfileType,
} from './types';
import { PublicProfilePaths } from '../../urls';

export class PublicProfileClient extends HttpClient {
  async getPublicProfile(
    publicProfileId: string,
  ): Promise<AxiosResponse<ApiResponse<PublicProfilePreview>>> {
    return await this.get<AxiosResponse<ApiResponse<PublicProfilePreview>>>(
      PublicProfilePaths.get(publicProfileId),
    );
  }

  async getPublicProfileByType(
    type: PublicProfileType,
    typeId: string,
  ): Promise<any> {
    return await this.get<AxiosResponse<ApiResponse<PublicProfilePreview>>>(
      PublicProfilePaths.getByType(type, typeId),
    );
  }

  async updatePublicProfile(
    type: PublicProfileType,
    typeId: string,
    fields: any,
  ): Promise<void> {
    await this.patch<AxiosResponse<any>>(
      PublicProfilePaths.update(type, typeId),
      fields,
    );
  }

  async createPublicProfile(
    type: PublicProfileType,
    typeId: string,
    initialData: Partial<PublicProfileFields>,
  ): Promise<void> {
    await this.post(PublicProfilePaths.create, {
      [type === 'group' ? 'groupId' : 'userId']: typeId,
      ...initialData,
    });
  }
}
