import { atom, RecoilState, useRecoilValue, RecoilRootProps } from 'recoil';

type StateTreeFromInitial<
  T extends { [key: string]: any },
  K extends keyof T
> = {
  [key in K]: { atom: RecoilState<T[K]>; default: T[K] };
};

export const createSyncedState = <
  T extends { [key: string]: any },
  K extends keyof T
>(
  initialState: T,
) => {
  let syncedStateTree = {} as StateTreeFromInitial<T, keyof T>;
  Object.keys(initialState).forEach((key) => {
    syncedStateTree[key as K] = {
      atom: atom({
        key,
        default: initialState[key],
      }),
      default: initialState[key],
    };
  });

  const $subscribers: Array<<K extends keyof T>(
    key: K,
    value?: T[K],
  ) => void> = [];

  const subscribe = (
    subscriber: <K extends keyof T>(key: K, value?: T[K]) => void,
  ) => {
    $subscribers.push(subscriber);
    const unsubscribe = () => {
      $subscribers.filter((s) => s === subscriber);
    };
    return unsubscribe;
  };

  const syncedState = {
    get: <K extends keyof T>(key: K) => {
      const valueFromStorage = localStorage.getItem(String(key));
      if (valueFromStorage === null) {
        return null;
      } else {
        // if JSON was modified from outside and is malformed
        try {
          return JSON.parse(valueFromStorage) as T[K];
        } catch (e) {
          return null;
        }
      }
    },
    set: <K extends keyof T>(key: K, value: T[K]) => {
      localStorage.setItem(String(key), JSON.stringify(value));
      $subscribers.forEach((subscriber) => subscriber<K>(key, value));
    },
    remove: <K extends string>(key: K) => {
      localStorage.removeItem(String(key));
      $subscribers.forEach((subscriber) => subscriber<K>(key));
    },
  };

  const useSyncedState = <K extends keyof T>(key: K) => {
    const value = useRecoilValue(syncedStateTree[key].atom);
    const typedValue: T[K] extends typeof syncedStateTree[K]['default']
      ? T[K]
      : never = value;
    const setValue = (value: T[K]) => {
      localStorage.setItem(String(key), JSON.stringify(value));
      $subscribers.forEach((subscriber) => subscriber(key, value));
    };

    return [typedValue, setValue] as const;
  };

  const getAtom = <K extends keyof T>(key: K) => {
    const atom = (syncedStateTree[key].atom as unknown) as RecoilState<T[K]>;
    return atom;
  };

  const initializeSyncedState = (
    set: Parameters<NonNullable<RecoilRootProps['initializeState']>>[0]['set'],
  ) => {
    (Object.keys(initialState) as Array<keyof T>).forEach((key) => {
      const storedValue = localStorage.getItem(String(key));
      if (storedValue !== null) {
        try {
          set(getAtom(key), JSON.parse(storedValue));
        } catch (e) {
          syncedState.set(key, syncedStateTree[key].default);
          set(getAtom(key), syncedStateTree[key].default);
        }
      }
    });
  };

  return {
    subscribe,
    syncedState,
    useSyncedState,
    getAtom,
    initializeSyncedState,
  };
};
