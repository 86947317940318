import { ApiResponse, HttpClient } from '../../HttpClient';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { TeamPaths, UserPaths } from '../../urls';
import {
  TeamInvoicesResponse,
  UserBillingDetailsResponse,
  ProrationResponse,
} from './types';
import { UserPayments } from './PaymentService';

export type CheckoutSession = {
  id: string;
};

export enum AvailableCheckoutDiscounts {
  Student = 'student',
}

export type CheckoutSessionPayload =
  | {
      mode: CheckoutSessionModes.Subscription;
      userId: string;
      priceId: string;
      discount?: AvailableCheckoutDiscounts;
    }
  | {
      mode: CheckoutSessionModes.Subscription;
      groupId: string;
      priceId: string;
    }
  | {
      mode: CheckoutSessionModes.Setup;
      groupId: string;
    }
  | {
      mode: CheckoutSessionModes.Setup;
      userId: string;
    };

export enum CheckoutSessionModes {
  Subscription = 'subscription',
  Setup = 'setup',
}

export interface PaymentClientProtocol {
  getGroupBillingPlans(): Promise<any>;
  getCheckoutSession(
    payload: CheckoutSessionPayload,
  ): Promise<AxiosResponse<ApiResponse<CheckoutSession>>>;
  getTeamInvoices(teamId: string): Promise<AxiosResponse<TeamInvoicesResponse>>;
}

export class PaymentClient extends HttpClient implements PaymentClientProtocol {
  async getGroupBillingPlans(): Promise<AxiosResponse<ApiResponse<any>>> {
    return this.get<AxiosResponse<ApiResponse<any>>>(TeamPaths.getBillingPlans);
  }

  async changeGroupBillingPlan(
    groupId: string,
    priceId: string,
  ): Promise<AxiosResponse<ApiResponse<any>>> {
    return this.patch<AxiosResponse<ApiResponse<any>>>(
      TeamPaths.getTeamBillingDetails(groupId),
      {
        priceId,
      },
    );
  }

  async getUserBillingPlans(): Promise<AxiosResponse<ApiResponse<any>>> {
    return this.get<AxiosResponse<ApiResponse<any>>>(UserPaths.getBillingPlans);
  }

  async getUserBillingDetails(
    userId: string,
    cancelToken?: CancelTokenSource,
  ): Promise<AxiosResponse<ApiResponse<UserBillingDetailsResponse>>> {
    const configOverride = cancelToken
      ? { cancelToken: cancelToken.token }
      : {};
    return this.get<AxiosResponse<ApiResponse<any>>>(
      UserPaths.getUserBillings(userId),
      configOverride,
    );
  }

  async getUserPayments(
    userId: string,
    cancelToken?: CancelTokenSource,
  ): Promise<AxiosResponse<ApiResponse<UserPayments>>> {
    const configOverride = cancelToken
      ? { cancelToken: cancelToken.token }
      : {};
    return this.get<AxiosResponse<ApiResponse<UserPayments>>>(
      UserPaths.getUserPayments(userId),
      configOverride,
    );
  }

  async getCheckoutSession(
    payload: CheckoutSessionPayload,
  ): Promise<AxiosResponse<ApiResponse<CheckoutSession>>> {
    return this.post<AxiosResponse<ApiResponse<CheckoutSession>>>(
      TeamPaths.getCheckoutSession,
      payload,
    );
  }

  async getTeamInvoices(
    groupId: string,
  ): Promise<AxiosResponse<TeamInvoicesResponse>> {
    return this.get<AxiosResponse<TeamInvoicesResponse>>(
      TeamPaths.getTeamInvoices(groupId),
    );
  }

  async getProration(groupId: string, priceId: string) {
    return this.get<AxiosResponse<ApiResponse<ProrationResponse>>>(
      TeamPaths.getProration(groupId, priceId),
    );
  }

  async uncancelTeamSubscription(teamId: string) {
    return this.post(TeamPaths.uncancelSubscription(teamId), {});
  }

  async cancelUsersSubscription(userId: string) {
    return this.post(UserPaths.cancelSubscription(userId), {});
  }

  async updateTeamBillingEmail(
    email: string,
    teamId: string,
  ): Promise<AxiosResponse<any>> {
    return this.patch<AxiosResponse<any>>(
      TeamPaths.getTeamBillingDetails(teamId),
      {
        email,
      },
    );
  }
  async updateUserBillingEmail(
    email: string,
    userId: string,
  ): Promise<AxiosResponse<any>> {
    return this.patch<AxiosResponse<any>>(UserPaths.billingDetails(userId), {
      email,
    });
  }
}
