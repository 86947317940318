import { useCallback, useEffect, useState } from 'react';
import { getRemoteConfigValues } from '../firebase';
import { User } from '../../features/login/UserEntity';
import { featureTogglesRemoteConfigKey } from '../../config';
import { useSyncedState } from '../../synced-state/synced-state';

export enum RemoteConfigFeatureToggles {
  Publish = 'publish',
  PublishAddFacebookIntegration = 'publish_add_facebook_integration',
  PublishAddPinterestIntegration = 'publish_add_pinterest_integration',
  FacebookInstantPublishEnabled = 'facebook_instant_publish_enabled',
  Insights = 'insights',
  ShowUnimplementedSpaceIntegrations = 'show_unimplemented_space_integrations',
}

type FeaturesState = {
  isError: boolean;
  values: Partial<Record<RemoteConfigFeatureToggles, any>>;
};
export type UseFeatureToggle = () => boolean;

const isAvailableForUser = (featureValue: any, user: User | null): boolean =>
  user != null &&
  Array.isArray(featureValue) &&
  featureValue.includes(user!._id);

export const useFeatureToggle = (
  featureName: RemoteConfigFeatureToggles,
): UseFeatureToggle => {
  const [features, setFeatures] = useState<FeaturesState>({
    isError: false,
    values: {},
  });
  const [user] = useSyncedState('user');
  useEffect(() => {
    getRemoteConfigValues()
      .then((config) => {
        setFeatures({
          isError: false,
          values: config[featureTogglesRemoteConfigKey],
        });
      })
      .catch(() =>
        setFeatures((prev) => ({
          isError: true,
          values: prev.values,
        })),
      );
  }, []);

  return useCallback(() => {
    if (process.env?.REACT_APP_ENABLE_ALL_FEATURES === 'true') {
      return true;
    }
    if (features.isError) {
      return false;
    }
    const value = features.values[featureName];

    if (Object.values(RemoteConfigFeatureToggles).includes(featureName)) {
      return isAvailableForUser(value, user);
    }

    return Boolean(value);
  }, [features, user]);
};
