import React, { FunctionComponent, Ref } from 'react';
import * as yup from 'yup';
import { useIntl } from '../../../../utils/hooks/useIntl';
import { Field, Form, Formik } from 'formik';
import { DependencyContainer } from '../../../../DependencyContainer';
import { useSetRecoilState } from 'recoil';
import {
  UserTeam,
  userTeamsAtom,
  Executable,
  ExecutableStatus,
} from '../../../../state';
import Input, { InputVariants } from '../../../../components/Input/Input';

export type CreateTeamFormProps = {
  inputRef?: Ref<HTMLInputElement>;
  retrieveHandleSubmit?: (handleSubmit: any) => void;
  onSuccess?: (newTeam: UserTeam) => void;
  onSubmit: (executable: Executable<any>) => void;
  onError?: () => void;
};

const service = new DependencyContainer().teamService;

const CreateTeamForm: FunctionComponent<CreateTeamFormProps> = ({
  inputRef,
  retrieveHandleSubmit,
  onSuccess,
  onSubmit,
  onError,
}) => {
  const { formatMessage } = useIntl();
  const setTeams = useSetRecoilState(userTeamsAtom);

  const addTeam = (name: string) =>
    service.createTeam(name).then((response) => {
      const newTeam: UserTeam = response.data.data;
      setTeams((currentTeams: UserTeam[] | null) => {
        return [...(currentTeams || []), newTeam];
      });
      return newTeam;
    });

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .trim()
      .min(
        1,
        formatMessage(
          {
            id: 'userTeamsCreateTeamMinLengthError',
          },
          { minLength: 1 },
        ),
      )
      .required(
        formatMessage(
          {
            id: 'userTeamsCreateTeamModalInputError',
          },
          { minLength: 1 },
        ),
      ),
  });

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        name: '',
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (onSubmit) {
          onSubmit({
            execute: () => addTeam(values.name.trim()),
            status: ExecutableStatus.NotExecuted,
          });
        }
      }}
    >
      {({ handleSubmit, errors, values }) => {
        retrieveHandleSubmit && retrieveHandleSubmit(handleSubmit);
        return (
          <Form>
            <Field
              as={Input}
              label={formatMessage({
                id: 'userTeamsCreateTeamModalInputPlaceholder',
              })}
              innerRef={inputRef}
              name="name"
              type="text"
              variant={InputVariants.Outlined}
              value={values.name}
              errorMessage={errors.name}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateTeamForm;
