import {
  Storyboard,
  CloneStoryboardTarget,
} from './features/marketplace/types';
import { UserTeam } from './state';

export enum WebviewMessages {
  ApplicationLoaded = 'APPLICATION_LOADED',
  StoryboardCloneSuccess = 'STORY_BOARD_CLONE_SUCCESS',
  StoryboardCloneFailure = 'STORY_BOARD_CLONE_FAILURE',
  TeamCreateSuccess = 'TEAM_CREATE_SUCCESS',
  TeamCreateFailure = 'TEAM_CREATE_FAILURE',
  ModalClosed = 'MODAL_CLOSED',
  TeamPaymentSuccess = 'TEAM_PAYMENT_SUCCESS',
  TeamPaymentFailure = 'TEAM_PAYMENT_FAILURE',
  AndroidTest = 'ANDROID_TEST_EVENT',
  ShowPaywall = 'SHOW_PAYWALL',
}

type ApplicationLoadedMessage = {
  name: WebviewMessages.ApplicationLoaded;
  payload: {};
};
type AndroidTestMessage = {
  name: WebviewMessages.AndroidTest;
  payload: {
    param: string;
  };
};
type StoryboardCloneSuccessMessage = {
  name: WebviewMessages.StoryboardCloneSuccess;
  payload: {
    storyboard: Storyboard;
    spaceId: string;
    target: CloneStoryboardTarget;
  };
};
type StoryboardCloneFailureMessage = {
  name: WebviewMessages.StoryboardCloneFailure;
  payload: {
    error: any;
  };
};
type TeamCreateSuccessMessage = {
  name: WebviewMessages.TeamCreateSuccess;
  payload: {
    team: UserTeam;
  };
};
type TeamCreateFailureMessage = {
  name: WebviewMessages.TeamCreateFailure;
  payload: {
    error: any;
  };
};
type TeamPaymentSuccess = {
  name: WebviewMessages.TeamPaymentSuccess;
  payload: {
    teamId: string;
  };
};
type TeamPaymentFailure = {
  name: WebviewMessages.TeamPaymentFailure;
  payload: {
    teamId: string;
  };
};
type ModalClosedMessage = {
  name: WebviewMessages.ModalClosed;
  payload: {
    source: ModalClosedSources;
  };
};

type ShowPaywall = {
  name: WebviewMessages.ShowPaywall;
  payload: {};
};

export enum ModalClosedSources {
  TeamWizardSummaryModal = 'TeamWizardSummaryModal',
  PaymentConfirmationModal = 'PaymentConfirmationModal',
  TeamWizardModal = 'TeamWizardModal',
}

export type WebviewMessage =
  | ApplicationLoadedMessage
  | StoryboardCloneSuccessMessage
  | StoryboardCloneFailureMessage
  | TeamCreateSuccessMessage
  | TeamCreateFailureMessage
  | ModalClosedMessage
  | TeamPaymentSuccess
  | TeamPaymentFailure
  | AndroidTestMessage
  | ShowPaywall;

export enum WebviewMessageHandlerTypes {
  Default = 'showMessageInNative',
}

export type WebviewMessageHandler = {
  postMessage: (message: string) => void;
};

export const webviewMessageDispatcher = (
  handlerName: WebviewMessageHandlerTypes = WebviewMessageHandlerTypes.Default,
) => {
  const dispatch = (message: WebviewMessage) => {
    const handlers = getHandlers();
    Object.values(handlers).map(
      (handler) => handler && handler.postMessage(JSON.stringify(message)),
    );
  };

  const getHandlerForIOS = (
    name: WebviewMessageHandlerTypes,
  ): WebviewMessageHandler | null => {
    const handlers =
      window.webkit && window.webkit.messageHandlers
        ? window.webkit.messageHandlers
        : null;
    if (!handlers) {
      return null;
    }
    return handlers[name];
  };

  const getHandlerForAndroid = () => {
    if (!window.Android) {
      return null;
    }
    return window.Android;
  };

  const getHandlers = () => {
    return {
      android: getHandlerForAndroid(),
      ios: getHandlerForIOS(handlerName),
    };
  };

  return {
    dispatch,
    getHandlers,
  };
};

export type UseWebviewMessageDispatcherType = {
  dispatch: (message: WebviewMessage) => void;
};

export const useWebviewMessageDispatcher = (
  handlerName: WebviewMessageHandlerTypes = WebviewMessageHandlerTypes.Default,
): UseWebviewMessageDispatcherType => {
  const dispatcher = webviewMessageDispatcher(handlerName);
  return {
    dispatch: dispatcher.dispatch,
  };
};
