import React, { FunctionComponent, useState } from 'react';
import * as yup from 'yup';
import SettingInput from '../SettingInput/SettingInput';
import { DependencyContainer } from '../../../../DependencyContainer';
import { Settings } from '../../SettingsService';
import AvatarChanger from '../AvatarChanger/AvatarChanger';
import { useMedia } from 'react-media';
import { GLOBAL_MEDIA_QUERIES } from '../../../../config';
import { useIntl } from '../../../../utils/hooks/useIntl';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import PublicProfileUpdater, {
  PublicProfileUpdaterStyles,
} from '../../../../components/PublicProfileUpdater/PublicProfileUpdater';
import { useSyncedState } from '../../../../synced-state/synced-state';

export const mockStore = {
  password: 'temporaryPassword',
};

const service = new DependencyContainer().settingsService;

const stylePublicProfileUpdater = (current: PublicProfileUpdaterStyles) => ({
  ...current,
  root: `${current.root} ProfileInformation__fields ProfileInformation__fields--public-profile`,
});

const ProfileInformation: FunctionComponent = () => {
  const [initialSettings, setInitialSettings] = useState<Settings>(
    service.getCachedSettings(),
  );
  const match = useMedia({ queries: GLOBAL_MEDIA_QUERIES });
  const { formatMessage } = useIntl();
  const [user] = useSyncedState('user');

  return (
    <div className="ProfileInformation">
      <h3 className="ProfileInformation__section-title large-title">
        <FormattedMessage id="userProfileAccountSectionTitle" />
      </h3>

      {!match.small && <AvatarChanger />}

      <div className="ProfileInformation__fields">
        <SettingInput
          shouldSaveOnBlur
          initialValue={initialSettings.fullName}
          name="fullName"
          label={formatMessage({
            id: 'userProfileNameInput',
          })}
          validationSchema={yup.object().shape({
            fullName: yup
              .string()
              .trim()
              .min(
                1,
                formatMessage(
                  {
                    id: 'userNameMinLengthError',
                  },
                  { minLength: 1 },
                ),
              )
              .required(
                formatMessage({
                  id: 'userProfileNameInputError',
                }),
              ),
          })}
          onAfterSubmit={() => setInitialSettings(service.getCachedSettings())}
        />
        <SettingInput
          shouldSaveOnBlur
          initialValue={initialSettings.email}
          name="email"
          label={formatMessage({
            id: 'userProfileEmailInput',
          })}
          caption={formatMessage({ id: 'userProfileNameInputCaption' })}
          type="email"
          validationSchema={yup.object().shape({
            email: yup
              .string()
              .email(
                formatMessage({
                  id: 'userProfileEmailInputError',
                }),
              )
              .required(
                formatMessage({
                  id: 'userProfileEmailInputErrorRequired',
                }),
              ),
          })}
          onAfterSubmit={() => setInitialSettings(service.getCachedSettings())}
        />
        <SettingInput
          initialValue={mockStore.password}
          name="password"
          label={formatMessage({
            id: 'userProfilePasswordInput',
          })}
          type="password"
          validationSchema={yup.object().shape({
            password: yup
              .string()
              .required(
                formatMessage({
                  id: 'userProfilePasswordInputErrorRequired',
                }),
              )
              .min(
                8,
                formatMessage({
                  id: 'userProfilePasswordInputError',
                }),
              )
              .test(
                'empty-check',
                formatMessage({
                  id: 'userProfilePasswordCannotHaveSpaces',
                }),
                (password) => !/\s/.test(password as string),
              ),
          })}
        />
      </div>

      <h3 className="ProfileInformation__section-title large-title">
        <FormattedMessage id="userProfilePublicProfileSectionTitle" />
      </h3>
      <p className="ProfileInformation__section-subtitle small-body">
        <FormattedMessage id="userProfilePublicProfileSectionSubtitle" />
      </p>
      {user && (
        <PublicProfileUpdater
          styles={stylePublicProfileUpdater}
          type="user"
          typeId={user._id}
        />
      )}
    </div>
  );
};

export default ProfileInformation;
