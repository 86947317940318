import { DependencyContainer } from '../../DependencyContainer';
import { syncedState } from '../../synced-state/synced-state';

export type Setting = 'fullName' | 'email' | 'avatarUrl' | 'password';

export type Settings = { [key in Setting]: string };

type SettingToUpdater = {
  [key in Setting]: (newValue: string) => Promise<void>;
};

export class SettingsService {
  private readonly settingToUpdaterMap: SettingToUpdater;

  constructor(private readonly factory: DependencyContainer) {
    this.settingToUpdaterMap = {
      fullName: (newValue: string) => this.updateFullName(newValue),
      avatarUrl: (newValue: string) => this.updateAvatar(newValue),
      email: (newValue: string) => this.updateEmail(newValue),
      password: (newValue: string) => this.updatePassword(newValue),
    };
  }

  getCachedSettings(): Settings {
    const user = syncedState.get('user');
    if (!user) {
      return {
        fullName: '',
        email: '',
        avatarUrl: '',
        password: '************',
      };
    }

    return {
      fullName: user.fullName || '',
      email: user.email,
      avatarUrl: user.avatarUrl,
      password: '************',
    };
  }

  async cacheSetting(setting: Setting, value: string) {
    const cachedUser = syncedState.get('user');
    if (cachedUser) {
      syncedState.set('user', {
        ...cachedUser,
        [setting]: value,
      });
    }
  }

  async countPersonalIntegrations(userId: string): Promise<number> {
    // As per WEB2-1600 we don't want to take care about limitations
    return Promise.resolve(0);
  }

  private async updateFullName(newValue: string) {
    const cachedUser = await this.factory.authenticationService.getCachedUser();
    const id = cachedUser._id;
    try {
      await this.factory.settingsClient.changeUserData(
        id,
        'fullName',
        newValue,
      );
      await this.cacheSetting('fullName', newValue);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private async updateAvatar(newValue: string) {
    const cachedUser = syncedState.get('user');
    if (cachedUser) {
      const id = cachedUser._id;
      try {
        await this.factory.settingsClient.changeUserData(
          id,
          'avatarUrl',
          newValue,
        );
        await this.cacheSetting('avatarUrl', newValue);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    }
  }

  private async updateEmail(newValue: string) {
    try {
      await this.factory.settingsClient.changeEmail(newValue);
      await this.cacheSetting('email', newValue);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private async updatePassword(newValue: string) {
    const cachedUser = await this.factory.authenticationService.getCachedUser();
    const id = cachedUser._id;
    try {
      await this.factory.settingsClient.changePassword(id, newValue);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async set(setting: Setting, newValue: string) {
    return this.settingToUpdaterMap[setting](newValue);
  }
}
