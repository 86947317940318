import { User } from './UserEntity';
import { ApiResponse, HttpClient } from '../../HttpClient';
import { UserPaths } from '../../urls';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import userDefaultAvatar from '../../assets/svg/user-default-avatar.svg';

export interface AuthenticationClientProtocol {
  makeSignIn(email: string, password: string): Promise<User>;
}

export class AuthenticationClient extends HttpClient
  implements AuthenticationClientProtocol {
  async makeSignIn(email: string, password: string): Promise<User> {
    try {
      const request = await this.post<AxiosResponse<ApiResponse<User>>>(
        UserPaths.login,
        {
          email,
          password,
          type: 'web',
        },
      );
      const data = request.data.data;
      const user: User = {
        _id: data._id,
        email: data.email,
        authToken: data.authToken,
        fullName: data.fullName,
        avatarUrl: data.avatarUrl || userDefaultAvatar,
        // @TODO: Fetch correct data from API
        publicProfile: {
          city: '',
          country: '',
          occupation: '',
          website: 'https://unum.la',
        },
      };
      return Promise.resolve(user);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  async verifyJWT(
    token: string,
  ): Promise<AxiosResponse<ApiResponse<{ email: string; authToken: string }>>> {
    return this.post(UserPaths.verifyJWT, { token });
  }

  async getUserByEmail(
    email: string,
    configOverwrite?: AxiosRequestConfig,
  ): Promise<User | null> {
    try {
      const request = await this.get<AxiosResponse<ApiResponse<User>>>(
        UserPaths.getUserByEmail(email),
        configOverwrite,
      );
      const data = request.data.data;
      const user: User = {
        _id: data._id,
        email: data.email,
        authToken: data.authToken,
        fullName: data.fullName,
        avatarUrl: data.avatarUrl || userDefaultAvatar,
      };
      return Promise.resolve(user);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getAuthFromJWT(oauthToken:string): Promise<AxiosResponse<ApiResponse<User>>> {
    return this.post(UserPaths.authWithJWT, { oauthToken });
  }
}
