import React, { FunctionComponent, useEffect, useState } from 'react';
import SettingInput from '../../features/user/components/SettingInput/SettingInput';
import {
  PublicProfileFields,
  PublicProfileFieldsNames,
  PublicProfileType,
} from '../../features/publicProfile/types';
import { useIntl } from '../../utils/hooks/useIntl';
import * as yup from 'yup';
import { StylableComponent, useStyles } from '../../utils/hooks/useStyles';
import TogglerRow from '../Toggler/TogglerRow';

import { DependencyContainer } from '../../DependencyContainer';
import Loadable, { LoadableStyles } from '../Loadable/Loadable';
import { InputType } from '../Input/Input';

type PublicProfileUpdaterFieldParams = {
  name: PublicProfileFieldsNames;
  label: string;
  validation?: any;
  type?: InputType;
  params?: any;
};

export type PublicProfileUpdaterProps = {
  onAfterSubmit?: (values: { [key: string]: string }) => void;
  onError?: (error: any) => void;
  type: PublicProfileType;
  typeId: string;
};

export type PublicProfileUpdaterFieldProps = {
  field: PublicProfileUpdaterFieldParams;
  value: any;
  onAfterSubmit?: (values: { [key: string]: string }) => void;
  onError?: (error: any) => void;
  type: PublicProfileType;
  typeId: string;
};

export type PublicProfileUpdaterStyles = {
  root: string;
  adjacentFields: string;
};

const { publicProfileService } = new DependencyContainer();

const PublicProfileUpdaterField: FunctionComponent<PublicProfileUpdaterFieldProps> = ({
  type,
  value,
  field,
  onError,
  onAfterSubmit,
  typeId,
}) => {
  const { formatMessage } = useIntl();
  const onSubmit = async (values: any, actions?: any) => {
    const value = values[field.name];
    try {
      await publicProfileService.updatePublicProfile(type, typeId, {
        [field.name]: value,
      });
    } catch (error) {
      if (actions) {
        const errorMessage =
          error && error.data && error.data.data && error.data.data.message
            ? error.data.data.message
            : formatMessage(
                { id: 'userProfileGenericError' },
                { field: field.label.toLowerCase() },
              );
        actions.setFieldError(field.name, errorMessage);
      }
      onError && onError(error);
    }
  };

  return (
    <>
      {field.type === 'checkbox' ? (
        <TogglerRow
          checked={Boolean(value)}
          name={field.name}
          {...field.params}
          onToggled={(name, value) => onSubmit({ [name]: value })}
        />
      ) : (
        <SettingInput
          enableReinitialize
          shouldSaveOnBlur
          initialValue={value}
          name={field.name}
          label={field.label}
          onAfterSubmit={onAfterSubmit}
          onSubmit={onSubmit}
          onError={onError}
          validationSchema={
            field.validation
              ? yup.object().shape({
                  [field.name]: field.validation,
                })
              : undefined
          }
        />
      )}
    </>
  );
};

const PublicProfileUpdater: StylableComponent<
  PublicProfileUpdaterProps,
  PublicProfileUpdaterStyles
> = ({ type, typeId, onAfterSubmit, onError, styles }) => {
  const [initialValues, setInitialValues] = useState<PublicProfileFields>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { formatMessage } = useIntl();
  const classes = useStyles<PublicProfileUpdaterStyles>(
    {
      root: 'PublicProfileUpdater',
      adjacentFields: 'PublicProfileUpdater__adjacent-fields',
    },
    styles,
  );

  const availableFields: Array<PublicProfileUpdaterFieldParams> = [
    {
      name: 'city',
      label: formatMessage({ id: 'userProfileCity' }),
      type: 'text',
    },
    {
      name: 'country',
      label: formatMessage({ id: 'userProfileCountry' }),
      type: 'text',
    },
    {
      name: 'occupation',
      label: formatMessage({ id: 'userProfileOccupation' }),
      type: 'text',
    },
    {
      name: 'website',
      label: formatMessage({ id: 'userProfileWebsite' }),
      type: 'text',
      validation: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          formatMessage({ id: 'userProfileWebsiteInputError' }),
        ),
    },
  ];

  const propsToPassIntoField = {
    type,
    typeId,
    onAfterSubmit,
    onError,
  };
  const adjacentFieldsNames = ['city', 'country'];
  const notAdjacentFieldsCollection = availableFields.filter(
    ({ name }) => !adjacentFieldsNames.includes(name),
  );
  const adjacentFieldsCollection = availableFields.filter(({ name }) =>
    adjacentFieldsNames.includes(name),
  );

  useEffect(() => {
    setLoading(true);
    publicProfileService
      .getPublicProfileByType(type, typeId)
      .then((response) => {
        setInitialValues(response);
        setLoading(false);
        setError(undefined);
      })
      .catch((error) => {
        setLoading(false);
        if (error && error.response && error.response.status === 404) {
          publicProfileService
            .createPublicProfile(type, typeId, {})
            .catch((error) => console.error(error));
          setInitialValues({
            city: '',
            country: '',
            occupation: '',
            website: '',
            published: false,
          });
        } else {
          setError(error);
        }
      });
  }, [type, typeId]);

  return (
    <Loadable
      styles={(current: LoadableStyles) => ({
        ...current,
        root: `${current.root} ${classes.root}`,
      })}
      loading={loading}
      hideContent
      isError={Boolean(error)}
    >
      {initialValues && (
        <>
          <PublicProfileUpdaterField
            field={{
              name: 'published',
              label: formatMessage({ id: 'userProfilePublished' }),
              type: 'checkbox',
              params: {
                outerLabel: formatMessage({ id: 'userProfilePublished' }),
                labelChecked: formatMessage(
                  { id: 'userProfilePublishedTrue' },
                  {
                    strong: (...chunks: any[]) => (
                      <strong key="userProfilePublished">{chunks}</strong>
                    ),
                  },
                ),
                labelUnchecked: formatMessage(
                  { id: 'userProfilePublishedFalse' },
                  {
                    strong: (...chunks: any[]) => (
                      <strong key="userProfileNotPublished">{chunks}</strong>
                    ),
                  },
                ),
              },
            }}
            value={initialValues.published}
            {...propsToPassIntoField}
          />
          <div className={classes.adjacentFields}>
            {adjacentFieldsCollection.map((field) => (
              <PublicProfileUpdaterField
                key={field.name}
                field={field}
                value={initialValues[field.name]}
                {...propsToPassIntoField}
              />
            ))}
          </div>
          {notAdjacentFieldsCollection.map((field) => (
            <PublicProfileUpdaterField
              key={field.name}
              field={field}
              value={initialValues[field.name]}
              {...propsToPassIntoField}
            />
          ))}
        </>
      )}
    </Loadable>
  );
};

export default PublicProfileUpdater;
