import {
  linkedInAuthorizationRedirectUri,
  instagramAuthorizationRedirectUri,
  facebookAuthorizationRedirectUri,
  pinterestAuthorizationRedirectUri,
} from '../../config';
import { hasProp } from '../../utils/hasProp';
import { includes } from '../../utils/includes';
import {
  IntegrationsTypes,
  SocialAccount,
  SocialAccountConnectionStrategies,
  SocialAccountReconnectionStrategies,
  SocialAccountTypes,
} from './types';
import { IntegrationPaths, PermissionScopes } from '../../urls';
import { isDefined } from '../../utils/isDefined';

// @TODO [WEB2-1811] move from defining connection/reconnection strategy to defining type of process + target integration
// eg. {
//   operation: 'add' | 'reconnect'
//   type: 'facebook:page',
//   payload?: { socialAccountIdToReconnect?: string },
// };

export enum OAuthFlows {
  Implicit = 'implicit',
  Code = 'code',
}

export const integrationsConfig = {
  'facebook:page': {
    authFlow: OAuthFlows.Implicit,
    type: SocialAccountTypes.Facebook,
    tokenType: 'page',
    redirectUri: facebookAuthorizationRedirectUri,
    authorizationRedirectUrl: IntegrationPaths.getFacebookAuthorizationUrl(
      facebookAuthorizationRedirectUri,
      PermissionScopes.FacebookPage,
    ),
    connectionStrategy: SocialAccountConnectionStrategies.AddFacebookPage,
    reconnectionStrategy:
      SocialAccountReconnectionStrategies.ReconnectFacebookPage,
    fetchExternalAccounts: 'getFacebookPages',
  },
  'facebook:personal': {
    authFlow: OAuthFlows.Implicit,
    type: SocialAccountTypes.Facebook,
    tokenType: 'personal',
    redirectUri: facebookAuthorizationRedirectUri,
    authorizationRedirectUrl: IntegrationPaths.getFacebookAuthorizationUrl(
      facebookAuthorizationRedirectUri,
      PermissionScopes.FacebookProfile,
    ),
    connectionStrategy: SocialAccountConnectionStrategies.AddFacebookProfile,
    reconnectionStrategy:
      SocialAccountReconnectionStrategies.ReconnectFacebookProfile,
    fetchExternalAccounts: 'getFacebookPersonalProfile',
  },
  'instagram:business': {
    authFlow: OAuthFlows.Implicit,
    type: SocialAccountTypes.Instagram,
    tokenType: 'business',
    redirectUri: facebookAuthorizationRedirectUri,
    authorizationRedirectUrl: IntegrationPaths.getFacebookAuthorizationUrl(
      facebookAuthorizationRedirectUri,
      PermissionScopes.InstagramBusinessAccount,
    ),
    connectionStrategy:
      SocialAccountConnectionStrategies.AddInstagramBusinessAccount,
    reconnectionStrategy:
      SocialAccountReconnectionStrategies.ReconnectInstagramBusinessAccount,
    fetchExternalAccounts: 'getInstagramBusinessAccounts',
  },
  linkedin: {
    authFlow: OAuthFlows.Code,
    type: SocialAccountTypes.Linkedin,
    // @TODO [WEB2-1452] temp solution - change when linkedin setup is fixed
    redirectUri: linkedInAuthorizationRedirectUri.replace(
      'integration',
      'callback',
    ),
    authorizationRedirectUrl: IntegrationPaths.getLinkedInAuthorizationUrl(
      linkedInAuthorizationRedirectUri,
    ),
    connectionStrategy: SocialAccountConnectionStrategies.AddLinkedInProfile,
    reconnectionStrategy:
      SocialAccountReconnectionStrategies.ReconnectLinkedInProfile,
  },
  'instagram:basic_display': {
    authFlow: OAuthFlows.Code,
    type: SocialAccountTypes.Instagram,
    tokenType: 'basic_display',
    redirectUri: instagramAuthorizationRedirectUri,
    authorizationRedirectUrl: IntegrationPaths.getInstagramAuthorizationUrl(
      instagramAuthorizationRedirectUri,
    ),
    connectionStrategy:
      SocialAccountConnectionStrategies.AddInstagramBasicDisplay,
    reconnectionStrategy:
      SocialAccountReconnectionStrategies.ReconnectInstagramBasicDisplay,
    withSpaceConnectionStrategy:
      SocialAccountConnectionStrategies.AddInstagramBasicDisplayToSpace,
  },
  pinterest: {
    authFlow: OAuthFlows.Code,
    type: SocialAccountTypes.Pinterest,
    redirectUri: pinterestAuthorizationRedirectUri,
    authorizationRedirectUrl: IntegrationPaths.getPinterestAuthorizationUrl(
      pinterestAuthorizationRedirectUri,
    ),
    connectionStrategy: SocialAccountConnectionStrategies.AddPinterestProfile,
    reconnectionStrategy:
      SocialAccountReconnectionStrategies.ReconnectPinterestProfile,
  },
} as const;

// @TODO [WEB2-1812] create an api like: fromIntegrationConfig.get(field).by(otherField).for(value)
export const getIntegrationTypeByConnectionStrategy = (
  strategy: SocialAccountConnectionStrategies,
) => {
  const foundIndex = Object.values(integrationsConfig).findIndex(
    (config) =>
      config.connectionStrategy === strategy ||
      ('withSpaceConnectionStrategy' in config &&
        config.withSpaceConnectionStrategy === strategy),
  );
  return foundIndex !== -1
    ? (Object.keys(integrationsConfig)[foundIndex] as IntegrationsTypes)
    : undefined;
};

export const getIntegrationTypeByReconnectionStrategy = (
  strategy: SocialAccountReconnectionStrategies,
) => {
  const foundIndex = Object.values(integrationsConfig).findIndex(
    (config) => config.reconnectionStrategy === strategy,
  );
  return foundIndex !== -1
    ? (Object.keys(integrationsConfig)[foundIndex] as IntegrationsTypes)
    : undefined;
};

export const getExternalAccountsFetcherByConnectionStrategy = (
  strategy: SocialAccountConnectionStrategies,
) => {
  if (!includes(facebookConnectionStrategies, strategy)) {
    return;
  }
  const found = Object.values(integrationsConfig).find(
    hasProp('connectionStrategy', strategy),
  );
  return found?.fetchExternalAccounts;
};

export const getExternalAccountsFetcherByReconnectionStrategy = (
  strategy: SocialAccountReconnectionStrategies,
) => {
  if (!includes(facebookReconnectionStrategies, strategy)) {
    return;
  }
  const found = Object.values(integrationsConfig).find(
    hasProp('reconnectionStrategy', strategy),
  );
  return found?.fetchExternalAccounts;
};

export const getIntegrationsTypeForAccount = (
  account: SocialAccount,
): IntegrationsTypes => {
  switch (account.type) {
    case SocialAccountTypes.Facebook:
      return account.properties.tokenType === 'page'
        ? 'facebook:page'
        : 'facebook:personal';
    case SocialAccountTypes.Instagram:
      return account.properties.tokenType === 'business'
        ? 'instagram:business'
        : 'instagram:basic_display';
    case SocialAccountTypes.Linkedin:
      return 'linkedin';
    case SocialAccountTypes.Pinterest:
      return 'pinterest';
  }
};

export const oAuthConnectionStrategies = Object.values(integrationsConfig)
  .filter(hasProp('authFlow', 'code'))
  .map((v) => v.connectionStrategy);

export const facebookConnectionStrategies = Object.values(integrationsConfig)
  .filter(hasProp('authFlow', 'implicit'))
  .map((v) => v.connectionStrategy);

export const oAuthReconnectionStrategies = Object.values(integrationsConfig)
  .filter(hasProp('authFlow', 'code'))
  .map((v) => v.reconnectionStrategy);

export const facebookReconnectionStrategies = Object.values(integrationsConfig)
  .filter(hasProp('authFlow', 'implicit'))
  .map((v) => v.reconnectionStrategy);

export const withSpaceConnectionStrategies = Object.values(integrationsConfig)
  .map((v) =>
    'withSpaceConnectionStrategy' in v
      ? v.withSpaceConnectionStrategy
      : undefined,
  )
  .filter(isDefined);
