import React from 'react';
import { StylableComponent, useStyles } from '../../utils/hooks/useStyles';
import { omit } from '../../utils/omit';
import userDefaultAvatar from '../../assets/svg/user-default-avatar.svg';
import teamDefaultAvatar from '../../assets/svg/team-default-avatar.svg';

export enum UserBadgeDefaults {
  Initials = 'initials',
  User = 'user',
  Team = 'team',
}

export type UserBadgeProps = {
  avatar?: string;
  displayName?: string;
  default?: UserBadgeDefaults;
} & JSX.IntrinsicElements['img'];

export type UserBadgeStyles = {
  root: string;
  avatar: string;
};

const UserBadge: StylableComponent<UserBadgeProps, UserBadgeStyles> = (
  props,
) => {
  const defaultAvatarType = props.default || UserBadgeDefaults.Initials;
  const displayName = props.displayName || 'User';
  const initials = displayName
    .split(' ')
    .map((n) => n[0])
    .join('');

  const classes = useStyles(
    {
      root: 'UserBadge',
      avatar: 'UserBadge__avatar',
    },
    props.styles,
  );
  const propsToPass = omit(props, 'displayName', 'avatar', 'styles');

  const mapDefaultToElement = {
    [UserBadgeDefaults.Initials]: initials,
    [UserBadgeDefaults.User]: (
      <img
        className={classes.avatar}
        src={userDefaultAvatar}
        title={displayName}
        alt={initials}
      />
    ),
    [UserBadgeDefaults.Team]: (
      <img
        className={classes.avatar}
        src={teamDefaultAvatar}
        title={displayName}
        alt={initials}
      />
    ),
  };

  const renderPicture = () => {
    if (props.avatar) {
      return (
        <img
          className={classes.avatar}
          src={props.avatar}
          title={displayName}
          alt={initials}
        />
      );
    }
    return mapDefaultToElement[defaultAvatarType];
  };

  return (
    <div className={classes.root} onClick={props.onClick} {...propsToPass}>
      {renderPicture()}
    </div>
  );
};

export default UserBadge;
