import React, { FunctionComponent, useState } from 'react';
import { Formik, Form, FieldArray, Field } from 'formik';
import * as Yup from 'yup';
import { useIntl } from '../../../../utils/hooks/useIntl';
import { DependencyContainer } from '../../../../DependencyContainer';
import Input, {
  InputVariants,
  InputStyles,
} from '../../../../components/Input/Input';
import Button, {
  ButtonVariant,
  ButtonSize,
} from '../../../../components/Button/Button';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import { Executable, ExecutableStatus } from '../../../../state';

export type InviteMembersFormProps = {
  teamId?: string;
  retrieveHandleSubmit?: (handleSubmit: any) => void;
  onSuccess?: (emails: string[]) => void;
  onError?: () => void;
  onSubmit?: (executables: Executable<string>[]) => void;
};

const teamService = new DependencyContainer().teamService;

const addBottomMargin = (current: InputStyles) => ({
  ...current,
  root: `${current.root} m-b-l`,
});

const InviteMembersForm: FunctionComponent<InviteMembersFormProps> = ({
  teamId,
  retrieveHandleSubmit,
  onSuccess,
  onError,
  onSubmit,
}) => {
  const { formatMessage } = useIntl();
  const [submittingInProgress, setSubmittingInProgress] = useState(false);

  return (
    <Formik
      initialValues={{ emails: [''] }}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        emails: Yup.array().of(
          Yup.string()
            .trim()
            .required(formatMessage({ id: 'errorsFieldRequired' }))
            .email(formatMessage({ id: 'errorsEmailInvalid' })),
        ),
      })}
      onSubmit={(values) => {
        if (submittingInProgress) {
          return;
        }

        const getPromises = (teamId: string) =>
          values.emails.map((email) =>
            teamService.sendInvitation(teamId, email.trim()),
          );

        setSubmittingInProgress(true);

        if (onSubmit) {
          onSubmit(makeExecutables(values.emails));
          return;
        }
        if (!teamId) {
          return;
        }
        Promise.all(getPromises(teamId))
          .then(() => {
            setSubmittingInProgress(false);
            onSuccess && onSuccess(values.emails);
          })
          .catch(() => {
            setSubmittingInProgress(false);
            onError && onError();
          });
      }}
    >
      {({ handleSubmit, errors, values }) => {
        retrieveHandleSubmit && retrieveHandleSubmit(handleSubmit);
        return (
          <Form>
            <FieldArray name="emails">
              {({ insert }) => (
                <>
                  {values.emails && values.emails.length
                    ? values.emails.map((email, index) => (
                        <Field
                          key={index}
                          as={Input}
                          variant={InputVariants.Outlined}
                          label={formatMessage({ id: 'emailAddress' })}
                          name={`emails.${index}`}
                          errorMessage={
                            errors && errors.emails && errors.emails[index]
                          }
                          styles={addBottomMargin}
                        />
                      ))
                    : null}
                  <Button
                    type="button"
                    variant={ButtonVariant.Secondary}
                    size={ButtonSize.Small}
                    onClick={() => insert(values.emails.length, '')}
                  >
                    <FormattedMessage id="teamAddAnother" />
                  </Button>
                </>
              )}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
};

const makeExecutables = (emails: string[]): Executable<string>[] =>
  emails.map((email) => ({
    execute: (teamId: string) =>
      teamService.sendInvitation(teamId, email.trim()),
    status: ExecutableStatus.NotExecuted,
  }));

export default InviteMembersForm;
