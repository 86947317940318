import React, { FunctionComponent } from 'react';
import Modal from '../../../../components/Modal/Modal';
import ModalBody from '../../../../components/Modal/ModalBody';
import ModalFooter from '../../../../components/Modal/ModalFooter';
import Button, {
  ButtonVariant,
  ButtonSize,
} from '../../../../components/Button/Button';
import { TeamWizardAction, TeamWizardActions } from '../../../../state';
import FormattedMessage from '../../../../utils/components/FormattedMessage';
import ButtonGroup, {
  ButtonGroupAlignment,
} from '../../../../components/ButtonGroup/ButtonGroup';
import { CircularArrowIcon } from '../../../../components/Icons/Icons';
import { Translation } from '../../../../translations/types';

type TeamWizardActionErrorProps = {
  currentAction: TeamWizardAction;
  isOpen: boolean;
  onRetryClick: () => void;
};

const actionToMessageMap: { [K in TeamWizardActions]: keyof Translation } = {
  [TeamWizardActions.CreateTeam]: 'teamWizardCreateTeamErrorMessage',
  [TeamWizardActions.CloneSpaces]: 'teamWizardCloneSpacesErrorMessage',
  [TeamWizardActions.InviteMembers]: 'teamWizardInviteMembersErrorMessage',
};

const TeamWizardActionError: FunctionComponent<TeamWizardActionErrorProps> = ({
  currentAction,
  isOpen,
  onRetryClick,
}) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody
        styles={(current) => ({
          root: current.root + ' p-l',
        })}
      >
        <h1 className="m-t-0 extra-large-title">
          <FormattedMessage id="teamWizardErrorAlertHeader" />
        </h1>
        <FormattedMessage
          id={
            actionToMessageMap[currentAction.name] || 'errorsSomethingWentWrong'
          }
        />
        <br />
        <FormattedMessage id="pleaseTryAgain" />
      </ModalBody>
      <ModalFooter
        styles={(current) => ({
          root: current.root + ' p-m',
        })}
      >
        <ButtonGroup align={ButtonGroupAlignment.Right}>
          <Button
            type="button"
            onClick={onRetryClick}
            variant={ButtonVariant.Danger}
            size={ButtonSize.Large}
            styles={(current) => ({
              ...current,
              root: current.root + ' p-x-m',
            })}
          >
            <span className="m-r-s">
              <CircularArrowIcon />
            </span>
            <FormattedMessage id="tryAgain" />
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default TeamWizardActionError;
