import { useEffect, DependencyList } from 'react';
import {
  TrackingEvent,
  activityTracker,
  EventChannel,
} from './activityTracker';
import * as amplitude from 'amplitude-js';
import { AmplitudeClient } from 'amplitude-js';
import { buildEventName, isEventOnChannel } from './eventUtils';
import { useRecoilValue } from 'recoil';
import { userTelemetrySelector, UserTelemetry } from '../../state';
import { capitalizeFirstLetter } from '../helpers';
import { SpaceTypes } from '../../features/dashboard/types';

const amplitudeSegmentSeparator = ' ';

const client = (() => {
  const key = process.env.REACT_APP_AMPLITUDE_KEY;
  if (key == null) {
    return;
  }

  const instance = amplitude.getInstance();
  instance.init(key);
  return instance;
})();

export const useAmplitude = () => {
  const userTelemetry = useRecoilValue(userTelemetrySelector);

  useEffectWithClient(
    (client) => {
      if (userTelemetry == null) {
        client.clearUserProperties();
        return;
      }

      client.setUserId(userTelemetry.id);
      client.setUserProperties(buildUserProperties(userTelemetry));
    },
    [userTelemetry],
  );

  useEffectWithClient((client) => {
    const trackerUnsubscribe = activityTracker.subscribe(
      (event: TrackingEvent) => {
        // amplitude processes events on analytics channel
        if (!isEventOnChannel(event, EventChannel.Analytics)) {
          return;
        }

        client.logEventWithTimestamp(
          buildEventName(event.type, amplitudeSegmentSeparator),
          event.metadata,
          event.timestamp.valueOf(),
        );
      },
    );

    return trackerUnsubscribe;
  }, []);
};

const useEffectWithClient = (
  effect: (client: AmplitudeClient) => void,
  deps?: DependencyList,
) =>
  useEffect(() => {
    if (client == null) {
      return;
    }

    effect(client);
  }, deps);

const buildUserProperties = (telemetry: UserTelemetry) => ({
  email: telemetry.email,
  'plan name': capitalizeFirstLetter(telemetry.userPlan),
  'using space':
    telemetry.currentSpaceType === SpaceTypes.InstagramGrid ? 'Grid' : 'Story',
  'grid spaces': telemetry.currentAccountSpaceIds.grid,
  'story spaces': telemetry.currentAccountSpaceIds.storyboard,
});
